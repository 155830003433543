import React from "react";
import { NotificationManager } from "react-notifications";
import ReactTooltip from "react-tooltip";
import ColorPicker from "../../picker/feedbackcolorpicker";


export class PoolEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: '',
            pool_description: '',
            pool_option: [],
            pool_status: '1',
            options: 2,
            custom_color_checked: false,
            poll_custom_color: '',
            pool_option_dummy: ['First Option', 'Second Option', 'Third Option', 'Forth Option'],
            poll_custom_color_text: '',
            poll_custom_color_bg: '',
            is_enable_email: '',

        };
        this.handleChange = this.handleChange.bind(this);
        this.updatePool = this.updatePool.bind(this);
        this.addOptionRow = this.addOptionRow.bind(this);
        this.removeOptionRow = this.removeOptionRow.bind(this);
        this.pollCustomColor = this.pollCustomColor.bind(this);
        this.customColorChecked = this.customColorChecked.bind(this);
        this.pollCustomColorText = this.pollCustomColorText.bind(this);
        this.isEnableEmail = this.isEnableEmail.bind(this);
    }
    componentDidMount() {
        this.getPool();
    }
    isEnableEmail(event) {
        if (event.target.checked == true) {
            this.setState({
                is_enable_email: true,
            });
        } else {
            this.setState({
                is_enable_email: false,
            });
        }
    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name == "pool_option") {
            var dataid = event.target.getAttribute("data-id");
            let poolPtion = this.state.pool_option
            poolPtion[dataid] = event.target.value;
            this.setState({ pool_option: poolPtion });
        }
    }

    getPool() {
        if (this.props != null && this.props.match != null && this.props.match.params != null && this.props.match.params.id != null) {
            let pool_id = this.props.match.params.id;
            var dataurl = this.props.basepath + "poll/edit/" + pool_id;
            var self = this;
            fetch(dataurl, {
                method: "get",
                credentials: "include",
            }).then(function (response) {
                    return response.json();
            }).then(function (response) {
                if (response && response.status==200 && response.data && response.data.length) {
                    let getPollCustomColor = JSON.parse(response.data[0].poll_custom_color);
                    self.setState({
                        data: response.data[0],
                        custom_color_checked: getPollCustomColor!= null && (getPollCustomColor.bg_color != '' || getPollCustomColor.text_color != '') ? true : false,
                        poll_custom_color: response.data[0].poll_custom_color,
                        pool_description: response.data[0].pool_description,
                        pool_option: JSON.parse(response.data[0].pool_option),
                        pool_create_date: response.data[0].pool_create_date,
                        pool_status: response.data[0].pool_status,
                        is_enable_email: response.data[0].is_enable_email,
                        options: (JSON.parse(response.data[0].pool_option)).length,
                        poll_custom_color_bg: getPollCustomColor ? getPollCustomColor['bg_color'] : '#8144e5',
                        poll_custom_color_text: getPollCustomColor ? getPollCustomColor['text_color'] : '#ffffff'
                    });
                }
                else{
                    NotificationManager.error("Server error: ",response.message);
                }
            }).catch(err=>{
                NotificationManager.error("Server error: " , err.message)
            });
        }
    }
    pollCustomColor(color) {
        this.setState({
            poll_custom_color_bg: color.hex,
        });
    }
    pollCustomColorText(color) {
        this.setState({
            poll_custom_color_text: color.hex
        });
    }
    customColorChecked(event) {
        if (event.target.checked == true) {
            this.setState({
                custom_color_checked: true,
                poll_custom_color: this.state.data.poll_custom_color ? this.state.data.poll_custom_color : ''
            });
        } else {
            this.setState({
                custom_color_checked: false,
                poll_custom_color: ''
            });
        }
    }
    updatePool() {
        if (this.state.pool_description == '') {
            NotificationManager.error("Validation Error", "Description field is required.");
            return false;
        }
        if (this.state.pool_option == '') {
            NotificationManager.error("Validation Error", "Poll positive option is required.");
            return false;
        }
        var data = {};
        let customColor = {
            bg_color: this.state.poll_custom_color_bg,
            text_color: this.state.poll_custom_color_text,
        }

        data["pool_description"] = this.state.pool_description;
        data["poll_custom_color"] = JSON.stringify(customColor);
        data["pool_status"] = this.state.pool_status;
        data["is_enable_email"] = this.state.is_enable_email;
        data["pool_option"] = JSON.stringify(this.state.pool_option);
        var jsondata = JSON.stringify(data);
        var postdata = "userid=" + this.props.user.userid + "&data=" + jsondata;

        var queryurl = this.props.basepath + "poll/update/" + this.state.data.id;
        var self = this;
        fetch(queryurl, {
            method: "post",
            credentials: "include",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: postdata,
        }).then(function (data) {
            return data.json();
        }).then(function (response) {
            if (response && response.status==200 && response.data) {
                self.props.history.push("/app/poll/share/" + response.data.pool_id + '/' + self.state.data.slug);
            } else {
                NotificationManager.error("Server error: ", response.message);
                return false;
            }
        }).catch(err=>{
            NotificationManager.error("Server error: " , err.message)
        });
    }
    handleSubmit(e) {
        e.preventDefault();
    }
    addOptionRow() {
        if (this.state.options <= 3) {
            this.setState(({ options }) => ({ options: options + 1 }));
        } else {
            NotificationManager.info("Info", "Max 4 option allow.");
            return false;
        }
    };
    removeOptionRow() {
        if (this.state.options > 2) {
            let poolOption = this.state.pool_option;
            poolOption = poolOption.splice(1)
            this.setState(({ options }) => ({
                pool_option: poolOption,
                options: options - 1,
            }));
        }
    }

    render() {
        return (
            <div>
                {this.state.pool_option.length ? (
                    <div className="poll-create-page">
                        <div className="col-md-6 col-xs-12">
                            <form onSubmit={this.handleSubmit.bind(this)}>
                                <div className="qzpanel-default" style={{ width: "100%" }}>
                                    <div
                                        className="qzpanel-body"
                                        style={{ minHeight: "100%", height: "100%" }}
                                    >
                                        <div className="frmqzsettings">
                                            <div className="form-group">
                                                <label className="Sign-In-Label">Poll Description</label>
                                                <textarea
                                                    style={{ height: "100px" }}
                                                    name="pool_description"
                                                    value={this.state.pool_description}
                                                    onChange={this.handleChange}
                                                    className="Sign-In-TextBox"
                                                    maxLength="250"
                                                    placeholder="Poll Description"
                                                ></textarea>
                                                <div className="counter-wrapper">
                                                    <span>{this.state.pool_description.length}/250</span>
                                                </div>
                                            </div>
                                            {Array.from({ length: this.state.options }, (_, index) => (
                                                <div className="form-group" key={index}>
                                                    <label className="Sign-In-Label">Option Label</label>
                                                    <input
                                                        name="pool_option"
                                                        className="Sign-In-TextBox"
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        value={this.state.pool_option[index] ? this.state.pool_option[index] : ''}
                                                        data-id={index}
                                                        maxLength="100"
                                                        placeholder="Please enter poll option"
                                                    />
                                                    <div className="counter-wrapper-option">
                                                        <span>{this.state.pool_option[index] ? this.state.pool_option[index].length : 0}/100</span>
                                                    </div>
                                                </div>
                                            ))}
                                            {this.state.data.total_vote == 0 ? (
                                                <div>
                                                    <button onClick={this.addOptionRow} className="option-add"><i className="fa fa-plus fa-2x"></i></button>
                                                    {this.state.options > 2 ?
                                                        <button onClick={this.removeOptionRow} className="option-remove"><i className="fa fa-minus fa-2x"></i></button>
                                                        : ''}
                                                </div>
                                            ) : ''}


                                            <div className="form-group">
                                                <label className="Sign-In-Label"> Poll Status</label>
                                                <br></br>
                                                <select
                                                    value={this.state.pool_status}
                                                    className="custom-select form-control"
                                                    onChange={this.handleChange}
                                                    name="pool_status"
                                                >
                                                    <option value="1">Published</option>
                                                    <option value="0">Un Publish</option>
                                                </select>
                                            </div>
                                            <div className="form-group poll-custom-color-wrapper">
                                                <label className="Sign-In-Label"> Custom Color</label>
                                                <label className="switch poll-custom-color-switch">
                                                    <input
                                                        type="checkbox"
                                                        defaultChecked={this.state.custom_color_checked}
                                                        onClick={this.customColorChecked}
                                                        id="custom_color"
                                                        name="custom_color"
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                                <br></br>
                                                {this.state.custom_color_checked ? (
                                                    <div className="poll-custom-color">
                                                        <ul className="poll-custom-color-ul">
                                                            <li className="poll-custom-color-li-one"><span>Background</span>
                                                                <ColorPicker
                                                                    custom_theme_color={this.state.poll_custom_color_bg ? this.state.poll_custom_color_bg : '#8144e5'}
                                                                    onSelectChangeColor={this.pollCustomColor}
                                                                /></li>
                                                            <li className="poll-custom-color-li-two"><span>Text</span>
                                                                <ColorPicker
                                                                    custom_theme_color={this.state.poll_custom_color_text ? this.state.poll_custom_color_text : '#ffffff'}
                                                                    onSelectChangeColor={this.pollCustomColorText}
                                                                /></li>
                                                        </ul>
                                                    </div>


                                                ) : ''}
                                            </div>
                                            <label for="scales">Do you want to collect user emails before showing the result?</label>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={this.state.is_enable_email == true ? true : false}
                                                    onClick={this.isEnableEmail}
                                                    name="is_enable_email"
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                            <br></br>
                                            {this.state.data.total_vote == 0 ? (
                                                <button
                                                    style={{ width: "27%" }}
                                                    onClick={this.updatePool}
                                                    className="btn-normal new-button-response"
                                                >
                                                    Update
                                                </button>
                                            ) :
                                                <div>
                                                    <ReactTooltip />
                                                    <button
                                                        data-tip="Voting already started, You can not update poll."
                                                        style={{ width: "27%", background: "#808080" }}
                                                        className="btn-normal new-button-response poll-disable-btn"
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 col-xs-12 text-center">
                            <h3 className="wr-p-title">Poll Preview </h3>
                            <div className="alert alert-success alert-dismissible poll-preview">
                                <span className="pull-right fc">
                                    X
                                </span>
                                <p style={{ marginTop: 20, marginBottom: 10 }}>
                                    {this.state.pool_description ? this.state.pool_description : "Poll description"}
                                </p>
                                <ul className="list-group">
                                    {Array.from({ length: this.state.options }, (_, index) => (
                                        <li ref={(el) => {
                                            if (el) {
                                                el.style.setProperty(
                                                    "background-color",
                                                    this.state.poll_custom_color_bg ? this.state.poll_custom_color_bg : ''
                                                );
                                                el.style.setProperty(
                                                    "color",
                                                    this.state.poll_custom_color_text ? this.state.poll_custom_color_text : ''
                                                );
                                            }
                                        }} key={index} className="list-group-item list-group-item-info poll-previe-item-c">{this.state.pool_option[index] ? this.state.pool_option[index] : this.state.pool_option_dummy[index]}</li>
                                    ))}
                                </ul>
                                <h5 className="poll-c-p-total">Total Vote: 100</h5>
                            </div>
                        </div>
                    </div>
                ) : ''}
            </div>
        );
    }
}
export default PoolEdit;
