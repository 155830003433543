/**
 * This file contains the functionality to render quiz table
 */
import React, { Component } from 'react';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ImageUpload } from "../../components/imageupload";
import { NotificationManager } from "react-notifications";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

var defaultBackgroundImage = "";
ChartJS.register(ArcElement, Tooltip, Legend);
var piedata = {
    labels: [],
    datasets: [
        {
            label: ['label', 'label','label'],
            data: [1, 1, 1],
            color: 'white',
            backgroundColor: [
                "rgba(254,229,3,1)",
                "rgba(252,158,2,1)",
                "rgba(251,53,1,1)",
                "rgba(202,1,111,1)",
                "rgba(141,1,248,1)",
                "rgba(44,19,248,1)",
                "rgba(98,197,47,1)",
                "rgba(7,96,237,1)"
            ],
            borderColor: [
                "rgba(254,229,3,1)",
                "rgba(252,158,2,1)",
                "rgba(251,53,1,1)",
                "rgba(202,1,111,1)",
                "rgba(141,1,248,1)",
                "rgba(44,19,248,1)",
                "rgba(98,197,47,1)",
                "rgba(7,96,237,1)"
            ],
            borderWidth: 1,
        },

    ],
};

var options = {
    plugins: {
        datalabels: {
            backgroundColor: function (context) {
                return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 2,
            color: 'white',
            formatter: function (value, context) {
                var label1 = context.dataset.label[context.dataIndex];
                var label = context.chart.data.labels[context.dataIndex];
                return label1;
            },
            font: {
                weight: 'bold'
            },
            padding: 6,
            //formatter: Math.round
        }
    }
}


export class WheelSpinnerCreate extends Component {
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();

        this.state = {
            rotateDeg: '',
            wheel_spinner_description: "",
            options: 3,
            getRotateDegree: '',
            chartdata: piedata,
            wheelSpinnerOptions: [],
            wheelSpinnerOptionDefault: ['1', '2', '3', '4', '5', '6'],
            is_enable_email: true,
            rotationTimeReached: false,
            playbuttontype:'text',
            playbuttontext:'Spin',
            segColors: [
                '#3f51b5',
                '#ff9800',
                '#e91e63',
                '#4caf50',
                '#009688',
                '#795548',
                '#9c27b0',
                '#e91e63'
            ]
        };
        this.handleChange = this.handleChange.bind(this);
        this.addOptionRow = this.addOptionRow.bind(this);
        this.removeOptionRow = this.removeOptionRow.bind(this);
        this.wheelSpinnerControl = this.wheelSpinnerControl.bind(this);
        this.createWheelSpinner = this.createWheelSpinner.bind(this);
        this.isEnableEmail = this.isEnableEmail.bind(this);
        this.onRadioChanged = this.onRadioChanged.bind(this);
        this.onChangeBackgroundImage = this.onChangeBackgroundImage.bind(this);
        this.onDeleteBackgroundImage = this.onDeleteBackgroundImage.bind(this);
        this.onPlayButtonTextChanged = this.onPlayButtonTextChanged.bind(this);
    }
    handleChange(event) {
        //this.setState({ [event.target.name]: event.target.value });
        if (event.target.name == "wheel_spinner_description") {
            this.setState({
                wheel_spinner_description: event.target.value
            })
        }
        if (event.target.name == "wheel_spinner_options") {
            this.setState({
                wheelSpinnerOptions: []
            })
            var dataid = event.target.getAttribute("data-id");
            let wheelSpinnerOptions = this.state.wheelSpinnerOptions;
            let chartdata = this.state.chartdata;

            wheelSpinnerOptions[dataid] = event.target.value;


            chartdata.datasets[0].data = [];
            chartdata.datasets[0].label = [];

            wheelSpinnerOptions.forEach(function (value, index) {
                chartdata.datasets[0].data[index] = 1;
                chartdata.datasets[0].label[index] = value;

            })

            //this.chartReference.current.chartIntstance.update();
            const chart = this.chartReference.current;
            chart.data.datasets[0].data = chartdata.datasets[0].data;
            chart.data.datasets[0].label = chartdata.datasets[0].label;

            chart.update();

            let getRotateDegree = (300 / this.state.wheelSpinnerOptions.length);
            this.setState({ [event.target.name]: event.target.value, wheelSpinnerOptions: wheelSpinnerOptions, getRotateDegree: getRotateDegree, chartdata });

        }
    }

    createWheelSpinner() {
        if (this.state.wheel_spinner_description == '') {
            NotificationManager.error("Validation Error", "Description is required.");
            return false;
        } else if (this.state.wheelSpinnerOptions.length < 3) {
            NotificationManager.error("Validation Error", "Min three option is required.");
            return false;
        }
        var data = {};
        data["description"] = this.state.wheel_spinner_description;
        data["is_enable_email"] = this.state.is_enable_email;
        data["options"] = JSON.stringify(this.state.wheelSpinnerOptions);
        data["status"] = this.state.wheel_spinner_status ? this.state.wheel_spinner_status : 1;
        data["playbuttontype"] = this.state.playbuttontype;
        data["playbuttontext"] = this.state.playbuttontext;
        data["playbuttonimg"] = this.state.playbuttonimg;
        
        
        var jsondata = JSON.stringify(data);
        var postdata = "userid=" + this.props.user.userid + "&data=" + jsondata;
        var queryurl = this.props.basepath + "wheelspin/create";
        var self = this;
        fetch(queryurl, {method: "post",credentials: "include",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: postdata,
        }).then(function (data) {
                return data.json();
        }).then(function (response) {
            if (response && response.status==200 && response.data) {
                self.props.history.push('/app/wheel_spinner/lists');
            }
            else{
                NotificationManager.error("Server error: ", response.message);
            }
        }).catch(err=>{
            NotificationManager.error("Server error: " , err.message)
        });
    }

    addOptionRow() {
        var {options} = this.state;
        if (options <= 7) {
            let chartdata = this.state.chartdata;
            chartdata.datasets[0].label.push("label");
            chartdata.datasets[0].data.push(1);

            const chart = this.chartReference.current;
            chart.data.datasets[0].data = chartdata.datasets[0].data;
            chart.data.datasets[0].label = chartdata.datasets[0].label;
            chart.update();
            options = options + 1;
            this.setState({chartdata, options });
            
            //this.setState(({ options }) => ({ options: options + 1 }));
            
        } else {
            NotificationManager.info("Info", "Max 8 option allow.");
            return false;
        }
    }

    removeOptionRow() {
        let options = this.state.options;
        if (options > 1) {
            let wheelSpinnerOptions = this.state.wheelSpinnerOptions;
            let chartdata = this.state.chartdata;


            chartdata.datasets[0].label.pop();
            chartdata.datasets[0].data.pop();


            const chart = this.chartReference.current;
            chart.data.datasets[0].data = chartdata.datasets[0].data;
            chart.data.datasets[0].label = chartdata.datasets[0].label;

            chart.update();
            options = options - 1;
            this.setState({ wheelSpinnerOptions, chartdata, options });

        }
    }
    handleSubmit(e) {
        e.preventDefault();
    }

    isEnableEmail(event) {
        if (event.target.checked == true) {
            this.setState({
                is_enable_email: true,
            });
        } else {
            this.setState({
                is_enable_email: false,
            });
        }
    }

    wheelSpinnerControl() {
        this.setState({ rotateDeg: 0, rotationTimeReached: false }, () => {

            let getCurrentWheelOptions = this.state.wheelSpinnerOptions.length != 0 ? this.state.wheelSpinnerOptions : this.state.wheelSpinnerOptionDefault;
            let numOptions = getCurrentWheelOptions.length;
            let getRandomNumber = Math.floor(Math.random() * (numOptions - 1) + 1);
            let getSingleAngle = (360 / getCurrentWheelOptions.length)
            // numOption * 5 is just for speed increase or descrease
            // getSingleAlngle is number of item added in circle 
            // (getSingleAngle * getRandomNumber) this means just subtract getRandomNumber index from circle
            let totalRotation = (getSingleAngle * (numOptions * (numOptions * 5))) - (getSingleAngle * getRandomNumber)

            var getResult = getCurrentWheelOptions.find(function (element, index) {
                return ((index + 1) == getRandomNumber);
            });
            this.setState({
                rotateDeg: (totalRotation + (getSingleAngle * 0.5)),
                winnerItem: getResult,
            });
        })
        setTimeout(() => {
            this.setState({
                rotationTimeReached: true
            })
        }, 5000);
    }

    onRadioChanged(e) {
        var {playbuttontype} = this.state;
        playbuttontype = e.target.value;
        this.setState({ playbuttontype });
    }

    onPlayButtonTextChanged(e) {
        var {playbuttontext} = this.state;
        playbuttontext = e.target.value;
        this.setState({ playbuttontext });
    }

    onChangeBackgroundImage(imgtype, imgid, imgpath) {
        var {playbuttonimg} = this.state;
        playbuttonimg = imgpath;
        this.setState({playbuttonimg }, () => {
          NotificationManager.success(
            "Image Upload",
            "Image uploaded successfully"
          );
          //this.createWheelSpinner();
        });
    }

    onDeleteBackgroundImage(imgtype, imgid, imgpath) {
        var {playbuttonimg} = this.state;
        // tempquizobj.backgroundimg = imgpath;
        playbuttonimg = defaultBackgroundImage;
        this.setState({ playbuttonimg }, () => {
            NotificationManager.success("Image Delete", "Image deleted successfully");
            //this.createWheelSpinner();
        });
    }

    componentDidMount() {
        let getCurrentWheelOptions = this.state.wheelSpinnerOptions.length != 0 ? this.state.wheelSpinnerOptions.length : this.state.wheelSpinnerOptionDefault.length;
        let getRotateDegree = (360 / getCurrentWheelOptions);
        this.setState({getRotateDegree});
        
    }

    

    render() {
        return (
            <div>
                <div className="wheel-spinner-create">
                    <div className="col-md-6 col-xs-12">
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            <div className="qzpanel-default" style={{ width: "100%" }}>
                                <div
                                    className="qzpanel-body"
                                    style={{ minHeight: "100%", height: "100%" }}
                                >
                                    <div className="frmqzsettings">
                                        <div className="form-group">
                                            <label className="Sign-In-Label">
                                                Wheel Spinner Description
                                            </label>
                                            <textarea
                                                style={{ height: "100px" }}
                                                name="wheel_spinner_description"
                                                onChange={this.handleChange}
                                                className="Sign-In-TextBox"
                                                maxLength="250"
                                                placeholder="Wheel spinner description"
                                            ></textarea>
                                            <div className="counter-wrapper">
                                                <span>
                                                    {this.state.wheel_spinner_description.length}/250
                                                </span>
                                            </div>

                                            <label className='form-group'>
                                                Customize Play Button: 
                                            </label>
                                            <input type="radio" name="playbuttontype" value="text" checked={this.state.playbuttontype=='text'} onChange={this.onRadioChanged} /><span style={{ marginLeft: 5 }}> Use Text </span>  
                                            <input type="radio" name="playbuttontype" value="image" checked={this.state.playbuttontype=='image'} onChange={this.onRadioChanged}/><span style={{ marginLeft: 5 }}> Use Image</span>  
                                            <br />
                                            {this.state.playbuttontype=='text'?<><input className="Sign-In-TextBox" type="text" placeholder="Enter the text for playbutton" onChange={this.onPlayButtonTextChanged} value={this.state.playbuttontext} maxLength="15"/>
                                            <div className="counter-wrapper">
                                                <span>
                                                    {this.state.playbuttontext.length}/15
                                                </span>
                                            </div></>
                                            
                                            :<ImageUpload
                                            basepath={this.props.basepath}
                                            userid={this.props.user.userid}
                                            quizid={
                                              this.state.quiz != null ? this.state.quiz.quizid : null
                                            }
                                            imgtype="playbuttonimg"
                                            title="Upload Play Button Image"
                                            uploaded_uri=""
                                            imagePreviewFlag={true}
                                            info_text="Preferred image size 1920x1075"
                                            imagepath={this.state.playbuttonimg}
                                            onImageUploadComplete={this.onChangeBackgroundImage}
                                            onImageDeleteComplete={this.onDeleteBackgroundImage}
                                          />}
                                        </div>

                                        <div className="form-group">
                                            <label className="Sign-In-Label">Option Label</label>
                                            {Array.from(
                                                { length: this.state.options },
                                                (_, index) => (
                                                    <>
                                                        <input
                                                            key={index}
                                                            name="wheel_spinner_options"
                                                            className="Sign-In-TextBox"
                                                            onChange={this.handleChange}
                                                            type="text"
                                                            maxLength="50"
                                                            data-id={index}
                                                            placeholder="Please enter option"
                                                        />
                                                        <div className="counter-wrapper-option">
                                                            <span>
                                                                {this.state.wheelSpinnerOptions[index]
                                                                    ? this.state.wheelSpinnerOptions[index]
                                                                        .length
                                                                    : 0}
                                                                /50
                                                            </span>
                                                        </div>
                                                    </>
                                                )
                                            )}
                                        </div>
                                        {this.state.options < 8 ? (
                                            <button onClick={this.addOptionRow} className="option-add">
                                                <i className="fa fa-plus fa-2x"></i>
                                            </button>
                                        ) : ''}
                                        {this.state.options > 3 ? (
                                            <button
                                                onClick={this.removeOptionRow}
                                                className="option-remove"
                                            >
                                                <i className="fa fa-minus fa-2x"></i>
                                            </button>
                                        ) : (
                                            ""
                                        )}

                                        <div className="form-group">
                                            <label className="Sign-In-Label"> Status</label>
                                            <br></br>
                                            <select
                                                className="custom-select form-control"
                                                onChange={this.handleChange}
                                                name="wheel_spinner_status"
                                            >
                                                <option value="1" defaultValue="1">
                                                    Published
                                                </option>
                                                <option value="0" defaultValue="0">
                                                    Un Publish
                                                </option>
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label for="scales">Do you want to collect user email ?</label>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={this.state.is_enable_email}
                                                    checked={this.state.is_enable_email}
                                                    onClick={this.isEnableEmail}
                                                    name="is_enable_email"
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <button
                                            style={{ width: "27%" }}
                                            onClick={this.createWheelSpinner}
                                            className="btn-normal new-button-response"
                                        >
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 col-xs-12 text-center">
                        <h3 className="wr-p-title">Wheel Spinner Preview</h3>
                        <span className="pull-right fc">X</span>
                        <p style={{ marginTop: 20, marginBottom: 10 }}>
                            {this.state.wheel_spinner_description
                                ? this.state.wheel_spinner_description
                                : "Wheel Spinner Description"}
                        </p>

                        <div className="wheel-spin-indicator">&#9660;</div>
                        <div className="wheel_spinner_main">
                            {this.state.playbuttontype=="text"?<button onClick={this.wheelSpinnerControl} id="spin">{this.state.playbuttontext}</button>:
                            <img src={this.state.playbuttonimg} id="spin" onClick={this.wheelSpinnerControl} />}

                            <div className="wheel_spinner_inner" style={{ transform: "rotate(" + this.state.rotateDeg + "deg)" }}>
                                <Pie style={{transform:'translateY(-4px)'}} ref={this.chartReference} data={this.state.chartdata} width={300} plugins={[ChartDataLabels]} height={300} options={options} />

                            </div>

                        </div>
                        {this.state.rotationTimeReached ? (
                            <h5>{this.state.winnerItem}</h5>
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }
}
export default WheelSpinnerCreate;
