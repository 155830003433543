import React, { Component } from "react";

class Questions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQuestionIndex: 0,
      currentQuestion: props.questions[0],
      selectedAnswer: [],
    };
  }

  handleAnswerClick = (answer) => {
    // Move to the next question
    const { currentQuestionIndex, selectedAnswer } = this.state;
    const { questions, onAllQuestionsSolved } = this.props;

    let getQuestion = questions[currentQuestionIndex];
    //why adding tile line because when anser string has "" it will create problem on backend to parse
    let ansModify = decodeURIComponent(answer);
    let filterans = ansModify.replace(/"/g, "'");
    //why adding tile line because when anser string has "" it will create problem on backend to parse
    let result = {
      questionId: getQuestion.id,
      answer: answer,
    };
    const updatedSelectedAnswer = [...this.state.selectedAnswer, result];

    if (currentQuestionIndex < questions.length - 1) {
      this.setState((prevState) => ({
        currentQuestionIndex: prevState.currentQuestionIndex + 1,
        currentQuestion: questions[prevState.currentQuestionIndex + 1],
        selectedAnswer: updatedSelectedAnswer,
      }));
    } else {
      // when all question clicked send status to parent
      onAllQuestionsSolved(updatedSelectedAnswer);
      this.props.getCurrentQuestionIndex(0);
    }
    this.props.getCurrentQuestionIndex(currentQuestionIndex);
  };

  render() {
    const { currentQuestion, selectedAnswer } = this.state;
    const { theme_colors, welcome_message_color, selectFont } = this.props;
    return (
      <div
        className="quiz_wrap__content text-center"
        style={{
          background: theme_colors ? theme_colors.header_content_bg : "",
          color: theme_colors ? theme_colors.header_content_text : "",
        }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="quiz_wrap__content___text ql-editor">
              <p
                className={selectFont ? selectFont : ""}
                style={{
                  color: welcome_message_color ? welcome_message_color : "",
                }}
                dangerouslySetInnerHTML={{ __html: currentQuestion.title }}
              ></p>
              {/* Quiz image */}
              {currentQuestion.queimg ? (
                <div className="quiz_wrap__content__img my-4">
                  <img
                    className="img-fluid"
                    src={currentQuestion.queimg}
                    alt=""
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* quiz question end  */}
        <div className="row">
          <div className="col-lg-12">
            <div className="row d-f-j-c">
              {/* Quiz options */}
              {JSON.parse(currentQuestion.jsonans).map((answer, index) => (
                <>
                  {answer && (
                    <div className="col-lg-6">
                      <div className="quiz_wrap__btn mb-4">
                        <div
                          className="quiz_ans__btn p-2 ql-editor"
                          onClick={() => {
                            this.setState({ selectedAnswer: answer });
                            this.handleAnswerClick(answer);
                          }}
                        >
                          <button
                            type="button"
                            className="p-0 mx-auto"
                            style={{
                              background: theme_colors.button_color
                                ? theme_colors.button_color
                                : welcome_message_color,
                              color: theme_colors
                                ? theme_colors.button_color_text
                                : "",
                            }}
                          >
                            {JSON.parse(currentQuestion.jsonansimg)[index] && (
                              <img
                                className="quiz_ans__img img-fluid"
                                src={
                                  JSON.parse(currentQuestion.jsonansimg)[index]
                                }
                                alt=""
                              />
                            )}
                            <div
                              className="quiz_btn__ans__text"
                              style={{
                                background: theme_colors.button_color
                                  ? theme_colors.button_color
                                  : welcome_message_color,
                                color: theme_colors
                                  ? theme_colors.button_color_text
                                  : "",
                              }}
                            >
                              <p className={selectFont ? selectFont : ""}>
                                {decodeURIComponent(answer.replace(/"/g, "'"))}
                              </p>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Questions;
