/**
 * This file contains the code for Data Card.
 * This is shown as part of the app dashboard.
 */
import React from 'react';
export class DataCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {cardItems: this.props.cardItems};
    }

    renderCards() 
    
    {
        return this.state.cardItems.map(function (cardItem) {
            return (
                <div className="col-md-3 col-sm-4 col-xs-12" key={cardItem.title}>
                    <div>
                        <div className="panel panel-blur animated">
                            <div className="panel-body">
                                <div className="">
                                    <div className="col-md-3 col-sm-3 col-xs-3" data-percent="60" style={{paddingLeft:0}}>
                                        <i className={`fa fa-${cardItem.icon} fa-${cardItem.iconsize}x`}></i>
                                    </div>
                                    <div className="col-md-9 col-sm-9 col-xs-9 text-center">
                                        <div className="cardtitle" style={{fontSize:20}}>{cardItem.title}</div>
                                        <div className="cardDescription m-t-sm">{(cardItem.stats==null || cardItem.stats=="")?0:cardItem.stats}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }, this);
    }

    rand(min, max, num) {
        var rtn = [];
        while (rtn.length < num) {
            rtn.push((Math.random() * (max - min)) + min);
        }
        return rtn;
    }

    render() {
        return (
            <div className="row">
                {this.renderCards()}
            </div>
        );
    }
}
