import React, { Component } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

class SocialSharing extends Component {
  render() {
    const shareUrl = window.location.href;
    const { enable_social_sharing_text, title,welcome_message_color } = this.props;
    return (
      <>
        <div className="col-md-12  social-sharing">
          <p style={{color:welcome_message_color?welcome_message_color:''}}>
            {" "}
            {enable_social_sharing_text
              ? enable_social_sharing_text
              : "Share it with your family, friends and colleagues"}
          </p>
          <div className="social-sharing-icons">
            <FacebookShareButton url={shareUrl} quote={title}>
              <FacebookIcon size={"shareBtnSize"} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} quote={title}>
              <TwitterIcon size={"shareBtnSize"} round />
            </TwitterShareButton>
            <LinkedinShareButton
              url={shareUrl}
              quote={"title"}
              windowWidth={750}
              windowHeight={600}
            >
              <LinkedinIcon size={"shareBtnSize"} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareUrl} quote={title} seperator="::">
              <WhatsappIcon size={"shareBtnSize"} round />
            </WhatsappShareButton>
            <EmailShareButton url={shareUrl} subject={title} body="email body">
              <EmailIcon size={"shareBtnSize"} round />
            </EmailShareButton>
          </div>
        </div>
      </>
    );
  }
}
export default SocialSharing;
