import React from "react";
import { MDBDataTable } from 'mdbreact';
import ReactExport from "react-data-export";
import { NotificationManager } from "react-notifications";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class PollResut extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            poolVotes: [],
            pollTitle: '',
            totalVoteCount: ''
        }
    }
    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                pollTitle: this.props.location.state[0],
                totalVoteCount: this.props.location.state[4]
            })
        }
        this.getPollResult();
    }

    getPollResult() {
        if (this.props != null && this.props.match != null && this.props.match.params != null && this.props.match.params.id != null) {
          let pool_id = this.props.match.params.id;
          var dataurl = this.props.basepath + "poll/result/list/" + pool_id;
          var self = this;
          fetch(dataurl, {
              method: "get",
              credentials: "include"
          }).then(function (response) {
              return response.json();
          }).then(function (response) {
            if (response && response.status==200 && response.data && response.data.length>0) {
              var tempData = [];
              response.data.map(function(item,index){
                tempData[index] = {
                  id:index + 1,
                  poll_vote: item.pool_vote,
                  pool_voter_email: item.pool_voter_email,
                  vote_date:item.vote_date,
                }
              })
                self.setState({
                    poolVotes: tempData
                })
            } else {
              NotificationManager.error("Server error: ", response.message);
            }
          }).catch(err=>{
            NotificationManager.error("Server error: " , err.message)
          });
        }
      }
        dataTablePollVote(){
          const data = {
            columns: [
              {
                label: 'Id',
                field: 'name',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Poll Vote',
                field: 'poll_vote',
                sort: 'asc',
                width: 270
              },
              {
                label: 'Voter Email',
                field: 'pool_voter_email',
                sort: 'asc',
                width: 200
              },
              {
                label: 'Vote Date',
                field: 'vote_date',
                sort: 'asc',
                width: 100
              }
            ],
            rows: this.state.poolVotes
          };
        
          return (
            <MDBDataTable
              striped
              bordered
              hover
              data={data}
            />
          );
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-9">
                                <h3>Poll Title: {this.state.pollTitle}</h3>
                                <h3>Total Vote: {this.state.poolVotes ? this.state.poolVotes.length : ''}</h3>
                            </div>
                            <div className="col-md-3 export-data-poll">
                                <ExcelFile element={<button className="btn btn-primary">Export Data</button>}>
                                    <ExcelSheet data={this.state.poolVotes} name={this.state.pollTitle}>
                                        <ExcelColumn width="500" label="Voter Email" value="pool_voter_email" />
                                        <ExcelColumn label="Vote" value="poll_vote" />
                                        <ExcelColumn label="Date of Vote" value="vote_date" />
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                        </div>
                        <div className="tableFixHead pool-data-list">
                        {this.dataTablePollVote()}
                        </div>
                </div>
            </div>
        );
    }
}

export default PollResut;
