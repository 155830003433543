/**
 * This file contains the functionlity of rendering table for user's invoices.
 */
import React, { useCallback } from 'react';
import {tableItemsArr} from '../global';
import { NotificationManager } from 'react-notifications';
export class InvoiceTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tableItems: null,
            tableTitle: 'Invoices',
            actionFlag:false,
            user:this.props.user,
            menuID : null,
          
           
        };
        //this.onHover = this.onHover.bind(this);
        this.getInvoices = this.getInvoices.bind(this);

        this.getInvoices();
    }
    
    getInvoices()
    {
        //var dataurl = this.props.basepath + "index.php/payment/getinvoices/"+this.state.user.userid;
        var dataurl = this.props.basepath + "payment/getinvoices/"+this.state.user.userid;
        var self = this;
        fetch(dataurl,{method:'get',credentials:'include'           
        }).then(function(response){
            return response.json();
        }).then(function(response){
            if(response && response.status==200 && response.data){
                var tempArr = self.state.tableItems;
                if(tempArr == null)
                {
                    tempArr={};
                }
                tempArr.rowdata = [];
                response.data.map(function(item,index)
                {
                    var arrItem = [item['invoiceid'], item['txndate'],item['invoiceid'] ,item['txnid'],item['amount'],item['status']];
                    tempArr.rowdata[index] = [];
                    tempArr.rowdata[index]  = arrItem;                      
                },self);

                tempArr.header = [];
                tempArr.header[0] =['Invoice ID','Date','Invoice ID','Transaction ID','Total Amount','Status'];
                self.setState({tableItems:tempArr});
            
            }else{
                NotificationManager.error("Server error: "+ response.message);
            }
        }).catch(err=>{
            NotificationManager.error("Server error: " , err.message)
          });
    }
    // onHover(event) 
    // {
    //     var menuid = event.target.getAttribute('data-id');
    //     var actionflag = this.state.actionFlag;
    //     this.setState({ menuID:menuid});        
    // }

    

        renderHeaders() 
        {
            if(this.state.tableItems == null || this.state.tableItems.header == null || this.state.tableItems.header.length == 0)
            {
                return null;
            }
            else
            {
                return this.state.tableItems.header.map(function (headerItem,index) {
                return (
                    <thead className="heading" key={index}>
                    <tr className="black-muted-bg sortable">
                        <th className="text-center hide ">{headerItem[0]}</th>
                        <th className="col-md-2 text-left">{headerItem[1]}</th>
                        <th className="col-md-2 text-center">{headerItem[2]}</th>
                        <th className="col-md-2 text-center">{headerItem[3]}</th>
                        <th className="col-md-2 text-center">{headerItem[4]}</th>
                        <th className="col-md-2 text-center">{headerItem[5]}</th>
                        
                    </tr>
                    </thead>
                );
            }, this);
        }


    }


    renderRows() 
    {
        if(this.state.tableItems == null || this.state.tableItems.rowdata == null || this.state.tableItems.rowdata.length == 0)
        {
            return <tr className="no-top-border">
                        <td>There are no records to show!!</td>
                    </tr>
        }

        return this.state.tableItems.rowdata.map(function (rowItem, index) 
        {
            return (

                <tr className="no-top-border new_table_block" key={index}>
                    <td className='hide'>{rowItem[0]}</td>
                    <td className='col-md-2 text-left'>{rowItem[1]}</td>
                    <td className='col-md-2 text-center'>{rowItem[2]}</td>
                    <td className='col-md-2 text-center'>{rowItem[3]}</td>
                    <td className='col-md-2 text-center'>$ {rowItem[4]}</td>
                    <td className='col-md-2 text-center'>{rowItem[5]}</td>
                </tr>
            );
        }, this)


    }

    render() 
    {
        return ( <div style={{marginTop:2+'%'}}>
                    <div className="col-md-12">
                        <div className="table-title">Invoice List 
                            <span className="table-subtitle">{(this.state.tableItems != null && this.state.tableItems.rowdata != null && this.state.tableItems.rowdata.length > 0 )?(this.state.tableItems.rowdata.length + ' Total') : '0 records'}</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableFixHead">
                            <table className="table table-striped">
                                {this.renderHeaders()}
                                <tbody>
                                {this.renderRows()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
        
        
        
            );
    }
}
