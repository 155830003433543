import React, { Component } from "react";
import ScheduleRetakeBtn from "./ScheduleRetakeBtn";
import SocialSharing from "./SocialSharing";

class GradedResult extends Component {
  render() {
    const {
      theme_colors,
      restartQuiz,
      is_enable_social_media,
      enable_social_sharing_text,
      title,
      welcome_message_color,
      selectFont,
    } = this.props;

    return (
      <>
        <div className="col-12">
          <div
            className="quiz_wrap__content text-center"
            style={{
              background: theme_colors ? theme_colors.header_content_bg : "",
              color: theme_colors.header_content_text ? theme_colors.header_content_text : ''
            }}
          >
            <div className="quiz_wrap__content___text ql-editor text-center">
              {!this.props.result_page_custom_text ? (
                <p
                  className={selectFont ? selectFont : ""}
                  style={{
                    color: welcome_message_color ? welcome_message_color : "",
                  }}
                >
                  This certificate is proudly presented on completion of the
                  quiz
                </p>
              ) : (
                ""
              )}

              {!this.props.result_page_custom_text ? (
                <>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: this.props.quiztitle,
                    }}
                  ></h3>

                  <div className="my-3">
                    <h4
                      className={selectFont ? selectFont : ""}
                      style={{
                        color: welcome_message_color
                          ? welcome_message_color
                          : "",
                      }}
                    >
                      To
                    </h4>
                  </div>
                </>
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.result_page_custom_text,
                    }}
                  ></div>
                </>
              )}
              <div className="mb-3">
                <h2
                  className={selectFont ? selectFont : ""}
                  style={{
                    color: welcome_message_color ? welcome_message_color : "",
                  }}
                >
                  {this.props.results.email}
                </h2>
              </div>
              {this.props.results.title ? (
                <h4
                  className={selectFont ? selectFont : ""}
                  style={{
                    color: welcome_message_color ? welcome_message_color : "",
                  }}
                >
                  {this.props.results.quizscoremsg}
                </h4>
              ) : (
                <h4
                  className={selectFont ? selectFont : ""}
                  style={{
                    color: welcome_message_color ? welcome_message_color : "",
                  }}
                >
                  {this.props.results.score}
                </h4>
              )}
            </div>
            <ScheduleRetakeBtn
              restartQuiz={restartQuiz}
              theme_colors={theme_colors}
              welcome_message_color={welcome_message_color}
              selectFont={selectFont}
            />
            {is_enable_social_media && (
              <SocialSharing
                enable_social_sharing_text={enable_social_sharing_text}
                title={title}
                welcome_message_color={welcome_message_color}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
export default GradedResult;
