import React from "react";

import * as DOMPurify from "dompurify";
import ThemeFirst from "../templates/theme_1";
import ThemeSecond from "../templates/theme_2";
import FirstThemePrev from "../templates/template-preview/theme1.jpg";
import SecondThemePrev from "../templates/template-preview/theme2.jpg";
import PFirstThemePrev from "../templates/template-preview/personality_theme_preview_1.jpg";
import PSecondThemePrev from "../templates/template-preview/personality_theme_preview_2.jpg";
import { quizTypeArr } from "../global";
import { adminusers } from "../global";
import { NotificationManager } from "react-notifications";

export class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz_title: this.props.quiz_title,
      content: this.props.content,
      header_logo: this.props.header_logo,
      user: this.props.user,
      quiz: this.props.quiz,
      themetype: null,
      quiztype: null,
      carouselactiveindex: 0,
      step: 1,
      templatefilter: "qztype1",
    };
    this.updateTheme = this.updateTheme.bind(this);
    this.saveAsTemplate = this.saveAsTemplate.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
    this.onSelectCarouselItem = this.onSelectCarouselItem.bind(this);
    this.previewTemplate = this.previewTemplate.bind(this);
    this.createQuizFromTemplate = this.createQuizFromTemplate.bind(this);
    this.onChangeTemplateFilter = this.onChangeTemplateFilter.bind(this);
  }

  componentDidMount() {
    this.getTemplates();
  }
  onSelectCarouselItem(selectedIndex, e) {
    this.setState({ carouselactiveindex: selectedIndex });
  }
  getTemplates() {
    var self = this;
    var queryurl = self.props.basepath + "template/gettemplate";
    fetch(queryurl, {
      method: "get",
      credentials: "include",
    }).then((response) => {
      return response.json();
    }).then((response) => {
      if (response && response.status == 200 && response.data) {
        self.setState({ templateArr: response.data });
      } else {
        NotificationManager.error("Server error: ", response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }
  updateTheme(event) {
    var themetype = event.target.getAttribute("data-id");
    var quiztype = event.target.getAttribute("data-quiztype");
    this.setState({ themetype, quiztype }, () => {
      var { themetype, quiztype } = this.state;

      if (themetype != null && quiztype != null) {
        var url = "Quiz/Create/?theme=" + themetype + "&quiztype=" + quiztype;
        this.props.history.push(url);
      }
    });
  }

  saveAsTemplate() {
    var tempids = this.state.templatequizid;
    if (tempids == null || tempids == "") {
      NotificationManager.error(
        "Validation Error",
        "Quiz ids cannot be null or empty "
      );
      return;
    }
    var self = this;
    var templatequizids = tempids.split(";");
    var jsondata = JSON.stringify(templatequizids);
    var postdata = "quizid=" + jsondata;
    var queryurl = self.props.basepath + "template/savetemplate";
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then((response) => {
      return response.json();
    }).then((response) => {
      if (response && response.status == 200 && response.data) {
        NotificationManager.success(
          "Template Create Success",
          "Template created successfully."
        );
      } else {
        NotificationManager.error("Server error: ", response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  onChangeField(event) {
    var value = event.target.value;
    var name = event.target.name;
    this.setState({ [name]: value });
  }

  onChangeTemplateFilter(event) {
    var templatefilter = event.target.getAttribute("datatype");
    var step = 2;
    this.setState({ templatefilter, step });
  }

  previewTemplate(event) {
    var templateid = event.target.getAttribute("dataid");
    var templatQuizTitle = event.target.getAttribute("datatitle");
    var templateQuizTitle = templatQuizTitle
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    // var url = this.props.localpath + "previewtemplate?quizid=" + templateid + "?title?&preview=true";
    var url =
      this.props.localpath +
      "previewtemplate?quiztitle=" +
      templateQuizTitle +
      "&quizid=" +
      templateid +
      "&preview=true";
    window.open(url);
  }

  createQuizFromTemplate(event) {
    var templateid = event.target.getAttribute("dataid");
    if (templateid == null || templateid == "") {
      NotificationManager.error(
        "Validation Error",
        "Template selected is invalid. Please try again."
      );
      return;
    }
    this.setState({ showLoaderFlag: true }, () => {
      if (templateid == -1) {
        var quiztype = this.state.templatefilter;
        var url = "Quiz/Create/?theme=1&quiztype=" + quiztype;
        this.props.history.push(url);
      } else {
        if (
          this.state.templateArr != null &&
          this.state.templateArr.length > 0
        ) {
          var filteredtemplate = this.state.templateArr.filter(
            (element) => element.quizid == templateid
          );

          if (filteredtemplate != null && filteredtemplate[0] != null) {
            var selectedtemplate = JSON.parse(
              JSON.stringify(filteredtemplate[0])
            );
            selectedtemplate["quizstatus"] = "draft";
            var jsonStr = JSON.stringify(selectedtemplate);
            var queryurl =
              this.props.basepath + "template/createquizfromtemplate";
            var self = this;

            fetch(queryurl, {
              method: "post",
              credentials: "include",
              headers: { "Content-type": "application/json" },
              body: jsonStr,
            }).then(function (response) {
              return response.json();
            }).then(function (response) {
              self.setState({ showLoaderFlag: false });
              if (response && response.status == 200 && response.data) {
                NotificationManager.success(
                  "Quiz Saved",
                  "Your quiz has been successfully saved as draft. Please ensure to publish it before sharing it in your network."
                );
                self.props.history.push(
                  "/app/Quiz/Edit/" + response.data.quizid
                );
              } else {
                NotificationManager.error("Server error: ", response.message);
              }
            }).catch(err=>{
              NotificationManager.error("Server error: " , err.message)
            });
          }
        } else {
          NotificationManager.error(
            "Validation Error",
            "Template selected is invalid. Please try again."
          );
          this.setState({ showLoaderFlag: false });
          return;
        }
      }
    });
  }

  renderTemplateHeader(data) {
    var theme_color = null;
    var headercontentcolor = null;
    if (data && data.theme_color) {
      try {
        theme_color = JSON.parse(data.theme_color);
        headercontentcolor =
          theme_color != null && theme_color.length > 0 && theme_color[1]
            ? theme_color[1].header_text
            : null;
      } catch (e) {
        //do nothing.
      }
    }

    return (
      <div
        className={`panel-heading ${
          data == null || data.theme == null ? "theme1" : data.theme
        }`}
        style={{
          background:
            theme_color != null && theme_color.length > 0
              ? theme_color[0].header_bg
              : null,
        }}
        dataid={data.quizid}
      >
        <div className="row" dataid={data.quizid}>
          <div
            className="col-md-10 col-sm-10 col-xs-10"
            ref={(el) => {
              if (el) {
                el.style.setProperty("color", headercontentcolor, "important");
              }
            }}
            style={{
              fontWeight: "bold",
              wordBreak: "break-word",
              color: headercontentcolor,
            }}
            dataid={data.quizid}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                data == null || data.title == null || data.title == ""
                  ? "Quiz Title"
                  : data.title
              ),
            }}
          ></div>
          <div className="col-md-2 col-sm-2 col-xs-2" dataid={data.quizid}>
            <img
              className="glyphicon img-responsive pull-right vcenter"
              src="https://app.quizzes4leads.com/assets/img/q4llogo.png"
              style={{ minWidth: 25, height: 25, display: "none" }}
              dataid={data.quizid}
            />
          </div>
        </div>
      </div>
    );
  }

  renderTemplateBody(data) {
    var theme_color = null;
    var bodycontentcolor = null;
    if (data && data.theme_color) {
      try {
        theme_color = JSON.parse(data.theme_color);
        bodycontentcolor =
          theme_color != null && theme_color.length > 0 && theme_color[3]
            ? theme_color[3].header_content_text
            : null;
      } catch (e) {
        //do nothing.
      }
    }

    return (
      <div
        className={`panel-body ${this.props.bgimg ? "remove-bg-colors" : ""}`}
        id="quizcontent"
        dataid={data.quizid}
        style={{
          background:
            theme_color != null && theme_color.length > 0
              ? theme_color[2].header_content_bg
              : null,
          color:
            theme_color != null && theme_color.length > 0
              ? theme_color[3].header_content_text
              : null,
        }}
      >
        <div className="m-t-md qzwcmsg" dataid={data.quizid}>
          <div
            dataid={data.quizid}
            style={{ fontSize: "medium", overflowWrap: "break-word" }}
          >
            <div
              className="panel-body"
              id="quizcontent"
              dataid={data.quizid}
              style={{
                background:
                  theme_color != null && theme_color.length > 0
                    ? theme_color[2].header_content_bg
                    : null,
                color:
                  theme_color != null && theme_color.length > 0
                    ? theme_color[3].header_content_text
                    : null,
              }}
            >
              <div
                className="m-t-md qzwcmsg"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      bodycontentcolor,
                      "important"
                    );
                  }
                }}
                dataid={data.quizid}
                style={{
                  background:
                    theme_color != null && theme_color.length > 0
                      ? theme_color[2].header_content_bg
                      : null,
                }}
              >
                <div
                  style={{ fontSize: "medium", overflowWrap: "break-word" }}
                  dataid={data.quizid}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      data == null ||
                        data.quizwelcomemsg == null ||
                        data.quizwelcomemsg == ""
                        ? "Quiz Welcome Message"
                        : data.quizwelcomemsg
                    ),
                  }}
                ></div>
              </div>
              <div className="templatecontainer">
                <div className="btn-wrapper">
                  <div
                    className="btntemplatepreview"
                    datatitle={data.title}
                    dataid={data.quizid}
                    onClick={this.previewTemplate}
                  >
                    Preview
                  </div>
                  <div
                    className="btntemplatecreate"
                    dataid={data.quizid}
                    onClick={this.createQuizFromTemplate}
                    disabled={this.state.showLoaderFlag}
                    readOnly={this.state.showLoaderFlag}
                  >
                    Use Template{" "}
                    {this.state.showLoaderFlag ? (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ marginLeft: 5 }}
                      ></i>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderTemplateFooter(data) {
    var theme_color = null;
    if (data && data.theme_color) {
      try {
        theme_color = JSON.parse(data.theme_color);
      } catch (e) {
        //do nothing.
      }
    }
    return (
      <div
        className={`panel-footer ccc ${
          data == null || data.theme == null ? "theme1" : data.theme
        }`}
        style={{
          background:
            theme_color != null && theme_color.length > 0
              ? theme_color[4].footer_bg
              : null,
          color:
            theme_color != null && theme_color.length > 0
              ? theme_color[5].footer_text_color
              : null,
        }}
      ></div>
    );
  }

  renderQuizTypes() {
    var content = null;
    if (quizTypeArr != null && quizTypeArr.length > 0) {
      var col = 6; //12 / (this.state.templateArr.length);
      content = quizTypeArr.map((element, index) => {
        var backgroundImage = "url('" + element.backgroundimg + "')";
        if (element.status == "inactive") {
          return null;
        }
        return (
          <div
            className="col-md-6"
            key={index}
            style={{ cursor: "pointer" }}
            datatype={element.value}
            onClick={this.onChangeTemplateFilter}
          >
            <div
              className="theme1"
              style={{ padding: 20 }}
              datatype={element.value}
            >
              <div
                className={`panel panel-blur animated slideIn ${
                  this.props.theme_color == null ||
                  this.props.theme_color == null
                    ? "theme1"
                    : this.props.theme_color
                }`}
                style={{ margin: 0, border: 0 }}
                datatype={element.value}
              >
                <div className="panel-heading theme1" datatype={element.value}>
                  <div className="row" datatype={element.value}>
                    <div
                      className="col-md-12 col-sm-12 col-xs-12"
                      datatype={element.value}
                      style={{ fontWeight: "bold", wordBreak: "break-word" }}
                    >
                      {element.name}
                    </div>
                  </div>
                </div>

                <div
                  datatype={element.value}
                  className={`panel-body ${
                    this.props.bgimg ? "remove-bg-colors" : ""
                  }`}
                  id="quizcontent"
                >
                  <img
                    style={{
                      maxHeight: "300px",
                      minHeight: "300px",
                      objectFit: "contain",
                    }}
                    src={element.backgroundimg}
                    datatype={element.value}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return content;
  }

  renderTemplates() {
    var content = null;

    if (this.state.templateArr != null && this.state.templateArr.length > 0) {
      var templatefilter = this.state.templatefilter;
      var filteredArr = this.state.templateArr.filter(
        (element) => element.quiztype == templatefilter
      );
      filteredArr.push({
        theme_type: 1,
        theme: "theme1",
        quizwelcomemsg:
          "This is a blank template. Use this template to create quiz from scratch.",
        title: "Blank Quiz",
        quizid: -1,
        quizbkgroundimage: "",
      });

      if (filteredArr == null || filteredArr.length == 0) {
        content = "No templates available for this quiz type";
      } else {
        var col = 4; //12 / (this.state.templateArr.length);
        content = filteredArr.map((element, index) => {
          var backgroundImage = "url('" + element.quizbkgroundimage + "')";
          return (
            <div
              className={`col-md-${col}`}
              key={index}
              id={
                element.quizid == "4cbe3e601a090ea3d73014e81a0b1b3337efd135"
                  ? "p-moon-phase"
                  : element.quizid == "0a7dc4f1cb254cccee8cbd7422c518bdcd2c8446"
                  ? "che-minimalista"
                  : element.quizid == "075b6125ced3bae892ef5ee7e3f2f9cca3777105"
                  ? "brand-archetype"
                  : element.quizid == "88cc6e247352a7475576d3654b289cbe1298ebf4"
                  ? "which-spark"
                  : ""
              }
            >
              <div
                className={`templatediv ${element.theme}`}
                style={{
                  backgroundImage,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  padding: 20,
                }}
                dataid={element.quizid}
              >
                <div
                  className={`panel panel-blur animated slideIn ${
                    this.props.theme_color == null ||
                    this.props.theme_color == null
                      ? "theme1"
                      : this.props.theme_color
                  }`}
                  style={{ margin: 0, border: 0 }}
                  dataid={element.quizid}
                >
                  {this.renderTemplateHeader(element)}
                  {this.renderTemplateBody(element)}

                  {/* <div className={`panel-body ${this.props.bgimg ? "remove-bg-colors" : ""}`}
                    id="quizcontent"
                    dataid={element.quizid}
                    style
                  >
                    <div className="m-t-md qzwcmsg" dataid={element.quizid}>
                      <div
                        style={{
                          fontSize: "medium",
                          overflowWrap: "break-word",
                        }}
                        dataid={element.quizid}
                      >
                        <div
                          className="panel-body"
                          id="quizcontent"
                          dataid={element.quizid}
                        >
                          <div
                            className="m-t-md qzwcmsg"
                            dataid={element.quizid}
                          >
                            <div
                              style={{
                                fontSize: "medium",
                                overflowWrap: "break-word",
                              }}
                              dataid={element.quizid}
                            >
                              {element.quizwelcomemsg}
                            </div>
                          </div>
                          <div className="templatecontainer">
                            <div className="btn-wrapper">
                              <div
                                className="btntemplatepreview"
                                datatitle={element.title}
                                dataid={element.quizid}
                                onClick={this.previewTemplate}
                              >
                                Preview
                              </div>
                              <div
                                className="btntemplatecreate"
                                dataid={element.quizid}
                                onClick={this.createQuizFromTemplate}
                                disabled={this.state.showLoaderFlag}
                                readOnly={this.state.showLoaderFlag}
                              >
                                Use Template{" "}
                                {this.state.showLoaderFlag ? (
                                  <i
                                    className="fa fa-spinner fa-spin"
                                    style={{ marginLeft: 5 }}
                                  ></i>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {this.renderTemplateFooter(element)}
                </div>
              </div>
              <div className="m-t-md">
                <button
                  className="btn"
                  dataid={element.quizid}
                  onClick={this.previewTemplate}
                >
                  {" "}
                  <i
                    className="fa fa-eye-alt fa-x cursor"
                    dataid={element.quizid}
                  ></i>{" "}
                </button>
              </div>
            </div>
          );
        });
      }
    }
    return content;
  }

  render() {
    return (
      <div>
        {this.state.step == 1 ? (
          <div style={{ marginTop: 2 + "%", height: 200, marginBottom: 10 }}>
            <div className="col-md-12">
              <div className="table-title alert alert-info">
                Select the type of your quiz
              </div>
              {this.renderQuizTypes()}
            </div>
          </div>
        ) : null}

        {this.state.step == 2 &&
        this.props.user != null &&
        adminusers.indexOf(this.props.user.userid) != -1 ? (
          <div className="template-wrapper-prev">
            <div className="col-md-12" style={{ marginBottom: 15 }}>
              <button
                className="btn-normal"
                id="btncreatetemplate"
                style={{ width: 130 }}
                onClick={() => {
                  this.setState({ showTemplateCreateFlag: true });
                }}
              >
                Create Template
              </button>
              {this.state.showTemplateCreateFlag ? (
                <div>
                  <label className="Sign-In-Label">
                    Enter the quizid of the quiz you want to save as template
                    (For multiple quizids, use ; as seperator)
                  </label>
                  <input
                    className="Sign-In-TextBox"
                    type="text"
                    value={this.state.templatequizid}
                    name="templatequizid"
                    onChange={this.onChangeField}
                  />
                  <div style={{ marginTop: 10 }}>
                    <button
                      className="btn-normal"
                      onClick={this.saveAsTemplate}
                      style={{ marginRight: 15 }}
                    >
                      Save
                    </button>
                    <button
                      className="btn-normal"
                      onClick={() => {
                        this.setState({ showTemplateCreateFlag: false });
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {this.state.step == 2 ? (
          <div style={{ marginTop: 2 + "%", height: 200, marginBottom: 10 }}>
            <div className="col-md-12">
              <div
                className="table-title alert alert-info"
                style={{ paddingBottom: 20, marginBottom: 10 }}
              >
                Choose a template or start from scratch
              </div>
              {this.renderTemplates()}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Templates;
