/**
 * This file contains the functionality to render quiz table
 */
import React from "react";
import swal from "sweetalert";
import { NotificationManager } from "react-notifications";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Loader } from "./loader";
import * as DOMPurify from "dompurify";

var month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
function formatDate(datestring) {
  var tempdate = new Date(datestring);
  return (
    month[tempdate.getMonth()] +
    " " +
    tempdate.getDate() +
    ", " +
    tempdate.getFullYear()
  );
}

export class QuizTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableItems: { header: null, rowdata: null },
      tableTitle: "Quizzes",
      dataLoadedFlag: false,
      actionFlag: false,
      user: this.props.user,
      menuID: null,
      search_query: "",
      gdata: [],
      pageCount: 0,
      perPage: "7",
      offset: "0",
      shortByQuizStatus: "",
      shortByQuizCompleted: "",
      sortByNumberOfQuestion: "",
      sortQuizCreatedDate: "",
      totalQuizCount: 0,
      selectedPage: 0,
    };
    this.onHover = this.onHover.bind(this);
    this.getQuizzes = this.getQuizzes.bind(this);
    this.quizShare = this.quizShare.bind(this);
    this.quizReport = this.quizReport.bind(this);
    this.quizPlay = this.quizPlay.bind(this);
    this.quizDelete = this.quizDelete.bind(this);
    this.quizEdit = this.quizEdit.bind(this);
    this.quizTogglePublish = this.quizTogglePublish.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.orderByQuizType = this.orderByQuizType.bind(this);
    this.orderByQuizPlayed = this.orderByQuizPlayed.bind(this);
    this.orderByNumberOfQuestion = this.orderByNumberOfQuestion.bind(this);
    this.orderQuizCreatedDate = this.orderQuizCreatedDate.bind(this);

    this.getQuizzes();
  }

  // get quiz by quiz status
  orderByQuizType() {
    this.setState({
      shortByQuizStatus:
        this.state.shortByQuizStatus == "draft" ? "published" : "draft",
      shortByQuizCompleted: "",
      sortByNumberOfQuestion: "",
      sortQuizCreatedDate: "",
    });
    this.getQuizzes();
  }
  // get orderBy quiz played
  orderByQuizPlayed() {
    this.setState({
      shortByQuizCompleted:
        this.state.shortByQuizCompleted == "desc" ? "asc" : "desc",
      shortByQuizStatus: "",
      sortByNumberOfQuestion: "",
      sortQuizCreatedDate: "",
    });
    this.getQuizzes();
  }
  // get orderByNumberOfQuestion
  orderByNumberOfQuestion() {
    this.setState({
      sortByNumberOfQuestion:
        this.state.sortByNumberOfQuestion == "desc" ? "asc" : "desc",
      shortByQuizStatus: "",
      shortByQuizCompleted: "",
      sortQuizCreatedDate: "",
    });
    this.getQuizzes();
  }
  // sort by orderQuizCreatedDate
  orderQuizCreatedDate() {
    this.setState({
      sortQuizCreatedDate:
        this.state.sortQuizCreatedDate == "asc" ? "desc" : "asc",
      shortByQuizStatus: "",
      shortByQuizCompleted: "",
      sortByNumberOfQuestion: "",
    });
    this.getQuizzes();
  }

  getQuizzes() {
    this.setState({ dataLoadedFlag: false }, () => {
      var dataurl =
        this.props.basepath +
        "dashboard/qzdashboard?limit=" +
        this.state.perPage +
        "&offset=" +
        this.state.offset +
        "&status=" +
        this.state.shortByQuizStatus +
        "&quizcompleted=" +
        this.state.shortByQuizCompleted +
        "&sortbynumberofquestion=" +
        this.state.sortByNumberOfQuestion +
        "&shorbyquizcreateddate=" +
        this.state.sortQuizCreatedDate;
      var self = this;
      fetch(dataurl, {
        method: "get",
        data: { limit: this.state.perPage, offset: this.state.offset },
        credentials: "include",
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (response) {
          if (response && response.status == 200 && response.data) {
            var tempArr = self.state.tableItems;
            tempArr.rowdata = [];
            self.setState({
              gdata: response.data.quiz,
              pageCount: Math.ceil(response.data.quizcount / 7),
              totalQuizCount: response.data.quizcount,
            });
            self.state.gdata.map(function (item, index) {
              if (item["templateflag"] == 1) {
                item["title"] = item["title"] + "*";
              }

              var arrItem = [
                item["quizid"],
                item["title"],
                item["que_count"],
                item["date"],
                item["quizstatus"],
                item["quizcompletedcount"],
                item["Action"],
                item["quiztype"],
              ];
              tempArr.rowdata[index] = [];
              tempArr.rowdata[index] = arrItem;
            }, self);

            tempArr.header = [];
            tempArr.header[0] = [
              "ID",
              "Title",
              "Questions",
              "Description",
              "Date",
              "Played",
              " Status",
              " Status",
            ];

            self.setState({ tableItems: tempArr, dataLoadedFlag: true });
          } else {
            NotificationManager.error("Server error: ", response.message);
            self.setState({ dataLoadedFlag: true });
          }
        })
        .catch((err) => {
          NotificationManager.error("Server error: ", err.message);
        });
    });
  }
  componentDidMount() {
    this.getQuizzes();
  }
  textWithoutHtml(str) {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return doc.documentElement.textContent;
  }
  handleChange(e) {
    var search_query = e.target.value;
    var dataurl = this.props.basepath + "quiz/search" + "?q=" + search_query;
    this.setState({ search_query });

    var self = this;
    fetch(dataurl, {
      method: "get",
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        if (response && response.status == 200 && response.data) {
          var tempArr = self.state.tableItems;
          tempArr.rowdata = [];
          self.setState({
            gdata: response.data.quiz,
            pageCount: Math.ceil(response.data.quizcount / 7),
            totalQuizCount: response.data.quizcount,
          });
          self.state.gdata.map(function (item, index) {
            if (item["templateflag"] == 1) {
              item["title"] = item["title"] + "*";
            }

            var arrItem = [
              item["quizid"],
              item["title"],
              item["que_count"],
              item["date"],
              item["quizstatus"],
              item["quizcompletedcount"],
              item["Action"],
              item["quiztype"],
            ];
            tempArr.rowdata[index] = [];
            tempArr.rowdata[index] = arrItem;
          }, self);

          tempArr.header = [];
          tempArr.header[0] = [
            "ID",
            "Title",
            "Questions",
            "Description",
            "Date",
            "Played",
            " Status",
            " Status",
          ];

          self.setState({ tableItems: tempArr, dataLoadedFlag: true });
        } else {
          NotificationManager.error("Server error: ", response.message);
          self.setState({ dataLoadedFlag: true });
        }
      })
      .catch((err) => {
        NotificationManager.error("Server error: ", err.message);
      });
  }

  quizShare(event) {
    var quizid = event.target.getAttribute("data-id");

    // get quiz
    const getSingleQuiz = this.state.gdata.find(
      (item) => item.quizid === quizid
    );

    var quizTitle = event.target.getAttribute("data-title");
    var getQuizTitleSlug = quizTitle
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    // this.props.history.push(
    //   "/app/Quiz/Share/" + quizid + "?quiztitle=" + getQuizTitleSlug
    // );
    this.props.history.push({
      pathname: `/app/Quiz/Share/${quizid}`,
      state: { quizType: getSingleQuiz.quiztype,quizTitle:getQuizTitleSlug, quizid },
    });
  }
  quizTogglePublish(event) {
    var quizid = event.target.getAttribute("data-id");
    var status = event.target.getAttribute("data-status");
    status = status == "draft" ? "published" : "draft";
    var self = this;
    var queryurl =
      this.props.basepath + "quiz/qzpublish/" + quizid + "/" + status;
    fetch(queryurl, {
      method: "get",
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        if (response && response.status == 200 && response.data) {
          NotificationManager.success(
            "Quiz Status",
            "Status of quiz has been updated to " + status
          );
          var tableItems = self.state.tableItems;
          if (tableItems != null && tableItems.rowdata != null) {
            var filteredrow = tableItems.rowdata.filter(
              (element) => element[0] == quizid
            );
            if (filteredrow != null && filteredrow.length > 0) {
              filteredrow[0][4] = status;
            }
            self.setState({ tableItems });
          }
        } else {
          NotificationManager.error("Server error: ", response.message);
        }
      })
      .catch((err) => {
        NotificationManager.error("Server error: ", err.message);
      });
  }

  quizReport(event) {
    var quizid = event.target.getAttribute("data-id");
    this.props.history.push("/app/Quiz/Analytics/" + quizid);
  }

  quizPlay(event) {
    var quizid = event.target.getAttribute("data-id");
    const getSingleQuiz = this.state.gdata.find(
      (item) => item.quizid === quizid
    );
    var quizTitle = event.target.getAttribute("data-title");
    var getQuizTitleSlug = quizTitle
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    // if quiztype 1 redirect to new player
    const validQuizTypes = ["qztype1", "qztype2", "qztype5", "qztype6","qztype7","qztype9"];
    if (validQuizTypes.includes(getSingleQuiz.quiztype)) {
      var quizurl =
        this.props.localpath +
        "quizplayer2.html?quiztitle=" +
        getQuizTitleSlug +
        "&quizid=" +
        quizid;
    } else {
      var quizurl =
        this.props.localpath +
        "quizplayer.html?quiztitle=" +
        getQuizTitleSlug +
        "&quizid=" +
        quizid;
    }

    window.open(quizurl);
  }

  quizDelete(event) {
    var quizid = event.target.getAttribute("data-id");
    var self = this;
    swal({
      title: "Delete Quiz",
      text: "Are you sure you want to delete this quiz? This quiz and all related data will be permanently deleted.",
      type: "warning",
      buttons: { cancel: true, text: "Yes, Delete it!!", closeModal: false },
      icon: "warning",
    }).then(function (isConfirm) {
      if (isConfirm) {
        var queryurl = self.props.basepath + "quiz/qzdelete/" + quizid;
        fetch(queryurl, {
          method: "get",
          credentials: "include",
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            if (response && response.status == 200 && response.data) {
              NotificationManager.success(
                "Quiz Delete",
                "Quiz has been deleted."
              );
            } else {
              NotificationManager.error(
                "Quiz Delete",
                "Error in deleting quiz."
              );
            }
            self.getQuizzes();
          })
          .catch((err) => {
            NotificationManager.error("Server error: ", err.message);
          });
      }
    });
  }

  quizEdit(event) {
    var quizid = event.target.getAttribute("data-id");
    this.props.history.push("/app/Quiz/Edit/" + quizid);
  }

  onHover(event) {
    var menuid = event.target.getAttribute("data-id");
    var actionflag = this.state.actionFlag;
    this.setState({ menuID: menuid });
  }

  renderHeaders() {
    if (
      this.state.tableItems == null ||
      this.state.tableItems.header == null ||
      this.state.tableItems.header.length == 0
    ) {
      return null;
    }

    return this.state.tableItems.header.map(function (headerItem, index) {
      return (
        <thead className="heading" key={index}>
          <tr className="black-muted-bg">
            <th className="thead_title thead-quiz-list-tile">
              {headerItem[1]}
            </th>
            <th onClick={this.orderQuizCreatedDate} className="thead_title">
              <div className="shorting-arrow">
                <div
                  className={
                    this.state.sortQuizCreatedDate == "asc"
                      ? "arrow-up"
                      : "arrow-down"
                  }
                ></div>
              </div>{" "}
              {headerItem[4]}
            </th>
            <th onClick={this.orderByNumberOfQuestion} className="thead_title">
              <div className="shorting-arrow">
                <div
                  className={
                    this.state.sortByNumberOfQuestion == "desc"
                      ? "arrow-down"
                      : "arrow-up"
                  }
                ></div>
              </div>{" "}
              {headerItem[2]}
            </th>

            <th onClick={this.orderByQuizPlayed} className="thead_title">
              <div className="shorting-arrow">
                <div
                  className={
                    this.state.shortByQuizCompleted == "desc"
                      ? "arrow-down"
                      : "arrow-up"
                  }
                ></div>
              </div>{" "}
              {headerItem[5]}
            </th>
            <th className="thead_title">Quiz Type</th>

            <th onClick={this.orderByQuizType} className="thead_title">
              <div className="shorting-arrow">
                <div
                  className={
                    this.state.shortByQuizStatus == "draft"
                      ? "arrow-down"
                      : "arrow-up"
                  }
                ></div>
              </div>{" "}
              {headerItem[6]}
            </th>

            <th className="thead_title thead-quiz-list-tile">Action</th>
          </tr>
        </thead>
      );
    }, this);
  }

  renderRows() {
    if (this.state.dataLoadedFlag == false) {
      return (
        <tr className="no-top-border">
          <td>Loading Data...</td>
        </tr>
      );
    }
    if (
      this.state.tableItems == null ||
      this.state.tableItems.rowdata == null ||
      this.state.tableItems.rowdata.length == 0
    ) {
      return (
        <tr className="no-top-border no-quiz-message">
          <td>
            Your quiz dashboard is currently empty. Let's get started by
            creating your first quiz.
          </td>
        </tr>
      );
    } else {
      return this.state.tableItems.rowdata.map(function (rowItem, index) {
        var title = rowItem[1];
        var contactTitle = this.textWithoutHtml(title);
        if (contactTitle.length > 30) {
          var titles = contactTitle.substr(0, 30);
          contactTitle = titles + "..";
        }
        var quizurl =
          "https://app.quizzes4leads.com/quizplayer.html?quizid=" + rowItem[0];
        return (
          <tr className="no-top-border" key={index}>
            <td
              className="thead_td thead-quiz-list-tile"
              style={{ wordWrap: "break-word", color: "#8144e5" }}
              data-id={rowItem[0]}
              onClick={this.quizEdit}
            >
              {contactTitle}
            </td>
            {/*Quiz published date*/}
            <td className="thead_td">{formatDate(rowItem[3])}</td>
            <td className="thead_td">{rowItem[2]}</td>

            <td className="thead_td">{rowItem[5]}</td>
            {rowItem[7] == "qztype6" ? (
              <td className="thead_td">Weighted Graded</td>
            ) : (
              <td className="thead_td">
                {rowItem[7] == "qztype1"
                  ? "Graded"
                  : rowItem[7] == "qztype2"
                  ? "Personality"
                  : rowItem[7] == "qztype7"
                  ? "Multiple Personalities"
                  : rowItem[7] == "qztype9"
                  ? "Category Graded"
                  : rowItem[7] == "qztype8"
                  ? "Category Multiple Personalities"
                  : "Graded with Personality"}
              </td>
            )}

            <td
              className="thead_td"
              style={{ cursor: "pointer" }}
              data-id={rowItem[0]}
              data-status={rowItem[4]}
            >
              {/* <span className={`${rowItem[4]}`} data-id={rowItem[0]} data-status={rowItem[4]}/>{" "} */}
              {/* <span className="text-capitalize" data-id={rowItem[0]} data-status={rowItem[4]}>
                {" "}{rowItem[4]}
              </span>  */}
              <br />
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={this.quizTogglePublish}
                  data-status={rowItem[4]}
                  data-id={rowItem[0]}
                  checked={
                    rowItem[4] != null && rowItem[4] == "published"
                      ? true
                      : false
                  }
                />
                <span
                  className="slider round"
                  data-status={rowItem[4]}
                  data-id={rowItem[0]}
                ></span>
              </label>
            </td>
            <td className="thead_td thead-quiz-list-control">
              <div className="btn-group btn_block">
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.quizEdit}
                  title="Edit quiz"
                  data-original-title="Edit"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-edit fa-x cursor"
                    data-id={rowItem[0]}
                  ></i>
                </span>
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.quizPlay}
                  title="Play quiz"
                  data-original-title="Play"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-play fa-x cursor"
                    data-id={rowItem[0]}
                    data-title={rowItem[1]}
                  ></i>
                </span>
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  onClick={this.quizDelete}
                  title="Delete quiz"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  data-original-title="Delete"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-trash fa-x cursor"
                    data-id={rowItem[0]}
                  ></i>
                </span>

                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.quizShare}
                  title="Share quiz"
                  data-original-title="Share"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-share-alt fa-x cursor"
                    data-id={rowItem[0]}
                    data-title={rowItem[1]}
                  ></i>
                </span>

                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.quizReport}
                  title="Quiz Analytics"
                  data-original-title="Analytics"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-line-chart fa-x cursor"
                    data-id={rowItem[0]}
                  ></i>
                </span>
              </div>
            </td>
          </tr>
        );
      }, this);
    }
  }
  handlePageClick(data) {
    let selected = data.selected;

    let offset = Math.ceil(selected * this.state.perPage);
    this.setState({ offset: offset }, () => {
      this.getQuizzes();
    });
    this.setState({
      selectedPage: selected,
    });
  }

  render() {
    return this.state.dataLoadedFlag == false ? (
      <Loader message="Loading Quizzes" />
    ) : this.state.tableItems != null &&
      this.state.tableItems.rowdata != null &&
      this.state.tableItems.rowdata.length > 0 ? (
      <div>
        <div className="col-md-12">
          <div className="table-title">
            Quizzes
            <span className="table-subtitle">
              {this.state.tableItems != null &&
              this.state.tableItems.rowdata != null &&
              this.state.tableItems.rowdata.length > 0
                ? this.state.totalQuizCount + " Total"
                : "0 records"}
            </span>
            <span className="table-subtitle">
              <input
                type="text"
                name="searchh_quiz"
                onChange={(e) => {
                  this.handleChange(e);
                }}
                className="Sign-In-TextBox pull-right"
                placeholder="search"
                style={{ width: 12.5 + "%" }}
              />
            </span>
          </div>
        </div>
        <div className="col-md-12">
          <div>
            <div className="tableFixHead queiz-analysis">
              <table className="table table-striped quizes-table man_table">
                {this.renderHeaders()}
                <tbody>{this.renderRows()}</tbody>
              </table>
            </div>
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination pagination_new"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active pageination-active-customize"}
              forcePage={this.state.selectedPage}
            />
          </div>
        </div>
      </div>
    ) : (
      <div>
        <h4>
          You don't have any data currently, let's get started by creating your
          first Quiz <br />
        </h4>
        <button
          className="btn-normal"
          id="btnquizcreate"
          style={{ width: 150 }}
        >
          <Link
            to="/app/Templates"
            style={{ color: "white", textDecoration: "none" }}
          >
            Build Your Quiz
          </Link>
        </button>
      </div>
    );
  }
}
