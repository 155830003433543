/**
 * This form shows the analytics of quiz.
 */
import React from "react";
import { DataCard } from "../components/datacard";
import { cardItemsArr } from "../global";

import { Link } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { MDBDataTable } from "mdbreact";
// import "mdbreact/dist/css/mdb.css";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class QuizReportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportdata: null,
      user: this.props.user,
      dataContent: "",
      quiz_title: "",
      quiz_type: ""
    };

    this.getQuizReport = this.getQuizReport.bind(this);
  }

  componentDidMount() {
    var quizid = this.props.match.params.id;
    this.setState({ quizid }, this.getQuizReport);
  }

  getQuizReport() {
    var quizid = this.state.quizid;
    var queryurl = this.props.basepath + "quiz/showreport/" + quizid;
    var self = this;
    fetch(queryurl, {
      method: "get",
      credentials: "include"
    }).then(function (response) {
      return response.json();
    }).then(function (response) {        
      if (response && response.status == 200 && response.data) {
        var playerinfo = response.data.playerinfo;
        let playerInforForDataContent = response.data.playerinfo;
        if(playerInforForDataContent && playerInforForDataContent.length > 0){
          playerInforForDataContent = playerInforForDataContent.map((element,index)=>{
            var url = '/app/answersheet/'+element.id;
            element.id = <Link to={url}>{element.id}</Link>;
            return element;
          })
          var quiz_title = response.data.quiz_info["0"].title.replace(/<[^>]*>/g, '');
          var cardItemsArray = [];
          response.data.kpi.map(function (headerItem, index) {
            if (index < cardItemsArr.length) {
              cardItemsArray[index] = {
                stats: headerItem.value,
                title: headerItem.key,
                icon: cardItemsArr[index].icon,
                iconsize: 4
              };
            }
          }, self);

          var cardItems = cardItemsArray;
          var reportdata = { carditem: cardItems };
          self.setState({ reportdata, dataContent: playerInforForDataContent, quiz_title, quiz_type: response.data.quiz_info["0"].quiztype });
        } else {
          self.setState({dataContent:null});
        }
      }else {
        NotificationManager.error("Quiz Report Error", response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }
  renderDataTable() {
    var dataContent = this.state.dataContent;
    if(dataContent){
      const tabledata = {
        columns: [
          {
            label: "Email",
            field: "email",
            sort: "asc"
          },
          {
            label: "Quiz Result",
            field: "quizscore",
            sort: "asc"
          },
          {
            label: "Date of play",
            field: "playdate",
            sort: "desc"
          },
          {
            label: "Country",
            field: "country",
            sort: "asc"
          },
          {
            label: "State",
            field: "state",
            sort: "asc"
          },
          {
            label: 'City',
            field: 'city',
            sort: 'asc'
          },
          {
            label: "Answers",
            field: "id",
            sort: "desc"
          }
        ],
        rows: dataContent
      };

      return <MDBDataTable striped bordered hover data={tabledata} />;
    }
    else{
      return null;
    }
  };

  // renderHeaders() {
  //   return this.state.reportdata.tableitem.header.map(function(
  //     headerItem,
  //     index
  //   ) {
  //     return (
  //       <th
  //         className={`col-md-2 text-center ${
  //           index == 0 ? "col-md-offset-1" : ""
  //         }`}
  //         key={index}
  //       >
  //         <h3>{headerItem}</h3>
  //       </th>
  //     );
  //   },
  //   this);
  // }

  // renderRows() {
  //   if (
  //     this.state.reportdata == null ||
  //     this.state.reportdata.tableitem == null ||
  //     this.state.reportdata.tableitem.rowdata == null ||
  //     this.state.reportdata.tableitem.rowdata.length == 0
  //   ) {
  //     return (
  //       <tr className="no-top-border">
  //         <td className="col-md-12 text-left">
  //           <h3>There is no data to show!</h3>
  //         </td>
  //       </tr>
  //     );
  //   } else {
  //     return this.state.reportdata.tableitem.rowdata.map(function(
  //       rowItem,
  //       index
  //     ) {
  //       if (rowItem == null || rowItem.length == 0) {
  //         return (
  //           <tr className="no-top-border" key={index}>
  //             <td className="col-md-12 text-left">
  //               <h3>There is no data to show!</h3>
  //             </td>
  //           </tr>
  //         );
  //       } else {
  //         return (
  //           <tr className="no-top-border" key={index}>
  //             <td className="col-md-offset-1 col-md-2 text-center">
  //               {rowItem[0]}
  //             </td>
  //             <td className="col-md-2 text-center">{rowItem[1]}</td>
  //             <td className="col-md-2 text-center">{rowItem[2]}</td>
  //             <td className="col-md-2 text-center">{rowItem[3]}</td>
  //             <td className="col-md-2 text-center">{rowItem[4]}</td>
  //             <td className="col-md-2 text-center">{rowItem[5]}</td>
  //           </tr>
  //         );
  //       }
  //     },
  //     this);
  //   }
  // }

  renderTable() {
    return (
      <div className="row">
        <div className="qzpanel qzpanel-blur with-scroll table-qzpanel animated zoomIn qzpanel_block">
          <div className="qzpanel-body ">
            <div>
              <div className="horizontal-scroll q-r" style={{overflow:'scroll'}}>
                <div className="row quiz-title-exprot-wrapper">
                  <div className="col-md-8">
                    <h4>
                      <b>Quiz Title:</b> {this.state.quiz_title}
                    </h4>
                    <h4>
                      <b>Quiz Type</b>:{" "}
                      {this.state.quiz_type == "qztype1"
                        ? "Graded Quiz"
                        : "Personality Quiz"}
                    </h4>
                  </div>
                  <div className="col-md-4 export-data">
                    <ExcelFile element={<button className="btn btn-primary">Export Data</button>}>
                      <ExcelSheet data={this.state.dataContent} name={this.state.quiz_title}>
                        <ExcelColumn width="200" label="Email" value="email" />
                        <ExcelColumn label="Quiz Result" value="quizscore" />
                        <ExcelColumn label="Date of Play" value="playdate" />
                        <ExcelColumn label="Country" value="country" />
                        <ExcelColumn label="State" value="state" />
                        <ExcelColumn label="City" value="city" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
                <br></br>
                <table className="table table-hover">
                  <thead>
                    <tr className="black-muted-bg sortable">
                      {/* {this.renderHeaders()} */}
                    </tr>
                  </thead>
                  <tbody className="q-report">
                    {/* {this.renderRows()} */}
                    {this.renderDataTable()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    var content = "";
    if (this.state.reportdata != null) {
      content = (
        <div>
          <DataCard cardItems={this.state.reportdata.carditem} />
          {this.renderTable()}
        </div>
      );
    } else{
      content = <h5>No player analytics found for this quiz.</h5>
    }
    return content;
  }
}
