/**
 * This file checks if user is loggedin or not.
 * if user is logged in, application will be rendered else login page will be rendered
 */
import React from 'react';
import packageJson from '../package.json';

import { Login } from './login';
import { Route, Switch } from 'react-router-dom';
import { UserRegistration, ForgetPassword } from './login';
import { Shopify } from './shopify';

import { Quizplayer } from './quizplayer';
import {Quizplayshort} from "./quizplayershort";
import { Dashboard } from './layout';
import { FeedbackPreview } from './forms/feedbackPreview';
import { NotificationManager } from 'react-notifications';
import PoolPreview from './forms/pool/poolPreview';
import PollDemoPreview from './forms/pool/pollDemoPreview';
import WheelSpinnerShow from './forms/wheelspinner/wheelspin_show';
import PlayQuiz from "./quizplay/PlayQuiz";

function checkNull(myval) {
    return myval != null;
}

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basepath: this.props.basepath,
            facebookloginurl: this.props.facebookloginurl,
            user: { isAuthenticated: false },
        };
        this.onLoginCheck = this.onLoginCheck.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    onLoginCheck(e) {
        var self = this;
        this.setState({ user: e }, () => {
            self.props.history.push("/app/");
        });
    }

    onLogout() {
        var queryurl = this.state.basepath + "quizzer/logout";
        var self = this;
        localStorage.setItem("alerted", "no");
        fetch(queryurl, {
            method: "get",
            credentials: "include",
        })
        .then((resp) => resp.json())
        .then(function (response) {
            if (response && response.status === 200 && response.data) {
                self.setState(
                    { user: { isAuthenticated: false, userid: null } },
                    () => {
                        self.props.history.push("/app/");
                    }
                );
            } else {
                NotificationManager.error("Server error: " + response.message);
            }
        }).catch(err=>{
            NotificationManager.error("Server error: " , err.message)
        });

    }

    render() {
        var content = null;
        if (this.state.user.isAuthenticated === true || this.state.user.isAuthenticated === "true") {
            content = (
                <Route
                    path="/app/"
                    component={(props) => (
                        <Dashboard
                            basepath={this.state.basepath}
                            localpath={this.props.localpath}
                            qzbasepath={this.props.qzbasepath}
                            user={this.state.user}
                            logourl={this.props.logourl}
                            appName={this.props.appName}
                            logout={this.onLogout}
                            {...this.props}
                            {...props}
                        />
                    )}
                />
            );
        } else {
            content = (
                <Route
                    component={(props) => (
                        <Login
                            basepath={this.state.basepath}
                            fbloginurl={this.state.facebookloginurl}
                            loginCheck={this.onLoginCheck}
                            loginTitle={[this.props.appTitle, " : Login"]}
                            registerUserUrl={this.props.registerUserUrl}
                            forgetPwdUrl={this.props.forgetPwdUrl}
                            {...this.props}
                            {...props}
                        />
                    )}
                />
            );
        }

        return (
            <Switch>
                <Route exact path="/quizplayer.html" component={(props) => <Quizplayer {...this.props} {...props} />} />
                <Route exact path="/previewtemplate" component={(props) => <Quizplayer {...this.props} {...props} />} />
                <Route exact path="/feedback/preview/:id" component={(props) => <FeedbackPreview {...this.props} {...props} />} />
                <Route exact path="/poll/publish/show/:id/:slug" component={(props) => <PoolPreview {...this.props} {...props} />} />
                <Route exact path="/wheelspin/publish/show/:id/:slug" component={(props) => <WheelSpinnerShow {...this.props} {...props} />} />
                <Route exact path="/poll/demo/preview/:id/:slug" component={(props) => <PollDemoPreview {...this.props} {...props} />} />
                <Route exact path="/ForgetPassword" component={(props) => <ForgetPassword {...this.props}  {...props} />} />
                <Route exact path="/Signup" component={(props) => <UserRegistration {...this.props}  {...props} loginCheck={this.onLoginCheck} />} />
                <Route exact path="/Shopify" component={(props) => <Shopify {...this.props}  {...props} loginCheck={this.onLoginCheck} />} />
                <Route exact path="/version" component={(props) => <div>Version is {packageJson.version}<br /> Env is {process.env.REACT_APP_ENV}<br /> Basepath is {process.env.REACT_APP_BASEPATH} <br />
                localpath is {process.env.REACT_APP_LOCALPATH}</div> } />
                <Route exact path="/play/:id" component={(props) => <Quizplayshort {...this.props}  {...props} />} />
                <Route exact path="/quizplayer2.html" component={(props) => <PlayQuiz {...this.props} {...props} />}/>
                {content}
            </Switch>
        );
    }
}
