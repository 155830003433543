import React from "react";
import "react-quill/dist/quill.snow.css";
//import ReactQuill from "react-quill";
const maxTextLength = 255;

class CustomTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.quill = require("react-quill");
    this.state = {
      editorValue: "",
      isEditable: true,
      limitReachText: 0,
    };
    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    this.updateEditorValue();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateEditorValue();
    }
  }

  updateEditorValue() {
    this.setState({ editorValue: this.props.value || "" });
  }

  setValue(value, fieldName) {
    let getColor = '';
    if (fieldName == "quizwelcomemsg") {
      getColor = this.getColorFromDesc(value);
    }

    let checkTextLimit = this.countTextWithoutHtml(value);

    if (checkTextLimit <= maxTextLength) {
      this.setState({ editorValue: value });
      this.props.onEditorValueChange(value, fieldName, getColor);
    } else if(checkTextLimit <= (maxTextLength + 1)) {
      this.setState({ isEditable: true });
    }
    this.setState({
      limitReachText: checkTextLimit,
    });
  }

  getColorFromDesc(string) {
    const colorRegex = /color:\s*rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
    const colorMatches = string.match(colorRegex);
    if (colorMatches) {
      // Extract the RGB values from the first match
      const redValue = colorMatches[1];
      const greenValue = colorMatches[2];
      const blueValue = colorMatches[3];
      
      // Create the color string in RGB format
      const color = `rgb(${redValue}, ${greenValue}, ${blueValue})`;
      
      return color;
    }
  }

  countTextWithoutHtml(str) {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return doc.documentElement.textContent.length;
  }

  render() {
    const modules = {
      toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ align: [] }],
        [{ color: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["clean"],
      ],
    };
    let fieldName = this.props.fieldname;
    const ReactQuill = this.quill;

    if (ReactQuill !== null) {
      return (
        <div>
          <ReactQuill
            readOnly={!this.state.isEditable}
            placeholder={this.props.placeholder}
            value={this.state.editorValue}
            onChange={(value) => this.setValue(value, fieldName)}
            theme="snow"
            modules={modules}
          />
          <div className="counter-wrapper">
            <span>
              {this.countTextWithoutHtml(this.state.editorValue || "0") +' / ' + maxTextLength}
            </span>
            {this.state.limitReachText > (maxTextLength +1) && (
              <span style={{ color: "red",marginLeft:5 }}>
                Char count of copied content is greater than char limit.
              </span>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default CustomTextEditor;
