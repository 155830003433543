/*
This file contains the functionality for user login, user registration and forget password
*/

import React from "react";
import queryString from "query-string";
import { NotificationManager } from "react-notifications";
// import windowListener from "react-tooltip/dist/decorators/windowListener";


export class Shopify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailFlag: false,
      loginErrormsg: "",
      passwordFlag: false,
      fbloginurl: this.props.fbloginurl,
      forgetPasswordFlag: false,
      userRegistrationFlag: false,
      loginformflag: true,
      showLoaderFlag: false,
    };

    this.shopifylogin = this.shopifylogin.bind(this);
    this.verifyshopifyrequest = this.verifyshopifyrequest.bind(this);
  }

  componentDidMount(){
    var self = this;
    //console.log('query string inside shopify react is '+JSON.stringify(this.props.location.search));
    var queryStringArr = queryString.parse(this.props.location.search);
    

    //console.log('query string Arr inside shopify react is '+JSON.stringify(queryStringArr));
    if(queryStringArr!=null){

      if(queryStringArr['hmac']!=null && queryStringArr['shop']!=null && queryStringArr['timestamp']!=null)
      {
         //this is the request coming from shopify so we need to verify it...props
         var querystring = Object.keys(queryStringArr).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(queryStringArr[k])).join('&');
    
         this.setState({shopifyshop:queryStringArr['shop']},()=>{self.shopifyinstall(querystring)});;

      }      
      // else{
      //   this.setState({shopifyshop:queryStringArr['shop']},()=>{
      //     self.shopifylogin();
      //   })
      // }  
    }
  }

  verifyshopifyrequest(shopifyparams){
    var self = this;
    var queryurl = this.props.basepath + "shopify/verifyrequest"+shopifyparams;
    //console.log('verifyshopifyrequest shopifyparams is '+queryurl);
    fetch(queryurl, {
      method: "get",
      credentials: "include",
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      if(response && response.status==200 && response.data){
        self.shopifyinstall();
      } else {
        self.shopifyinstall();
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }


  shopifylogin(){
    var self = this;
    var queryurl = this.props.basepath + "shopify/oauth";
    fetch(queryurl, {
      method: "get",
      credentials: "include",
    }).then(function (response) {
        return response.json();
    }).then(function (response) {
      if (response && response.status == 200 && response.data) {
        var userdata = {
          name: response.data.username,
          isAuthenticated: response.data.status,
          userid: response.data.userid,
          mode: response.data.mode,
          usertype: "shopifyuser",
        };
        self.props.loginCheck(userdata);
        self.setState({ showLoaderFlag: false });
      } else {
        self.shopifyinstall();
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  shopifyinstall(shopifyparams){
    var self = this;
    //console.log('shopifyparams in shopify install is '+shopifyparams)
    var queryurl = self.props.basepath + "shopify/install?"+shopifyparams;
    fetch(queryurl, {
      method: "get",
      credentials: "include"
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      //console.log('response in shopify install '+JSON.stringify(response));
      if(response && response.status == 200 && response.data){
        window.location.href = response.data;
      } else{
        NotificationManager.error("Server error: " + response.message)
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  shopifyoauth(){

  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12" style={{ height: 100 + "vh" }}>
          <div className="header-block">
            <div className="Sign-In-Header">Welcome to Quizzes4Leads</div>
          </div>
          <div className="header2-block">
            <div className="Sign-In-Text">
              Logging User....
            </div>
          </div>
          <div className="Sign-In-Error text-center ">
            {this.state.loginErrormsg}
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12 sideimage">
        </div>
      </div>
    );
  }
}
