import React, { Component } from "react";
import queryString from "query-string";
import Questions from "./Questions";
import QuizStart from "./QuizStart";
import SubmitResult from "./SubmitResult";
import ResultShow from "./ResultShow";
import EmailFormCode from "./ResultScreens/EmailFormCode";
import LoadingScreen from "./ResultScreens/LoadingScreen";

class PlayQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      backgroundImageUrl: undefined,
      quizData: undefined,
      isQuizStart: false,
      allQuestionsSolved: false,
      resultSaved: false,
      themeId: "",
      allQuestions: [],
      allSelectedAnswers: [],
      quizPlayResult: [],
      cssLoaded: false,
      currentQuestionIndex: 0,
      selectFont: "",
      isIframe: ""
    };
  }

  componentDidMount() {
    const currentUrl = window.location.href;
    // bootstrap 5 imported on this new style.css & its affect dashboard ui
    // using this function on player2 render this style will load
    if (currentUrl.includes("quizplayer2.html")) {
      import("./assets/style.css")
        .then(() => {
          console.log("new-play.css has been loaded");
          this.setState({ cssLoaded: true });
        })
        .catch((error) => {
          console.error("Error loading new-play.css", error);
        });
    }
    if(currentUrl.includes("isiframe=true")) {
      this.setState({
        isIframe: 'yes'
      })
    }

    this.getQuizData();
    this.getQuestion();
  }

  // get quiz id & base path
  getQuizData = () => {
    const query = this.props.location.search;
    const queryParams = queryString.parse(query);
    const quizid = queryParams.quizid;
    const basePath = this.props.basepath;

    // const queryurl = basePath + "quizplayer/getquiz/" + quizid;
    const queryurl = basePath + "v1quizplay/quiz/" + quizid;

    console.log("queryurl", queryurl);

    fetch(queryurl, {
      method: "get",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then(function (response) {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          if (response.data) {
            // get selected font class name from quizwelcome message
            let match = response.data.quizwelcomemsg.match(/class="([^"]*)"/);
            if (match && match.length > 1) {
              var classNames = match[1];
              this.state.selectFont = classNames;
            }
          }
          this.setBackgroundImageUrl(response.data.quizbkgroundimage);
          this.themeClassId(response.data.theme);
          this.setQuizData(response.data);
          this.setLoading(false);
        } else {
          throw new Error("Invalid response data");
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        this.setLoading(false);
      });
  };

  getQuestion = () => {
    const query = this.props.location.search;
    const queryParams = queryString.parse(query);
    const quizid = queryParams.quizid;
    const basePath = this.props.basepath;

    const queryUrl = basePath + "v1quizplay/questions/" + quizid;

    fetch(queryUrl, {
      method: "get",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        let questions = data.data;
        this.setState({
          allQuestions: questions,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  themeClassId = (themeId) => {
    console.log("THEME ID", themeId);
    const themeMappings = {
      theme1: "sweet_peachy",
      theme2: "melting_mauve",
      theme3: "teal_green",
      theme4: "sunny_daze",
      theme5: "rose_pink",
      theme6: "pulpy_orange",
      theme7: "pulpy_orange",
      theme8: "fiercy_red",
      theme9: "starry_blue",
      theme10: "grass_green",
      theme11: "blue_x_gray",
      theme12: "how_good_your_leader_ship",
      theme13: "transparent-x-ark",
    };

    if (themeMappings.hasOwnProperty(themeId)) {
      this.setThemeId(themeMappings[themeId]);
    } else {
      console.warn(`Unknown themeId: ${themeId}`);
    }
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  setBackgroundImageUrl = (backgroundImageUrl) => {
    this.setState({ backgroundImageUrl });
  };

  setQuizData = (quizData) => {
    this.setState({ quizData });
  };

  setThemeId = (themeId) => {
    this.setState({ themeId });
  };

  quizStartStatus = () => {
    this.setState({ isQuizStart: true, currentQuestionIndex: 1 });
  };

  restartQuiz = () => {
    this.setState({
      isQuizStart: false,
      allQuestionsSolved: false,
      resultSaved: false,
    });
  };

  handleAllQuestionsSolved = (answers) => {
    this.setState({
      allQuestionsSolved: true,
      allSelectedAnswers: answers,
    });
  };

  handleOnResultSave = (user) => {
    this.setState({ resultSaved: true });
    const basePath = this.props.basepath;

    var postdata =
      "name=" +
      user.name +
      "&email=" +
      user.email +
      "&quiztype=" +
      this.state.quizData.quiztype +
      "&quizid=" +
      this.state.quizData.id +
      "&selected_ans=" +
      JSON.stringify(this.state.allSelectedAnswers);

    const queryurl = basePath + "v1quizplay/result/";

    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    })
      .then(function (response) {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        if (response.status) {
          this.setState({
            quizPlayResult: response.data,
          });
          if (
            response.data.personalitydetails &&
            response.data.quizType === "qztype2" &&
            response.data.personalitydetails.landingformurl
          ) {
            window.location.href =
              response.data.personalitydetails.landingformurl;
          }
          // if has landing page redirect
          if (this.state.quizData.landingformtype === "custom") {
            // window.location.href = this.state.quizData.landingformurl;
          }
        }
        console.log("response", response.data);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        this.setLoading(false);
      });
  };

  handleGetCurrentQuestionIndex = (index) => {
    this.setState({
      currentQuestionIndex: index + 1,
    });
  };

  render() {
    const {
      loading,
      backgroundImageUrl,
      quizData,
      isQuizStart,
      allQuestionsSolved,
      resultSaved,
      themeId,
      allQuestions,
    } = this.state;

    if (!this.state.cssLoaded) {
      return null;
    }
    console.log("PLYA, quiz ", quizData);

    return (
      <section
        className="quiz_section"
        id={themeId}
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          position: "relative",
          height: this.state.isIframe ? 'unset' : ''
        }}
      >
        {loading ? (
          <div
            className="loading-indicator"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <LoadingScreen msg="Loading Quiz" />
          </div>
        ) : (
          <div
            className={
              "container h-100 v2" +
              quizData.quiztype +
              " v2them" +
              quizData.theme +
              " v2themetype" +
              quizData.theme_type
            }
          >
            <div className="row h-100">
              <div className="col-lg-10 mx-auto h-100">
                <div className="d-flex align-items-center h-100 w-100">
                  <div className="quiz_wrap-block w-100">
                    {quizData.is_enable_quiz_title && (
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="quiz_wrap__header d-flex align-items-center"
                            style={{
                              background: quizData.theme_colors
                                ? quizData.theme_colors.header_bg ||
                                  quizData.theme_colors.header_bg ||
                                  ""
                                : "",
                            }}
                          >
                            <div className="header_title ql-editor">
                              <h4
                                style={{
                                  color: quizData.theme_colors
                                    ? quizData.theme_colors.header_content_text
                                    : "",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: quizData.quiztitle,
                                }}
                              ></h4>
                            </div>
                            <div className="header_logo">
                              <img
                                className="img-fluid"
                                src="https://www.quizzes4leads.com/wp-content/themes/quizzes4leads/assets/images/logo.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!isQuizStart ? (
                      <QuizStart
                        continue_button_text={
                          quizData ? quizData.continue_button_text : ""
                        }
                        btn_start_text={quizData ? quizData.btn_start_text : ""}
                        quizData={quizData}
                        quizStartStatus={this.quizStartStatus}
                        selectFont={
                          this.state.selectFont ? this.state.selectFont : ""
                        }
                      />
                    ) : !allQuestionsSolved ? (
                      <Questions
                        questions={allQuestions ? allQuestions : "no question"}
                        onAllQuestionsSolved={this.handleAllQuestionsSolved}
                        theme_colors={quizData ? quizData.theme_colors : ""}
                        getCurrentQuestionIndex={
                          this.handleGetCurrentQuestionIndex
                        }
                        welcome_message_color={
                          quizData ? quizData.welcome_message_color : ""
                        }
                        selectFont={
                          this.state.selectFont ? this.state.selectFont : ""
                        }
                      />
                    ) : !resultSaved ? (
                      quizData.emailformtype !== "custom" ? (
                        <SubmitResult
                          enter_your_email_text={
                            quizData ? quizData.enter_your_email_text : ""
                          }
                          enter_your_name_text={
                            quizData ? quizData.enter_your_name_text : ""
                          }
                          privacy_policy_text={
                            quizData ? quizData.privacy_policy_text : ""
                          }
                          quizemailmsg={quizData ? quizData.quizemailmsg : ""}
                          onResultSave={this.handleOnResultSave}
                          theme_colors={quizData ? quizData.theme_colors : ""}
                          welcome_message_color={
                            quizData ? quizData.welcome_message_color : ""
                          }
                          selectFont={
                            this.state.selectFont ? this.state.selectFont : ""
                          }
                        />
                      ) : (
                        <EmailFormCode
                          quizemailformcode={
                            quizData ? quizData.quizemailformcode : ""
                          }
                          header_content_bg={
                            quizData
                              ? quizData.theme_colors.header_content_bg
                              : ""
                          }
                          onResultSave={this.handleOnResultSave}
                        />
                      )
                    ) : this.state.quizData.landingformtype != "custom" ? (
                      <ResultShow
                        landingformtype={this.state.quizData.landingformtype}
                        results={this.state.quizPlayResult}
                        quizData={quizData}
                        restartQuiz={this.restartQuiz}
                        selectFont={
                          this.state.selectFont ? this.state.selectFont : ""
                        }
                      />
                    ) : (
                      <>
                        <LoadingScreen
                        msg="Processing your response"
                          theme_colors={quizData ? quizData.theme_colors : ""}
                        />
                        <div
                          className="quiz_wrap__footer"
                          style={{
                            background: quizData.theme_colors.footer_bg
                              ? quizData.theme_colors.footer_bg
                              : "",
                          }}
                        ></div>
                      </>
                    )}

                    <div
                      className="quiz_wrap__footer ql-editor"
                      style={{
                        background: quizData.theme_colors.footer_bg
                          ? quizData.theme_colors.footer_bg
                          : "",
                      }}
                    >
                      {this.state.isQuizStart && allQuestionsSolved != true ? (
                        <div
                          className={
                            "text-center " +
                            (this.state.selectFont ? this.state.selectFont : "")
                          }
                        >
                          {this.state.currentQuestionIndex}/
                          {allQuestions ? allQuestions.length : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

export default PlayQuiz;
