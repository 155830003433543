/*
This file contains the functionality for user login, user registration and forget password
*/

import React from "react";
import { NotificationManager } from "react-notifications";
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


export class UserRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password2: "",
      token: "",
      userid: null,
      emailFlag: false,
      emailSentFlag: false,
      hide: this.props.hide,
      fname: "",
      lname: "",
      company: "",
      companyurl: "",
      termsflag: false,
      fbloginurl: this.props.fbloginurl,
      tabnum: 0,
      tabs: [],
      progress: 25,
      tokenerrormsg: "",
      pwderrormsg: "",
      emailerrormsg: "",
      showLoaderFlag: false,
      loaderMessage: "Loading...",
    };
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.nexttab = this.nexttab.bind(this);
    this.prevtab = this.prevtab.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.clearState = this.clearState.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(event) {
    this.clearState();
    this.props.history.push("/index.html");
  }

  componentDidMount(){
    //window.dataLayer.push({'signup_initiated': true, 'signup_success':false,'signup_error':false});
  }

  clearState() {
    this.setState({
      tabnum: 0,
      progress: 25,
      email: "",
      password: "",
      password2: "",
      token: "",
      emailFlag: false,
      emailSentFlag: false,
      hide: this.props.hide,
      fname: "",
      lname: "",
      company: "",
      companyurl: "",
      termsflag: true,
      tokenerrormsg: "",
      pwderrormsg: "",
      showLoaderFlag: false,
      emailerrormsg: "",
    });
  }

  authenticateUser() {
    this.setState({
      showLoaderFlag: true,
      loaderMessage: "Signing in the user...",
    });
    var self = this;
    // var queryurl = this.props.basepath + 'index.php/quizzer/authn';
    //for node
    var queryurl = this.props.basepath + "quizzer/authn";
    var postdata ="action=userauth&pwd=" +this.state.password + "&email=" + this.state.email + "&uname=q1";

    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      self.setState({ showLoaderFlag: false });
      var userdata = {
        name: null,
        isAuthenticated: false,
        userid: null,
        mode: null,
        usertype: null,
      };

      if (response && response.status === 200 && response.data) {
        userdata = response.data;
        userdata.isAuthenticated = true;
        self.props.loginCheck(userdata);
        self.props.history.push("/app/");
      } else {
        NotificationManager.error("Login Error",response.message);
        return false;
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  onTextChange(event) {
    var name = event.target.name;
    var txtValue = event.target.value;
    var emailerrormsg = this.state.emailerrormsg;
    var pwderrormsg = this.state.pwderrormsg;
    if (name === "email") {
      emailerrormsg = "";
    }
    if (name === "password") {
      pwderrormsg = "";
    }

    this.setState({ [name]: txtValue, emailerrormsg, pwderrormsg });
  }

  islasttab() {
    return this.state.tabnum === this.state.tabs.length - 1;
  }

  isfirsttab() {
    return this.state.tabnum === 0;
  }
  issecondtab() {
    return this.state.tabnum === 1;
  }
  isthirdtab() {
    return this.state.tabnum === 2;
  }

  selecttab(tabNum) {
    if (this.isAvailable(this.state.tabs[this.state.tabnum])) {
      this.setState({ tabnum: tabNum });
    }
  }

  handleClickCancel(e) {
    e.preventDefault();
    this.props.onClose();
  }

  nexttab(event) {
    var index = this.state.tabnum;
    var skipflag = false;
    //var maxindex = this.state.tabs.length - 1;
    //var flag = false;
    if (validateEmail(this.state.email) === false) {
      //this.setState({showLoaderFlag:false})
      this.setState({ emailerrormsg: "Invalid Email Format" });
    } else {
      switch (index) {
        case 0:
          if (this.state.fname === null || this.state.fname === "") {
            //this.setState({showLoaderFlag:false})
            alert("Full Name cannot be empty");
            return false;
          } else {
            this.generateToken(false);
          }
          break;
        case 1:
          //flag = this.verifyToken();
          var btnSkip = event.target.name;
          if (btnSkip === "btnskip") {
            skipflag = true;
          } else {
            if (this.state.company === null || this.state.company === "") {
              this.setState({ showLoaderFlag: false });
              alert("Company name cannot be empty");
              return false;
            }
          }
          this.setState({ showLoaderFlag: false });
          var tabnum = this.state.tabnum;
          tabnum++;
          this.setState({ tabnum, skipflag });

          break;
        case 2:
          skipflag = this.state.skipflag;
          this.verifyToken(skipflag);
          break;
        default:
          //todo: Handle error case / default case
      }
    }
  }

  generateToken(skipflag) {
    this.setState({
      showLoaderFlag: true,
      loaderMessage: "Setting up account...",
    });
    var self = this;
    var email = this.state.email;
    // var queryurl = this.props.basepath + "index.php/quizzer/authn";
    //for node
    var queryurl = this.props.basepath + "quizzer/register";

    var postdata = "action=generatetoken&email=" + email;

    //alert('postdata is ' + postdata);
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      self.setState({ showLoaderFlag: false });
      //var respArr = data;
      if (response && response.status===200 && response.data) 
      {
        var index = self.state.tabnum;
        index++;
        if (skipflag === false) {
          self.setState({ tabnum: index });
        }
      } else {
        self.setState({ emailerrormsg: response.message, tokenerrormsg: "" });
        return false;
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  verifyToken(skipflag) {
    this.setState({
      showLoaderFlag: true,
      loaderMessage: "Verifying Token...",
    });
    var self = this;
    var username = this.state.email;
    var password = this.state.password;
    var email = this.state.email;
    var token = this.state.token;
    // var queryurl = this.props.basepath + "index.php/quizzer/authn";
    var queryurl = this.props.basepath + "quizzer/register";

    var postdata ="action=verifytoken&email=" +email +"&token=" +token +"&username=" +username +"&password=" +password;
    //alert('postdata is ' + postdata);
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      self.setState({ showLoaderFlag: false });
      //var respArr = data;
      if (response && response.status===200 && response.data) 
      {
        //window.dataLayer.push({'signup_initiated': false, 'signup_success':true,'signup_error':false});
        var index = self.state.tabnum;
        //var progressVal =self.state.progress + (1 / self.state.tabs.length) * 100;
        index++;
        self.setState({ tabnum: index }, () => {self.createUser(skipflag);});
      } else {
        //window.dataLayer.push({'signup_initiated': false, 'signup_success':false,'signup_error':true});
        self.setState({ emailerrormsg: response.message });
        return false;
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }


  createUser(skipflag) {
    this.setState({ showLoaderFlag: true,loaderMessage: "Creating user account..."});
    var login = {};
    login["username"] = this.state.fname + " " + this.state.lname;
    login["password"] = this.state.password;
    login["email"] = this.state.email;

    var user = {};
    user["fname"] = user["lname"] = user["companyname"] = "";
    if (skipflag === false) {
      user["fname"] = this.state.fname;
      user["lname"] = this.state.lname;
      user["companyname"] = this.state.company;
      user["companyurl"] = this.state.companyurl;
    }
    user["emailid"] = this.state.email;
    //user['id'] = this.state.userid;
    user["name"] = this.state.name;

    // var queryurl = this.props.basepath + "index.php/quizzer/authn";
    var queryurl = this.props.basepath + "quizzer/register";

    var postdata ="action=createuser&login=" +JSON.stringify(login) +"&user=" + JSON.stringify(user);

    var self = this;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      self.setState({ showLoaderFlag: false });
      if (response && response.status===200 && response.data) {
        self.authenticateUser();
      } else {
        self.setState({emailerrormsg:response.message});
        return false;
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  prevtab() {
    var index = this.state.tabnum - 1;
    var progressVal = this.state.progress - (1 / this.state.tabs.length) * 100;
    this.setState({ tabnum: index, progress: progressVal });
  }

  render() {
    console.log("this.state.tabnum",this.state.tabnum)
    return (
      <div className="row">
        <div
          className="col-md-6 col-sm-6 col-xs-12"
          style={{ height: 100 + "vh" }}
        >
          <div className="steps">
            <div className={`frmCredentials ${this.state.tabnum != "0" ? "hide" : ""}`}>
              <div className="logo-block" style={{ marginTop: 45 }}>
                <img
                  src="/assets/img/q4llogo.png"
                  className="Sign-In-Logo center-block"
                />
              </div>
              <div className="header-block">
                <div className="Sign-In-Header">Welcome to Quizzes4Leads</div>
              </div>
              <div className="header2-block">
                {/* <div className="Sign-In-Text">Please enter your credentials to proceed.</div> */}
              </div>
              <div className="Sign-In-Error text-center">
                {this.state.emailerrormsg}
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 Sign-In-Form-Div">
                <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-control-box">
                    <div className="Sign-In-Label">FULL NAME</div>

                    <input
                      name="fname"
                      className="Sign-In-TextBox"
                      required
                      type="text"
                      tabIndex="1"
                      onChange={this.onTextChange}
                      value={this.state.fname}
                    />
                  </div>
                </div>
                <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-control-box">
                    <div className="Sign-In-Label">EMAIL ADDRESS</div>

                    <input
                      name="email"
                      className="Sign-In-TextBox"
                      id="exampleqztitle"
                      required
                      type="email"
                      onChange={this.onTextChange}
                      value={this.state.email}
                    />
                  </div>
                </div>
                <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-control-box">
                    <div className="Sign-In-Label">PASSWORD</div>
                    <input
                      name="password"
                      className="Sign-In-TextBox"
                      required
                      type="password"
                      onChange={this.onTextChange}
                      value={this.state.password}
                    />
                  </div>
                </div>

                <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
                  <div className="signin-btn-block">
                    {/* <h6>
                      By signing up, I agree to the  f
                      <a
                        href="https://quizzes4leads.com/privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>{" "}
                      and
                      <a
                        href="https://quizzes4leads.com/terms-of-service"
                        target="_blank"
                      >
                        Terms of Service
                      </a>
                    </h6> */}
                    <div
                      className="Sign-In-Button center-block"
                      onClick={this.nexttab}
                    >
                      {this.islasttab() ? "hide" : "Continue"} {this.state.showLoaderFlag ? <i class="fa fa-spinner fa-spin"></i> : ''}
                    </div>
                  </div>
                </div>

                <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
                  <div className="signup-block">
                    <div className="Sign-In-Text center-block">
                      Already have an account?
                      <a className="Sign-In-Link" href="/index.html">
                        {" "}
                        Sign in
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-offset-3 col-md-6 col-sm-6 col-xs-12">
                                        <div className="Sign-In-Line center-block">
                                            <div className="Sign-In-Oval">OR</div>
                                        </div>
                                    </div>
                                    <div className="col-md-offset-3 col-md-6 col-sm-6 col-xs-12">
                                        <div className="header3-block">
                                            <div className="Sign-In-Text">Sign in with one click</div>
                                        </div>
                                    </div>
                                    <div className="col-md-offset-3 col-md-6 col-sm-6 col-xs-12">
                                        <div className="signin-fb">
                                        <FacebookLogin
                                        appId="3805076026252317"
                                        fields="name,email,picture,first_name,last_name"
                                        callback={this.checkfbStatus}
                                        render={renderProps=>(
                                            <img title="Facebook Login" data-login="fblogin"
                                         onClick={renderProps.onClick} className="Sign-In-Facebook-logo center-block"
                                         src="/assets/img/fblogo_new.png" style={{cursor:'pointer'}}/>
                                        )}                                        
                                    />
                                        </div>
                                    </div> */}
              </div>
            </div>
            <div
              className={`frmPersonalDetails ${this.state.tabnum != "1" ? "hide" : ""
                }`}
            >
              <div className="row" style={{ marginTop: 3.5 + "%" }}>
                <div className="col-md-12" style={{ marginLeft: 15 }}>
                  <div className="pull-left">
                    <a className="Sign-In-Link" onClick={this.prevtab}>
                      Back
                    </a>
                  </div>
                  <div className="pull-right">
                    <a
                      className="Sign-In-Link"
                      onClick={this.nexttab}
                      name="btnskip"
                    >
                      Skip for Now {" > "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="logo-block" style={{ marginTop: 25 }}>
                <img
                  src="/assets/img/q4llogo.png"
                  className="Sign-In-Logo center-block"
                />
              </div>

              <div className="header-block">
                <div className="Sign-In-Header">Almost there...</div>
              </div>
              <div className="header2-block">
                <div className="Sign-In-Text">
                  Please enter your company details to
                </div>
                <div className="Sign-In-Text" style={{ marginTop: 0 }}>
                  help us serve you better.
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 Sign-In-Form-Div">
                <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-control-box">
                    <div className="Sign-In-Label">COMPANY'S NAME</div>
                    <input
                      name="company"
                      className="Sign-In-TextBox"
                      required
                      type="text"
                      tabIndex="3"
                      onChange={this.onTextChange}
                      value={this.state.company}
                    />
                  </div>
                </div>
                <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-control-box">
                    <div className="Sign-In-Label">COMPANY'S URL</div>

                    <input
                      name="companyurl"
                      className="Sign-In-TextBox"
                      required
                      type="text"
                      tabIndex="4"
                      onChange={this.onTextChange}
                      value={this.state.companyurl}
                    />
                  </div>
                </div>
                <div className="col-md-offset-2 col-md-8 col-sm-12 col-xs-12">
                  {/* <div
                    className="Sign-In-Text center-block"
                    style={{ marginTop: 4 + "%" }}
                  >
                    By continuing into you read and accepted the{" "}
                    <a className="Sign-In-Link" href="https://www.quizzes4leads.com/privacy-policy">
                      terms and conditions
                    </a>
                  </div> */}
                </div>
                <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
                  <div className="signin-btn-block">
                    <div className="signin-btn-block">
                      <div
                        className="Sign-In-Button center-block"
                        onClick={this.nexttab}
                      >
                        {this.islasttab() ? "hide" : "Create Account"} {this.state.showLoaderFlag ? <i class="fa fa-spinner fa-spin"></i> : ''}
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            <div
              className={`frmVerifyToken ${this.state.tabnum != "2" ? "hide" : ""
                }`}
            >
              <div className="row" style={{ marginTop: 7 + "%" }}>
                <div className="col-md-12" style={{ marginLeft: 15 }}>
                  <div className="pull-left">
                    <a className="Sign-In-Link" onClick={this.prevtab}>
                      Back
                    </a>
                  </div>
                </div>
              </div>
              <div className="logo-block" style={{ marginTop: 45 }}>
                <img
                  src="/assets/img/q4llogo.png"
                  className="Sign-In-Logo center-block"
                />
              </div>

              <div className="header-block">
                <div className="Sign-In-Header">Verify Your Email</div>
              </div>
              <div className="header2-block">
                <div className="Sign-In-Text">
                  A token has been sent to your email <a>{this.state.email}</a>
                </div>
                <div className="Sign-In-Text" style={{ marginTop: 0 }}>
                  Please enter it here.
                </div>
                <div className="Sign-In-Error text-center ">
                  {this.state.emailerrormsg}
                </div>
              </div>

              <div className="col-md-12 col-sm-12 col-xs-12 Sign-In-Form-Div">
                <div className="col-md-offset-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="form-control-box">
                    <div className="Sign-In-Label">VERIFICATION TOKEN</div>
                    <input
                      name="token"
                      className="Sign-In-TextBox"
                      required=""
                      type="text"
                      onChange={this.onTextChange}
                      value={this.state.token}
                    />
                  </div>
                </div>
                <div className="col-md-offset-2 col-md-8 col-sm-12 col-xs-12">
                  <div
                    className="Sign-In-Text center-block"
                    style={{ marginTop: 4 + "%" }}
                  >
                    By continuing into you read and accepted the{" "}
                    <a className="Sign-In-Link" href=" https://www.quizzes4leads.com/privacy-policy">
                      terms and conditions
                    </a>
                  </div>
                </div>
                <div className="col-md-offset-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="signin-btn-block">
                    <div
                      className="Sign-In-Button center-block"
                      onClick={this.nexttab}
                    >
                      Create Account {this.state.showLoaderFlag ? <i class="fa fa-spinner fa-spin"></i> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-md-offset-2 col-md-8 col-sm-12 col-xs-12">
                  <div className="signup-block">
                    <div className="Sign-In-Text center-block">
                      Haven't received a token?{" "}
                      <a
                        className="Sign-In-Link"
                        href="#"
                        onClick={() => {
                          this.generateToken(true);
                        }}
                      >
                        Send Again
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div
            className="ba-wizard-navigation-container"
            style={{ bottom: 20, position: "absolute" }}
          >
            <div
              className={`ba-wizard-navigation ${this.state.tabnum == 0 ? "active" : ""
                }`}
              data-index="0"
            >
              Account Details
            </div>
            <div
              className={`ba-wizard-navigation ${this.state.tabnum == 1 ? "active" : ""
                }`}
              data-index="1"
            >
              Personal Details
            </div>
            <div
              className={`ba-wizard-navigation ${this.state.tabnum == 2 ? "active" : ""
                }`}
              data-index="2"
            >
              Verification
            </div>
          </div>
          <div
            style={{
              width: 100 + "%",
              position: "absolute",
              bottom: 0,
              left: 0,
              width: 100 + "%",
            }}
          >
            <div className="progress ba-wizard-progress">
              <div
                className="progress-bar progress-bar-quizzer active"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: ((this.state.tabnum + 1) * 100) / 3 + "%" }}
              ></div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12 sideimage"></div>
        <div>
        </div>{" "}
      </div>
    );
  }
}
export class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailFlag: false,
      emailSentFlag: false,
      hide: this.props.hide,
      emailmsg: null,
      fbloginurl: this.props.fbloginurl,
    };
    this.handleClickSubmit = this.handleClickSubmit.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
  }

  onEmailChange(e) {
    var email = e.target.value;
    this.setState({ emailFlag: true, email: email, emailmsg: null });
  }

  handleClickSubmit(e) {
    e.preventDefault();
    if (this.state.emailFlag) {
      var queryurl = this.props.basepath + "quizzer/forgotpassword";
      var postdata =
        "action=forgetpwd" + "&email=" + this.state.email + "&uname=q1";
      var self = this;
      fetch(queryurl, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: postdata,
      }).then(function (response) {
        return response.json();
      }).then(function (response) {
        if (response && response.status ==200 && response.data) {
          self.setState({emailSentFlag: true,emailmsg:"We have received your request. If your email is registered with us, we will send details."});
        } else {
          self.setState({emailSentFlag: false, emailmsg:"We have received your request. If your email is registered with us, we will send details."});
        }
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });
    }
  }

  handleClickCancel(e) {
    e.preventDefault();
    //this.props.onClose();
    this.props.history.push("/index.html");
  }

  render() {
    return (
      <div className="row">
        <div
          className="col-md-6 col-sm-6 col-xs-12"
          style={{ height: 100 + "vh" }}
        >
          <div className="row" style={{ marginTop: 7 + "%" }}>
            <div className="col-md-12" style={{ marginLeft: 15 }}>
              <div className="pull-left">
                <a className="Sign-In-Link" onClick={this.handleClickCancel}>
                  Back
                </a>
              </div>
            </div>
          </div>
          <div className="logo-block">
            <img
              className="Sign-In-Logo center-block"
              src="/assets/img/q4llogo.png"
            />
          </div>

          <div className="header-block">
            <div className="Sign-In-Header">Confirm Your Identity</div>
          </div>
          <div className="header2-block">
            <div className="Sign-In-Text">
              Please enter your registered email address.
            </div>
            <div className="Sign-In-Text" style={{ marginTop: 0 }}>
              We will send you temporary password
            </div>
          </div>

          <div
            className={`Sign-In-Text text-center ${this.state.emailmsg == null ? "hide" : ""
              }`}
            style={{ color: "#8144e5" }}
          >
            {this.state.emailmsg}
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12 Sign-In-Form-Div">
            <div className="col-md-offset-3 col-md-6 col-sm-6 col-xs-12">
              <div className="form-control-box">
                <div className="Sign-In-Label">REGISTERED EMAIL ADDRESS</div>
                <input
                  className="Sign-In-TextBox"
                  id="inputEmail3"
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={this.onEmailChange}
                />
              </div>
            </div>

            <div className="col-md-offset-3 col-md-6 col-sm-6 col-xs-12">
              <div className="signin-btn-block">
                <div
                  className="Sign-In-Button center-block"
                  onClick={this.handleClickSubmit}
                >
                  EMAIL MY PASSWORD {this.state.showLoaderFlag ? <i class="fa fa-spinner fa-spin"></i> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-sm-6 col-xs-12 sideimage"
          style={{ height: 100 + "vh" }}
        >
          {/* <img className="img-fluid" src="/assets/img/Sign-in-img.png" style={{height:100+'%',width:100+'%'}} /> */}
        </div>
      </div>
    );
  }
}

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailFlag: false,
      loginErrormsg: "",
      passwordFlag: false,
      fbloginurl: this.props.fbloginurl,
      forgetPasswordFlag: false,
      userRegistrationFlag: false,
      loginformflag: true,
      showLoaderFlag: false,
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleClickSubmit = this.handleClickSubmit.bind(this);
    this.handleClickFacebook = this.handleClickFacebook.bind(this);
    this.pwdKeyPress = this.pwdKeyPress.bind(this);
    this.handleForgetPassword = this.handleForgetPassword.bind(this);
    this.closeForgetPassword = this.closeForgetPassword.bind(this);
    this.fblogin = this.fblogin.bind(this);
    this.checkfbStatus = this.checkfbStatus.bind(this);
    this.setCredentials = this.setCredentials.bind(this);
    this.authenticateUser = this.authenticateUser.bind(this);
    this.getUserSession = this.getUserSession.bind(this);
  }

  componentDidMount() {
    this.getUserSession();
  }

getUserSession(){
    var dataurl = this.props.basepath + "quizzer/getusersession";
    var self = this;

    fetch(dataurl, {method: "get",credentials: "include"})
    .then(resp=>resp.json())
    .then(function (response) {
      var user = { isAuthenticated: false, userid: null, name: null,mode:null}
      if(response && response.status == 200 && response.data){
        user = response.data;
        self.props.loginCheck(user);
      }else{
        // no user session exists or user is not authenticated
        user = { isAuthenticated: false, userid: null }
        //self.props.loginCheck(user);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value, emailFlag: true });
  }

  handleChangePassword(e) {
    this.setState({ password: e.target.value, passwordFlag: true });
  }

  pwdKeyPress(e) {
    if (e.key == "Enter") {
      this.authenticateUser("q4l");
    }
  }

  handleForgetPassword(e) {
    this.setState({
      forgetPasswordFlag: true,
      userRegistrationFlag: false,
      loginformflag: false,
    });
  }

  closeForgetPassword() {
    this.props.history.push("/index.html");
  }

  handleClickSubmit(e) {
    e.preventDefault();
    var logintype = e.target.getAttribute("data-login");
    this.authenticateUser(logintype);
  }

  fblogin(response) {
    // this.checkfbStatus()
    /*SAMEER - need to revisit again */
    /*FB.getLoginStatus(function (response) {
            var flag = false;
            if (response.status === 'connected') {
                self.checkfbStatus();
            } else {
                FB.login(function (response) {

                    if (response.status === 'connected') {
                        self.checkfbStatus();
                    } else {
                        var userdata = {'name': null, 'isAuthenticated': false, 'userid': null, 'mode': 'free'};

                    }
                });
            }
        });*/
  }


  checkfbStatus(response) {
    var self = this;
    {
      var user = {};
      user["id"] = response.id;
      user["name"] = response.name;
      user["email"] = response.email;
      user["fname"] = response.first_name;
      user["lname"] = response.last_name;

      var queryurl = self.props.basepath + "quizzer/fbauthEx/login";
      var postdata = "user=" + JSON.stringify(user);
      var querytype = "POST";

      fetch(queryurl, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: postdata,
      }).then(function (response) {
        return response.json();
      }).then(function (respArr) {
          var userdata = {
            name: null,
            isAuthenticated: "false",
            userid: null,
            mode: "free",
            usertype: null,
            picture: null,
          };

          if (respArr.status === "true") {
            userdata = {
              name: respArr.username,
              isAuthenticated: "true",
              userid: respArr.userid,
              mode: respArr.mode,
              usertype: "fbuser",
              picture: response.picture.data.url,
            };
            window.fbq("track", "Lead");
            self.props.loginCheck(userdata);
            return true;
          } else {
            // swal(
            //   "Login failed",
            //   "There was an error logging in. Please try again!!",
            //   "error"
            // );
            NotificationManager.error(
              "Login failed",
              "There was an error logging in. Please try again!!"
            );
            userdata.isAuthenticated = "false";
            self.props.loginCheck(userdata);
            return false;
          }
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });
    }
  }

  setCredentials(email, password) {
    this.setState(
      { email: email, password: password, emailFlag: true, passwordFlag: true },
      () => {
        this.authenticateUser("q4l");
      }
    );
  }

  authenticateUser(logintype) {
    var queryurl = "";
    var self = this;
    var querytype = "POST";
    var postdata = null;

    if (logintype == "fblogin") {
      this.fblogin();
      return;
    } else if (logintype == "q4l") {
      if (this.state.emailFlag && this.state.passwordFlag) {
        if (this.state.email === "") {
          return;
        }
        this.setState({
          showLoaderFlag: true,
          loaderMessage: "Validating user credentials...",
        });
        var queryurl = this.props.basepath + "quizzer/authn";
        postdata =
          "action=userauth&pwd=" +
          this.state.password +
          "&email=" +
          this.state.email +
          "&uname=q1";

        fetch(queryurl, {
          method: "post",
          credentials: "include",
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: postdata,
        }).then(response=> {
          return response.json()
        }).then(function (response) {
          if (response && response.status === 200 && response.data) {
            window.fbq("track", "Lead");
            var userdata = response.data;
            self.props.loginCheck(userdata);
            self.setState({ showLoaderFlag: false });
            //window.gtaglogin();
          } else {
            self.setState({
              loginErrormsg:
                response.message,
              showLoaderFlag: false,
            });
            // swal("Login Error","Login failed. Please check your email and password and try again!!");

            return false;
          }
        }).catch(err=>{
          NotificationManager.error("Server error: " , err.message)
        });
      }
    }
  }

  handleClickFacebook(e) {
    window.location = this.state.fbloginurl;
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12" style={{ height: 100 + "vh" }}>
          <div className="logo-block">
            <a href="https://www.quizzes4leads.com/">
              <img
                className="Sign-In-Logo center-block"
                src="/assets/img/q4llogo.png" />
            </a>
          </div>

          <div className="header-block">
            <div className="Sign-In-Header">Welcome to Quizzes4Leads</div>
          </div>
          <div className="header2-block">
            <div className="Sign-In-Text">
              Please enter your credentials to proceed.
            </div>
          </div>

          <div className="Sign-In-Error text-center ">
            {this.state.loginErrormsg}
          </div>

          <div className="col-md-12 col-sm-12 col-xs-12 Sign-In-Form-Div">
            <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
              <div className="form-control-box">
                <div className="Sign-In-Label">EMAIL ADDRESS</div>
                <input className="Sign-In-TextBox" id="inputEmail3" type="email" placeholder="Email" onChange={this.handleChangeEmail} />
              </div>
            </div>
            <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
              <div className="form-control-box">
                <div className="Sign-In-Label col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <div className="pull-left">PASSWORD</div>
                </div>
                <input className="Sign-In-TextBox"
                  id="inputPassword3"
                  type="password"
                  placeholder="Password"
                  onChange={this.handleChangePassword}
                  onKeyPress={this.pwdKeyPress}
                />
                <div className="pull-right">
                  <a className="Sign-In-Link" href="/ForgetPassword">Forgot Password?</a>
                </div>
              </div>
            </div>

            <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
              <div className="signin-btn-block">
                <div
                  className="Sign-In-Button center-block"
                  onClick={this.handleClickSubmit}
                  data-login="q4l"
                >
                  Sign In {this.state.showLoaderFlag ? <i className="fa fa-spinner fa-spin"></i> : ''}
                </div>
              </div>
            </div>
            <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
              <div className="signup-block">
                <div className="Sign-In-Text center-block">
                  Don't have an account?
                  <a className="Sign-In-Link" href="/Signup">
                    {" "}
                    Sign up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12 sideimage">
        </div>
      </div>
    );
  }
}
