/**
 * This file will show price plans and initiate the payment for plan purchase.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import swal from 'sweetalert';
import { NotificationManager } from 'react-notifications';
import * as DOMPurify from 'dompurify';

//import paypal from 'paypal-checkout';

let PayPalButton = null;
if (typeof window !== 'undefined') {
  PayPalButton = require('react-paypal-button-v2').PayPalButton;
}

//const PayPalButton = paypal.Button.driver('react', {React,ReactDOM });

var CREATE_PAYMENT_URL = 'http://localhost:8888/paypal/createpayment';
var EXECUTE_PAYMENT_URL = 'http://localhost:8888/paypal/executepayment';


//const CREATE_PAYMENT_URL  = 'https://app.quizzes4leads.com/paypal/createpayment';
//const EXECUTE_PAYMENT_URL = 'https://app.quizzes4leads.com/paypal/executepayment';

//const CREATE_PAYMENT_URL  = 'https://uat.quizzes4leads.com/index.php/paypal/createpayment';
//const EXECUTE_PAYMENT_URL = 'https://uat.quizzes4leads.com/index.php/paypal/executepayment';


const client = { 'production': 'AV8iaBZS1KsvPvksMcjNsrjWBeqAQU-16Ii9MSzLB7xNEs-OVUupUzJV2LCMoQb-E2R7Jts8qiORhzF8', 'sandbox': 'AYdoFIGt-ctylhXiMuNblFTZIjnNu-QjjzjJXe-sX8AG5yFKtL4B3BAoTB0huO9JJEmCfu_ZYISJKWP6' };
//client['production'] = 'AV8iaBZS1KsvPvksMcjNsrjWBeqAQU-16Ii9MSzLB7xNEs-OVUupUzJV2LCMoQb-E2R7Jts8qiORhzF8';
var env = 'sandbox';
if (process.env.REACT_APP_ENV === 'stage') {
  CREATE_PAYMENT_URL = 'https://stage.quizzes4leads.com:8443/paypal/createpayment';
  EXECUTE_PAYMENT_URL = 'https://stage.quizzes4leads.com:8443/paypal/executepayment';
}

if (process.env.NODE_ENV === 'production') {
  env = 'production';
  CREATE_PAYMENT_URL = 'https://app.quizzes4leads.com:8443/paypal/createpayment';
  EXECUTE_PAYMENT_URL = 'https://app.quizzes4leads.com:8443/paypal/executepayment';
}

export class PlanForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      planItems: this.props.planItems,
      selectedPlanId: null,
      user: this.props.user,
      activePlanId: 1,
      discountcode: null,
      discountamount: 0.00,
      dataLoadedFlag: false,
      paypalflag: true,
      cuponSuccess: '',
      cuponFailed: '',
      showLoaderFlag: false,
      newPlanItem: true,
      activeTab: 1,
      disablePriceVaritationDiscount: true,
      selectedPlanType: 1
    };

    this.onSelectPlan = this.onSelectPlan.bind(this);
    this.getPlans = this.getPlans.bind(this);
    this.getPlanPrice = this.getPlanPrice.bind(this);
    this.renderPlanIcon = this.renderPlanIcon.bind(this);
    this.onApplyDiscountCode = this.onApplyDiscountCode.bind(this);
    this.getDiscountCode = this.getDiscountCode.bind(this);
    this.getPlans();
    this.getUserPlan();
    this.priceVaritationMontly = this.priceVaritationMontly.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ user: nextProps['user'] });

  }

  componentDidMount() {
    if (this.state.user == null || this.state.user.isAuthtenticated == false) {
      this.props.history.push("/index.html");

    }
  }
  getPlans() {
    var dataurl = this.props.basepath + "payment/getplans";
    var self = this;
    fetch(dataurl,
      {
        method: 'get',
        credentials: 'include'
      }).then(function (response) {
        return response.json();
      }).then(function (response) {
        if (response && response.data && response.status == 200) {
          self.setState({ planItems: response.data, dataLoadedFlag: true });
        }
        else {
          NotificationManager.error("Server error: ", response.message);
          self.setState({ dataLoadedFlag: true });
        }
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });

  }

  getUserPlan() {

    var dataurl = this.props.basepath + "payment/getuserplan";
    var self = this;
    
    fetch(dataurl,
      {
        method: 'get',
        credentials: 'include'
      }).then(function (response) {
        return response.json();
      }).then(function (response) {
        if (response && response.data && response.status == 200) {
          self.setState({ activePlanId: response.data, dataLoadedFlag: true });
        }
        else {
          NotificationManager.error("Server error: ",response.message);
          self.setState({ dataLoadedFlag: true });
        }
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });

  }

  onSelectPlan(event) {
    var planid = event.target.getAttribute('data-id');
    var planname = event.target.getAttribute('data-planname');
    if (planid == this.state.activePlanId) {
      //swal("Quizzer Plan","Selected Plan is already active in your account","info");
      NotificationManager.info("Quizzer Plan", "Selected plan is already active for your account.");
    }
    else {
      this.setState({ selectedPlanId: planid, selectedPlanName: planname, checkOutFormFlag: true }, () => { this.getPlanPrice() });
    }
  }

  renderPlanIcon(planid, planname) {
    return <div className={`plan-icon ${planname.toLowerCase()}`} />
  }

  renderPlans() {
    if (this.state.dataLoadedFlag == false) {
      return <h3>Loading Data...</h3>
    }
    if (this.state.planItems == null || this.state.planItems.length == 0) {
      return <h3>"An error has occured. Please refresh the page."</h3>
    }
    else {
      return this.state.planItems.map(function (planItem, index) {
        var col = "col-md-" + (12 / this.state.planItems.length);
        var self = this;
        return (
          <div className={col} key={planItem.planid}>
            <div className="plan-container">
              <div className="qzplan-panel animated zoomIn">
                <div className="qzpanel-body" >
                  <div className="text-center">
                    {this.renderPlanIcon(planItem.planid, planItem.planname)}
                  </div>
                  <div className="text-center">
                    <div className="">
                      <div className="plan-price">
                        $ {planItem.planprice} monthly
                      </div>
                      <div className="plan-title">
                        {planItem.planname}
                      </div>
                      <div className="plan-subtitle">
                        {/* FREE FOR EVER */}
                      </div>
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: planItem.plandescription }}></div>
                        <button data-id={planItem.planid} data-planname={planItem.planname} onClick={this.onSelectPlan} className={`${planItem.planid == self.state.activePlanId ? 'btn-plan-active' : 'btn-plan-normal'}`}>{planItem.planid == self.state.activePlanId ? 'CURRENT PLAN' : 'BUY NOW'}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        );
      }, this);
    }


  }

  createOrder(data, actions, planid) {
    if (planid == 1) {
      //swal("Plan","This is a free plan for ever. Enjoy it!!","info");
      NotificationManager.info("Plan", "This is a free plan for ever. Enjoy it!!");
      return;
    }
    var self = this;
    // console.log('data in create order is '+JSON.stringify(data));
    var {discountcode} = self.state; 
    var userid = self.state.user.userid;
    var postdata = JSON.stringify({userid,planid,discountcode})
    return fetch(CREATE_PAYMENT_URL, {
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      body: postdata
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      //self.setState({orderID:data.orderID});
      console.log('order id is ',data.orderID);
      return data.orderID;
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });;
  }

  onApprove(data,details) {
    var self = this;
    var orderID = data.orderID;
    var userid = self.state.user.userid;
    var postdata = JSON.stringify({orderID,userid});
    
    return fetch(EXECUTE_PAYMENT_URL, {
      method: 'post',
      headers: { 'content-type': 'application/json' },
      body: postdata
    }).then(function (res) {
      return res.json();
    }).then(function (response) {
      if(response && response.status==200 && response.data){
        NotificationManager.success('Payment Success', 'Your payment is successful.');
      }else{
        NotificationManager.error('Payment failed', response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  onApplyDiscountCode() {
    this.setState({
      showLoaderFlag: true
    })
    var discountcode = this.state.discountcode;
    if (discountcode == null || discountcode == '') {
      alert('Disount code cannot be null or empty.');
      return;
    }
    else {
      // call to API to validate discount code and return the exact discount amount.

      var discountamount = 0;
      var queryurl = this.props.basepath + "payment/validatediscountcode";
      var self = this;
      var postdata = 'discountcode=' + discountcode + '&planid=' + self.state.selectedPlanId + '&planname=' + self.state.selectedPlanName + '&userid=' + self.state.user.userid;

      fetch(queryurl, {
        method: "post",
        credentials: "include",
        headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: postdata
      }).then(function (response) {
        return response.json();
      }).then(function (response) {
        // console.log("self.state.planpricedata",self.state.planpricedata.plancost)
        if (response && response.status == 200 && response.data && response.data.discountamount > 0 ) {
          var planpricedata = self.state.planpricedata;
          discountamount = response.data.discountamount;
          planpricedata.totalcost = planpricedata.plancost - discountamount;
          planpricedata.totalcost = planpricedata.totalcost.toFixed(2);

          self.setState({
            discountamount,
            planpricedata,
            cuponSuccess: response.data.message,
            paypalflag: response.data.paypalflag,
            cuponFailed: '',
            showLoaderFlag: false
          })
        } else {
          var planpricedata = self.state.planpricedata;
          planpricedata.totalcost = self.state.planpricedata.plancost;

          self.setState({
            cuponFailed: response.message,
            cuponSuccess: '',
            showLoaderFlag: false,
            paypalflag: true,
            discountamount
          })
        }
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });
    }
  }

  getDiscountCode(event) {
    var discountcode = event.target.value;
    this.setState({ discountcode });
  }

  getPlanPrice() {
    this.setState({
      disablePriceVaritationDiscount: false
    })
    var selectedplan = this.state.planItems.filter((item) => (item.planid == this.state.selectedPlanId));
    if (selectedplan == null || selectedplan.length == 0) {
      return <div className="col-md-12">An Error has occured please try again!!</div>
    }
    else {
      var planprice = selectedplan[0].planprice * this.state.selectedPlanType;
      var tax = (planprice * 0.18).toFixed(2);
      var totalcost = parseInt(planprice) + parseFloat(tax);
      totalcost = totalcost.toFixed(2);
      var plancost = totalcost;
      var planname = selectedplan[0].planname;
      var planid = selectedplan[0].planid;
      var planpricedata = { planprice, tax, plancost, totalcost, planname, planid }
      this.setState({ planpricedata });
    }
  }

  renderSelectedPlanDetails() {
    var planpricedata = this.state.planpricedata;
    if (planpricedata == null) {
      return <div className="col-md-12">An Error has occured please try again!!</div>
    }
    var tax = planpricedata.tax;
    var totalcost = parseFloat(planpricedata.totalcost);
    var discountamount = this.state.discountamount;
    totalcost = totalcost.toFixed(2);
    return (

      <div className="col-md-8 column_center">
        <table className="table">
          <thead className="heading">
            <tr>
              <th className="text-left">Item Name</th>
              <th className="text-right">Price (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="text-left">
                  {planpricedata.planname}
                </div>
              </td>
              <td>
                <div className="text-right">
                  $ {planpricedata.planprice.toFixed(2)}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-left">
                  Tax
                </div>
              </td>
              <td>
                <div className="text-right">
                  $ {tax}
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div className="text-left">
                  Discount Code
                </div>
              </td>
              <td>
                <div className="text-right">
                  <div className=" col-md-offset-7 col-md-3">
                    <textarea className="form-control Sign-In-TextBox" style={{ marginTop: 0 }} name="discountcode" onChange={this.getDiscountCode} id="discountcode" rows="1" value={this.state.discountcode}></textarea>
                  </div>
                  <div className="col-md-2">
                    <button className="btn-normal" type="button" onClick={this.onApplyDiscountCode}>Apply {this.state.showLoaderFlag ? <i className='fa fa-spinner fa-spin' style={{ marginLeft: 5 }}></i> : null}</button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-left">
                  Discount amount
                </div>
              </td>
              <td>
                <div className="text-right">
                  $ {Number(discountamount).toFixed(2)}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-left">
                  Total Cost
                </div>
              </td>
              <td>
                <div className="text-right">
                  $ {totalcost}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {this.state.cuponSuccess ? (
          <div className="success-danger-modify alert alert-success" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.cuponSuccess) }} />
        ) : ''}
        {this.state.cuponFailed ? (
          <div className="success-danger-modify alert alert-danger">{this.state.cuponFailed}</div>
        ) : ''}
      </div>

    )

  }

  renderCheckOutForm() {
    var planpricedata = this.state.planpricedata;

    var totalcost = null;
    if (planpricedata != null) {
      totalcost = planpricedata.totalcost;
    }
    return <div className="col-md-12">

      <div className="col-md-12">
        {this.renderSelectedPlanDetails()}
      </div>
      <div className='checkout-page-all-feature-text'>
      {this.allFeatureIncludeInfo()}
      </div>
      <div className="col-md-12">
        <div className="col-md-offset-4 col-md-4">
          {PayPalButton && this.state.paypalflag ? <PayPalButton
            amount={totalcost}
            onSuccess={(details, data) => {
              this.onApprove(data, details);
            }}
            createOrder={(details, data) => {
              return this.createOrder(data, details, planpricedata.planid);
            }}
            
            options={{ clientId: (process.env.NODE_ENV === 'production' ? client.production : client.sandbox) }}
          /> : null}

        </div>
      </div>
    </div>
  }

  priceVaritationMontly(e, pricePlanDiscount, selectedPlanType) {
    e.preventDefault();
    if (pricePlanDiscount > 0) {
      if (this.state.newPlanItem == true) {
        for (var i = 0; i < this.state.planItems.length; i++) {
          var currentPrice = this.state.planItems[i].planprice;
          var newPrice = (currentPrice - (currentPrice * pricePlanDiscount) / 100).toFixed(0);
          this.state.planItems[i].planprice = newPrice;
        }
        this.setState({
          planItems: this.state.planItems,
          newPlanItem: false,
          selectedPlanType: selectedPlanType,
          activeTab: 2
        })
      }

    } else {
      this.getPlans();
      this.setState({
        newPlanItem: true,
        activeTab: 1,
        selectedPlanType: selectedPlanType,
      })
    }
  }

  render() {
    var content = this.renderPlans();
    let isRendarCheckoutPage = false;
    if (this.state.checkOutFormFlag) {
      isRendarCheckoutPage = true;
      content = this.renderCheckOutForm();
    }
    return (
      <div className="">
        {this.state.disablePriceVaritationDiscount == true ?
          <div className="col-md-12 price-tab-list">
            <ul>
              <li className={this.state.activeTab == 1 ? 'active' : ''}><a onClick={(e) => this.priceVaritationMontly(e, 0, 1)} href="#">Monthly</a></li>
              <li className={this.state.activeTab == 2 ? 'active' : ''}><a onClick={(e) => this.priceVaritationMontly(e, 12, 12)} href="#">12 Month <span>-12%</span></a></li>
            </ul>
          </div>
          : ''}
        {/* <div className="plan-container"> */}
        {content}
        
        {isRendarCheckoutPage ? '' : this.allFeatureIncludeInfo()}
        
      </div>

    );
  }

  allFeatureIncludeInfo(){
    return (
      <div className='col-md-12 plan-detail-feature-free'>
          <ul>
            <li>→ All Features included</li>
            <li>→ Unlimited questions</li>
            <li>→ Unlimited quizzes, polls and in-moment feedback</li>
          </ul>
        </div>
    )
  }
}