import React, { Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ScheduleRetakeBtn from "./ScheduleRetakeBtn";
import SocialSharing from "./SocialSharing";
ChartJS.register(ArcElement, Tooltip, Legend);

var piedata = {
  labels: [],
  datasets: [
    {
      label: ["a", "b", "c", "d", "e", "f"],
      data: [1, 1, 1, 1, 1, 1],
      color: "white",
      backgroundColor: ["red", "green", "orange", "lightblue"],
      borderColor: ["red", "green", "orange", "lightblue"],
      borderWidth: 1,
    },
  ],
};

class MultiPersonality extends Component {
  constructor(props) {
    super(props);

    this.chartReference = React.createRef();

    this.state = {
      chartdata: piedata,
    };
  }

  componentDidMount() {
    const { chartdata } = this.state;
    chartdata.datasets[0].data = [];
    chartdata.datasets[0].label = [];
    if (this.props.results) {
      this.props.results.personalitydetails.forEach(function (value, index) {
        chartdata.datasets[0].data[index] = value.occurence;
        chartdata.labels[index] = value.title;
      });
    }
  }

  render() {
    const {
      theme_colors,
      restartQuiz,
      is_enable_social_media,
      enable_social_sharing_text,
      title,
    } = this.props;

    return (
      <>
        <div className="col-12 qztype7-wrapper">
          <div
            className="quiz_wrap__content text-center"
            style={{
              background: theme_colors ? theme_colors.header_content_bg : "",
            }}
          >
            <div className="qztype7-result-text">
              <ul>
                {this.props.results.personalitydetails.map((value, index) => (
                  <li key={index}>
                    {value.title} - {value.occurence}%
                  </li>
                ))}
              </ul>
            </div>
            <div className="quiz_wrap__content___text">
              <Pie
                ref={this.chartReference}
                data={this.state.chartdata}
                width={300}
                height={300}
              />
            </div>
            <ScheduleRetakeBtn restartQuiz={restartQuiz} />
            {is_enable_social_media && (
              <SocialSharing
                enable_social_sharing_text={enable_social_sharing_text}
                title={title}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default MultiPersonality;
