/**
 * This file contains the code for usersetting form.
 */

import React from "react";
import { ImageUpload } from "../components/imageupload";
import {NotificationManager} from 'react-notifications';

export class UserSettingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currpwd: "",
      newpwd1: "",
      newpwd2: "",
      user: this.props.user,
      showLoaderFlag: false,
      // email:this.props.profiledata.email,
      // fname:this.props.profiledata.fname,
      // lname:this.props.profiledata.lname,
      // compname:this.props.profiledata.companyname,
      // compurl:this.props.profiledata.companyurl,
      // complogo:this.props.profiledata.companylogo,
      collapseArr: { passwordpanel: true, personal: true },
      profiledata: this.props.profiledata
    };

    //this.onChangeEmail = this.onChangeEmail.bind(this);
    // this.onChangeFirstName = this.onChangeFirstName.bind(this);
    // this.onChangeLastName = this.onChangeLastName.bind(this);
    // this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
    // this.onChangePassword = this.onChangePassword.bind(this);
    // this.onChangePassword2 = this.onChangePassword2.bind(this);

    this.onChangeFormField = this.onChangeFormField.bind(this);

    this.onProfileUpdate = this.onProfileUpdate.bind(this);
    this.onPasswordUpdate = this.onPasswordUpdate.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    // this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    // this.onChangeCompanyUrl = this.onChangeCompanyUrl.bind(this);
    this.onChangeCompanyLogo = this.onChangeCompanyLogo.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);

    this.getUserProfile();
  }

  getUserProfile() {
    var self = this;
    var dataurl = this.props.basepath + "user/getprofile";
    fetch(dataurl, {method: "get",credentials: "include"
    }).then(function(response) {
        return response.json();
    }).then(function(response) {
      if (response && response.status==200 && response.data) {
        self.setState({ profiledata: response.data });
      } else {
        NotificationManager.error("Server error: ", response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  toggleCollapse(event) {
    var name = event.target.getAttribute("name");
    var collapseArr = this.state.collapseArr;
    if (collapseArr == null) {
      collapseArr = {};
    }
    if (collapseArr[name] == null) {
      collapseArr[name] = true;
    } else {
      collapseArr[name] = !collapseArr[name];
    }

    this.setState({ collapseArr: collapseArr });
  }
  onChangeFormField(event) {
    var fieldname = event.target.name;
    var fieldvalue = event.target.value;
    var profiledata = this.state.profiledata;
    if (profiledata == null) {
      profiledata = {};
    }
    profiledata[fieldname] = fieldvalue;
    this.setState({ profiledata: profiledata });
  }

  onChangeCompanyLogo(imgtype, id, imgpath) {
    var profiledata = this.state.profiledata;
    profiledata.companylogo = imgpath;
    this.setState({ profiledata });
  }

  onProfileUpdate(event) {
    this.setState({
      showLoaderFlag: true
    })
    var data = {};

    data["fname"] = this.state.profiledata.fname;
    data["lname"] = this.state.profiledata.lname;
    data["companyname"] = this.state.profiledata.companyname;
    data["companyurl"] = this.state.profiledata.companyurl;
    data["emailid"] = this.state.profiledata.email;

    // if has http leave same url 
    if (data["companyurl"] && data["companyurl"].toLowerCase().indexOf("http://") == 0) {
      data["companyurl"] = data["companyurl"];
    } else if (data["companyurl"] && data["companyurl"].toLowerCase().indexOf("https://") == 0) {
      // if has https leave same url  
      data["companyurl"] = data["companyurl"];
    } else {
      // if no http or https set default https
      let checkIsHasUrl = data["companyurl"] ? data["companyurl"] : '';
      data["companyurl"] = "https://" + checkIsHasUrl;
    }

    data["companylogo"] = this.state.profiledata.companylogo;

    var jsondata = JSON.stringify(data);
    var postdata = "userid=" + this.props.user.userid + "&data=" + jsondata;

    var queryurl = this.props.basepath + "user/saveuserprofile";
    var self = this;
    fetch(queryurl, {method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: postdata
    }).then(function(data) {
      return data.json();
    }).then(function(response) {
      if (response && response.status==200 && response.data) {
        NotificationManager.success("Record Updated", "Profile updated successfully");
      } else {
        NotificationManager.error("Server error: ", response.message);
      }
      self.setState({showLoaderFlag: false})
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  onPasswordUpdate(event) {
    var queryurl = this.props.basepath + "quizzer/updatePassword";

    var pwd = this.state.profiledata.newpwd1;
    var pwd2 = this.state.profiledata.newpwd2;
    var currpwd = this.state.profiledata.currpwd;
    if (pwd == "" || pwd == null) 
    {
      //swal("Error", "Please enter new password", "error");
      NotificationManager.error("Validation Error", "New password cannot be null or empty");
      return false;
    } else if (pwd != pwd2) {
      //swal("Error","New password and confirm password did not match","warning" );
      NotificationManager.error("Validation Error", "New password and confirm password did not match");
      return false;
    }
    var postdata = "userid=" + this.props.user.userid + "&currpwd=" + currpwd + "&pwd=" + pwd;

    fetch(queryurl, {method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: postdata
    }).then(function(data) {
        return data.json();
    }).then(function(response) {
      if (response && response.status==200 && response.data) {
        NotificationManager.success("Password change", "Password upated successfully!!");
      } else {
        NotificationManager.error("Server error: ", response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  render() {
    return (
      <div className="row" style={{ marginTop: 2 + "%" }}>
        <div
          className={`col-md-6 ${
            this.props.user.usertype == "fbuser" ? "hide" : ""
          }`}
        >
          <div className="qzpanel-default">
            <div className="qzpanel-heading clearfix">
              <div
                className="qzpanel-title"
                name="passwordpanel"
                onClick={this.toggleCollapse}
              >
                <i
                  className={`glyphicon m-r-sm ${
                    this.state.collapseArr == null ||
                    this.state.collapseArr["passwordpanel"] == null ||
                    this.state.collapseArr["passwordpanel"] == false
                      ? "glyphicon-chevron-right"
                      : "glyphicon-chevron-down"
                  }`}
                  name="passwordpanel"
                ></i>
                Account Details
              </div>
              <div className="Sign-In-Label" style={{ paddingLeft: 15 }}>
                Change your password
              </div>
            </div>
            <div
              className={`collapse ${
                this.state.collapseArr == null ||
                this.state.collapseArr["passwordpanel"] == null ||
                this.state.collapseArr["passwordpanel"] == false
                  ? null
                  : "show"
              } `}
              id="passwordpanel"
            >
              <div className="qzpanel-body">
                <div className="row">
                  <div className="col-md-12">
                    <label className="Sign-In-Label">EMAIL ADDRESS</label>
                    <input
                      disabled
                      type="text"
                      required
                      className="Sign-In-TextBox"
                      placeholder=""
                      name="email"
                      onChange={this.onChangeFormField}
                      value={
                        // this.state.profiledata != null
                        //   ? this.state.profiledata.email
                        //   : ""
                        this.state.profiledata
                          ? this.state.profiledata.emailid
                          : ""
                      }
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="Sign-In-Label">CURRENT PASSWORD</label>
                    <input
                      type="password"
                      required
                      className="Sign-In-TextBox"
                      name="currpwd"
                      onChange={this.onChangeFormField}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="Sign-In-Label">NEW PASSWORD</label>
                    <input
                      type="password"
                      required
                      className="Sign-In-TextBox"
                      name="newpwd1"
                      onChange={this.onChangeFormField}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="Sign-In-Label">CONFIRM PASSWORD</label>
                    <input
                      type="password"
                      required
                      className="Sign-In-TextBox"
                      name="newpwd2"
                      onChange={this.onChangeFormField}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="btn-normal"
                      type="button"
                      onClick={this.onPasswordUpdate}
                      style={{ width: 100 + "%", marginTop: 10 }}
                    >
                      Save Changes 
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="qzpanel-default">
            <div className="qzpanel-heading clearfix">
              <div
                className="qzpanel-title"
                name="personal"
                onClick={this.toggleCollapse}
              >
                <i
                  className={`glyphicon m-r-sm ${
                    this.state.collapseArr == null ||
                    this.state.collapseArr["personal"] == null ||
                    this.state.collapseArr["personal"] == false
                      ? "glyphicon-chevron-right"
                      : "glyphicon-chevron-down"
                  }`}
                  name="personal"
                ></i>
                Personal Details
              </div>
              <div className="Sign-In-Label" style={{ paddingLeft: 15 }}>
                Edit your personal details
              </div>
            </div>
            <div
              className={`collapse ${
                this.state.collapseArr == null ||
                this.state.collapseArr["personal"] == null ||
                this.state.collapseArr["personal"] == false
                  ? null
                  : "show"
              } `}
            >
              <div className="qzpanel-body">
                <div className="row">
                  <div className="col-md-12">
                    <label className="Sign-In-Label">FULL NAME</label>
                    <input
                      type="text"
                      required
                      className="Sign-In-TextBox"
                      placeholder=""
                      name="fname"
                      onChange={this.onChangeFormField}
                      value={
                        this.state.profiledata != null
                          ? this.state.profiledata.fname
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="Sign-In-Label">COMPANY NAME</label>
                    <input
                      type="text"
                      required
                      className="Sign-In-TextBox"
                      placeholder=""
                      name="companyname"
                      onChange={this.onChangeFormField}
                      value={
                        this.state.profiledata != null
                          ? this.state.profiledata.companyname
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Company Url</label>
                    <input
                      type="text"
                      required
                      className="Sign-In-TextBox"
                      placeholder=""
                      name="companyurl"
                      onChange={this.onChangeFormField}
                      value={
                        this.state.profiledata != null
                          ? this.state.profiledata.companyurl
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 user-profile-section">
                    <ImageUpload
                      basepath={this.props.basepath}
                      userid={this.props.user.userid}
                      imgtype="companylogo"
                      title="Upload Company Logo (50x50)"
                      uploaded_uri=""
                      imagepath={
                        this.state.profiledata != null
                          ? this.state.profiledata.companylogo
                          : ""
                      }
                      onImageUploadComplete={this.onChangeCompanyLogo}
                    />
                    <img
                      src={
                        this.state.profiledata != null
                          ? this.state.profiledata.companylogo
                          : null
                      }
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="btn-normal"
                      type="button"
                      onClick={this.onProfileUpdate}
                      style={{ width: 100 + "%", marginTop: 10 }}
                    >
                      Update {this.state.showLoaderFlag ? <i className='fa fa-spinner fa-spin' style={{ marginLeft: 5 }}></i> : null}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
