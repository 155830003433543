/**
 * This file contains the code for application settings like email notification and CRM settings
 */
import React from "react";
import swal from "sweetalert";
import {NotificationManager} from 'react-notifications';
export class SettingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { crmsetting: null, emailnotificationsetting: null,emailnotificationstatus:true };
    this.onChangeCRMField = this.onChangeCRMField.bind(this);
    this.onChangeEmailNotificationField = this.onChangeEmailNotificationField.bind(
      this
    );
    this.onCRMSettingUpdate = this.onCRMSettingUpdate.bind(this);
    this.onEmailNotificationUpdate = this.onEmailNotificationUpdate.bind(this);
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    this.onSendTestRequestWebhook = this.onSendTestRequestWebhook.bind(this);

    this.getEmailNotificationSetting();
    this.getCRMSetting();
    this.getAffiliateCode();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ setting: nextProps.setting });
  }

  getCRMSetting() {
    var postdata = "userid=" + this.props.user.userid;

    //var queryurl= this.props.basepath + "index.php/crm/getcrmsetting";
    var queryurl = this.props.basepath + "crm/getcrmsetting";
    var self = this;

    fetch(queryurl, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: postdata,
      credentials: "include"
    }).then(function(data) {
      return data.json();
    }).then(function(response) {
      if (response && response.status==200 && response.data && response.data.length >0) {
        var crmsetting = JSON.parse(response.data[0]["crmdata"]);
        self.setState({ crmsetting });
      }else{
        NotificationManager.error("Server error: ", response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
    //   $.ajax({
    //     type: "POST", url: queryurl, dataType: "json",data:postdata,xhrFields: { withCredentials: true },
    //     success: function (response)
    //     {

    //     },
    //     error: function (data)
    //     {
    //         return false;
    //     }
    // });
  }

  getAffiliateCode() {
    var queryurl = this.props.basepath + "affiliate/getaffiliatecode";
    var self = this;

    fetch(queryurl, {
      method: "get",
      credentials: "include"
    }).then(function(data) {
      return data.json();
    }).then(function(response) {
      if (response && response.status == 200 && response.data) {
        var affiliatecode = response.data["affiliatecode"];
        var affiliateurl = 'https://quizzes4leads.com/affiliate/payment.html?affiliatecode='+affiliatecode;
        self.setState({ affiliateurl });
      }else{
        NotificationManager.error("Server error: ", response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  getEmailNotificationSetting() {
    var postdata = "userid=" + this.props.user.userid;

    //var queryurl= this.props.basepath + "index.php/crm/getemailnotificationsetting";
    var queryurl = this.props.basepath + "crm/getemailnotificationsetting";
    var self = this;

    fetch(queryurl, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: postdata,
      credentials: "include"
    }).then(function(data) {
      return data.json();
    }).then(function(response) {
      if (response && response.status == 200 && response.data && response.data.length > 0) {
        self.setState({ emailnotificationsetting: response.data[0],emailnotificationstatus : response.data[0]['emailnotificationstatus'] || response.data[0].emailnotificationflag ? true: false });
      }else{
        NotificationManager.error("Server error: ", response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  onChangeCheckBox(event) {
    var fieldname = event.target.name;
    var fieldvalue = event.target.checked ? 1 : 0;
    var type = event.target.getAttribute("data-type");
    var setting = null;
    if (type == "crmsetting") {
      setting = this.state.crmsetting;
    }
    if (event.target.name == "emailnotificationstatus") {
      this.setState({
        emailnotificationstatus: event.target.checked
      })
      if(event.target.checked == false) {
        this.setState({
          emailnotificationsetting: setting
        })
      }
    }
    if (type == "emailnotification") {
      setting = this.state.emailnotificationsetting;
      if(fieldvalue == 1) {
        this.setState({emailnotificationstatus: true})
      }
    }
    if (setting == null) {
      setting = {};
    }
    setting[fieldname] = fieldvalue; //(fieldvalue==true?"1":"0");
    if (type == "crmsetting") {
      this.setState({ crmsetting: setting });
    }
    if (type == "emailnotification") {
      this.setState({ emailnotificationsetting: setting });
    }
  }

  onChangeCRMField(event) {
    var fieldname = event.target.name;
    var fieldvalue = event.target.value;
    var crmsetting = this.state.crmsetting;
    if (crmsetting == null) {
      crmsetting = {};
    }
    crmsetting[fieldname] = fieldvalue;
    this.setState({ crmsetting: crmsetting });
  }

  onChangeEmailNotificationField(event) {
    var fieldname = event.target.name;
    var fieldvalue = event.target.value;
    var emailnotificationsetting = this.state.emailnotificationsetting;
    if (emailnotificationsetting == null) {
      emailnotificationsetting = {};
    }
    emailnotificationsetting[fieldname] = fieldvalue;
    this.setState({ emailnotificationsetting: emailnotificationsetting });
  }

  onCRMSettingUpdate(event) {
    var data = this.state.crmsetting;

    var jsondata = JSON.stringify(data);
    var postdata = "userid=" + this.props.user.userid + "&crmdata=" + jsondata;

    //var queryurl= this.props.basepath + "index.php/crm/updatecrmsetting";
    var queryurl = this.props.basepath + "crm/updatecrmsetting";
    fetch(queryurl, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: postdata,
      credentials: "include"
    }).then(function(data) {
      return data.json();
    }).then(function(response) {
      if (response && response.status==200 && response.data) {
        //swal("Settings", "Settings updated successfully!!", "success");
        NotificationManager.success("Settings Update", "Settings updated successfully!!");
      } else {
        //swal("Settings", "Error in updating settings!!", "error");
        NotificationManager.error("Settings Update", "Error in updating settings!!");
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });

    /*$.ajax({
          type: "POST", url: queryurl, dataType: "json",data:postdata,xhrFields: { withCredentials: true },
          success: function (response) 
          {
              if (response["status"] == true) 
              {
                  alert("settings upated successfully!!");
              }

          },
          error: function (data) 
          {
              return false;
          }
      });*/
  }

  onEmailNotificationUpdate(event) {
    //console.log(":this.state.emailnotificationstatus",this.state.emailnotificationstatus)
    var emailrecipients = JSON.stringify(
      this.state.emailnotificationsetting ? this.state.emailnotificationsetting.emailrecipients: ''
    );
    var emailnotificationflag = this.state.emailnotificationsetting ? this.state.emailnotificationsetting.emailnotificationflag : '';
    var postdata =
    "userid=" +
    this.props.user.userid +
    "&emailrecipients=" +
    emailrecipients +
    "&emailnotificationflag=" +
    emailnotificationflag + "&emailnotificationstatus=" + this.state.emailnotificationstatus;

      //console.log("postdata",postdata)
    //var queryurl= this.props.basepath + "index.php/crm/updateemailnotification";
    var queryurl = this.props.basepath + "crm/updateemailnotificationsetting";

    fetch(queryurl, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: postdata,
      credentials: "include"
    }).then(function(data) {
      return data.json();
    }).then(function(response) {
      if (response && response.status==200 && response.data) {
        //swal("Settings", "Settings updated successfully!!", "success");
        NotificationManager.success("Settings Update", "Settings updated successfully!!");
      } else {
        //swal("Settings", "Error in updating settings!!", "error");
        NotificationManager.error("Settings Update", "Error in updating settings!!");
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
    /*$.ajax({
          type: "POST", url: queryurl, dataType: "json",data:postdata,xhrFields: { withCredentials: true },
          success: function (response) 
          {
              if (response["status"] == true) 
              {
                  alert("settings upated successfully!!");
              }

          },
          error: function (data) 
          {
              return false;
          }
      });*/
  }

  onPasswordUpdate(event) {
    var queryurl = this.props.basepath + "index.php/user/updatePassword";

    var pwd = this.state.profiledata.newpwd1;
    var currpwd = this.state.profiledata.currpwd;
    var postdata ="userid=" +this.props.user.userid +"&currpwd=" +currpwd +"&pwd=" +pwd;
    fetch(queryurl, {method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: postdata,
      credentials: "include"
    }).then(function(data) {
      return data.json();
    }).then(function(response) {
      if (response && response.status ==200 && response.data) {
        //swal("Settings", "Settings updated successfully!!", "success");
        NotificationManager.success("Password Update", "Password updated successfully!!");
      } else {
        //swal("Settings", "Error in updating settings!!", "error");
        NotificationManager.error("Password Update", "Error in updating password!!");
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  onSendTestRequestWebhook(event){
    var url = this.state.crmsetting['webhookurl'];
    if(url!=null){
      var data={email:'webhooktest@quizzes4leads.com',
      name:"webhooktest",
      quiztitle:"Webhook test quiz",
      quizid:"123456789"}
      var jsondata = JSON.stringify(data);
      fetch(url, {
        method: "post",
        body: jsondata
      }).then(function(response) {
        return response.json();
      }).then(function(response) {
        if(response){
          NotificationManager.success("Webhook Integration", "Webhook integrated successfully!!");
        } else {
          //swal("Settings", "Error in updating settings!!", "error");
          NotificationManager.error("Webhook Integration", "Error in integrating webhook!!");
        }
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });
    }
  }

  render() {
    return (
      <div className="" style={{ marginTop: 2 + "%" }}>
        <div className="col-md-6">
          <div className="qzpanel-default">
            <div className="qzpanel-heading clearfix">
              <div className="qzpanel-title">CRM Integration</div>
            </div>
            <div className="qzpanel-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="checkbox" style={{ color: "#8798ad" }}>
                    <span>Enable CRM Integration?</span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={
                          this.state.crmsetting != null &&
                          this.state.crmsetting.crmflag != null &&
                          this.state.crmsetting.crmflag == "1"
                            ? true
                            : false
                        }
                        name="crmflag"
                        data-type="crmsetting"
                        onChange={this.onChangeCheckBox}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag != null &&
                this.state.crmsetting.crmflag == "1" ? (
                  <div className="col-md-12 seating-label">
                    <label className="Sign-In-Label">
                      Select Your CRM Provider
                    </label>
                    <select
                      className="form-control col-md-6 select-text"
                      id="sel1"
                      name="type"
                      value={
                        this.state.crmsetting != null &&
                        this.state.crmsetting.type != null
                          ? this.state.crmsetting.type
                          : ""
                      }
                      onChange={this.onChangeCRMField}
                    >
                      <option value="">Click to Select</option>
                      <option value="getresponse">GetResponse</option>
                      <option value="agilecrm">AgileCRM</option>
                      <option value="activecampaign">Active Campaign</option>
                      <option value="mailerlite">Mailerlite</option>
                      <option value="mailchimp">MailChimp</option>
                      <option value="birdsend">Birdsend</option>
                      <option value="sendfox">Sendfox</option>
                      <option value="vbout">Vbout</option>
                      <option value="useinbox">Useinbox</option>
                      <option value="sendinblue">Sendinblue</option>
                      <option value="klaviyo">Klaviyo</option>      
                      <option value="acumba">Acumba</option>                      
                      <option value="webhook">Webhook</option>
                    </select>
                  </div>
                ) : null}
                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                this.state.crmsetting.type == "getresponse" ? (
                  <div className="col-md-12">
                    <label className="Sign-In-Label"> Enter your API key</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="api_key"
                      value={this.state.crmsetting.api_key}
                      onChange={this.onChangeCRMField}
                    />
                    <label className="Sign-In-Label">
                      Enter your campaign ID for GetResponse
                    </label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="campaignid"
                      value={this.state.crmsetting.campaignid}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}
                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "agilecrm") ? (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your API key</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      value={this.state.crmsetting.api_key}
                      name="api_key"
                      onChange={this.onChangeCRMField}
                    />
                    <label className="Sign-In-Label">
                      Enter domain for AgileCRM
                    </label>
                    <input
                      className="Sign-In-TextBox"
                      name="agilecrm_domain"
                      type="text"
                      value={this.state.crmsetting.agilecrm_domain}
                      onChange={this.onChangeCRMField}
                    />
                    <label className="Sign-In-Label">
                      Enter email for AgileCRM
                    </label>
                    <input
                      className="Sign-In-TextBox"
                      name="agilecrm_email"
                      type="text"
                      value={this.state.crmsetting.agilecrm_email}
                      onChange={this.onChangeCRMField}
                    />
                    <label className="Sign-In-Label">
                      Enter campaign tag for AgileCRM
                    </label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}
                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "activecampaign") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your API key</label>
                    <input className="Sign-In-TextBox" type="text" value={this.state.crmsetting.api_key} name="api_key" onChange={this.onChangeCRMField}/>
                    <label className="Sign-In-Label">
                      Enter account for Active Campaign
                    </label>
                    <input
                      className="Sign-In-TextBox"
                      name="activecampaign_account"
                      type="text"
                      value={this.state.crmsetting.activecampaign_account}
                      onChange={this.onChangeCRMField}
                    />
                    <label className="Sign-In-Label">
                      Enter tags for Active Campaign
                    </label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}
                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "mailerlite") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your API key</label>
                    <input className="Sign-In-TextBox" type="text" value={this.state.crmsetting.api_key} name="api_key" onChange={this.onChangeCRMField}/>
                    <label className="Sign-In-Label">Enter tag for Mailerlite</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}
                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "mailchimp") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your API key</label>
                    <input className="Sign-In-TextBox" type="text" value={this.state.crmsetting.api_key} name="api_key" onChange={this.onChangeCRMField}/>
                    <label className="Sign-In-Label">Enter Audience / List name for MailChimp</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}



                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "birdsend") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your Personal Access Token</label>
                    <input className="Sign-In-TextBox" type="text" value={this.state.crmsetting.api_key} name="api_key" onChange={this.onChangeCRMField}/>
                    <label className="Sign-In-Label">Enter tag for Birdsend</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}
                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "sendfox") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your Personal Access Token</label>
                    <input className="Sign-In-TextBox" type="text" value={this.state.crmsetting.api_key} name="api_key" onChange={this.onChangeCRMField}/>
                    <label className="Sign-In-Label">Enter list tag for Sendfox</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}
                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "vbout") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your API User Key</label>
                    <input className="Sign-In-TextBox" type="text" value={this.state.crmsetting.api_key} name="api_key" onChange={this.onChangeCRMField}/>
                    <label className="Sign-In-Label">Enter list name</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}

                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "sendinblue") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your API Key</label>
                    <input className="Sign-In-TextBox" type="text" value={this.state.crmsetting.api_key} name="api_key" onChange={this.onChangeCRMField}/>
                    <label className="Sign-In-Label">Enter list name</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}
                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "useinbox") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your username (email)</label>
                    <input className="Sign-In-TextBox" type="text" value={this.state.crmsetting.username} name="username" onChange={this.onChangeCRMField}/>
                    <label className="Sign-In-Label">Enter your password</label>
                    <input className="Sign-In-TextBox" type="password" value={this.state.crmsetting.password} name="password" onChange={this.onChangeCRMField}/>
                    
                    <label className="Sign-In-Label">Enter list name</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}

                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "klaviyo") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your private key</label>
                    <input className="Sign-In-TextBox" type="password" value={this.state.crmsetting.password} name="password" onChange={this.onChangeCRMField}/>
                    
                    <label className="Sign-In-Label">Enter list name</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}

                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "acumba") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter your auth token</label>
                    <input className="Sign-In-TextBox" type="password" value={this.state.crmsetting.password} name="password" onChange={this.onChangeCRMField}/>
                    
                    <label className="Sign-In-Label">Enter list name</label>
                    <input
                      className="Sign-In-TextBox"
                      type="text"
                      name="tag"
                      value={this.state.crmsetting.tag}
                      onChange={this.onChangeCRMField}
                    />
                  </div>
                ) : null}

                {this.state.crmsetting != null &&
                this.state.crmsetting.crmflag == "1" &&
                this.state.crmsetting.type != null &&
                (this.state.crmsetting.type == "webhook") ? 
                (
                  <div className="col-md-12">
                    <label className="Sign-In-Label">Enter Webhook url</label>
                    <input className="Sign-In-TextBox" type="text" value={this.state.crmsetting.webhookurl} name="webhookurl" onChange={this.onChangeCRMField}/>
                    <button className="btn-normal" onClick={this.onSendTestRequestWebhook} style={{ width: 270, marginTop: 10 }}> Send Test Request </button>
                  </div>
                ) : null}



                <div className="col-md-12">
                  <button
                    className="btn-normal"
                    onClick={this.onCRMSettingUpdate}
                    style={{ width: 270, marginTop: 10 }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="qzpanel-default">
            <div className="qzpanel-heading clearfix">
              <div className="qzpanel-title">Email Notification</div>
            </div>
            <div className="qzpanel-body">
              <div className="row">
                <div className="col-md-12">
                <div className="checkbox" style={{ color: "#8798ad" }}>
                    <span>Enable email notifications</span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked= {this.state.emailnotificationstatus ? true : false}
                        name="emailnotificationstatus"
                        onChange={this.onChangeCheckBox}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="checkbox" style={{ color: "#8798ad" }}>
                    <span>Enable email notifications for additional users</span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={
                          this.state.emailnotificationsetting != null &&
                          this.state.emailnotificationsetting
                            .emailnotificationflag != null &&
                          this.state.emailnotificationsetting
                            .emailnotificationflag == "1" 
                            ? true
                            : false
                        }
                        name="emailnotificationflag"
                        data-type="emailnotification"
                        onChange={this.onChangeCheckBox}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  
                </div>
                {this.state.emailnotificationsetting != null &&
                this.state.emailnotificationsetting.emailnotificationflag !=
                  null &&
                this.state.emailnotificationsetting.emailnotificationflag ==
                  "1" ? (
                  <div className="">
                    <div className="col-md-12">
                      <label className="Sign-In-Label">
                        Enter email addresses seperated by ','
                      </label>
                      <textarea
                        className="Sign-In-TextBox"
                        name="emailrecipients"
                        onChange={this.onChangeEmailNotificationField}
                        value={
                          this.state.emailnotificationsetting != null &&
                          this.state.emailnotificationsetting.emailrecipients !=
                            null
                            ? this.state.emailnotificationsetting
                                .emailrecipients
                            : ""
                        }
                      />
                    </div>
                  </div>
                ) : null}

                <div className="col-md-12">
                  <button
                    className="btn-normal"
                    onClick={this.onEmailNotificationUpdate}
                    style={{ width: 270, marginTop: 10 }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="qzpanel-default">
            <div className="qzpanel-heading clearfix">
              <div className="qzpanel-title">Affiliate url</div>
            </div>
            <div className="qzpanel-body">
              <div className="row">
                <div className="col-md-12">
                    {this.state.affiliateurl }
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
