import React from "react";
//import 'chart.js/auto';
import moment from 'moment';

  import { Chart as ChartJS,CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend} from 'chart.js';

    import { Line } from "react-chartjs-2";

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );

const options = {
    scales: {
        yAxes: 
            {
                ticks: {
                    beginAtZero: false,
                    stepSize: 1
                },
                
            },
        
    },
}


export class QuizGraphData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quizCount: [], data: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        fill: false,
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgba(255, 99, 132, 0.2)',
                        color: "black", //**Change the color here

                    },
                ],
            }
        };
    }
    componentWillReceiveProps(nextProps) {
        var labels = [];
        var quizCount = [];
        var labelText = 'No Data Found';
        if (nextProps.getQuizGraphData['data']) {
            for (let i = 0; i < nextProps.getQuizGraphData['data'].length; i++) {
                labelText = nextProps.getQuizGraphData['data'] ? "Quiz Played" : "";
                let quizPlayDate = nextProps.getQuizGraphData['data'][i]['quizPlayDate'];
                labels.push(moment(quizPlayDate).format("MMM Do"));
                quizCount.push(nextProps.getQuizGraphData['data'][i]['quizCompletedCount'])
            }
        }
        this.setState({
            getQuizGraphData: nextProps, data: {
                labels: labels,
                datasets: [
                    {
                        label: labelText,
                        data: quizCount,
                        fill: false,
                        backgroundColor: '#6418c3',
                        borderColor: '#6418c3',
                    },
                ],
            }
        });

    }
    render() {
        return (
            <div>
                <Line height={70} data={this.state.data} options={options} />
            </div>
        );
    }
}
