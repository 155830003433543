/**
 * This file contains the code for showing the quiz share options.
 * This will be called from quiz table when user clicks the quiz share button and when user
 * creates quiz and save it.
 */

import React from 'react';
import ReactTooltip from "react-tooltip";

export class FeedbackShare extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedShareIcon: 'iframe',
            copyText: "Copy",
        }
        this.onChangeShareIcon = this.onChangeShareIcon.bind(this)
    }

    onChangeShareIcon(e) {
        var selectedShareIcon = e.target.getAttribute('name');
        var self = this;
        this.setState({ selectedShareIcon });
    }

    render() {
        var content = null;
        if (this.props != null && this.props.match != null && this.props.match.params != null && this.props.match.params.id != null){
            let feedback_id = this.props.match.params.id
            var feedbackpath = this.props.localpath+"Feedback/preview/"+feedback_id;
            var scriptsrc = "https://api.quizzes4leads.com/feedback-widget.js?version="+Date.now();
                if (process.env.REACT_APP_ENV === 'stage') {
                    scriptsrc = "https://api.quizzes4leads.com/feedback-widget-stage.js?version="+Date.now();
                }
            
            var iframecode = "<iframe width='713' height='419' src='" + feedbackpath + "' frameborder='0' allowfullscreen></iframe>";
            if(this.state.selectedShareIcon=="iframe"){
                content = <textarea className="form-control Sign-In-TextBox" readOnly value={iframecode} style={{ height: 100 }}></textarea>;
            } else if(this.state.selectedShareIcon == "widget")
            {
            
            var feedbackwidget= `<script type="text/javascript">
            (function()
            {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src="${scriptsrc}"
                var feedbackid=${feedback_id};
                var charsetmetatag = document.createElement('meta');
                charsetmetatag.setAttribute("charset", "UTF-8");
                document.getElementsByTagName("head")[0].appendChild(charsetmetatag);
                document.getElementsByTagName("head")[0].appendChild(script);
                if(script.readyState){
                    script.onreadystatechange = function(){
                        if(script.readyState==='loaded' || script.readyState==='complete'){
                            script.onreadystatechange = null;
                            init(feedbackid);
                        }
                    }
                }
                else {  //Others
                script.onload = function() {
                init(feedbackid);
                };
            } 
            })();
        
        </script>`;
            navigator.clipboard.writeText(feedbackwidget);
            content = <textarea className="form-control Sign-In-TextBox" readOnly value={feedbackwidget} style={{height:100,overflowY: 'scroll'}}></textarea>;
            }
        }

        return <div id="quizformwrapper">
          <div className="row">
                <div className="col-md-6 col-md-offset-3">
                    <div id="quizpanel" className="qzpanel qzpanel-blur">
                        <div style={{backgroundColor: "#8144e5"}} className="panel-heading text-center">
                            <h2 style={{color:"#fff"}}>Your Web Response is ready</h2>
                        </div>
                        <div className="panel-body">
                           
                            <div className="form-group">
                                <div className="col-md-12 icon_block" style={{marginBottom:30}}>
                                    <div data-tip="Feedback Iframe, copy and Paste this inside html, Where you want to show." name="iframe" className={`col-md-2 text-center share-icon ${this.state.selectedShareIcon == "iframe"?'active':''}`} onClick={this.onChangeShareIcon}>
                                        <i name="iframe" className="fa fa-globe fa-3x"></i>
                                    </div>
                                    <ReactTooltip />
                                    <div data-tip="Feedback Widget, copy and Paste this inside your website header file." name="widget" className={`col-md-3 text-center share-icon ${this.state.selectedShareIcon == "widget" ? 'active' : ''}`} onClick={this.onChangeShareIcon}><i name="widget" class="fa fa-code fa-3x" aria-hidden="true"></i></div>

                                </div>
                            </div>
                            <hr /> 
                            <div className="form-group">
                                <div className="col-md-12">
                                   {content}
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <button style={{ marginRight: '15px' }} className="btn-normal copy-btn" onClick={this.onClose}>{this.state.copyText}</button>
                    </div>
                </div>
            </div>
        </div>;
    }
}
export default FeedbackShare;