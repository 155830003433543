/**
 * This file contains the functionality to render quiz table
 */
import React from "react";
import swal from "sweetalert";
import { Link, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import ColorPicker from "../../picker/feedbackcolorpicker";

export class PoolCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: "",
      pool_description: "",
      pool_option: [],
      pool_status: "1",
      options: 2,
      poll_custom_color: "",
      pool_option_dummy: [
        "First Option",
        "Second Option",
        "Third Option",
        "Forth Option",
      ],
      custom_color_checked: false,
      poll_custom_color_text: "",
      poll_custom_color_bg: "",
      is_enable_email: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.createPool = this.createPool.bind(this);
    this.addOptionRow = this.addOptionRow.bind(this);
    this.removeOptionRow = this.removeOptionRow.bind(this);
    this.pollCustomColor = this.pollCustomColor.bind(this);
    this.customColorChecked = this.customColorChecked.bind(this);
    this.pollCustomColorText = this.pollCustomColorText.bind(this);
    this.isEnableEmail = this.isEnableEmail.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name == "pool_option") {
      var dataid = event.target.getAttribute("data-id");
      let poolPtion = this.state.pool_option;
      poolPtion[dataid] = event.target.value;
      this.setState({ pool_option: poolPtion });
    }
  }
  isEnableEmail(event) {
    if (event.target.checked == true) {
      this.setState({
        is_enable_email: true,
      });
    } else {
      this.setState({
        is_enable_email: false,
      });
    }
  }

  createPool() {
    if (this.state.pool_description == "") {
      NotificationManager.error(
        "Validation Error",
        "Description field is required."
      );
      return false;
    }
    if (this.state.pool_status == "") {
      NotificationManager.error("Validation Error", "Poll status is required.");
      return false;
    }

    if (this.state.pool_option == "") {
      NotificationManager.error("Validation Error", "Poll option is required.");
      return false;
    }
    if (this.state.pool_option.length <= 1) {
      NotificationManager.error(
        "Validation Error",
        "Min two option is required."
      );
      return false;
    }
    var data = {};
    data["pool_description"] = this.state.pool_description;
    data["is_enable_email"] = this.state.is_enable_email;
    let customColor = {
      bg_color: this.state.poll_custom_color_bg,
      text_color: this.state.poll_custom_color_text,
    };

    data["poll_custom_color"] = JSON.stringify(customColor);
    data["pool_status"] = this.state.pool_status;
    data["pool_option"] = JSON.stringify(this.state.pool_option);
    
    var jsondata = JSON.stringify(data);
    var postdata = "userid=" + this.props.user.userid + "&data=" + jsondata;

    var queryurl = this.props.basepath + "poll/create";
    var self = this;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (data) {
        return data.json();
    }).then(function (response) {
      if (response && response.status==200 && response.data) {
        self.props.history.push("/app/poll/share/" + response.data.id + "/" + response.data.slug);
      }
      else{
        NotificationManager.error("Server error: ",response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  pollCustomColor(color) {
    this.setState({
      poll_custom_color_bg: color.hex,
    });
  }
  pollCustomColorText(color) {
    this.setState({
      poll_custom_color_text: color.hex,
    });
  }
  customColorChecked(event) {
    if (event.target.checked == true) {
      this.setState({
        custom_color_checked: true,
      });
    } else {
      this.setState({
        custom_color_checked: false,
      });
    }
  }
  addOptionRow() {
    if (this.state.options <= 3) {
      this.setState(({ options }) => ({ options: options + 1 }));
    } else {
      NotificationManager.info("Info", "Max 4 option allow.");
      return false;
    }
  }
  removeOptionRow() {
    if (this.state.options > 2) {
      this.setState(({ options }) => ({ options: options - 1 }));
    }
  }

  render() {
    return (
      <div>
        <div className="pll-create-page">
          <div className="col-md-6 col-xs-12">
            <form onSubmit={this.handleSubmit.bind(this)}>
              <div className="qzpanel-default" style={{ width: "100%" }}>
                <div
                  className="qzpanel-body"
                  style={{ minHeight: "100%", height: "100%" }}
                >
                  <div className="frmqzsettings">
                    <div className="form-group">
                      <label className="Sign-In-Label">Poll Description</label>
                      <textarea
                        style={{ height: "100px" }}
                        name="pool_description"
                        onChange={this.handleChange}
                        className="Sign-In-TextBox"
                        maxLength="250"
                        placeholder="Poll Description"
                      ></textarea>
                      <div className="counter-wrapper">
                        <span>{this.state.pool_description.length}/250</span>
                      </div>
                    </div>
                    {Array.from({ length: this.state.options }, (_, index) => (
                      <div className="form-group" key={index}>
                        <label className="Sign-In-Label">Option Label</label>
                        <input
                          name="pool_option"
                          className="Sign-In-TextBox"
                          onChange={this.handleChange}
                          type="text"
                          maxLength="100"
                          data-id={index}
                          placeholder="Please enter poll option"
                        />
                        <div className="counter-wrapper-option">
                          <span>
                            {this.state.pool_option[index]
                              ? this.state.pool_option[index].length
                              : 0}
                            /100
                          </span>
                        </div>
                      </div>
                    ))}
                    <button onClick={this.addOptionRow} className="option-add">
                      <i className="fa fa-plus fa-2x"></i>
                    </button>
                    {this.state.options > 2 ? (
                      <button
                        onClick={this.removeOptionRow}
                        className="option-remove"
                      >
                        <i className="fa fa-minus fa-2x"></i>
                      </button>
                    ) : (
                      ""
                    )}

                    <div className="form-group">
                      <label className="Sign-In-Label"> Poll Status</label>
                      <br></br>
                      <select
                        className="custom-select form-control"
                        onChange={this.handleChange}
                        name="pool_status"
                      >
                        <option value="1" defaultValue="1">
                          Published
                        </option>
                        <option value="0" defaultValue="0">
                          Un Publish
                        </option>
                      </select>
                    </div>
                    <div className="form-group poll-custom-color-wrapper">
                      <label className="Sign-In-Label"> Custom Color</label>
                      <label className="switch poll-custom-color-switch">
                        <input
                          type="checkbox"
                          defaultChecked={this.state.custom_color_checked}
                          onClick={this.customColorChecked}
                          id="custom_color"
                          name="custom_color"
                        />
                        <span className="slider round"></span>
                      </label>
                      <br></br>
                      {this.state.custom_color_checked ? (
                        <div className="poll-custom-color">
                          <ul className="poll-custom-color-ul">
                            <li className="poll-custom-color-li-one">
                              <span>Background</span>
                              <ColorPicker
                                custom_theme_color="#8144e5"
                                onSelectChangeColor={this.pollCustomColor}
                              />
                            </li>
                            <li className="poll-custom-color-li-two">
                              <span>Text</span>
                              <ColorPicker
                                custom_theme_color="#ffffff"
                                onSelectChangeColor={this.pollCustomColorText}
                              />
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <label for="scales">Do you want to collect user emails before showing the result?</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={this.state.is_enable_email}
                        onClick={this.isEnableEmail}
                        name="is_enable_email"
                      />
                      <span className="slider round"></span>
                    </label>
                    <br></br>
                    <button
                      style={{ width: "27%" }}
                      onClick={this.createPool}
                      className="btn-normal new-button-response"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6 col-xs-12 text-center">
            <h3 className="wr-p-title">Poll Preview </h3>
            <div className="alert alert-success alert-dismissible poll-preview">
              <span className="pull-right fc">X</span>
              <p style={{ marginTop: 20, marginBottom: 10 }}>
                {this.state.pool_description
                  ? this.state.pool_description
                  : "Poll description"}
              </p>
              <ul className="list-group">
                {Array.from({ length: this.state.options }, (_, index) => (
                  <li
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty(
                          "background-color",
                          this.state.poll_custom_color_bg
                            ? this.state.poll_custom_color_bg
                            : ""
                        );
                        el.style.setProperty(
                          "color",
                          this.state.poll_custom_color_text
                            ? this.state.poll_custom_color_text
                            : ""
                        );
                      }
                    }}
                    key={index}
                    className="list-group-item list-group-item-info poll-previe-item-c"
                  >
                    {this.state.pool_option[index]
                      ? this.state.pool_option[index]
                      : this.state.pool_option_dummy[index]}
                  </li>
                ))}
              </ul>
              <h5 className="poll-c-p-total">Total Vote: 100</h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PoolCreate;
