import React, { Component } from "react";

class SubmitResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      error: "",
    };
  }

  checkValidation = () => {
    if (!this.state.name) {
      this.setState({ error: "Name is required" });
      return false;
    } else if (!this.state.email) {
      this.setState({ error: "Email is required" });
      return false;
    } else if (!this.isValidEmail(this.state.email)) {
      this.setState({ error: "Enter a valid email" });
      return false;
    } else {
      this.setState({ error: "" });
      return true;
    }
  };

  handleSumitResult = () => {
    const { name, email } = this.state;

    if (this.checkValidation()) {
      this.props.onResultSave({ name, email });
    }
  };

  isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  handleInput = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  render() {
    const { theme_colors, welcome_message_color, selectFont } = this.props;

    return (
      <div
        className="quiz_wrap__content text-center ql-editor"
        style={{
          background: theme_colors ? theme_colors.header_content_bg : "",
        }}
      >
        <div className="quiz_wrap__content___text">
          <p
            className={selectFont ? selectFont : ""}
            style={{
              color: welcome_message_color ? welcome_message_color : "",
            }}
          >
            {this.props.quizemailmsg
              ? this.props.quizemailmsg
              : "Enter your email to receive your results."}
          </p>
        </div>
        <div className="col-lg-8 col-12 mx-auto">
          <div className="quiz_login__form mt-4">
            <input
              className={"form-control mb-3 " + (selectFont ? selectFont : "")}
              type="text"
              id="firstName"
              name="name"
              placeholder={
                this.props.enter_your_name_text
                  ? this.props.enter_your_name_text
                  : "Enter your name"
              }
              onChange={this.handleInput}
            />
            <input
              type="email"
              className={"form-control " + (selectFont ? selectFont : "")}
              name="email"
              id="email"
              onChange={this.handleInput}
              placeholder={
                this.props.enter_your_email_text
                  ? this.props.enter_your_email_text
                  : "Enter your Email"
              }
            />
          </div>
          {this.state.error && (
            <p style={{ color: "red" }}>{this.state.error}</p>
          )}
          {this.props.privacy_policy_text && (
            <div className="quiz_wrap__content___text privacy-policy-text">
              <p className={selectFont ? selectFont : selectFont} style={{
              color: welcome_message_color ? welcome_message_color : ""
            }}>
                {this.props.privacy_policy_text}
              </p>
            </div>
          )}
        </div>

        <div className="quiz_wrap__btn mb-4">
          <div
            className="start_btn quiz_ans__btn mt-5"
            onClick={() => {
              this.handleSumitResult();
            }}
          >
            <button
              type="button"
              className="p-0 mx-auto"
              style={{
                background: theme_colors.button_color
                  ? theme_colors.button_color
                  : welcome_message_color,
                color: theme_colors ? theme_colors.button_color_text : "",
              }}
            >
              <div
                className="quiz_btn__ans__text p-2"
                style={{
                  background: theme_colors.button_color
                    ? theme_colors.button_color
                    : welcome_message_color,
                  color: theme_colors ? theme_colors.button_color_text : "",
                }}
              >
                <p className={selectFont ? selectFont : ""}>Continue</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SubmitResult;
