/**
 * This file contains the functionality to render quiz table
 */
import React from "react";
import $ from "jquery";
import swal from "sweetalert";
import {NotificationManager} from 'react-notifications';

import { Link, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

export class FeedbackPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emotion: false,
      star: false,
      thumb_up: false,
      scale: false,
      data: [],
      title: "",
      question: "",
      feeling_type: "",
      active: false,
      show: "show",
      feedbackMsg: "hide_feedback_msg",
      feedback_content: "",
      feedback_error: "",
      feedbackEmailshow: "",
      email: "",
      custom_theme_color:''
    };
    
    this.toggleClass = this.toggleClass.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.saveFeedback = this.saveFeedback.bind(this);
    this.getFeedback();
  }
  toggleClass(e){
    this.setState({
      feedbackEmailshow: "feedbackemailshow"
    });
    let classes = "f-class";
    let els = document.getElementsByClassName("f-class active");
    if (els) {
      while (els[0]) {
        els[0].classList.remove("active");
      }
      // console.log("EVEnt",e.currentTarget.getAttribute('value'));
      // this.sortOn(e.currentTarget.getAttribute('data-column'));
      this.setState({
        feedback_content: e.currentTarget.getAttribute("value")
      });
    }
    e.target.className = classes.replace("f-class", "f-class active");
  };

  getFeedback() {
    if(this.props.match!=null && this.props.match.params !=null)
    {
      let feedback_id = this.props.match.params.id;
      var dataurl = this.props.basepath + "feedback/preview/" + feedback_id;
      var self = this;
      fetch(dataurl, {
        method: "get"
      }).then(function(response) {
        return response.json();
      }).then(function(response) {
        if(response && response.status==200 && response.data){
          self.setState({
            title: response.data.title,
            question: response.data.question,
            feeling_type: response.data.feeling_type,
            feedbackid: response.data.id,
            custom_theme_color:response.data.custom_theme_color,
            is_enable_email:response.data.is_enable_email,
          });
        }
        else{
          NotificationManager.error("Server error: ", response.message);
        }
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });
    }
  }

  componentDidMount() {
    $(function() {
      $("#feedback-tab").click(function() {
        $("#feedback-form").toggle("slide");
      });

      $("#feedback-form form").on("submit", function(event) {
        var $form = $(this);
        $.ajax({
          type: $form.attr("method"),
          url: $form.attr("action"),
          data: $form.serialize(),
          success: function() {
            $("#feedback-form")
              .toggle("slide")
              .find("textarea")
              .val("");
          }
        });
        event.preventDefault();
      });
    });
  }

  getEmail(event) {
    this.setState({
      email: event.target.value
    });
  };

  saveFeedback(event) {
    this.setState({ [event.target.name]: event.target.value });
    let self = this;
    if (
      this.state.feedback_content == "" ||
      this.state.feedback_content == null
    ) {
      this.setState({
        feedback_error: "Feedback can not be empty."
      });
      setTimeout(() => {
        this.setState({
          feedback_error: ""
        });
      }, 3000);
      return false;
    }

    if (this.state.is_enable_email && (this.state.email == "" || this.state.email == null)) {
      this.setState({
        feedback_error: "Email is required."
      });
      setTimeout(() => {
        this.setState({
          feedback_error: ""
        });
      }, 3000);
      return false;
    }

    var data = {};

    data["feedback_type"] = this.state.feeling_type;
    data["feedback"] = this.state.feedback_content;
    data["email"] = this.state.email;
    var jsondata = JSON.stringify(data);
    var postdata = "data=" + jsondata;

    var queryurl =
      this.props.basepath + "feedbackr/response/" + this.state.feedbackid;
    fetch(queryurl, {
      method: "post",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: postdata
    }).then(function(data) {
      return data.json();
    }).then(function(response) {
      if (response && response.data && response.status == 200) {
        // swal("Saved", "Thanks for your feedback.", "success");
        self.setState({show: "hidefeedback_form",feedbackMsg: "show_feedback_msg",feedback_error: ""});
      }else{
        NotificationManager.error("Server error: ",response.message)
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  };

  render() {
    return (
      <div>
        <div id="feedback">
          <div
            id="feedback-form"
            className="col-xs-4 col-md-4 panel panel-default"
          >
         <a href="" className="feedback-tab feedback-close-btn"><i className="fa fa-times" aria-hidden="true"></i></a>
            <form
              method="POST"
              action="/feedback"
              id={this.state.show}
              className="form panel-body"
              role="form"
            >
              <h4>{this.state.title}</h4>
              <p>{this.state.question}</p>
              {this.state.feeling_type === "emotion" ? (
                <div>
                  
                  <span value="Angry" onClick={e => this.toggleClass(e)}>
                    😠
                  </span>
                  <span value="Worried " onClick={e => this.toggleClass(e)}>
                    😟
                  </span>
                  <span value="Neutral" onClick={e => this.toggleClass(e)}>
                    😐
                  </span>
                  <span value="Happy" onClick={e => this.toggleClass(e)}>
                    🙂
                  </span>
                  <span value="delighted" onClick={e => this.toggleClass(e)}>
                    😀
                  </span>
                </div>
              ) : this.state.feeling_type === "star" ? (
                <div>
                  <span value="1*" onClick={e => this.toggleClass(e)}>
                    ⭐
                  </span>
                  <span value="2*" onClick={e => this.toggleClass(e)}>
                    ⭐
                  </span>
                  <span value="3*" onClick={e => this.toggleClass(e)}>
                    ⭐
                  </span>
                  <span value="4*" onClick={e => this.toggleClass(e)}>
                    ⭐
                  </span>
                  <span value="5*" onClick={e => this.toggleClass(e)}>
                    ⭐
                  </span>
                </div>
              ) : this.state.feeling_type === "thumb" ? (
                <div>
                  <span value="Thumbs Up" onClick={e => this.toggleClass(e)}>
                    👍
                  </span>
                  <span value="Thumbs Down" onClick={e => this.toggleClass(e)}>
                    👎
                  </span>
                </div>
              ) : this.state.feeling_type === "scale" ? (
                <div className="value-box">
                  <span value="1" onClick={e => this.toggleClass(e)}>
                    1
                  </span>
                  <span value="2" onClick={e => this.toggleClass(e)}>
                    2
                  </span>
                  <span value="3" onClick={e => this.toggleClass(e)}>
                    3
                  </span>
                  <span value="4" onClick={e => this.toggleClass(e)}>
                    4
                  </span>
                  <span value="5" onClick={e => this.toggleClass(e)}>
                    5
                  </span>
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                {this.state.is_enable_email?<input
                  type="email"
                  name="email"
                  onChange={this.getEmail}
                  id={this.state.feedbackEmailshow}
                  className="form-control feedback-email hide"
                  placeholder="Please enter your email"
                /> : null}
              </div>
              
              <button
                className="btn btn-primary"
                onClick={this.saveFeedback}
                type="submit"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty('background-color', this.state.custom_theme_color, 'important')
                  }
                }}>
                Send
              </button>
              <a href="https://www.quizzes4leads.com/" className="feed-link">Wanna try on your website?</a>
            </form>
            <div className="text-success" id={this.state.feedbackMsg}>
              Thanks for your feedback.
            </div>
            <p className="text-danger feedback-error">
              {this.state.feedback_error}
            </p>
          </div>
          {/* <div ref={(el) => {
                  if (el) {
                    el.style.setProperty('background-color', this.state.custom_theme_color, 'important')
                  }
                }} id="feedback-tab">Feedback</div> */}
        </div>
      </div>
    );
  }
}
