import React from "react";

export class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: this.props.message
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ message: nextProps.message });
    }
    render() {
        return (
            <h3>
                {this.state.message} <i className='fa fa-spinner fa-spin'></i>
            </h3>
        )
    }
}