import React, { Component } from "react";

class QuizStart extends Component {
  render() {
    const { quizData, quizStartStatus, btn_start_text, selectFont } =
      this.props;

    return (
      <div
        className="quiz_wrap__content text-center  ql-editor"
        style={{
          background: quizData.theme_colors.header_content_bg
            ? quizData.theme_colors.header_content_bg
            : "",
            color: quizData.theme_colors.header_content_text
            ? quizData.theme_colors.header_content_text
            : "",
        }}
      >
        <div
          className="quiz_wrap__content___text"
          dangerouslySetInnerHTML={{ __html: quizData.description }}
        ></div>
        <div className="quiz_wrap__btn mb-4">
          <div className="start_btn quiz_ans__btn mt-5">
            <button
              type="button"
              className="p-0 mx-auto"
              onClick={() => {
                quizStartStatus("start");
              }}
              style={{
                background: quizData.theme_colors.button_color
                  ? quizData.theme_colors.button_color
                  : quizData.welcome_message_color,
                color: quizData.theme_colors.button_color_text
                  ? quizData.theme_colors.button_color_text
                  : quizData.welcome_message_color,
              }}
            >
              <div
                className="quiz_btn__ans__text p-2"
                style={{
                  background: quizData.theme_colors.button_color
                    ? quizData.theme_colors.button_color
                    : quizData.welcome_message_color,
                  color: quizData.theme_colors
                    ? quizData.theme_colors.button_color_text
                    : "",
                }}
              >
                <p className={selectFont ? selectFont : ""}>
                  {btn_start_text ? btn_start_text : "Continue"}
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default QuizStart;
