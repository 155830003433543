import React, { Component } from "react";
import ScheduleRetakeBtn from "./ScheduleRetakeBtn";
import SocialSharing from "./SocialSharing";

class PersonalityResult extends Component {
  render() {
    const {
      theme_colors,
      restartQuiz,
      is_enable_social_media,
      enable_social_sharing_text,
      title,
      welcome_message_color
    } = this.props;

    return (
      <>
        <div className="col-12">
          <div
            className="quiz_wrap__content text-center"
            style={{
              background: theme_colors ? theme_colors.header_content_bg : "",
            }}
          >
            <>
              <div className="quiz_wrap__content___text">
                {this.props.results.quizscoremsg ? (
                  <p className="p-q-s-m" style={{color:welcome_message_color?welcome_message_color:''}}>{this.props.results.quizscoremsg}</p>
                ) : (
                  ""
                )}
                {this.props.results.personalityimage ? (
                  <div className="quiz_wrap__content__img my-4">
                    <img
                      className="img-fluid"
                      src={this.props.results.personalityimage}
                      alt=""
                    />
                  </div>
                ) : (
                  ""
                )}
                {this.props.results.description ? (
                  <p style={{color:welcome_message_color?welcome_message_color:''}}>{this.props.results.description}</p>
                ) : (
                  ""
                )}
                <div className="quiz-offer-message-personality">
                  {this.props.quizoffermsg ? (
                    <p style={{color:welcome_message_color?welcome_message_color:''}}>{this.props.quizoffermsg}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <ScheduleRetakeBtn restartQuiz={restartQuiz} 
               theme_colors={theme_colors}
               welcome_message_color={welcome_message_color}/>
              {is_enable_social_media && (
                <SocialSharing
                  enable_social_sharing_text={enable_social_sharing_text}
                  welcome_message_color={welcome_message_color}
                  title={title}
                />
              )}
            </>
          </div>
        </div>
      </>
    );
  }
}
export default PersonalityResult;
