/**
 * This file contains the code to render application dashboard which shows data card - quick snapshot of
 * application
 */

import React from "react";
import { cardItemsArr } from "./global";
import swal from "sweetalert";
import { NotificationManager } from 'react-notifications';
import { Link, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Loader } from './components/loader';
import { QuizGraphData } from "./QuizGraphData";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { compareAsc, format } from 'date-fns'
import { isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';


export class AppDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardItems: cardItemsArr,
      user: this.props.user,
      loaderflag: true,
      getQuizGraphData: "",
      startDate: moment().subtract(30, "days"),
      endDate: moment(),
    };
    this.getDashboard = this.getDashboard.bind(this);
    this.getFilterQuiz = this.getFilterQuiz.bind(this);

    this.getDashboard();
    this.getDashboardGraphData();



  }
  handleChange(e, getDate) {
    var { startDate, endDate } = this.state;
    var self = this;

    if (getDate === 's_date') {
      startDate = e.target.value;
    }
    if (getDate === 'e_date') {
      endDate = e.target.value;

    }
    this.setState({ startDate: startDate, endDate: endDate }, () => {
      self.getDashboardGraphData();
    });

  }

  getDashboard() {
    var { startDate, endDate } = this.state;
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);

    var dataurl = this.props.basepath + "dashboard/appdashboard?start_date=" + startDate + "&end_date=" + endDate;
    var self = this;

    fetch(dataurl, {
      method: "get",
      credentials: "include"
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
        self.setState({ loaderflag: false });
        if(response && response.status==200 && response.data){
          if (response.data[1].keyvalue == "0") {
            var alerted = localStorage.getItem("alerted") || "";
            if (alerted != "yes") {
              // swal('How to create a quiz?','Watch tutorial','info')
              const el = document.createElement("div");
              el.innerHTML =
                "<a class='btn btn-primary dsfsdf' target='_blank' href='https://res.cloudinary.com/quizzes4leads/video/upload/v1576569297/Tutorial/How%20to%20Create%20your%20First%20Quiz.mp4'>Watch tutorial</a>";
              swal({
                title: "How to create a quiz?",
                content: el,
                icon: "info",
                button: "Close",
                className:'no-quiz-info-btn'
              });
              localStorage.setItem("alerted", "yes");
            }
          }
          response.data.map(function (headerItem, index) {
            if (index < cardItemsArr.length) {
              cardItemsArr[index].stats = headerItem.keyvalue;
            }
          }, self);
          self.setState({ cardItems: cardItemsArr });
        }
        else{
          NotificationManager.error("Server error: "+response.message);
        }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  getDashboardGraphData() {
    var { startDate, endDate } = this.state;
    startDate = this.formatDate(startDate);
    endDate = this.formatDate(endDate);

    var dataurl = this.props.basepath + "dashboard/appdashboard/graph?start_date=" + startDate + "&end_date=" + endDate;
    var self = this;
    fetch(dataurl, {
      method: "get",
      credentials: "include"
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      if(response && response.status==200 && response.data){
        self.setState({ getQuizGraphData: response.data })
      }else{
        NotificationManager.error("Server error: " + response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  getFilterQuiz() {
    this.getDashboardGraphData();
  }

  renderCards() {
    return (
      <div>
        {this.state.cardItems[0].stats > 0 ? (
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div className="row">
                <div className="com-md-12">
                  <div className="datebutton-block">
                    <DateRangePicker
                      startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                      endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                      numberOfMonths={2}
                    />
                    <button onClick={this.getFilterQuiz} type="button" className="btn btn-primary">Filter</button>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-md-12 col-xs-12 quize_padding">
              <div className="table-title">Leads Generated: {this.state.cardItems['5'].stats}</div>
              <div className="quiz-graph-wrapper">
                <QuizGraphData getQuizGraphData={this.state.getQuizGraphData}></QuizGraphData>
              </div>
            </div>

          </div>
        ) : ''}
        <div className="row">
          <div className="col-md-6 col-xs-12">
            {this.renderQuizDataForList()}
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="funnel-design-wrapper">
              <div className="funnel-design-1">{this.state.cardItems['3'].title}: {this.state.cardItems['3'].stats}</div>
              <div className="funnel-design-2">{this.state.cardItems['4'].title}: {this.state.cardItems['4'].stats}</div>
              <div className="funnel-design-3">{this.state.cardItems['5'].title}: {this.state.cardItems['5'].stats}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderQuizDataForList() {
    return this.state.cardItems.map(function (cardItem) {
      if (cardItem.itemType != 'web-response' && cardItem.status == "show") {
        return (
          <div className="col-md-6 col-sm-6 col-xs-12" key={cardItem.title} >
            <div>
              <div className="panel panel-blur animated" style={{ cursor: 'pointer' }}>
                <Link to="/app/Quizzes" style={{ color: 'black' }}>
                  <div className="panel-body">
                    <div className="panel_block">
                      <div className="col-md-3 col-sm-3 col-xs-3" data-percent="60" style={{ paddingLeft: 0, paddingRight: 30 }}>
                        <i className={`fa fa-${cardItem.icon} fa-${cardItem.iconsize}x`}></i>
                      </div>
                      <div className="col-md-9 col-sm-9 col-xs-9 text-center quize-right-label">
                        <div className="cardtitle" style={{ fontSize: 20, textTransform: 'capitalize' }}>
                          {cardItem.title}
                        </div>
                        <div className="cardDescription m-t-sm">
                          {cardItem.stats == null || cardItem.stats == ""
                            ? 0
                            : cardItem.stats}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        );
      }
    }, this);
  }

  renderWebResponseCards() {
    return this.state.cardItems.map(function (cardItem) {
      if (cardItem.itemType == 'web-response') {
        return (
          <div className="col-md-3 col-sm-4 col-xs-12" key={cardItem.title} >
            <div>
              <div className="panel panel-blur animated" style={{ cursor: 'pointer' }}>
                <Link to="/app/FeedbackLists" style={{ color: 'black' }}>
                  <div className="panel-body">
                    <div className="panel_block_two">
                      <div className="col-md-3 col-sm-3 col-xs-3" data-percent="60" style={{ paddingLeft: 0 }}>
                        <i className={`fa fa-${cardItem.icon} fa-${cardItem.iconsize}x`}></i>
                      </div>
                      <div className="col-md-9 col-sm-9 col-xs-9 text-center quize-right-label">
                        <div className="cardtitle" style={{ fontSize: 20 }}>
                          {cardItem.title}
                        </div>
                        <div className="cardDescription m-t-sm">
                          {cardItem.stats == null || cardItem.stats == ""
                            ? 0
                            : cardItem.stats}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        );
      }
    }, this);
  }
  rand(min, max, num) {
    var rtn = [];
    while (rtn.length < num) {
      rtn.push(Math.random() * (max - min) + min);
    }
    return rtn;
  }

  render() {
    var content = <Redirect to={{ pathname: "/index.html" }} />;
    //if(this.state.user == null || this.state.user.isAuthenticated == false )
    if (this.state.loaderflag) {
      content = <Loader message='Loading Dashboard' />
    }
    else {
      content = <div>
        {(this.state.cardItems != null && this.state.cardItems.length > 0 && this.state.cardItems[0].stats == 0) ?
          <div className="row">
            <div className="quize_block">
              <h4>You don't have any data currently, let's get started by creating your first Quiz <br /></h4>
              <button className="btn-normal" id="btnquizcreate" style={{ width: 150 }}><Link to="/app/Templates" style={{ color: 'white', textDecoration: 'none' }}>Build Your Quiz</Link></button>
            </div>
          </div> : null}
        <div className="col-md-12 table-title">
          <div className="table-title"><h2><span>Quiz</span></h2></div>
        </div>
        <div className="row">{this.renderCards()}</div>
        <div className="col-md-12">
          <div className="table-title" style={{ marginTop: 20, marginBottom: 17 }}><h2><span>Web Response</span></h2></div>
        </div>
        <div className="row">{this.renderWebResponseCards()}</div>

      </div>;
    }


    return content;
  }
}
