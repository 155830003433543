import React from "react";

export class Admin extends React.Component {
    constructor(props) {
      super(props);
    //   this.state = {
    //   };
    }
  
    render() {
      return (
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12" style={{ height: 100 + "vh" }}>
            <div className="logo-block">
              <img className="Sign-In-Logo center-block" src="/assets/img/q4llogo.png"/>
            </div>
            <div className="header-block">
              <div className="Sign-In-Header">Admin Section</div>
            </div>
          </div>
        </div>
      );
    }
  }
  