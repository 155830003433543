/**
 * This file contains the functionality to render quiz table
 */
import React, { Component } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { NotificationManager } from "react-notifications";


ChartJS.register(ArcElement, Tooltip, Legend);
var piedata = {
  labels: [],
  datasets: [
    {
      label: ["a", "b", "c", "d", "e", "f"],
      data: [1, 1, 1, 1, 1, 1],
      color: "white",
      backgroundColor: [
        "rgba(100, 24, 195, 1)",
        "rgba(135, 24, 195, 1)",
        "rgba(195, 24, 168, 1)",
        "rgba(195, 24, 123, 1)",
        "rgba(195, 24, 66, 1)",
        "rgba(168, 58, 24, 1)",
        "rgba(123, 100, 24, 1)",
        "rgba(66, 168, 24, 1)",
      ],
      borderColor: [
        "rgba(100, 24, 195, 1)",
        "rgba(135, 24, 195, 1)",
        "rgba(195, 24, 168, 1)",
        "rgba(195, 24, 123, 1)",
        "rgba(195, 24, 66, 1)",
        "rgba(168, 58, 24, 1)",
        "rgba(123, 100, 24, 1)",
        "rgba(66, 168, 24, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

var options = {
  plugins: {
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.backgroundColor;
      },
      borderColor: "white",
      borderRadius: 25,
      borderWidth: 2,
      color: "white",
      formatter: function (value, context) {
        var label1 = context.dataset.label[context.dataIndex];
        return label1;
      },
      font: {
        weight: "bold",
      },
      padding: 6,
      //formatter: Math.round
    },
  },
};

export class WheelSpinnerShow extends Component {
  constructor(props) {
    super(props);
    this.chartReference = React.createRef();

    this.state = {
      rotateDeg: "",
      wheel_spinner_description: "",
      options: 1,
      getRotateDegree: "",
      chartdata: piedata,
      wheelSpinnerOptions: [],
      wheelSpinnerOptionDefault: ["1", "2", "3", "4", "5", "6"],
      is_enable_email: true,
      player_email: "",
      rotationTimeReached: false,
      wheelSpinPlayed: false,
      email_error: "",
      segColors: [
        "#3f51b5",
        "#ff9800",
        "#e91e63",
        "#4caf50",
        "#009688",
        "#795548",
        "#9c27b0",
        "#e91e63",
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.wheelSpinnerControl = this.wheelSpinnerControl.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getWheelSpin();
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  wheelSpinnerControl() {
    if (!this.state.email) {
      this.setState({
        email_error: "Please enter your email",
      });
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(this.state.email)) {
      this.setState({
        email_error: "Please enter a valid email",
      });
      return false;
    }
    this.setState({ rotateDeg: 0, rotationTimeReached: false }, () => {
      let getCurrentWheelOptions =
        this.state.wheelSpinnerOptions.length != 0
          ? this.state.wheelSpinnerOptions
          : this.state.wheelSpinnerOptionDefault;
      let numOptions = getCurrentWheelOptions.length;
      let getRandomNumber = Math.floor(Math.random() * (numOptions - 1) + 1);
      let getSingleAngle = 360 / getCurrentWheelOptions.length;
      let totalRotation =
        getSingleAngle * (numOptions * (numOptions * 5)) -
        getSingleAngle * getRandomNumber;

      var getResult = getCurrentWheelOptions.find(function (element, index) {
        return index + 1 == getRandomNumber;
      });
      this.saveWheelSpinResultSave(getResult);
      this.setState({
        rotateDeg: totalRotation + getSingleAngle * 0.5,
        winnerItem: getResult,
      });
    });

    setTimeout(() => {
      this.setState({
        rotationTimeReached: true,
      });
    }, 5000);
  }

  saveWheelSpinResultSave(getResult) {
    var data = {};
    data["email"] = this.state.email;
    data["result"] = getResult;
    data["id"] = this.state.getData.id;

    var jsondata = JSON.stringify(data);
    var postdata = "data=" + jsondata;

    var queryurl = this.props.basepath + "wheelspin/result/save/" + this.state.getData.id;
    var self = this;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (data) {
      return data.json();
    }).then(function (response) {
      if(response && response.status==200 && response.data){
        self.setState({wheelSpinPlayed: true,});
      } else{
        NotificationManager.error("Server error: ", response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  componentDidMount() {
    let getCurrentWheelOptions =
      this.state.wheelSpinnerOptions.length != 0
        ? this.state.wheelSpinnerOptions.length
        : this.state.wheelSpinnerOptionDefault.length;
    let getRotateDegree = 360 / getCurrentWheelOptions;
    this.setState({
      getRotateDegree: getRotateDegree,
    });
  }

  getWheelSpin() {
    if (
      this.props != null &&
      this.props.match != null &&
      this.props.match.params != null &&
      this.props.match.params.id != null
    ) {
      let wheelspin_id = this.props.match.params.id;
      let wheelspin_slug = this.props.match.params.slug;
      let chartdata = { datasets: [{ data: [], label: [] }] };
      let self = this;
      var dataurl =
        this.props.basepath +
        "wheelspin/get/" +
        wheelspin_id +
        "/" +
        wheelspin_slug;
      fetch(dataurl, {
        method: "get",
        credentials: "include",
      }).then(function (response) {
          return response.json();
      }).then(function (response) {
        if (response && response.status==200 && response.data) {
          let chart = self.chartReference.current;
          if (chart != null) {
            let getOptions = JSON.parse(response.options);
            getOptions.forEach(function (value, index) {
              chartdata.datasets[0].data[index] = 1;
              chartdata.datasets[0].label[index] = value;
            });

            chart.data.datasets[0].data = chartdata.datasets[0].data;
            chart.data.datasets[0].label = chartdata.datasets[0].label;

            chart.update();

            self.setState({
              getData: response,
              wheelSpinnerOptions: getOptions,
              options: getOptions.length,
              wheel_spinner_description: response.description,
              is_enable_email: response.is_enable_email,
            });
          }
        }else{
          NotificationManager.error("Server error: ",response.message)
        }
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });
    }
  }

  render() {
    return (
      <div className="wheel-spin-widget">
        <div className="wheel-spinner-create">
          <div
            className="col-md-12 col-xs-12 text-center"
            style={{ marginBottom: 10 }}
          >
            <p style={{ marginTop: 20, marginBottom: 10 }}>
              {this.state.wheel_spinner_description}
            </p>

            <div className="wheel-spin-indicator">&#9660;</div>
            <div className="wheel_spinner_main">
              <button onClick={this.wheelSpinnerControl} id="spin">
                Spin
              </button>

              <div
                className="wheel_spinner_inner"
                style={{ transform: "rotate(" + this.state.rotateDeg + "deg)" }}
              >
                <Pie
                  ref={this.chartReference}
                  data={this.state.chartdata}
                  width={300}
                  plugins={[ChartDataLabels]}
                  height={300}
                  options={options}
                />
              </div>
            </div>

            {this.state.rotationTimeReached ? (
              <h5>{this.state.winnerItem}</h5>
            ) : (
              ""
            )}
          </div>
          {!this.state.wheelSpinPlayed ? (
            <div
              className="email-id"
              style={{ textAlign: "center", margin: "0 auto", width: 300 }}
            >
              <div className="form-group">
                <h5 style={{ color: "red", fontSize: "14px" }}>
                  {this.state.email_error}
                </h5>
                <input
                  onChange={this.handleChange}
                  placeholder="Enter your email"
                  type="email"
                  name="email"
                  className="form-control"
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default WheelSpinnerShow;
