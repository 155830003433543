/**
 * This file contains the code for uploading and rendering image.
 * This component is used for uploading user image, company logo, quiz background images etc
 */
import React from "react";
import swal from "sweetalert";
import { NotificationManager } from "react-notifications";

export class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data_uri: "",
      processing: false,
      deleting: false,
      userid: this.props.userid,
      imgtype: this.props.imgtype,
      title: this.props.title,
      quizid: this.props.quizid,
      queid: this.props.queid,
      ansid: this.props.ansid,
      imagepath: this.props.imagepath,
      uploaded_uri: this.props.uploaded_uri,
      imagePreviewFlag: this.props.imagePreviewFlag,
    };
    this.changeFile = this.changeFile.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.delImage = this.delImage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      quizid: nextProps["quizid"],
      queid: nextProps["queid"],
      ansid: nextProps["ansid"],
      imagepath: nextProps["imagepath"],
      imagePreviewFlag: nextProps["imagePreviewFlag"],
    });
  }

  delImage(e) {
    e.preventDefault();
    var self = this;
    if (this.state.filename == null || this.state.filename == "") {
      NotificationManager.error(
        "Image Delete",
        "You can not delete this image , Please replace this image with new image!"
      );
      return false;
    } else {
      this.setState(
        {
          deleting: true,
          showLoaderFlag: true,
        },
        () => {
          var formdata = new FormData();
          formdata.append("filepath", this.state.imagepath);
          formdata.append("filetype", this.state.filetype);
          formdata.append("filename", this.state.filename);
          formdata.append("quizid", this.state.quizid);
          formdata.append("userid", this.state.userid);
          formdata.append("imgtype", this.state.imgtype);
          formdata.append("queid", this.state.queid);
          formdata.append("ansid", this.state.ansid);

          //var queryurl = this.props.basepath + "index.php/quizzer/deletefileEx";
          var queryurl = this.props.basepath + "file/deletefile";
          fetch(queryurl, {
            method: "post",
            headers: {
              Accept: "application/json",
            },
            body: formdata,
            credentials: "include",
          }).then(function (response) {
            return response.json();
          }).then(function (response) {
              self.setState({
                deleting: false,
                showLoaderFlag: false,
                uploaded_uri: null,
                imagepath: null,
                filename: null,
              });
              if (self.props.onImageDeleteComplete != null) {
                self.props.onImageDeleteComplete(
                  self.state.imgtype,
                  self.state.ansid,
                  null
                );
              }
          }).catch(err=>{
            NotificationManager.error("Server error: " , err.message)
          });
        }
      );
    }
  }

  fileUpload() {
    this.setState({ showLoaderFlag: true }, () => {
      var formdata = new FormData();

      formdata.append("data_uri", this.state.data_uri);
      formdata.append("filetype", this.state.filetype);
      formdata.append("filename", this.state.filename);
      formdata.append("quizid", this.state.quizid);
      formdata.append("userid", this.state.userid);
      formdata.append("imgtype", this.state.imgtype);
      formdata.append("queid", this.state.queid);
      formdata.append("ansid", this.state.ansid);

      var self = this;
      //var queryurl = this.props.basepath + "index.php/quizzer/uploadfileEx";
      var queryurl = this.props.basepath + "file/uploadfile";
      fetch(queryurl, {
        method: "post",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: formdata,
      }).then((response) => {
        return response.json();
      }).then((response) => {
        self.setState({ showLoaderFlag: false }, () => {
          if (response && response.status == 200 && response.data) {
            var filepath = response.data.filepath;
            self.props.onImageUploadComplete(
              self.state.imgtype,
              self.state.ansid,
              filepath
            );
          } else {
            //swal("File Upload", "Error in File upload!!", "error");
            NotificationManager.error(
              "File Upload Error",
              "Error in File upload!!"
            );
          }
        });
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });
    });
  }
  changeFile(e) {
    const reader = new FileReader();
    const file = e.target.files[0];
    var self = this;
    reader.onload = (upload) => {
      self.setState(
        {
          data_uri: upload.target.result,
          filename: file.name,
          filetype: file.type,
          myfile: file,
        },
        self.fileUpload
      );
    };

    reader.readAsDataURL(file);
    e.target.value = null;
  }
  render() {
    let processing;
    let uploaded;

    if (this.state.uploaded_uri) {
      uploaded = (
        <div>
          <div>Image uploaded!</div>
        </div>
      );
    }

    if (this.state.processing) {
      processing = "Processing image, hang tight";
    } else if (this.state.deleting) {
      processing = "";
    }

    return (
      <div className="m-t-sm">
        <div className="">
          {this.state.title != null ? (
            <label>{this.state.title}</label>
          ) : (
            "Select Image"
          )}
          <i data-tip={this.props.info_text} className="fa fa-info-circle"></i>
          <div className="upload-block">
            {this.state.imagepath != null && this.state.imagepath != "" ? (
              <div className="upload-btn-wrapper" style={{ zIndex: "-1" }}>
                <button className="upload-btn">
                  {this.state.showLoaderFlag ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fa fa-image"></i>
                  )}
                </button>
                <input
                  type="file"
                  onChange={this.changeFile}
                  style={{ color: "transparent" }}
                />
              </div>
            ) : (
              <div className="upload-btn-wrapper">
                <button className="upload-btn">
                  {this.state.showLoaderFlag ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fa fa-image"></i>
                  )}
                </button>
                <input
                  type="file"
                  onChange={this.changeFile}
                  style={{ color: "transparent" }}
                />
              </div>
            )}
            {/* <button className={`btn btn-primary m-t-sm m-l-sm ${this.state.imagepath==null || this.state.imagepath==''?'hide':''}`} onClick={this.delImage}><i className="fa fa-trash"/></button> */}
            {processing}
            {uploaded}
            {this.state.imagePreviewFlag == true ? (
              <div className="image-upload-wrapper">
                {this.state.imagepath != null && this.state.imagepath != "" ? (
                  <div className="img-preview pull-right">
                    <label
                      style={{ maxWidth: "100%", margin: 0, cursor: "pointer" }}
                    >
                      <input
                        type="file"
                        id="image-delete"
                        onChange={this.changeFile}
                        style={{ color: "transparent" }}
                      />
                      <img
                        className="image-preview"
                        src={this.state.imagepath}
                        style={{ height: 35, width: 35 }}
                      />
                    </label>
                    <div className="image-upload-delete">
                      <i
                        className="fa fa-trash delete"
                        onClick={this.delImage}
                      />
                      <input
                        type="file"
                        id="image-delete"
                        onChange={this.changeFile}
                        style={{ color: "transparent" }}
                      />
                      <label
                        for={
                          "image-change-q image-change-q-" + this.state.ansid ==
                          "undefined"
                            ? this.state.quizid
                            : this.state.ansid
                        }
                        className="image-replace-icon"
                      >
                        <i
                          for={
                            "image-change-q-" + this.state.ansid == "undefined"
                              ? this.state.quizid
                              : this.state.ansid
                          }
                          className="fa fa-image"
                        ></i>
                        <input
                          id={
                            "image-change-q-" + this.state.ansid == "undefined"
                              ? this.state.quizid
                              : this.state.ansid
                          }
                          type="file"
                          onChange={this.changeFile}
                          style={{ color: "transparent", width: "100%" }}
                        />
                      </label>
                    </div>
                    <div className="loading-p">
                      {this.state.showLoaderFlag ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
