import React, { Component } from "react";

class EmailFormCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
    };

    // Bind event handler in the constructor
    this.onCustomEmailFormSubmit = this.onCustomEmailFormSubmit.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener(
      "submit",
      this.onCustomEmailFormSubmit,
      false
    );
  }

  componentWillUnmount() {
    document.body.removeEventListener(
      "submit",
      this.onCustomEmailFormSubmit,
      false
    );
  }

  onCustomEmailFormSubmit(event) {
    event.preventDefault();

    const form = event.target;
    let name = "";
    let email = "";
    let option = false;

    for (let i = 0; i < form.elements.length; i++) {
      const element = form.elements[i];
      const elementType = element.getAttribute("type");
      const elementValue = element.value.trim();

      if (elementType === "email") {
        email = elementValue;
      } else if (elementType === "text") {
        name = elementValue;
      } else if (elementType === "checkbox") {
        option = element.checked;
      }
    }
    // save result
    this.props.onResultSave({ name, email });
  }

  render() {
    const { quizemailformcode, header_content_bg } = this.props;
    const { errorMessage } = this.state;

    return (
      <div className="col-12">
        <div
          className="quiz_wrap__content text-center custom-form-field"
          style={{
            background: header_content_bg ? header_content_bg : "",
          }}
        >
          <div className="quiz_wrap__content___text">
            <div dangerouslySetInnerHTML={{ __html: quizemailformcode }} />
            {errorMessage && <p className="text text-danger">{errorMessage}</p>}
          </div>
        </div>
      </div>
    );
  }
}

export default EmailFormCode;
