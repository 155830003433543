import React from "react";
import { MDBDataTable } from 'mdbreact';
import ReactExport from "react-data-export";
import { NotificationManager } from "react-notifications";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class WheelSpinResponse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            poolVotes: [],
            wheelspinTitle: ''
        }
    }
    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                wheelspinTitle: this.props.location.state[1]
            })
        }
        this.getWheelSpinResponse();
    }

    getWheelSpinResponse() {
        if (this.props != null && this.props.match != null && this.props.match.params != null && this.props.match.params.id != null) {
            let wheelSpinId = this.props.match.params.id;
            var dataurl = this.props.basepath + "wheelspin/result/list/" + wheelSpinId;
            var self = this;
            fetch(dataurl, {method: "get",credentials: "include"
            }).then(function (response) {
                return response.json();
            }).then(function (response) {
                if (response && response.status==200 && response.data && response.data.length > 0) {
                    var tempData = [];
                    response.data.map(function (item, index) {
                        tempData[index] = {
                            id: index + 1,
                            email: item.email,
                            result: item.result,
                            play_date: item.play_date,
                        }
                    })
                    self.setState({
                        poolVotes: tempData
                    })
                } else {
                    NotificationManager.error("Server error: ", response.message);
                }
            }).catch(err=>{
                NotificationManager.error("Server error: " , err.message)
            });
        }
    }
    dataTablePollVote() {
        const data = {
            columns: [
                {
                    label: 'Id',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Result',
                    field: 'result',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Play Date',
                    field: 'play_date',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.poolVotes
        };

        return (
            <MDBDataTable
                striped
                bordered
                hover
                data={data}
            />
        );
    }

    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        
                        <div className="col-md-3 export-data-poll">
                            <ExcelFile element={<button className="btn btn-primary">Export Data</button>}>
                                <ExcelSheet data={this.state.poolVotes} name={this.state.wheelspinTitle}>
                                    <ExcelColumn width="500" label="Player Email" value="email" />
                                    <ExcelColumn label="Spin Result" value="result" />
                                    <ExcelColumn label="Date of Spin" value="play_date" />
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                    </div>
                    <div className="tableFixHead pool-data-list">
                        {this.dataTablePollVote()}
                    </div>
                </div>
            </div>
        );
    }
}

export default WheelSpinResponse;
