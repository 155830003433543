import React, { Component } from "react";

class LoadingScreen extends Component {
  render() {
    const { theme_colors,msg } = this.props;
    return (
      <div
        className="loading-text d-flex text-center justify-content-center align-items-center"
        style={{
          background: theme_colors ? theme_colors.header_content_bg : "",
          height: "350px",
        }}
      >
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <span style={{color:'#000'}}>{msg}</span>
      </div>
    );
  }
}
export default LoadingScreen;
