import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
  
    this.state = {
      displayColorPicker: false,
      color: "#000"
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick()
  {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose() 
  {
    this.setState({ displayColorPicker: false })
  }

  handleChange(color){
    let newCorlo = {
      hex:"rgb"+"("+color.rgb.r +' '+color.rgb.g +' '+ color.rgb.b + ' /' + (color.rgb.a*100+"%")+")" 
    };
    this.props.onSelectLanguage(newCorlo); 
    this.setState({ color: color.rgb })
  }

  
  render() 
  {

    const styles = reactCSS({
      'default': {
        color: {
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          paddingRight: '4px',
          background: this.props.scolor,
          display:this.props.enableCustomTheme == true ? 'block':'none',
        },
        swatch: {
          padding: '1px',
          background: '#fff',
          borderRadius: '50%',
        
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
          left: '-129px'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div className="picker-wrapper" style={{display:this.props.enableCustomTheme === true?'block':'none'}}>

        <div className="picker-inner" style={ styles.swatch } onClick={ this.handleClick }>
        <div className="picker-icon-custom" style={ styles.color } />
        <span className="picker-label"><i class="fa fa-crosshairs" aria-hidden="true"></i>{this.props.picker_label}</span>

        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
        </div> : null }

      </div>
    )
  }
}

export default ColorPicker