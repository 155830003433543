import React from "react";
import swal from "sweetalert";
import { NotificationManager } from "react-notifications";
import { Link, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ColorPicker from "../picker/feedbackcolorpicker";

// READ ME
// please use function name with parameter through out the code else will result in build error
// function_name(parameter)
// Dont use anonymous function like var variable_name = ()=> {}

export class Feedbacks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emotion: false,
      star: false,
      thumb_up: false,
      scale: false,
      data: "",
      title_error: "",
      custom_theme_color: "",
      custom_color_checked: false,
      is_enable_email: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.createFeedback = this.createFeedback.bind(this);
    this.feedbackCustomColor = this.feedbackCustomColor.bind(this);
    this.customColorChecked = this.customColorChecked.bind(this);
    this.isEnableEmail = this.isEnableEmail.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value == "emotion") {
      this.setState({
        emotion: true,
        star: false,
        thumb_up: false,
        scale: false,
      });
    } else if (event.target.value == "star") {
      this.setState({
        emotion: false,
        star: true,
        thumb_up: false,
        scale: false,
      });
    } else if (event.target.value == "thumb") {
      this.setState({
        emotion: false,
        star: false,
        thumb_up: true,
        scale: false,
      });
    } else if (event.target.value == "scale") {
      this.setState({
        emotion: false,
        star: false,
        thumb_up: false,
        scale: true,
      });
    }
  }
  customColorChecked(event) {
    if (event.target.checked == true) {
      this.setState({
        custom_color_checked: true,
      });
    } else {
      this.setState({
        custom_color_checked: false,
      });
    }
  }

  isEnableEmail(event) {
    if (event.target.checked == true) {
      this.setState({
        is_enable_email: true,
      });
    } else {
      this.setState({
        is_enable_email: false,
      });
    }
  }

  //please use function name with parameter through out the code else will result in build error
  createFeedback(event) {
    this.setState({ [event.target.name]: event.target.value });
    var data = {};
    if (this.state.title == "" || this.state.title == null) {
      //swal("Required", "Title field is required.", "warning");
      NotificationManager.error("Validation Error", "Title field is required.");
      return false;
    } else if (
      this.state.feeling_type == "" ||
      this.state.feeling_type == null
    ) {
      //swal("Required", "Please select feeling type.", "warning");
      NotificationManager.error(
        "Validation Error",
        "Feeling type is required."
      );
      return false;
    } else if (this.state.question == "" || this.state.question == null) {
      //swal("Required", "Question field is required.", "warning");
      NotificationManager.error(
        "Validation Error",
        "Question field is required."
      );
      return false;
    }
    //feedback custom color
    data["custom_theme_color"] = this.state.custom_theme_color.hex;
    data["title"] = this.state.title;
    data["feeling_type"] = this.state.feeling_type;
    data["question"] = this.state.question;
    data['is_enable_email'] = this.state.is_enable_email;
    var jsondata = JSON.stringify(data);
    var postdata = "userid=" + this.props.user.userid + "&data=" + jsondata;

    var queryurl = this.props.basepath + "feedback/save";
    var self = this;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (data) {
      return data.json();
    }).then(function (response) {
      if(response && response.status==200 && response.data){
        self.props.history.push("Feedback/share/" + response.id);
      }else{
        NotificationManager.error("Server error: " + response.message)
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    // e.target.reset();
  }
  feedbackCustomColor(color) {
    this.setState({
      custom_theme_color: color,
    });
  }

  render() {
    return (
      <div>
        <div className="feedback-create-page">
          <div className="col-md-6 col-xs-12">
            <form onSubmit={this.handleSubmit.bind(this)}>
              <div className="qzpanel-default" style={{ width: "100%" }}>
                <div
                  className="qzpanel-body"
                  style={{ minHeight: "100%", height: "100%" }}
                >
                  <div className="frmqzsettings">
                    <div className="form-group has-feedback">
                      <label className="Sign-In-Label"> Title</label>
                      <input
                        name="title"
                        className="Sign-In-TextBox"
                        id="exampleqztitle"
                        onChange={this.handleChange}
                        type="text"
                        placeholder="Title here"
                      />
                    </div>

                    <div className="form-group has-feedback">
                      <label className="Sign-In-Label"> Feeling</label>
                      <br></br>
                      <select
                        class="custom-select form-control"
                        onChange={this.handleChange}
                        name="feeling_type"
                      >
                        <option>Select Feeling</option>
                        <option value="emotion">Emotion</option>
                        <option value="star">Star</option>
                        <option value="thumb">Thumb Up</option>
                        <option value="scale">Scale</option>
                      </select>
                    </div>
                    <div
                      className={
                        this.state.emotion == false ? "hidden" : "show"
                      }
                    >
                      <span title="Angry">😠</span>
                      <span title="Worried">😟</span>
                      <span title="Neutral">😐</span>
                      <span title="Happy">🙂</span>
                      <span title="Delighted">😀</span>

                    </div>
                    <div
                      className={this.state.star == false ? "hidden" : "show"}
                    >
                      <span>⭐</span>
                      <span>⭐</span>
                      <span>⭐</span>
                      <span>⭐</span>
                      <span>⭐</span>
                    </div>
                    <div className={this.state.thumb_up == false ? "hidden" : "show"}>
                      <span>👍</span>
                      <span>👎</span>
                    </div>
                    <div className={this.state.scale == false ? "hidden" : "show"}>
                      <span>1</span>
                      <span>2</span>
                      <span>3</span>
                      <span>4</span>
                      <span>5</span>
                    </div>
                    <div className="form-group has-feedback">
                      <label className="Sign-In-Label">Question</label>
                      <textarea
                        style={{ height: "100px" }}
                        name="question"
                        onChange={this.handleChange}
                        className="Sign-In-TextBox"
                        id="exampleqztitle"
                        placeholder="Tell us about your experience."
                      ></textarea>
                    </div>
                    <label for="scales">Custom Color</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={this.state.custom_color_checked}
                        onClick={this.customColorChecked}
                        id="custom_color"
                        name="custom_color"
                      />
                      <span className="slider round"></span>
                    </label>
                    <br></br>
                    <label for="scales">Do you want to collect user emails before showing the result?</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={this.state.is_enable_email}
                        onClick={this.isEnableEmail}
                        name="is_enable_email"
                      />
                      <span className="slider round"></span>
                    </label>
                    <br></br>
                    <button
                      style={{ width: "27%" }}
                      onClick={this.createFeedback}
                      className="btn-normal new-button-response"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6 col-xs-12 text-center">
            <h3 className="wr-p-title">Web Response Preview</h3>
            <div className="alert alert-success alert-dismissible feedback-preview">
              <span
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      this.state.custom_theme_color.hex,
                      "important"
                    );
                  }
                }}
                className="pull-right fc"
              >
                X
              </span>
              <h4 >
                {this.state.title
                  ? this.state.title
                  : "Your Experience is Important for Us!"}
              </h4>
              <p style={{ marginTop: 20, marginBottom: 10 }}>
                {this.state.question
                  ? this.state.question
                  : "Do you find the information on this page useful?"}
              </p>
              <div className={this.state.emotion == false ? "hidden" : "show"}>
                <span title="Angry">😠</span>
                <span title="Worried">😟</span>
                <span title="Neutral">😐</span>
                <span title="Happy">🙂</span>
                <span title="Delighted">😀</span>

              </div>
              <div className={this.state.star == false ? "hidden" : "show"}>
                <span>⭐</span>
                <span>⭐</span>
                <span>⭐</span>
                <span>⭐</span>
                <span>⭐</span>
              </div>
              <div className={this.state.thumb_up == false ? "hidden" : "show"}>
                <span>👍</span>
                <span>👎</span>
              </div>
              <div className={this.state.scale == false ? "hidden" : "show"}>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
              </div>
              <button
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "background-color",
                      this.state.custom_theme_color.hex,
                      "important"
                    );
                  }
                }}
                className="button-preview btn btn-primary q4l-send-feedback"
              >
                Send
              </button>
              <a className="feed-link" href="https://www.quizzes4leads.com">
                Wanna try on your website?
              </a>
              <button
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "background-color",
                      this.state.custom_theme_color.hex,
                      "important"
                    );
                  }
                }}
                className="btn btn-primary feedback-open"
              >
                Feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Feedbacks;
