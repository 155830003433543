import React from "react";
import ReactTooltip from "react-tooltip";

export class WheelSpinShare extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedShareIcon: "iframe",
      copyText: "Copy",
    };
    this.onChangeShareIcon = this.onChangeShareIcon.bind(this);
  }
  onChangeShareIcon(e) {
    var selectedShareIcon = e.target.getAttribute("name");
    var self = this;
    this.setState({ selectedShareIcon });
  }
  render() {
    var content = null;
    if (
      this.props != null &&
      this.props.match != null &&
      this.props.match.params != null &&
      this.props.match.params.id != null
    ) {
      let wheelspin_id = this.props.match.params.id;
      let wheelspin_slug = this.props.match.params.slug;
      var poolPath = this.props.localpath + "wheelspin/publish/show/" + wheelspin_id + "/" + wheelspin_slug;
      var iframecode = "<iframe width='713' height='419' src='" + poolPath + "' frameborder='0' allowfullscreen></iframe>";
      if (this.state.selectedShareIcon == "iframe") {
        content = (<textarea className="form-control Sign-In-TextBox" readOnly value={iframecode} style={{ height: 100 }}></textarea>);
      } else if (this.state.selectedShareIcon == "widget") {
        var wheelspinPath = this.props.localpath + "wheelspin/publish/show/" + wheelspin_id + "/" +wheelspin_slug;
        var wheelspinWidget = `<script type="text/javascript">
        
                (function()
                {
                    var script = document.createElement("script");
                    script.type = "text/javascript";
                    script.src="https://api.quizzes4leads.com/wheelspin.js";
        
                    var script1 = document.createElement("script");
                    script1.type = "text/javascript";
                    script1.src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.2/Chart.min.js";
        
                    var script2 = document.createElement("script");
                    script2.type = "text/javascript";
                    script2.src="https://cdn.jsdelivr.net/npm/chartjs-plugin-datalabels@0.4.0/dist/chartjs-plugin-datalabels.min.js";
        
                    var wheelSpinId = ${wheelspin_id};
                    var charsetmetatag = document.createElement('meta');
                    charsetmetatag.setAttribute("charset", "UTF-8");
                    document.getElementsByTagName("head")[0].appendChild(charsetmetatag);
                    document.getElementsByTagName("head")[0].appendChild(script);
                    document.getElementsByTagName("head")[0].appendChild(script1);
                    document.getElementsByTagName("head")[0].appendChild(script2);
                    if(script.readyState){
                        script.onreadystatechange = function(){
                            if(script.readyState==='loaded' || script.readyState==='complete'){
                                script.onreadystatechange = null;
                                init(wheelSpinId);
                            }
                        }
                    }
                    else {  //Others
                    script.onload = function() {
                      init(wheelSpinId);
                    };
                  } 
                })();
            
              </script>`;
        navigator.clipboard.writeText(wheelspinWidget);
        content = (
          <textarea
            className="form-control Sign-In-TextBox"
            readOnly
            value={wheelspinWidget}
            style={{ height: 100, overflowY: "scroll" }}
          ></textarea>
        );
      }
    }
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <div id="quizpanel" className="qzpanel qzpanel-blur">
              <div
                className="panel-heading text-center"
                style={{ backgroundColor: "#5d13e7" }}
              >
                <h2 style={{ color: "#fff" }}>Your Wheel Spinner Is Ready</h2>
              </div>
              <div className="panel-body">
                <div className="form-group">
                  <div
                    className="col-md-12 icon_block"
                    style={{ marginBottom: 30 }}
                  >
                    <div
                      data-tip="Wheel Spinner Iframe, copy and Paste this inside html, Where you want to show."
                      name="iframe"
                      className={`col-md-2 text-center share-icon ${
                        this.state.selectedShareIcon == "iframe" ? "active" : ""
                      }`}
                      onClick={this.onChangeShareIcon}
                    >
                      <i name="iframe" className="fa fa-globe fa-3x"></i>
                    </div>
                    <ReactTooltip />
                    <div
                      data-tip="Wheel Spinner, copy and Paste this inside your website header file."
                      name="widget"
                      className={`col-md-3 text-center share-icon ${
                        this.state.selectedShareIcon == "widget" ? "active" : ""
                      }`}
                      onClick={this.onChangeShareIcon}
                    >
                      <i
                        name="widget"
                        class="fa fa-code fa-3x"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="form-group">
                  <div className="col-md-12">{content}</div>
                </div>
              </div>
              <hr />
              <button
                style={{ marginRight: "15px" }}
                className="btn-normal copy-btn"
                onClick={this.onClose}
              >
                {this.state.copyText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WheelSpinShare;
