/**
 * This file contains the code for creating or editing the quiz
 */

import React from "react";
import swal from "sweetalert";
import { themeArr, quizTypeArr } from "../global";
import { ImageUpload } from "../components/imageupload";
import { QueTable } from "../forms/questiontable";
import { Queform } from "../forms/questionform";
import ReactTooltip from "react-tooltip";
import QuizeThemeFirst from "../templates/theme_1";
import QuizeThemeSecond from "../templates/theme_2";
import { Link, BrowserRouter } from "react-router-dom";
import FirstThemePrev from "../templates/template-preview/theme1.jpg";
import { NotificationManager } from "react-notifications";
import ColorPicker from "../../src/picker/colorpicker";
import CustomTextEditor from "../components/customtexteditor";
import * as DOMPurify from "dompurify";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";


var defaultBackgroundImage = "";
var shareBtnSize = 36;
var shareUrl = "";


export class QuizEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabnum: 0,
      tabs: [
        { id: 0, title: "Settings", selected: true },
        { id: 1, title: "Design", selected: false },
        { id: 2, title: "Lead", selected: false },
        { id: 3, title: "Outcome", selected: false },
        { id: 4, title: "Advanced", selected: false },
      ],
      progress: 20,
      user: this.props.user,
      que: null,
      ansArr: null,
      queformActive: false,
      quiz: this.props.quiz,
      minanscount: 2,
      animationType: "slideInRight",
      quetable: {
        header: [["id", "Questions", "Answer Count", "Action"]],
        rowdata: [[]],
      },
      crmdata: null,
      crmprovider: null,
      selectedQue: null,
      showLoaderFlag: false,
      messageUpgrade: "",
      makedisabled: true,
      makedisabledCheckbox: false,
      messageUpgradechecbox: "",
      checkTheme: "",
      bgimg: "",
      quiz: { backgroundimg: "" },
      themeType: "",
      header_bg: "",
      header_text: "",
      header_content_bg: "",
      header_content_text: "",
      button_color: "",
      button_color_text: "",
      footer_bg: "",
      footer_text_color: "",
      enableCustomTheme: true,
      customThemeHideForTransparentTheme: true,
      scrollAddClass: false
    };

    this.nexttab = this.nexttab.bind(this);
    this.prevtab = this.prevtab.bind(this);
    this.onTabClick = this.onTabClick.bind(this);
    this.updateQuiz = this.updateQuiz.bind(this);
    this.updateQue = this.updateQue.bind(this);
    this.updateSelectedQue = this.updateSelectedQue.bind(this);
    this.updateAns = this.updateAns.bind(this);
    this.quizPlay = this.quizPlay.bind(this);
    this.content = this.showAns.bind(this);

    // this.saveQue = this.saveQue.bind(this);
    // this.editQue = this.editQue.bind(this);
    // this.delQue = this.delQue.bind(this);
    this.finish = this.finish.bind(this);
    this.handleQuePreview = this.handleQuePreview.bind(this);
    this.activateQueForm = this.activateQueForm.bind(this);
    this.closeQueForm = this.closeQueForm.bind(this);

    this.showAns = this.showAns.bind(this);
    this.getQuizData = this.getQuizData.bind(this);
    this.changeColorHeaderBg = this.changeColorHeaderBg.bind(this);
    this.changeColorHeaderText = this.changeColorHeaderText.bind(this);
    this.changeColorHeaderContentBg =
      this.changeColorHeaderContentBg.bind(this);
    this.changeColorHeaderContentText =
      this.changeColorHeaderContentText.bind(this);
    this.changeColorButton = this.changeColorButton.bind(this);
    this.changeColorFooterBg = this.changeColorFooterBg.bind(this);
    this.changeColorFooterText = this.changeColorFooterText.bind(this);
    this.getSeoSeetingInfo = this.getSeoSeetingInfo.bind(this);
    this.changeColorButtonText = this.changeColorButtonText.bind(this);

    //this.upgradeMessage = this.upgradeMessage.bind(this);
    //this.checkboxUpgradePlan = this.checkboxUpgradePlan.bind(this);

    // var temptabarr = this.state.tabs;
    // temptabarr.push({title: "Settings", selected: true});
    // temptabarr.push({title: "Design", selected: false});
    // temptabarr.push({title: "Publish", selected: false});

    // this.setState({tabs: temptabarr});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ quiz: nextProps["quiz"] });
    window.addEventListener('scroll', this.handleScroll);

  }
  handleScroll = () => {
    if (window.scrollY > 100) {
      this.setState({
        scrollAddClass: true
      })
    } else {
      this.setState({
        scrollAddClass: false
      })
    }
  };

  componentWillUnmount() {
    // Clean up the listener when the component unmounts
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.props.match.params != null && this.props.match.params.id) {
      var quizid = this.props.match.params.id;
      this.setState({ quizid }, this.getQuizData);
    } else {
      var quiz = {};
      quiz.randomque = "0";
      quiz.randomans = "0";
      quiz.title = "";
      quiz.description = "";
      quiz.quizwelcomemsg = "";
      quiz.quizfootermsg = "";
      // quiz.theme = "theme1";
      quiz.theme2 = "theme2";
      quiz.quizthankyoumsg = "";
      quiz.quizemailmsg = "";
      quiz.quizoffermsg = "";
      quiz.quizscoremsg = "";
      quiz.quizbtntxt = "";
      quiz.quizofferbtntxt = "Schedule Now";
      quiz.scoretype = "Percentage";
      quiz.seoSeetingInformation = ["hhhhhh"];

      quiz.quizmetadata = null;
      quiz.theme_type = localStorage.getItem("selected_theme_id");
      // /uploads/theme_first_bg.png
      if (this.state.quiz.backgroundimg == "") {
        const query = new URLSearchParams(this.props.location.search);
        const selected_theme = query.get("theme");
        const selected_theme_type = query.get("quiztype");

        if (selected_theme == "1" && selected_theme_type == "qztype1") {
          defaultBackgroundImage = "/assets/templates/theme_first_bg.png";
          quiz.backgroundimg = "/assets/templates/theme_first_bg.png";
          // defaultBackgroundImage = '/uploads/theme_first_bg.png';
          // quiz.backgroundimg = '/uploads/theme_first_bg.png';
          //quiz.theme = "theme1";
        } else if (selected_theme == "2" && selected_theme_type == "qztype1") {
          defaultBackgroundImage = "/assets/templates/theme_second_bg.jpg";
          quiz.backgroundimg = "/assets/templates/theme_second_bg.jpg";
          // defaultBackgroundImage = '/uploads/theme_first_bg.png';
          // quiz.backgroundimg = '/uploads/theme_second_bg.jpg';
          //quiz.theme = "theme2";
        } else if (selected_theme == "1" && selected_theme_type == "qztype2") {
          defaultBackgroundImage = "/assets/templates/personality_quiz_1.jpg";
          quiz.backgroundimg = "/assets/templates/personality_quiz_1.jpg";
          //quiz.theme = "theme4";
        } else if (selected_theme == "2" && selected_theme_type == "qztype2") {
          defaultBackgroundImage = "/assets/templates/personality_quiz_2.jpg";
          quiz.backgroundimg = "/assets/templates/personality_quiz_2.jpg";
          //quiz.theme = "theme5";
        }
        var quiztype = query.get("quiztype");
        quiz.quiztype = quiztype;
      } else {
        quiz.backgroundimg = "";
        //quiz.theme = "";
      }

      quiz.theme = "custom_theme";
      quiz.enableCustomTheme = true;
      quiz.bgimg = "";
      quiz.collectuseremailflag = 1;
      quiz.is_enable_player_result = 0;
      quiz.que = [];
      this.setState({ quiz }, this.createQuiz);
    }
  }
  getSeoSeetingInfo(seoSeetingData) {
    var quiz = this.state.quiz;
    quiz["meta_title"] = seoSeetingData["meta_title"];
    quiz["meta_description"] = seoSeetingData["meta_description"];
    quiz["google_analytics"] = seoSeetingData["google_analytics"];
    quiz["crm_tag"] = seoSeetingData["crm_tag"];

    this.setState({
      quiz: quiz,
    });
  }
  componentWillMount() {
    // this.setState({
    //     quiz: {
    //         backgroundimg: 'uploads/smb-06803c47a981856d9fc628243a886769-undefined-theme_second_bg.jpg',
    //     },
    // })
    const query = new URLSearchParams(this.props.location.search);
    const selected_theme = query.get("theme");
    const selected_theme_type = query.get("quiztype");
    localStorage.setItem("selected_theme_id", selected_theme);
    this.setState({
      themeType: selected_theme,
    });
    if (selected_theme == "1" && selected_theme_type == "qztype1") {
      this.setState({
        themeType: "uploads/theme_first_bg.png",
      });
    } else if (selected_theme == "2" && selected_theme_type == "qztype1") {
      this.setState({
        themeType: "uploads/theme_second_bg.jpg",
      });
    } else if (selected_theme == "1" && selected_theme_type == "qztype2") {
      this.setState({
        themeType: "uploads/personality_quiz_1.jpg",
      });
    } else if (selected_theme == "2" && selected_theme_type == "qztype2") {
      this.setState({
        themeType: "uploads/personality_quiz_2.jpg",
      });
    }
  }

  createQuiz() {
    var quiz = this.state.quiz;
    quiz.quizstatus = "draft";

    var jsonStr = JSON.stringify(quiz);
    jsonStr = encodeURIComponent(jsonStr);
    var postdata =
      "userid=" +
      this.state.user.userid +
      "&type=quiz&action=savequiz" +
      "&params=" +
      jsonStr;

    //var queryurl = this.props.qzbasepath + "getdata";
    var queryurl = this.props.basepath + "quiz/savequiz";
    var self = this;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      self.setState({ showLoaderFlag: false });
      if (response && response.status == 200 && response.data) {
        quiz["quizid"] = response.data.quizid;
        self.setState({ quiz });
      }else{
        NotificationManager.error("Server error: " + response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  modifyQuiz() {
    var quiz = this.state.quiz;
    let theme_colors = [
      { header_bg: this.state.header_bg.hex },
      { header_text: this.state.header_text.hex },
      { header_content_bg: this.state.header_content_bg.hex },
      { header_content_text: this.state.header_content_text.hex },
      { footer_bg: this.state.footer_bg.hex },
      { footer_text_color: this.state.footer_text_color.hex },
      { button_color: this.state.button_color.hex },
      { button_color_text: this.state.button_color_text.hex },
    ];
    quiz.theme_color = JSON.stringify(theme_colors);
    // if(quiz.quiztype == 'qztype8'){
    //   var metadata = quiz.quizmetadata;
    //   quiz.quizmetadata = JSON.stringify(metadata);
    // }

    var jsonStr = JSON.stringify(quiz);
    jsonStr = encodeURIComponent(jsonStr);
    var postdata =
      "userid=" +
      this.state.user.userid +
      "&type=quiz&action=savequiz" +
      "&params=" +
      jsonStr;
    var queryurl = this.props.basepath + "quiz/savequiz";
    var self = this;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      self.setState({ showLoaderFlag: false });
      if (response && response.status == 200 && response.data) {
        self.setState(quiz);
      }else{
        NotificationManager.error("Server error: " + response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  getQuizData() {
    var flag = false;
    var quizid = this.state.quizid;
    //var queryurl = this.props.basepath + "index.php/quizzer/qzcreate/1";
    var queryurl = this.props.basepath + "quiz/getdata";
    var postdata =
      "flag=" +
      flag +
      "&quizid=" +
      quizid +
      "&userid=" +
      this.state.user.userid;
    var self = this;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      if(response && response.status==200 && response.data){
        var quizobj = response.data;
        quizobj["enableCustomTheme"] = true;
        
        // if (quizobj.theme == "custom_theme") {
        //   quizobj["enableCustomTheme"] = true;
        // }
        quizobj.personalityArr = null;
        if ((quizobj.quiztype == "qztype2" || quizobj.quiztype == "qztype7") && quizobj.quizmetadata) {
          quizobj.personalityArr = JSON.parse(quizobj.quizmetadata);
        }
        if ((quizobj.quiztype == "qztype8" || quizobj.quiztype == "qztype9")  && quizobj.quizmetadata) {
          var metadata = JSON.parse(quizobj.quizmetadata);
          if(metadata.personalityArr){
            quizobj.personalityArr = JSON.parse(metadata.personalityArr);
          }
          if(metadata.quecategory){
            quizobj.quecategory = JSON.parse(metadata.quecategory);
          }
        }

        // for theme colors select
        var header_bg = { hex: "" };
        var header_text = { hex: "" };
        var header_content_bg = { hex: "" };
        var header_content_text = { hex: "" };
        var footer_bg = { hex: "" };
        var footer_text_color = { hex: "" };
        var button_color = { hex: "" };
        var button_color_text = { hex: "" };
        if (quizobj != null && quizobj.theme_color != null && quizobj.theme_color != "") {
          let theme_colors = JSON.parse(quizobj.theme_color);
          header_bg.hex = theme_colors[0].header_bg;
          header_text.hex = theme_colors[1].header_text;
          header_content_bg.hex = theme_colors[2].header_content_bg;
          header_content_text.hex = theme_colors[3].header_content_text;
          footer_bg.hex = theme_colors[4].footer_bg;
          footer_text_color.hex = theme_colors[5].footer_text_color;
          button_color.hex = theme_colors[6].button_color;
          button_color_text.hex = theme_colors[7]
            ? theme_colors[7].button_color_text
            : "";
        }
        var makeFalseCustomThemehideForTransparent = true;
        if (quizobj.emailformtype == null) {
          quizobj.emailformtype = "standard";
        }
        if (quizobj.landingformtype == null) {
          quizobj.landingformtype = "standard";
        }

        self.setState({
          quiz: quizobj, header_bg, header_text, header_content_bg, header_content_text,footer_bg, footer_text_color, button_color,
          button_color_text: button_color_text ? button_color_text : "",
          customThemeHideForTransparentTheme:true,
        },() => {
          self.updateQuiz(quizobj);
        });
      }else{
        NotificationManager.error("Server error: " + response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  finish(e) {
    var draftflag = e.target.getAttribute("data-draft");
    //var jsoncrmdata = JSON.stringify(this.state.crmdata);
    var quizdata = this.state.quiz;

    if (quizdata.title == null || quizdata.title == "") {
      alert("Quiz title cannot be empty");
      return false;
    }
    if (quizdata.que == null || quizdata.que.length == 0) {
      alert(
        "There are no questions in this quiz. Please add atleast one question before publishing the quiz."
      );
      return false;
    }

    //if(quizdata.crmdata == null)
    // {
    //     quizdata.crmdata = jsoncrmdata;
    // }
    this.saveQuiz(draftflag);
  }

  activateQueForm() {
    this.setState({ queformActive: true });
  }

  closeQueForm() {
    this.setState({ queformActive: false });
  }

  updateQuiz(quiz) {
    this.setState({ quiz: quiz });
  }

  saveQuiz(saveAsDraft) {
    this.setState({
      showLoaderFlag: true,
      loaderMessage: "Saving Quiz Please Wait...",
    });

    var selectedquiz = this.state.quiz;
    let theme_colors = [
      { header_bg: this.state.header_bg.hex },
      { header_text: this.state.header_text.hex },
      { header_content_bg: this.state.header_content_bg.hex },
      { header_content_text: this.state.header_content_text.hex },
      { footer_bg: this.state.footer_bg.hex },
      { footer_text_color: this.state.footer_text_color.hex },
      { button_color: this.state.button_color.hex },
      { button_color_text: this.state.button_color_text.hex },
    ];
    selectedquiz.theme_color = JSON.stringify(theme_colors);
    // generate quiz title slug from title
    var getQuizTitleSlug = this.state.quiz.title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");

    selectedquiz["quiz_slug"] = getQuizTitleSlug;

    selectedquiz["quizarray"] = null;

    if (saveAsDraft) {
      selectedquiz.quizstatus = "draft";
    } else {
      selectedquiz.quizstatus = "published";
    }

    if (selectedquiz.que == null || selectedquiz.que.length == 0) {
      selectedquiz.que_count = 0;
    } else {
      selectedquiz.que_count = selectedquiz.que.length;
    }
    selectedquiz.company = "Socialmbuzz";
    if (
      selectedquiz.quiztype == "qztype2" ||
      selectedquiz.quiztype == "qztype5" ||
      selectedquiz.quiztype == "qztype6" ||
      selectedquiz.quiztype == "qztype7"
    ) {
      selectedquiz.quizmetadata = JSON.stringify(selectedquiz.personalityArr);
    } else if (selectedquiz.quiztype == "qztype8" || selectedquiz.quiztype == "qztype9") {
      // if(selectedquiz.quizmetadata == null){
      //   selectedquiz.quizmetadata = {};
      // }
      var metadata = {personalityArr:JSON.stringify(selectedquiz.personalityArr), quecategory:JSON.stringify(selectedquiz.quecategory)};
      //selectedquiz.quizmetadata.quecategory = JSON.stringify(selectedquiz.quecategory);
      selectedquiz.quizmetadata = JSON.stringify(metadata);

    } else {
      selectedquiz.quizmetadata = null;
    }
    selectedquiz.description = selectedquiz.quizwelcomemsg;

    var jsonStr = JSON.stringify(selectedquiz);
    jsonStr = encodeURIComponent(jsonStr);
    var postdata =
      "userid=" +
      this.state.user.userid +
      "&type=quiz&action=savequiz" +
      "&params=" +
      jsonStr;
    var queryurl = this.props.basepath + "quiz/savequiz";
    var self = this;

    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      self.setState({ showLoaderFlag: false });
      if (response && response.status==200 && response.data) {
        if (saveAsDraft) {
          NotificationManager.success(
            "Quiz Saved",
            "Your quiz has been successfully saved as draft. Please ensure to publish it before sharing it in your network."
          );
        } else {
          // swal(
          //   "Quiz Saved",
          //   "Your quiz has been successfully saved and published!!",
          //   "success"
          // );
          NotificationManager.success(
            "Quiz Saved",
            "Your quiz has been successfully saved and published."
          );
          self.props.history.push({pathname:"/app/Quiz/Share/" + response.data.quizid,
          state:{quizType:selectedquiz.quiztype, quizTitle:response.data.quiz_slug, quizid:response.data.quizid}});
        }
      }
      else{
        NotificationManager.error("Server error: ",response.message)
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  updateQue(que) {
    this.setState({ que });
  }
  updateSelectedQue(selectedQue) {
    this.setState({ selectedQue: selectedQue });
  }

  updateAns(ansArr) {
    this.setState({ ansArr: ansArr });
  }

  islasttab() {
    return this.state.tabnum == this.state.tabs.length - 1;
  }

  isfirsttab() {
    return this.state.tabnum == 0;
  }

  onTabClick(event) {
    var tabid = event.target.getAttribute("dataid");
    var tabnum = this.state.tabnum;
    var progressVal = this.state.progress;
    var tempqueArr = this.state.quetable;
    var selectedQue = this.state.selectedQue;
    if (tabid != tabnum) {
      tabnum = tabid;
      var index = parseInt(tabnum) + 1;
      progressVal = ((1 * index) / this.state.tabs.length) * 100;
      if (tabnum == 1) {
        var tempArr = [];
        if (
          this.state.quiz == null ||
          this.state.quiz.que == null ||
          this.state.quiz.que.length == 0
        ) {
          tempArr[0] = [0, "There are no questions in this quiz!!", null, null];
        } else {
          //this.state.quiz.que.map(function(item, index) {
          Object.values(this.state.quiz.que).map(function (item, counter) {
            var jsonansobj = JSON.parse(item.jsonans);
            tempArr[counter] = [
              counter,
              item.title,
              jsonansobj.length,
              item.action,
            ];
          }, this);
        }

        tempqueArr.rowdata = tempArr;
        selectedQue = this.state.quiz.que[0];
      }

      this.setState({
        quetable: tempqueArr,
        selectedQue,
        tabnum,
        progress: progressVal,
        animationType: "slideInRight",
      });
    }
  }

  nexttab() {
    if (this.state.quiz.title == "") {
      NotificationManager.error(
        "Validation Error",
        "Quiz title cannot be empty"
      );
      return false;
    }

    if (this.state.tabnum == 2) {
      if (this.state.quiz.quiztype == "qztype1") {
        if (
          !this.state.quiz.quizemailformcode &&
          this.state.quiz.emailformtype == "custom"
        ) {
          NotificationManager.error(
            "Validation Error",
            "Enter code for lead generation form."
          );
          return false;
        } else if (
          !this.state.quiz.landingformurl &&
          this.state.quiz.landingformtype == "custom"
        ) {
          NotificationManager.error(
            "Validation Error",
            "Enter url for custom landing page."
          );
          return false;
        }
      }

      if (
        this.state.quiz.quiztype == "qztype2" ||
        this.state.quiz.quiztype == "qztype7" || 
        this.state.quiz.quiztype == "qztype8"
      ) {
        // if empty Please enter url for landing page for personalities
        if (this.state.quiz.landingformtype == "custom") {
          let checkPersonalityFormUrl = false;
          this.state.quiz.personalityArr.map(function (item) {
            if (!item.landingformurl || item.landingformurl == undefined) {
              checkPersonalityFormUrl = true;
            }
          });
          if (checkPersonalityFormUrl) {
            NotificationManager.error(
              "Validation Error",
              "Enter url for custom landing page."
            );
            return false;
          }
        }

        // if Empty Please enter html code for email form for personalities
        if (this.state.quiz.emailformtype == "custom") {
          let checkPersonalityEmailformcode = false;
          this.state.quiz.personalityArr.map(function (item) {
            if (
              !item.quizemailformcode ||
              item.quizemailformcode == undefined
            ) {
              checkPersonalityEmailformcode = true;
            }
          });
          if (checkPersonalityEmailformcode) {
            NotificationManager.error(
              "Validation Error",
              "Please enter html code for email form for personalities."
            );
            return false;
          }
        }
      }
    }
    if (this.state.quiz.quiztype == "qztype2" ||this.state.quiz.quiztype == "qztype7") {
      let checkPersonalityTitleValidation = false;
      let checkPersonalityDescriptionValidation = false;

      // check if quiz desc not added
      if (this.state.quiz.quizwelcomemsg == "") {
        NotificationManager.error("Validation Error","Welcome Message is required.");
        return false;
      }
      // check if personality not added on blank template
      if (
        this.state.quiz.personalityArr == "undefined" ||
        this.state.quiz.personalityArr == "" ||
        this.state.quiz.personalityArr == null
      ) {
        NotificationManager.error(
          "Validation Error",
          "Personalities is required."
        );
        return false;
      }
      this.state.quiz.personalityArr.map(function (item) {
        if (item.title == "") {
          checkPersonalityTitleValidation = true;
        }
        if (item.description == "") {
          checkPersonalityDescriptionValidation = true;
        }
      });
      if (!this.state.quiz.personalityArr) {
        //swal("Personality", "You have to add min two personality", "info");
        NotificationManager.error(
          "Validation Error",
          "Please add atleast two personalities."
        );
        return false;
      } else if (checkPersonalityTitleValidation) {
        NotificationManager.error(
          "Validation Error",
          "Personality title is required."
        );
        return false;
      } else if (checkPersonalityDescriptionValidation) {
        NotificationManager.error(
          "Validation Error",
          "Personality description is required."
        );
        return false;
      }
    }
    if (this.state.quiz.quiztype == "qztype8" || this.state.quiz.quiztype == "qztype9") {
      let checkPersonalityTitleValidation = false;
      let checkPersonalityDescriptionValidation = false;
      let checkQueCategoryTitleValidation = false;
      let checkQueCategoryDescriptionValidation = false;

      // check if quiz desc not added
      if (this.state.quiz.quizwelcomemsg == "") {
        NotificationManager.error("Validation Error","Welcome Message is required.");
        return false;
      }
      // check if personality not added on blank template
      if (this.state.quiz.personalityArr == "undefined" || this.state.quiz.personalityArr == "" || this.state.quiz.personalityArr == null) {
        NotificationManager.error("Validation Error","Personalities is required.");
        return false;
      }
      if (this.state.quiz.quecategory == "undefined" || this.state.quiz.quecategory == "" || this.state.quiz.quecategory == null) {
        NotificationManager.error("Validation Error","Question category is required.");
        return false;
      }

      this.state.quiz.personalityArr.map(function (item) {
        if (item.title == "") {
          checkPersonalityTitleValidation = true;
        }
        if (item.description == "") {
          checkPersonalityDescriptionValidation = true;
        }
      });

      this.state.quiz.quecategory.map(function (item) {
        if (item.title == "") {
          checkQueCategoryTitleValidation = true;
        }
        if (item.description == "") {
          checkQueCategoryDescriptionValidation = true;
        }
      });


      if (!this.state.quiz.personalityArr) {
        NotificationManager.error("Validation Error", "Please add atleast two personalities.");
        return false;
      } else if (checkPersonalityTitleValidation) {
        NotificationManager.error("Validation Error","Personality title is required.");
        return false;
      } else if (checkPersonalityDescriptionValidation) {
        NotificationManager.error("Validation Error","Personality description is required.");
        return false;
      }

      if (!this.state.quiz.quecategory) {
        NotificationManager.error("Validation Error", "Please add atleast two question category.");
        return false;
      } else if (checkQueCategoryTitleValidation) {
        NotificationManager.error("Validation Error","Question title is required.");
        return false;
      } else if (checkQueCategoryDescriptionValidation) {
        NotificationManager.error("Validation Error","Question description is required.");
        return false;
      }
    }

    var index = parseInt(this.state.tabnum) + 1;
    // if (index <= 1) {
    //   this.setState({
    //     customThemeHideForTransparentTheme: false,
    //   });
    // }
    var tempqueArr = this.state.quetable;

    if (index == 1) {
      var tempArr = [];
      if (
        this.state.quiz == null ||
        this.state.quiz.que == null ||
        this.state.quiz.que.length == 0
      ) {
        tempArr[0] = [0, "There are no questions in this quiz!!", null, null];
      } else {
        //this.state.quiz.que.map(function(item, index) {
        Object.values(this.state.quiz.que).map(function (item, index) {
          var jsonansobj = JSON.parse(item.jsonans);
          tempArr[index] = [index, item.title, jsonansobj.length, item.action];
        }, this);
      }

      tempqueArr.rowdata = tempArr;
    }

    var progressVal = this.state.progress + (1 / this.state.tabs.length) * 100;

    this.setState(
      {
        quetable: tempqueArr,
        tabnum: index,
        progress: progressVal,
        animationType: "slideInRight",
      },
      this.modifyQuiz
    );
  }

  prevtab() {
    var index = this.state.tabnum - 1;
    // if (index == 0) {
    //   this.setState({
    //     customThemeHideForTransparentTheme: true,
    //   });
    // }
    var progressVal = this.state.progress - (1 / this.state.tabs.length) * 100;
    this.setState({
      tabnum: index,
      progress: progressVal,
      animationType: "slideInLeft",
    });
  }

  rendertabs() {
    return this.state.tabs.map(function (tabItem, counter) {
      return (
        <div
          key={counter}
          className={`ba-wizard-navigation ${
            this.state.tabnum == "" + counter ? "active" : ""
          }`}
          data-index={counter}
        >
          <div
            className={
              tabItem.title == "Landing Page" ? "col-md-9" : "col-md-11"
            }
            dataid={tabItem.id}
            onClick={this.onTabClick}
          >
            {tabItem.title}
          </div>
          <div className="col-md-2 col-xs-12">
            {tabItem.title == "Landing Page" ? (
              <button className="btn btn-sm" onClick={this.finish}>
                Publish
              </button>
            ) : (
              <button
                className="btn btn-sm"
                style={{ visibility: "hidden" }}
              ></button>
            )}
          </div>
        </div>
      );
    }, this);
  }
  //show answers in preview when hover on rows in question table
  showAns() {
    var content = "";
    var que = this.state.selectedQuePreview;

    var tempquetype = que == null ? "MCSA" : que.quetype;
    var jsonans = null;
    var jsonansimg = null;

    if (que != null) {
      jsonans = que.jsonans;
      jsonansimg = que.jsonansimg;
    }
    var ans = null;
    var anscount = 0;
    if (jsonans != null) {
      ans = JSON.parse(jsonans);
      anscount = ans.length;
    }

    var img = null;
    if (jsonansimg != null) {
      img = JSON.parse(jsonansimg);
    }

    if (tempquetype == null || tempquetype == "" || tempquetype == "MCSA") {
      content = (
        <div>
          <div className="col-md-8 col-md-offset-2 m-t-sm">
            <div id="divans1" className="">
              <button
                style={{ width: 100 + "%", whiteSpace: "normal" }}
                className="btn qzwcmsg"
              >
                {ans == null
                  ? "Sample Ans Title"
                  : ans[0] == null || ans[0] == ""
                  ? "Sample Ans Title"
                  : ans[0]}
                <img
                  className={
                    img == null || img[0] == null || img[0] == "" ? "hide" : ""
                  }
                  src={img == null ? "" : img[0]}
                  style={{ height: 48, width: 48 }}
                />
              </button>
            </div>
          </div>
          <div className="col-md-8 col-md-offset-2 m-t-sm">
            <div id="divans2" className="">
              <button
                style={{ width: 100 + "%", whiteSpace: "normal" }}
                className="btn qzwcmsg"
              >
                {ans == null
                  ? "Sample Ans Title"
                  : ans[1] == null || ans[1] == ""
                  ? "Sample Ans Title"
                  : ans[1]}
                <img
                  className={
                    img == null || img[1] == null || img[1] == "" ? "hide" : ""
                  }
                  src={img == null ? "" : img[1]}
                  style={{ height: 48, width: 48 }}
                />
              </button>
            </div>
          </div>
          <div
            className={`col-md-8 col-md-offset-2 m-t-sm ${
              anscount > this.state.minanscount ? "" : "hide"
            }`}
          >
            <div id="divans3" className="">
              <button
                style={{ width: 100 + "%", whiteSpace: "normal" }}
                className="btn qzwcmsg"
              >
                {ans == null
                  ? "Sample Ans Title"
                  : ans[2] == null || ans[2] == ""
                  ? "Sample Ans Title"
                  : ans[2]}
                <img
                  className={
                    img == null || img[2] == null || img[2] == "" ? "hide" : ""
                  }
                  src={img == null ? "" : img[2]}
                  style={{ height: 48, width: 48 }}
                />
              </button>
            </div>
          </div>
          <div
            className={`col-md-8 col-md-offset-2 m-t-sm ${
              anscount > this.state.minanscount + 1 ? "" : "hide"
            }`}
            style={{ marginTop: 10 }}
          >
            <div id="divans4" className="">
              <button
                style={{ width: 100 + "%", whiteSpace: "normal" }}
                className="btn qzwcmsg"
              >
                {ans == null
                  ? "Sample Ans Title"
                  : ans[3] == null || ans[3] == ""
                  ? "Sample Ans Title"
                  : ans[3]}
                <img
                  className={
                    img == null || img[3] == null || img[3] == "" ? "hide" : ""
                  }
                  src={img == null ? "" : img[3]}
                  style={{ height: 48, width: 48 }}
                />
              </button>
            </div>
          </div>
        </div>
      );
    } else if (tempquetype == "TF") {
      content = (
        <div>
          <div className="col-md-8 col-md-offset-2 m-t-sm">
            <div id="divans1" className="">
              <button
                style={{ width: 100 + "%", whiteSpace: "normal" }}
                className="btn qzwcmsg"
              >
                {ans == null
                  ? "Sample Ans Title"
                  : ans[0] == null || ans[0] == ""
                  ? "Sample Ans Title"
                  : ans[0]}
                <img
                  className={
                    img == null || img[0] == null || img[0] == "" ? "hide" : ""
                  }
                  src={img == null ? "" : img[0]}
                  style={{ height: 48, width: 48 }}
                />
              </button>
            </div>
          </div>
          <div className="col-md-8 col-md-offset-2 m-t-sm">
            <div id="divans2" className="">
              <button
                style={{ width: 100 + "%", whiteSpace: "normal" }}
                className="btn qzwcmsg"
              >
                {ans == null
                  ? "Sample Ans Title"
                  : ans[1] == null || ans[1] == ""
                  ? "Sample Ans Title"
                  : ans[1]}
                <img
                  className={
                    img == null || img[1] == null || img[1] == "" ? "hide" : ""
                  }
                  src={img == null ? "" : img[1]}
                  style={{ height: 48, width: 48 }}
                />
              </button>
            </div>
          </div>
        </div>
      );
    }

    return content;
  }

  handleQuePreview(event) {
    if (this.state.quiz != null) {
      var queid = event.target.parentElement.getAttribute("data-id");
      var queArr = this.state.quiz["que"];
      var que = Object.values(queArr);
      if (que != null && que.length != 0) {
        //&& queArr.length < queid)
        //var selectedQue = queArr[queid - 1];
        var selectedQue = que.filter((element) => element.queid == queid);
        if (selectedQue != null && selectedQue.length > 0) {
          this.setState({ selectedQue: selectedQue[0] });
        }
      }
    }
  }

  quizPlay(event) {
    var quizData = this.state.quiz;
    var quizidp = JSON.stringify(quizData);
    var quizurl =
      "https://app.quizzes4leads.com/quiz_preview?qObject=" + quizidp;

    window.open(quizurl);
  }
  changeColorHeaderBg(header_bg) {
    this.setState({
      header_bg: header_bg,
    });
  }
  changeColorHeaderText(header_text) {
    this.setState({
      header_text: header_text,
    });
  }
  changeColorHeaderContentBg(header_content_bg) {
    this.setState({
      header_content_bg: header_content_bg,
    });
  }
  changeColorHeaderContentText(header_content_text) {
    this.setState({
      header_content_text: header_content_text,
    });
  }
  changeColorButton(button_color) {
    this.setState({
      button_color: button_color,
    });
  }
  changeColorButtonText(button_color_text) {
    this.setState({
      button_color_text: button_color_text,
    });
  }

  changeColorFooterBg(footer_bg) {
    this.setState({
      footer_bg: footer_bg,
    });
  }
  changeColorFooterText(footer_text_color) {
    this.setState({
      footer_text_color: footer_text_color,
    });
  }

  render() {
    let theme_colors = this.state.quiz.theme_color
      ? JSON.parse(this.state.quiz.theme_color)
      : "";
    return (
      <div className="">
        <div className="qzpanel-body">
          <div className="col-md-6">
            <div
              className="col-md-12"
              style={{ backgroundColor: "white", padding: "15px" }}
            >
              <div
                className="ba-wizard-navigation-container"
                style={{
                  position: "absolute",
                  paddingTop: 3,
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                {this.rendertabs()}
              </div>
              <div
                className="progress ba-wizard-progress"
                style={{ marginTop: 25 }}
              >
                <div
                  className="progress-bar progress-bar-quizzer active"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: this.state.progress + "%" }}
                ></div>
              </div>
              <div className="steps">
                <section
                  className={`step animated aaa ${this.state.animationType} ${
                    this.state.tabnum != "0" ? "hide" : ""
                  }`}
                >
                  <QuizSetting
                    selectedQue={this.state.selectedQue}
                    quiz={this.state.quiz}
                    user={this.state.user}
                    updateQuiz={this.updateQuiz}
                    basepath={this.props.basepath}
                  />
                </section>
                <section
                  className={`step animated ${this.state.animationType} ${
                    this.state.tabnum != "1" ? "hide" : ""
                  }`}
                >
                  <QueSetting
                    quiz={this.state.quiz}
                    user={this.state.user}
                    updateQuiz={this.updateQuiz}
                    updateQue={this.updateQue}
                    updateSelectedQue={this.updateSelectedQue}
                    updateAns={this.updateAns}
                    queformActive={this.state.queformActive}
                    selectedQue={this.state.selectedQue}
                    quetable={this.state.quetable}
                    activateQueForm={this.activateQueForm}
                    closeQueForm={this.closeQueForm}
                    handleQuePreview={this.handleQuePreview}
                    basepath={this.props.basepath}
                    saveQuiz={this.saveQuiz}
                  />
                </section>
                <section
                  className={`step animated ${this.state.animationType} ${
                    this.state.tabnum != "2" ? "hide" : ""
                  }`}
                >
                  <LeadGenerationSetting
                    quiz={this.state.quiz}
                    user={this.state.user}
                    updateQuiz={this.updateQuiz}
                    basepath={this.props.basepath}
                  />
                </section>
                <section
                  className={`step animated ${this.state.animationType} ${
                    this.state.tabnum != "3" ? "hide" : ""
                  }`}
                  >
                    <LandingPageSetting
                    quiz={this.state.quiz}
                    user={this.state.user}
                    updateQuiz={this.updateQuiz}
                    basepath={this.props.basepath}
                  />
                </section>
                
                <section
                  className={`step animated ${this.state.animationType} ${
                    this.state.tabnum != "4" ? "hide" : ""
                  }`}
                >
                  <SeoSettingTab
                    quiz={this.state.quiz}
                    sendSeoSeetingData={this.getSeoSeetingInfo}
                  />
                </section>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <nav>
                  <ul className="pager ba-wizard-pager">
                    <li className="previous">
                      <button
                        className={`btn-normal ${
                          this.state.queformActive || this.isfirsttab()
                            ? "hide"
                            : ""
                        }`}
                        type="button"
                        onClick={this.prevtab}
                      >
                        <span aria-hidden="true">←</span> Previous
                      </button>
                    </li>
                    {this.islasttab() ? (
                      <li className="next">
                        <button
                          className="btn-normal"
                          type="button"
                          onClick={this.finish}
                        >
                          Finish
                        </button>
                      </li>
                    ) : (this.islasttab() || this.state.queformActive) ==
                      false ? (
                      <li className="next">
                        <button
                          className="btn-normal"
                          type="button"
                          onClick={this.nexttab}
                        >
                          Next
                          <span aria-hidden="true">→</span>
                        </button>
                      </li>
                    ) : null}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          {/* {this.state.themeType} */}

          <div className={`col-md-6 scroll-sticky ${this.state.scrollAddClass ? 'sticky' : ''}`} style={{ backgroundColor: "white" }}>
            <div className="row">
              <div className="ba-wizard-navigation-container">
                <div className="ba-wizard-navigation active">Quiz Preview</div>
              </div>
            </div>
            {this.state.tabnum == "0" ? (
              <div
                className="col-md-12 color-picker-wrapper"
                style={{
                  display:
                    this.state.customThemeHideForTransparentTheme == false
                      ? "none"
                      : "",
                }}
              >
                <div className="col-item-block">
                  <div className="col-item-sub">
                    <div className="header-block"> Header</div>
                    <div className="background-text-block">
                      <div className="col-item">
                        {!this.state.quiz.enableCustomTheme ? (
                          <div className="content-padding"> Background</div>
                        ) : (
                          ""
                        )}
                        <ColorPicker
                          picker_label="Background"
                          enableCustomTheme={this.state.quiz.enableCustomTheme}
                          scolor={
                            theme_colors.header_bg
                              ? theme_colors[0].header_bg
                              : this.state.header_bg.hex
                          }
                          onSelectLanguage={this.changeColorHeaderBg}
                        />
                        <i
                          style={{ left: "-10%" }}
                          data-tip="Select custom theme to pick your own colors."
                          className="fa fa-info-circle"
                        ></i>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-item-block">
                  <div className="col-item-sub">
                    <div className="header-block">Content </div>
                    <div className="background-text-block">
                      <div className="col-item">
                        {!this.state.quiz.enableCustomTheme ? (
                          <div className="content-padding"> Background</div>
                        ) : (
                          ""
                        )}
                        <ColorPicker
                          picker_label="Background"
                          enableCustomTheme={this.state.quiz.enableCustomTheme}
                          scolor={
                            theme_colors.header_content_bg
                              ? theme_colors[2].header_content_bg
                              : this.state.header_content_bg.hex
                          }
                          onSelectLanguage={this.changeColorHeaderContentBg}
                        />
                        <i
                          style={{ left: "-10%" }}
                          data-tip="Select custom theme to pick your own colors."
                          className="fa fa-info-circle"
                        ></i>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-item-block">
                  <div className="col-item-sub">
                    <div className="header-block">Button </div>
                    <div className="background-text-block">
                      <div className="col-item">
                        {!this.state.quiz.enableCustomTheme ? (
                          <div className="content-padding">Background</div>
                        ) : (
                          ""
                        )}
                        <ColorPicker
                          picker_label="Background"
                          enableCustomTheme={this.state.quiz.enableCustomTheme}
                          scolor={
                            theme_colors.button_color
                              ? theme_colors[6].button_color
                              : this.state.button_color.hex
                          }
                          onSelectLanguage={this.changeColorButton}
                        />
                        <i
                          data-tip="Select custom theme to pick your own colors."
                          className="fa fa-info-circle"
                        ></i>
                        <br></br>
                      </div>
                      <div className="col-item">
                        {!this.state.quiz.enableCustomTheme ? (
                          <div className="content-padding"> Text</div>
                        ) : (
                          ""
                        )}
                        <ColorPicker
                          picker_label="Text"
                          enableCustomTheme={this.state.quiz.enableCustomTheme}
                          scolor={
                            theme_colors.button_color_text
                              ? theme_colors[7].button_color_text
                              : this.state.button_color_text.hex
                          }
                          onSelectLanguage={this.changeColorButtonText}
                        />
                        <i
                          data-tip="Select custom theme to pick your own colors."
                          className="fa fa-info-circle"
                        ></i>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-item-block">
                  <div className="col-item-sub">
                    <div className="header-block"> Footer </div>
                    <div className="background-text-block">
                      <div className="col-item">
                        {!this.state.quiz.enableCustomTheme ? (
                          <div className="content-padding">Background </div>
                        ) : (
                          ""
                        )}
                        <ColorPicker
                          picker_label="Background"
                          enableCustomTheme={this.state.quiz.enableCustomTheme}
                          scolor={
                            theme_colors.footer_bg
                              ? theme_colors[4].footer_bg
                              : this.state.footer_bg.hex
                          }
                          onSelectLanguage={this.changeColorFooterBg}
                        />
                        <i
                          style={{ left: "-10%" }}
                          data-tip="Select custom theme to pick your own colors."
                          className="fa fa-info-circle"
                        ></i>
                        <br></br>
                      </div>
                      {/* <div className="col-item">
                      {!this.state.quiz.enableCustomTheme ? (
                        <div className="content-padding"> Text</div>
                      ) : (
                        ""
                      )}
                      <ColorPicker
                        picker_label="Text"
                        enableCustomTheme={this.state.quiz.enableCustomTheme}
                        scolor={
                          theme_colors.footer_text_color
                            ? theme_colors[5].footer_text_color
                            : this.state.footer_text_color.hex
                        }
                        onSelectLanguage={this.changeColorFooterText}
                      />
                      <br></br>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div
              className="col-md-12"
              style={{
                backgroundImage: `url(${
                  this.state.quiz.backgroundimg == ""
                    ? this.state.themeType
                    : this.state.quiz.backgroundimg
                    ? this.state.quiz.backgroundimg
                    : this.state.quiz.quizbkgroundimage
                })`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                padding: "20px",
                paddingTop: "20",
              }}
            >
              <QuizPreview
                tabnum={this.state.tabnum}
                header_bg={this.state.header_bg}
                header_text={this.state.header_text}
                header_content_bg={this.state.header_content_bg}
                header_content_text={this.state.header_content_text}
                button_color={this.state.button_color}
                button_color_text={this.state.button_color_text}
                footer_bg={this.state.footer_bg}
                footer_text_color={this.state.footer_text_color}
                quiz={this.state.quiz}
                queformActive={this.state.queformActive}
                que={this.state.que}
                selectedQue={this.state.selectedQue}
                basepath={this.props.basepath}
                user={this.state.user}
              />
            </div>
          </div>
        </div>
        {this.state.showLoaderFlag ? (
          <div
            style={{
              width: 100 + "%",
              height: 100 + "%",
              background: "rgb(0,0,0,0.5)",
              position: "fixed",
            }}
          >
            <div className="loader"></div>
          </div>
        ) : (
          <div className="loader" style={{ display: "none" }}></div>
        )}
        {this.state.showLoaderFlag ? (
          <div className="loaderSaveQuiz">{this.state.loaderMessage}</div>
        ) : (
          <div className="loaderMessage" style={{ display: "none" }}></div>
        )}
      </div>
    );
  }
}

class QuizSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz: this.props.quiz,
      user: this.props.user,
      flagshowPersonalityForm: false,
      selectsize: 10,
      enableCustomTheme: true,
      makedisabled: true,
      btn_start_text: "Start",
      is_company_logo: false,
      selectedQue: this.props.selectedQue,
    };
    this.onChangeBackgroundImage = this.onChangeBackgroundImage.bind(this);
    this.onDeleteBackgroundImage = this.onDeleteBackgroundImage.bind(this);
    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.checkboxUpgradePlan = this.checkboxUpgradePlan.bind(this);
    this.showPersonalityForm = this.showPersonalityForm.bind(this);
    this.closePersonalityForm = this.closePersonalityForm.bind(this);
    this.upgradeMessage = this.upgradeMessage.bind(this);
    this.companyLogoUpload = this.companyLogoUpload.bind(this);
    this.companyLogoDelete = this.companyLogoDelete.bind(this);
    this.handleEditorValueChange = this.handleEditorValueChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ quiz: nextProps["quiz"], user: nextProps["user"] });
  }
  onFieldChanged(e) {
    if (e.target.name == "theme" && e.target.value === "custom_theme") {
      var tempquizobj = this.state.quiz;
      var name = e.target.name;
      tempquizobj[name] = e.target.value;
      tempquizobj["enableCustomTheme"] = true;
      this.setState({ quiz: tempquizobj }, () => {
        this.props.updateQuiz(tempquizobj);
      });
      return false;
    }
    if (e.target.name == "btn_start_text") {
      this.setState({
        btn_start_text: e.target.value,
      });
    }
    var tempquizobj = this.state.quiz;
    if (tempquizobj == null) {
      tempquizobj = {};
    }
    var name = e.target.name;
    tempquizobj[name] = e.target.value;
    // if (e.target.name == "theme" && e.target.value != "custom_theme") {
    //   tempquizobj["enableCustomTheme"] = false;
    // }
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
    // for company logo
    if (e.target.name == "is_company_logo") {
      tempquizobj["is_company_logo"] = e.target.checked;
      this.setState(
        { quiz: tempquizobj, is_company_logo: e.target.checked },
        () => {
          this.props.updateQuiz(tempquizobj);
        }
      );
    }
  }

  onCheckboxChange(e) {
    var tempquizobj = this.state.quiz;
    var name = e.target.name;
    tempquizobj[name] = e.target.checked;
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
  }
  showPersonalityForm() {
    this.setState({ flagshowPersonalityForm: true });
  }
  closePersonalityForm() {
    this.setState({ flagshowPersonalityForm: false });
  }

  onChangeBackgroundImage(imgtype, imgid, imgpath) {
    var tempquizobj = this.state.quiz;
    tempquizobj.backgroundimg = imgpath;
    this.setState({ quiz: tempquizobj, bgimg: imgpath }, () => {
      //swal("Image Upload", "Image uploaded successfully", "success");
      NotificationManager.success(
        "Image Upload",
        "Image uploaded successfully"
      );
      this.props.updateQuiz(tempquizobj);
    });
  }
  companyLogoUpload(imgtype, imgid, imgpath) {
    var tempquizobj = this.state.quiz;
    tempquizobj.companylogo = imgpath;
    this.setState({ quiz: tempquizobj, bgimg: imgpath }, () => {
      NotificationManager.success(
        "Image Upload",
        "Image uploaded successfully"
      );
      this.props.updateQuiz(tempquizobj);
    });
  }

  companyLogoDelete(imgtype, imgid, imgpath) {
    var tempquizobj = this.state.quiz;
    tempquizobj.companylogo = "";
    this.setState({ quiz: tempquizobj }, () => {
      //swal("Image Delete", "Image deleted successfully", "success");
      NotificationManager.success("Image Delete", "Image deleted successfully");
      this.props.updateQuiz(tempquizobj);
    });
  }

  onDeleteBackgroundImage(imgtype, imgid, imgpath) {
    var tempquizobj = this.state.quiz;
    // tempquizobj.backgroundimg = imgpath;
    tempquizobj.backgroundimg = defaultBackgroundImage;
    this.setState({ quiz: tempquizobj }, () => {
      //swal("Image Delete", "Image deleted successfully", "success");
      NotificationManager.success("Image Delete", "Image deleted successfully");
      this.props.updateQuiz(tempquizobj);
    });
  }

  upgradeMessage() {
    if (this.state.user.mode == "free") {
      this.setState({
        messageUpgrade: "Upgrade your plan to use this feature",
        quizfootermsg: "Powered By Quizzes4Leads",
        makedisabled: true,
      });
    } else {
      this.setState({
        messageUpgrade: null,
        quizfootermsg: "",
        makedisabled: false,
      });
    }
  }
  checkboxUpgradePlan() {
    if (this.state.user.mode == "free") {
      this.setState({
        messageUpgradechecbox: "Upgrade your plan to use this feature",
        makedisabledCheckbox: true,
      });
    }
  }

  renderthemes() {
    if (this.state.quiz.theme != "" || this.state.quiz.theme != "undefined") {
      var self = this;
      return themeArr.map(function (theme, counter) {
        return (
          <option
            defaultValue={theme.value}
            key={counter}
            value={theme != null ? theme.value : null}
            selected={self.state.quiz.theme == theme.value}
          >
            {theme.name}
          </option>
        );
      }, this);
    }
  }
  renderQuizType() {
    var self = this;
    return quizTypeArr.map(function (quiztype, counter) {
      return (
        <option
          key={counter}
          value={quiztype.value}
          selected={
            self.state.quiz != null &&
            self.state.quiz.quiztype == quiztype.value
              ? true
              : false
          }
        >
          {quiztype.name}
        </option>
      );
    }, this);
  }
  handleEditorValueChange(value, fieldname, color) {
    var tempquizobj = this.state.quiz;
    var name = fieldname;
    tempquizobj[name] = value;
    // if has color than only update
    if (color) {
      tempquizobj["welcome_message_color"] = color;
    }
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
  }
  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <div className="col-md-12">
          <div name="frmqzsettings" noValidate="">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group has-feedback">
                  <div className="Sign-In-Label">
                    Quiz Title{" "}
                    <i
                      data-tip="Add an attractive title for your quiz."
                      className="fa fa-info-circle"
                    ></i>
                  </div>
                  <CustomTextEditor
                    fieldname={"title"}
                    value={
                      this.state.quiz != null ? this.state.quiz.title : null
                    }
                    placeholder={`${
                      (this.state.quiz != null &&
                        this.state.quiz.quiztype == "qztype2") ||
                      this.state.quiz.quiztype == "qztype7"
                        ? "Eg : What Personality type are you?"
                        : "Eg : How good are you in grade 5 mathematics?"
                    }`}
                    onEditorValueChange={this.handleEditorValueChange}
                  />
                  <span className="help-block error-block basic-block">
                    Required
                  </span>
                </div>
                <ReactTooltip />
                <div className="checkbox">
                  <div className="Sign-In-Label">
                    <div
                      className="col-md-12"
                      style={{ marginLeft: 5, height: 20 }}
                    >
                      <input
                        type="checkbox"
                        onChange={this.onCheckboxChange}
                        name="is_enable_quiz_title"
                        checked={
                          this.state.quiz == null ||
                          this.state.quiz.is_enable_quiz_title == "1"
                            ? true
                            : false
                        }
                      />
                      <div>
                        <span>Enable quiz title on question ?</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="Sign-In-Label">
                    Welcome Message{" "}
                    <i
                      data-tip="Expand on your title to share what is your quiz about?"
                      className="fa fa-info-circle"
                    ></i>
                  </div>
                  <CustomTextEditor
                    fieldname={"quizwelcomemsg"}
                    value={
                      this.state.quiz != null
                        ? this.state.quiz.quizwelcomemsg
                        : ""
                    }
                    placeholder={`${
                      (this.state.quiz != null &&
                        this.state.quiz.quiztype == "qztype2") ||
                      this.state.quiz.quiztype == "qztype7"
                        ? "Eg: Always wondered what type of person you were and where you fit? "
                        : "Eg: Test your mathematic skills and compete with grade 5."
                    }`}
                    onEditorValueChange={this.handleEditorValueChange}
                  />
                  <span className="help-block error-block basic-block">
                    Proper email required
                  </span>
                </div>
                {this.state.user != null && this.state.user.mode == "free" ? (
                  <div className="form-group">
                    <div
                      className="Sign-In-Label"
                      disabled={this.state.user.mode == "free" ? true : false}
                    >
                      Quiz Footer
                    </div>
                    <input
                      name="quizfootermsg"
                      className="Sign-In-TextBox"
                      id="qzfootermsg"
                      required
                      type="text"
                      placeholder="Footer Message"
                      onChange={this.onFieldChanged}
                      onClick={this.upgradeMessage}
                      value={
                        this.state.quiz != null &&
                        this.state.quiz.quizfootermsg != null &&
                        this.state.quiz.quizfootermsg != ""
                          ? this.state.quiz.quizfootermsg
                          : "Powered By Quizzes4Leads"
                      }
                      disabled={this.state.makedisabled}
                    />
                    <p className="upgrade_error">{this.state.messageUpgrade}</p>
                    <span className="help-block error-block basic-block">
                      Required
                    </span>
                  </div>
                ) : null}
                <div className="form-group" onClick={this.checkboxUpgradePlan}>
                  {/* <div className="checkbox">
                    <div className="Sign-In-Label" style={{ marginTop: "0" }}>
                      <div
                        className="col-md-12"
                        style={{ marginLeft: 5, height: 20 }}
                      >
                        <input
                          type="checkbox"
                          onChange={this.onCheckboxChange}
                          name="randomque"
                          // disabled={this.state.user.mode == "free" ? true : false}
                          disabled={this.state.makedisabledCheckbox}
                          title={
                            this.state.user.mode == "free"
                              ? "This is a paid feature"
                              : "This will present questions in random order to player"
                          }
                          checked={
                            this.state.quiz == null ||
                              this.state.quiz.randomque == "1"
                              ? true
                              : false
                          }
                        />
                        <div>
                          <span>Randomize Questions</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="checkbox">
                    <div className="Sign-In-Label">
                      <div
                        className="col-md-12"
                        style={{ marginLeft: 5, height: 20 }}
                      >
                        <input
                          type="checkbox"
                          onChange={this.onCheckboxChange}
                          name="randomans"
                          disabled={this.state.makedisabledCheckbox}
                          title={
                            this.state.user.mode == "free"
                              ? "This is a paid feature"
                              : "This will present answers in random order to player"
                          }
                          checked={
                            this.state.quiz == null ||
                            this.state.quiz.randomans == "1"
                              ? true
                              : false
                          }
                        />
                        <div>
                          <span>Randomize Answers</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="checkbox">
                    <div className="Sign-In-Label">
                      <div
                        className="col-md-12"
                        style={{ marginLeft: 5, height: 20 }}
                      >
                        <input
                          type="checkbox"
                          onChange={this.onCheckboxChange}
                          name="negativeMarking"
                          // disabled={this.state.user.mode == "free" ? true : false}
                          disabled={this.state.makedisabledCheckbox}
                          title={
                            this.state.user.mode == "free"
                              ? "This is a paid feature"
                              : "This will present answers in random order to player"
                          }
                          checked={
                            this.state.quiz == null ||
                              this.state.quiz.negativeMarking == "1"
                              ? true
                              : false
                          }
                        />
                        <div>
                          <span>Negative Marking</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <p className="upgrade_error">
                    {this.state.messageUpgradechecbox}
                  </p>
                </div>
                {/* <div className="form-group">
                  <div className="Sign-In-Label theme_block">
                    {" "}
                    Select Quiz Theme
                  </div>
                  <select
                    tabIndex="-98"
                    title="Category"
                    style={{ marginTop: 8 }}
                    className="form-control Sign-In-TextBox"
                    selectpicker=""
                    onChange={this.onFieldChanged}
                    name="theme"
                  >
                    {this.renderthemes()}
                  </select>
                </div> */}
                <ImageUpload
                  basepath={this.props.basepath}
                  userid={this.props.user.userid}
                  quizid={
                    this.state.quiz != null ? this.state.quiz.quizid : null
                  }
                  imgtype="quizbkgroundimage"
                  title="Upload Quiz Background Image"
                  uploaded_uri=""
                  imagePreviewFlag={true}
                  info_text="Preferred image size 1920x1075"
                  imagepath={
                    this.state.quiz.backgroundimg != null
                      ? this.state.quiz.backgroundimg
                      : this.state.quiz.quizbkgroundimage
                  }
                  onImageUploadComplete={this.onChangeBackgroundImage}
                  onImageDeleteComplete={this.onDeleteBackgroundImage}
                />
                <div class="form-group">
                  <div class="Sign-In-Label" disabled="">
                    Button Text
                  </div>
                  <input
                    maxLength="15"
                    name="btn_start_text"
                    onChange={this.onFieldChanged}
                    class="Sign-In-TextBox"
                    type="text"
                    value={
                      this.state.quiz.btn_start_text
                        ? this.state.quiz.btn_start_text
                        : this.state.btn_start_text
                    }
                  />
                  <div className="counter-wrapper">
                    <span>
                      {this.state.quiz.btn_start_text != null
                        ? this.state.quiz.btn_start_text.length
                        : "0"}
                      /15
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <div class="Sign-In-Label" disabled="">
                    Company Logo{" "}
                    <input
                      onChange={this.onFieldChanged}
                      name="is_company_logo"
                      checked={
                        this.state.is_company_logo
                          ? this.state.is_company_logo
                          : this.state.quiz.is_company_logo
                          ? true
                          : false
                      }
                      type="checkbox"
                    />
                  </div>
                  {/* Show image upload option only when enable company logo */}
                  {this.state.is_company_logo ||
                  this.state.quiz.is_company_logo ? (
                    <div className="company-log-quiz-edit">
                      <img src={this.state.quiz.companylogo} />
                      <i
                        data-tip={
                          "To change company logo, go to account</a> tab."
                        }
                        className="fa fa-info-circle"
                      ></i>
                      <ReactTooltip html={true} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/*this.state.quiz != null && this.state.quiz.quiztype == "qztype2" ? (
                  <button
                    className="btn btn-default cpq"
                    onClick={this.showPersonalityForm}
                  >
                    {" "}
                    Create Personality
                  </button>
                ) : null*/}
                {this.state.quiz != null &&
                (this.state.quiz.quiztype == "qztype2" ||
                  this.state.quiz.quiztype == "qztype7" )?(
                  //|| this.state.quiz.quiztype == "qztype8"  || this.state.quiz.quiztype == 'qztype9') ? (
                  <PersonalityForm
                    quiz={this.state.quiz}
                    showForm={this.state.flagshowPersonalityForm}
                    closeForm={this.closePersonalityForm}
                    updateQuiz={this.props.updateQuiz}
                    basepath={this.props.basepath}
                  />
                ) : null}
                {this.state.quiz != null && (this.state.quiz.quiztype == "qztype8" || this.state.quiz.quiztype =='qztype9') ? (
                  <QueCategoryForm
                    quiz={this.state.quiz}
                    showForm={this.state.flagshowQueCategoryForm}
                    closeForm={this.closeQueCategoryForm}
                    updateQuiz={this.props.updateQuiz}
                    basepath={this.props.basepath}
                  />
                ) : null}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class LeadGenerationSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz: this.props.quiz,
      user: this.props.user,
      result_page_custom_text: "",
      is_enable_player_result: false,
      privacy_policy_text: [],
    };

    this.collectUserEmailChange = this.collectUserEmailChange.bind(this);
    this.isEnablePlayerResult = this.isEnablePlayerResult.bind(this);
    this.handleScoreType = this.handleScoreType.bind(this);
    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.onRadioChanged = this.onRadioChanged.bind(this);
    this.changePersonalityEmailFormCode =
      this.changePersonalityEmailFormCode.bind(this);
    this.changePersonalityLandingFormUrl =
      this.changePersonalityLandingFormUrl.bind(this);
    this.isEnableResultPageCustomText =
      this.isEnableResultPageCustomText.bind(this);
    this.isEnableSocialMedia = this.isEnableSocialMedia.bind(this);
    this.onChangePersonalityWithGrade =
      this.onChangePersonalityWithGrade.bind(this);
    this.onChangeScoreRange = this.onChangeScoreRange.bind(this);
    this.privacyPolicyText = this.privacyPolicyText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    var quiz = nextProps.quiz;
    var user = nextProps.user;
    if (quiz != null && (quiz.quiztype == "qztype5" || quiz.quiztype == "qztype6")) {
      var personalityArr = null;
      if (quiz.quizmetadata != null) {
        personalityArr = JSON.parse(quiz.quizmetadata);
      }
      if (personalityArr == null) {
        personalityArr = [];
        personalityArr.push({ mingrade: 0, maxgrade: 20 });
        personalityArr.push({ mingrade: 21, maxgrade: 40 });
        personalityArr.push({ mingrade: 41, maxgrade: 60 });
        personalityArr.push({ mingrade: 61, maxgrade: 80 });
        personalityArr.push({ mingrade: 81, maxgrade: 100 });
      }
      quiz.personalityArr = personalityArr;
    }

    this.setState({ quiz, user });
  }

  onChangePersonalityWithGrade(e) {
    var quiz = this.state.quiz;
    var personalityArr = quiz.personalityArr;

    var name = e.target.name;
    var index = e.target.getAttribute("data-id");

    personalityArr[index][name] = e.target.value;
    quiz.personalityArr = personalityArr;
    quiz.quizmetadata = JSON.stringify(personalityArr);
    this.setState({ quiz }, () => {
      this.props.updateQuiz(quiz);
    });
  }
  onChangeScoreRange(e) {
    var quiz = this.state.quiz;
    var personalityArr = quiz.personalityArr;

    var name = e.target.name;
    var index = e.target.getAttribute("data-id");

    personalityArr[index][name] = e.target.value;
    quiz.personalityArr = personalityArr;
    quiz.quizmetadata = JSON.stringify(personalityArr);
    this.setState({ quiz }, () => {
      this.props.updateQuiz(quiz);
    });
  }

  onFieldChanged(e) {
    var tempquizobj = this.state.quiz;
    if (tempquizobj == null) {
      tempquizobj = {};
    }
    var name = e.target.name;
    tempquizobj[name] = e.target.value;
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
  }

  onRadioChanged(e) {
    var tempquizobj = this.state.quiz;
    if (tempquizobj == null) {
      tempquizobj = {};
    }
    var name = e.target.name;
    tempquizobj[name] = e.target.value;
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
  }

  collectUserEmailChange(e) {
    var tempquizobj = this.state.quiz;
    if (tempquizobj == null) {
      tempquizobj = {};
    }
    tempquizobj.collectuseremailflag = e.target.checked ? "1" : "0";
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
  }

  isEnablePlayerResult(e) {
    var tempquizobj = this.state.quiz;
    if (tempquizobj == null) {
      tempquizobj = {};
    }
    tempquizobj.is_enable_player_result = e.target.checked ? 1 : 0;
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
  }

  handleScoreType(event) {
    var scoreType = event.target.getAttribute("data-value");

    var quiz = this.state.quiz;
    if (quiz == null) {
      quiz = {};
    }
    quiz.scoretype = scoreType;
    this.setState({ quiz: quiz });
  }

  changePersonalityEmailFormCode(event) {
    var index = event.target.getAttribute("data-id");
    var quiz = this.state.quiz;
    var personalityArr = quiz != null ? quiz.personalityArr : null;
    if (personalityArr && personalityArr[index]) {
      personalityArr[index].quizemailformcode = event.target.value;
      quiz.personalityArr = personalityArr;
      this.setState({ quiz }, () => {
        this.props.updateQuiz(quiz);
      });
    }
  }
  changePersonalityLandingFormUrl(event) {
    var index = event.target.getAttribute("data-id");
    var quiz = this.state.quiz;
    var personalityArr = quiz != null ? quiz.personalityArr : null;
    if (personalityArr && personalityArr[index]) {
      personalityArr[index].landingformurl = event.target.value;
      quiz.personalityArr = personalityArr;
      this.setState({ quiz }, () => {
        this.props.updateQuiz(quiz);
      });
    }
  }

  
  privacyPolicyText(e) {
    var quiz = this.state.quiz;
    quiz.privacy_policy_text = e.target.value;
    this.setState({ quiz }, () => {
      this.props.updateQuiz(quiz);
    });
  }

  isEnableResultPageCustomText(e) {
    var getQuiz = this.props.quiz;
    getQuiz.result_page_custom_text = e.target.checked
      ? "<h3>This certificate is proudly presented on completion of the quiz</h3><h2>" +
        this.state.quiz.title +
        "</h2><h2>To</h2>"
      : "";
    this.setState({
      quiz: getQuiz,
    });
  }
  isEnableSocialMedia(e) {
    var getQuiz = this.props.quiz;
    getQuiz.is_enable_social_media = e.target.checked ? 1 : 0;
    this.setState({
      quiz: getQuiz,
    });
  }

  renderPersonalities() {
    var self = this;
    var personalityArr = null;
    if (this.state.quiz != null && this.state.quiz.personalityArr) {
      personalityArr = this.state.quiz.personalityArr;
    }
    if (personalityArr != null && personalityArr.length > 0) {
      return personalityArr.map(function (personality, counter) {
        return (
          <option
            key={counter}
            value={personality.title}
            selected={
              self.state.personalityArr != null &&
              self.state.personalityArr[counter] != null &&
              self.state.quiz.personalityArr[counter].title == personality.title
                ? true
                : false
            }
          >
            {personality.title}
          </option>
        );
      }, this);
    } else {
      return null;
    }
  }

  //Email form setting for personality quiz
  renderPersonalityEmailFormSettings() {
    var personalities =
      this.state.quiz != null ? this.state.quiz.personalityArr : null;
    var content = null;
    if (personalities != null) {
      var temp = personalities.map((element, index) => {
        return (
          <div
            className="col-md-12 ans-wrapper-modify"
            key={index}
            style={{ paddingLeft: 0 }}
          >
            <div id={"divpersonality" + index} className="">
              <div
                style={{ color: "red", fontSize: 12 }}
                id={"anserror" + index + 1}
              ></div>
              <div className="">
                <div
                  className="col-md-3 col-sm-3 col-xs-12"
                  style={{ height: 50, marginTop: 0, paddingLeft: 0 }}
                >
                  <label>{element.title}</label>
                </div>
                <div className="col-md-9 col-sm-9 col-xs-12 ans-option-list">
                  <textarea
                    className="Sign-In-TextBox"
                    id={"ans" + index + 1}
                    onChange={this.changePersonalityEmailFormCode}
                    data-id={index}
                    value={element == null ? "" : element.quizemailformcode}
                    placeholder="Enter HTML code for email form"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });
      content = (
        <div>
          <h5 className="Sign-In-Label">
            Please enter html code for email form for personalities{" "}
          </h5>
          <br />
          {temp}
        </div>
      );
    } else{
      content = <h6>There are no personalities in this quiz. Please create one and then come back here</h6>
    }

    return content;
  }

  //Email form setting for graded quiz
  renderGradedEmailFormSetting() {
    return (
      <>
        <div className="Sign-In-Label">
          Enter HTML code for lead generation form
        </div>
        <textarea
          className="Sign-In-TextBox"
          onChange={this.onFieldChanged}
          name="quizemailformcode"
          value={
            this.state.quiz != null ? this.state.quiz.quizemailformcode : ""
          }
          style={{ marginTop: 5, width: 100 + "%", height: 150 }}
        />
        <span className="help-block error-block basic-block">Required</span>
      </>
    );
  }

  
  //Email form setting for multiple grades
  renderMultipleGradeEmailFormSetting() {
    var self = this;
    var personalityArr = null;
    var customlandingflag = self.state.quiz && self.state.quiz.emailformtype == "custom"? true: false;
      if (this.state.quiz != null && this.state.quiz.personalityArr) {
        personalityArr = this.state.quiz.personalityArr;
      }
      if (personalityArr != null && personalityArr.length > 0) {
        return personalityArr.map(function (personality, index) {
          return (
            <div className="row" style={{ marginBottom: 8 }}>
              <div className="col-md-3">
                {personality.mingrade} - {personality.maxgrade} %
              </div>
              {customlandingflag ? (
                <div className="col-md-9">
                  <textarea
                    className="Sign-In-TextBox"
                    type="text"
                    placeholder="Enter HTML code for lead generation form"
                    name="quizemailformcode"
                    data-id={index}
                    onChange={self.onChangePersonalityWithGrade}
                    value={personality.emailformcode || personality.quizemailformcode}
                  ></textarea>
                </div>
              ) : null}
            </div>
          );
        }, this);
      } else {
        return null;
      }
  
    //else{
    // return (
    //   <>
    //     <div className="Sign-In-Label">
    //       Enter HTML code for lead generation form
    //     </div>
    //     <textarea
    //       className="Sign-In-TextBox"
    //       onChange={this.onFieldChanged}
    //       name="quizemailformcode"
    //       value={
    //         this.state.quiz != null ? this.state.quiz.quizemailformcode : ""
    //       }
    //       style={{ marginTop: 5, width: 100 + "%", height: 150 }}
    //     />
    //     <span className="help-block error-block basic-block">Required</span>
    //   </>
    // );
  }

  //Landing form setting for personality quiz
  renderPersonalityLandingFormSettings() {
    var personalities =
      this.state.quiz != null ? this.state.quiz.personalityArr : null;
    var content = null;
    if (personalities != null) {
      var temp = personalities.map((element, index) => {
        return (
          <div
            className="col-md-12 ans-wrapper-modify"
            key={index}
            style={{ paddingLeft: 0 }}
          >
            <div id={"divpersonality" + index} className="">
              <div
                style={{ color: "red", fontSize: 12 }}
                id={"anserror" + index + 1}
              ></div>
              <div className="">
                <div
                  className="col-md-2 col-sm-2 col-xs-12"
                  style={{ height: 50, marginTop: 0 }}
                >
                  <label>{element.title}</label>
                </div>
                <div className="col-md-10 col-sm-10 col-xs-10 ans-option-list">
                  <input
                    type="url"
                    className="Sign-In-TextBox"
                    id={"ans" + index + 1}
                    onChange={this.changePersonalityLandingFormUrl}
                    data-id={index}
                    value={element == null ? "" : element.landingformurl}
                    placeholder="Enter url for landing page"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });
      content = (
        <div>
          <h5 className="Sign-In-Label">
            Please enter url for landing page for personalities{" "}
          </h5>
          <br />
          {temp}
        </div>
      );
    } else{
      content = <h6>There are no personalities in this quiz. Please create one and then come back here</h6>
    }

    return content;
  }

  //Landing form setting for graded quiz
  renderGradedLandingFormSetting() {
    return (
      <>
        <div className="Sign-In-Label">Enter url for custom landing page</div>
        <input
          className="Sign-In-TextBox"
          type="url"
          onChange={this.onFieldChanged}
          name="landingformurl"
          value={this.state.quiz != null ? this.state.quiz.landingformurl : ""}
          style={{ marginTop: 5, width: 100 + "%" }}
        />
        <span className="help-block error-block basic-block">Required</span>
      </>
    );
  }

  //Landing form setting for graded quiz with grades
  renderPersonalitiesWithGrades() {
    var self = this;
    var personalityArr = null;
    var customlandingflag =
      self.state.quiz && self.state.quiz.landingformtype == "custom"
        ? true
        : false;
    if (this.state.quiz != null && this.state.quiz.personalityArr) {
      personalityArr = this.state.quiz.personalityArr;
    }
    if (personalityArr != null && personalityArr.length > 0) {
      return personalityArr.map(function (personality, index) {
        return (
          <div className="row" style={{ marginBottom: 8 }}>
            <div className="col-md-3">
              {personality.mingrade} - {personality.maxgrade} %
            </div>
            <div className={customlandingflag ? "hide" : "col-md-4"}>
              <textarea
                className="Sign-In-TextBox"
                type="text"
                placeholder="Enter personality title"
                name="title"
                data-id={index}
                onChange={this.onChangePersonalityWithGrade}
                value={personality.title}
              ></textarea>
            </div>
            <div className={customlandingflag ? "hide" : "col-md-5"}>
              <textarea
                className="Sign-In-TextBox"
                type="text"
                placeholder="Enter personality description"
                name="description"
                data-id={index}
                onChange={this.onChangePersonalityWithGrade}
                value={personality.description}
              ></textarea>
            </div>
            {customlandingflag ? (
              <div className="col-md-9">
                <textarea
                  className="Sign-In-TextBox"
                  type="text"
                  placeholder="Enter url for landing page for score-range"
                  name="landingformurl"
                  data-id={index}
                  onChange={this.onChangePersonalityWithGrade}
                  value={personality.landingformurl}
                ></textarea>
              </div>
            ) : null}
          </div>
        );
      }, this);
    } else {
      return null;
    }
  }

  renderOutcomesWithScoreRange() {
    var self = this;
    var personalityArr = null;
    var customlandingflag =
      self.state.quiz && self.state.quiz.landingformtype == "custom"
        ? true
        : false;

    if (this.state.quiz != null && this.state.quiz.personalityArr) {
      personalityArr = this.state.quiz.personalityArr;
    }
    if (personalityArr != null && personalityArr.length > 0) {
      return personalityArr.map(function (personality, index) {
        return (
          <div className="row" style={{ marginBottom: 8 }}>
            <div className={`${customlandingflag?'col-md-2':'col-md-2'}`}>
              <input style={{padding:0, textAlign:"center"}}
                type="text"
                data-id={index}
                className="form-control custome_input"
                value={personality.mingrade}
                name="mingrade"
                onChange={this.onChangeScoreRange}
              />
            </div>
            <div className={`${customlandingflag ? "col-md-2" : "col-md-2"}`}>
              <input
                type="text"
                data-id={index}
                className="form-control custome_input"
                value={personality.maxgrade}
                name="maxgrade"
                onChange={this.onChangeScoreRange}
              />
            </div>
            <div className={`${customlandingflag?'hide':'col-md-4'}`}>
              <textarea
                className="Sign-In-TextBox"
                type="text"
                placeholder="Enter outcome title"
                name="title"
                data-id={index}
                onChange={this.onChangePersonalityWithGrade}
                value={personality.title}
              ></textarea>
            </div>
            <div className={`${customlandingflag?'hide':'col-md-4'}`}>
              <textarea
                className="Sign-In-TextBox"
                type="text"
                placeholder="Enter outcome description"
                name="description"
                data-id={index}
                onChange={this.onChangePersonalityWithGrade}
                value={personality.description}
              ></textarea>
            </div>
            {customlandingflag?<div className={`${customlandingflag?'col-md-8':'col-md-4'}`}>
                <textarea
                  className="Sign-In-TextBox"
                  type="text"
                  placeholder="Enter outcome url"
                  name="url"
                  data-id={index}
                  onChange={this.onChangePersonalityWithGrade}
                  value={personality.url}
                ></textarea>
              </div>
            : null}
          </div>
        );
      }, this);
    } else {
      return null;
    }
  }

  render() {
    return (
      <div>
        <div className="col-md-12">
          <div>
            <div className="checkbox checkboxemailflag">
              <div className="Sign-In-Label">
                <div
                  className="col-md-12"
                  style={{ marginLeft: 5, height: 20 }}
                >
                  <input
                    type="checkbox"
                    onChange={this.collectUserEmailChange}
                    name="collectuseremailflag"
                    checked={
                      this.state.quiz == null ||
                      this.state.quiz.collectuseremailflag == "1"
                        ? true
                        : false
                    }
                  />
                  <div>
                    <span style={{}}>
                      Do you want to collect user emails before showing the
                      result?{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {this.state.quiz && this.state.quiz.collectuseremailflag == "1"?<div>
              <div className="row">
                <div className="col-md-12 lead-landing-wrapper">
                  <h5 className="Sign-In-Label">Select lead generation form:</h5>
                  <input
                    type="radio"
                    onChange={this.onRadioChanged}
                    name="emailformtype"
                    value="standard"
                    checked={
                      this.state.quiz == null ||
                      this.state.quiz.emailformtype == null ||
                      this.state.quiz.emailformtype == "standard"
                        ? true
                        : false
                    }
                  />
                  <span style={{ marginLeft: 5 }}>Standard</span>
                  <input
                    type="radio"
                    onChange={this.onRadioChanged}
                    name="emailformtype"
                    value="custom"
                    style={{ marginLeft: 20 }}
                    checked={
                      this.state.quiz != null &&
                      this.state.quiz.emailformtype != null &&
                      this.state.quiz.emailformtype == "custom"
                        ? true
                        : false
                    }
                  />
                  <span style={{ marginLeft: 5 }}>Custom</span>
                  <p className="upgrade_error">
                    {this.state.messageUpgradechecbox}
                  </p>
                </div>
              </div>
              <div
                className={`${
                  this.state.quiz == null ||
                  this.state.quiz.collectuseremailflag == "0" ||
                  this.state.quiz.emailformtype == "custom"
                    ? "hide"
                    : ""
                }`}
              >
                <div className="Sign-In-Label">Email message</div>
                <input
                  type="text"
                  maxLength="50"
                  onChange={this.onFieldChanged}
                  name="quizemailmsg"
                  className="Sign-In-TextBox"
                  placeholder="Enter email message"
                  value={
                    this.state.quiz != null ? this.state.quiz.quizemailmsg : ""
                  }
                />
                <div className="counter-wrapper">
                  <span>
                    {this.state.quiz.quizemailmsg != null
                      ? this.state.quiz.quizemailmsg.length
                      : "0"}
                    /50
                  </span>
                </div>
                <span className="help-block error-block basic-block">
                  Required
                </span>
              </div>
              {this.state.quiz.emailformtype == "standard" ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group privacy-policy-wrapper">
                      <div className=" Sign-In-Label">Privacy policy</div>
                      <div>
                        <textarea
                          type="text"
                          onChange={(e) => this.privacyPolicyText(e)}
                          value={this.state.quiz.privacy_policy_text}
                          name="privacy_policy_text"
                          className="Sign-In-TextBox"
                          style={{ width: 99 + "%" }}
                          id="txtqzque"
                          maxLength="200"
                        >
                          {this.state.quiz.privacy_policy_text}
                        </textarea>
                        <div className="counter-wrapper">
                          <span>
                            {this.state.quiz.privacy_policy_text
                              ? this.state.quiz.privacy_policy_text.length
                              : "0"}
                            /200
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="Sign-In-Label">Lead form field settings</div>
                    <input
                      name="enter_your_name_text"
                      onChange={this.onFieldChanged}
                      className="Sign-In-TextBox"
                      type="text"
                      placeholder="Name"
                      maxLength="50"
                      value={
                        this.state.quiz != null
                          ? this.state.quiz.enter_your_name_text
                          : ""
                      }
                    />
                    <div className="counter-wrapper">
                      <span>
                        {this.state.quiz.enter_your_name_text != null
                          ? this.state.quiz.enter_your_name_text.length
                          : "0"}
                        /50
                      </span>
                    </div>

                    <input
                      name="enter_your_email_text"
                      onChange={this.onFieldChanged}
                      className="Sign-In-TextBox"
                      type="text"
                      maxLength="50"
                      placeholder="Email"
                      value={
                        this.state.quiz != null
                          ? this.state.quiz.enter_your_email_text
                          : ""
                      }
                    />
                    <div className="counter-wrapper">
                      <span>
                        {this.state.quiz.enter_your_email_text != null
                          ? this.state.quiz.enter_your_email_text.length
                          : "0"}
                        /50
                      </span>
                    </div>
                    <div className="Sign-In-Label">Button Text</div>
                    <input
                      name="continue_button_text"
                      onChange={this.onFieldChanged}
                      className="Sign-In-TextBox"
                      type="text"
                      placeholder="Check Your Score"
                      maxLength="20"
                      value={
                        this.state.quiz != null
                          ? this.state.quiz.continue_button_text
                          : ""
                      }
                    />
                    <div className="counter-wrapper">
                      <span>
                        {this.state.quiz.continue_button_text != null
                          ? this.state.quiz.continue_button_text.length
                          : "0"}
                        /20
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                id="lead-generate-form"
                className={`${
                  this.state.quiz != null &&
                  this.state.quiz.emailformtype == "custom"
                    ? ""
                    : "hide"
                }`}
              >
                {this.state.quiz != null &&
                (this.state.quiz.quiztype == "qztype2" ||
                  this.state.quiz.quiztype == "qztype7" || this.state.quiz.quiztype == "qztype8" || this.state.quiz.quiztype == "qztype9")
                  ? this.renderPersonalityEmailFormSettings()
                  : (this.state.quiz.quiztype == "qztype5"|| this.state.quiz.quiztype == "qztype6"?this.renderMultipleGradeEmailFormSetting():this.renderGradedEmailFormSetting())}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="checkbox">
                    <div className="Sign-In-Label">
                      <div className="col-md-12" style={{ marginLeft: 5, height: 20 }}>
                        <input
                          type="checkbox"
                          onChange={this.isEnablePlayerResult}
                          name="is_enable_player_result"
                          checked={
                            this.state.quiz == null ||
                            this.state.quiz.is_enable_player_result == 1
                              ? true
                              : false
                          }
                        />
                        <div style={{ marginTop: 2.5 }}>
                          <span>
                            Send results to respondents on their email ?
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>:null}  
          </div>
        </div>
      </div>
    );
  }
}

class LandingPageSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz: this.props.quiz,
      user: this.props.user,
      result_page_custom_text: "",
      is_enable_player_result: false,
      privacy_policy_text: [],
    };

    this.isEnablePlayerResult = this.isEnablePlayerResult.bind(this);
    this.handleScoreType = this.handleScoreType.bind(this);
    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.onRadioChanged = this.onRadioChanged.bind(this);
    this.changePersonalityEmailFormCode =
      this.changePersonalityEmailFormCode.bind(this);
    this.changePersonalityLandingFormUrl =
      this.changePersonalityLandingFormUrl.bind(this);
    this.isEnableResultPageCustomText =
      this.isEnableResultPageCustomText.bind(this);
    this.isEnableSocialMedia = this.isEnableSocialMedia.bind(this);
    this.onChangePersonalityWithGrade =
      this.onChangePersonalityWithGrade.bind(this);
    this.onChangeScoreRange = this.onChangeScoreRange.bind(this);
    
  }

  componentWillReceiveProps(nextProps) {
    var quiz = nextProps.quiz;
    var user = nextProps.user;
    if (quiz != null && (quiz.quiztype == "qztype5" || quiz.quiztype == "qztype6")) {
      var personalityArr = null;
      if (quiz.quizmetadata != null) {
        personalityArr = JSON.parse(quiz.quizmetadata);
      }
      if (personalityArr == null) {
        personalityArr = [];
        personalityArr.push({ mingrade: 0, maxgrade: 20 });
        personalityArr.push({ mingrade: 21, maxgrade: 40 });
        personalityArr.push({ mingrade: 41, maxgrade: 60 });
        personalityArr.push({ mingrade: 61, maxgrade: 80 });
        personalityArr.push({ mingrade: 81, maxgrade: 100 });
      }
      quiz.personalityArr = personalityArr;
    }

    this.setState({ quiz, user });
  }

  onChangePersonalityWithGrade(e) {
    var quiz = this.state.quiz;
    var personalityArr = quiz.personalityArr;

    var name = e.target.name;
    var index = e.target.getAttribute("data-id");

    personalityArr[index][name] = e.target.value;
    quiz.personalityArr = personalityArr;
    quiz.quizmetadata = JSON.stringify(personalityArr);
    this.setState({ quiz }, () => {
      this.props.updateQuiz(quiz);
    });
  }
  onChangeScoreRange(e) {
    var quiz = this.state.quiz;
    var personalityArr = quiz.personalityArr;

    var name = e.target.name;
    var index = e.target.getAttribute("data-id");

    personalityArr[index][name] = e.target.value;
    quiz.personalityArr = personalityArr;
    quiz.quizmetadata = JSON.stringify(personalityArr);
    this.setState({ quiz }, () => {
      this.props.updateQuiz(quiz);
    });
  }

  onFieldChanged(e) {
    var tempquizobj = this.state.quiz;
    if (tempquizobj == null) {
      tempquizobj = {};
    }
    var name = e.target.name;
    tempquizobj[name] = e.target.value;
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
  }

  onRadioChanged(e) {
    var tempquizobj = this.state.quiz;
    if (tempquizobj == null) {
      tempquizobj = {};
    }
    var name = e.target.name;
    tempquizobj[name] = e.target.value;
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
  }

  collectUserEmailChange(e) {
    var tempquizobj = this.state.quiz;
    if (tempquizobj == null) {
      tempquizobj = {};
    }
    tempquizobj.collectuseremailflag = e.target.checked ? "1" : "0";
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
  }

  isEnablePlayerResult(e) {
    var tempquizobj = this.state.quiz;
    if (tempquizobj == null) {
      tempquizobj = {};
    }
    tempquizobj.is_enable_player_result = e.target.checked ? 1 : 0;
    this.setState({ quiz: tempquizobj }, () => {
      this.props.updateQuiz(tempquizobj);
    });
  }

  handleScoreType(event) {
    var scoretype = event.target.getAttribute("data-value");
    var quiz = this.state.quiz;
    if (quiz == null) {
      quiz = {};
    }
    quiz.scoretype = scoretype;
    this.setState({ quiz }, () => {
      this.props.updateQuiz(quiz);
    });
  }

  changePersonalityEmailFormCode(event) {
    var index = event.target.getAttribute("data-id");
    var quiz = this.state.quiz;
    var personalityArr = quiz != null ? quiz.personalityArr : null;
    if (personalityArr && personalityArr[index]) {
      personalityArr[index].quizemailformcode = event.target.value;
      quiz.personalityArr = personalityArr;
      this.setState({ quiz }, () => {
        this.props.updateQuiz(quiz);
      });
    }
  }
  changePersonalityLandingFormUrl(event) {
    var index = event.target.getAttribute("data-id");
    var quiz = this.state.quiz;
    var personalityArr = quiz != null ? quiz.personalityArr : null;
    if (personalityArr && personalityArr[index]) {
      personalityArr[index].landingformurl = event.target.value;
      quiz.personalityArr = personalityArr;
      this.setState({ quiz }, () => {
        this.props.updateQuiz(quiz);
      });
    }
  }

  privacyPolicy() {}

  privacyPolicyText(e) {
    console.log("e", e.target.value);
    var getQuiz = this.props.quiz;
    getQuiz.privacy_policy_text = e.target.value;
    this.setState({
      quiz: getQuiz,
    });
  }

  isEnableResultPageCustomText(e) {
    var quiz = this.state.quiz;
    quiz.result_page_custom_text = e.target.checked
      ? "<h3>This certificate is proudly presented on completion of the quiz</h3><h2>" +
        this.state.quiz.title +
        "</h2><h2>To</h2>"
      : "";
      this.setState({ quiz }, () => {
        this.props.updateQuiz(quiz);
      });
  }
  isEnableSocialMedia(e) {
    var quiz = this.state.quiz;
    quiz.is_enable_social_media = e.target.checked ? 1 : 0;
    this.setState({ quiz }, () => {
      this.props.updateQuiz(quiz);
    });
  }

  renderPersonalities() {
    var self = this;
    var personalityArr = null;
    if (this.state.quiz != null && this.state.quiz.personalityArr) {
      personalityArr = this.state.quiz.personalityArr;
    }
    if (personalityArr != null && personalityArr.length > 0) {
      return personalityArr.map(function (personality, counter) {
        return (
          <option
            key={counter}
            value={personality.title}
            selected={
              self.state.personalityArr != null &&
              self.state.personalityArr[counter] != null &&
              self.state.quiz.personalityArr[counter].title == personality.title
                ? true
                : false
            }
          >
            {personality.title}
          </option>
        );
      }, this);
    } else {
      return null;
    }
  }

  //Email form setting for personality quiz
  renderPersonalityEmailFormSettings() {
    var personalities =
      this.state.quiz != null ? this.state.quiz.personalityArr : null;
    var content = null;
    if (personalities != null) {
      var temp = personalities.map((element, index) => {
        return (
          <div
            className="col-md-12 ans-wrapper-modify"
            key={index}
            style={{ paddingLeft: 0 }}
          >
            <div id={"divpersonality" + index} className="">
              <div
                style={{ color: "red", fontSize: 12 }}
                id={"anserror" + index + 1}
              ></div>
              <div className="">
                <div
                  className="col-md-3 col-sm-3 col-xs-12"
                  style={{ height: 50, marginTop: 0, paddingLeft: 0 }}
                >
                  <label>{element.title}</label>
                </div>
                <div className="col-md-9 col-sm-9 col-xs-12 ans-option-list">
                  <textarea
                    className="Sign-In-TextBox"
                    id={"ans" + index + 1}
                    onChange={this.changePersonalityEmailFormCode}
                    data-id={index}
                    value={element == null ? "" : element.quizemailformcode}
                    placeholder="Enter HTML code for email form"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });
      content = (
        <div>
          <h5 className="Sign-In-Label">
            Please enter html code for email form for personalities{" "}
          </h5>
          <br />
          {temp}
        </div>
      );
    }

    return content;
  }

  //Email form setting for graded quiz
  renderGradedEmailFormSetting() {
    return (
      <>
        <div className="Sign-In-Label">
          Enter HTML code for lead generation form
        </div>
        <textarea
          className="Sign-In-TextBox"
          onChange={this.onFieldChanged}
          name="quizemailformcode"
          value={
            this.state.quiz != null ? this.state.quiz.quizemailformcode : ""
          }
          style={{ marginTop: 5, width: 100 + "%", height: 150 }}
        />
        <span className="help-block error-block basic-block">Required</span>
      </>
    );
  }

  
  //Email form setting for multiple grades
  renderMultipleGradeEmailFormSetting() {
    var self = this;
    var personalityArr = null;
    var customlandingflag = self.state.quiz && self.state.quiz.emailformtype == "custom"? true: false;
      if (this.state.quiz != null && this.state.quiz.personalityArr) {
        personalityArr = this.state.quiz.personalityArr;
      }
      if (personalityArr != null && personalityArr.length > 0) {
        return personalityArr.map(function (personality, index) {
          return (
            <div className="row" style={{ marginBottom: 8 }}>
              <div className="col-md-3">
                {personality.mingrade} - {personality.maxgrade} %
              </div>
              {customlandingflag ? (
                <div className="col-md-9">
                  <textarea
                    className="Sign-In-TextBox"
                    type="text"
                    placeholder="Enter HTML code for lead generation form"
                    name="quizemailformcode"
                    data-id={index}
                    onChange={self.onChangePersonalityWithGrade}
                    value={personality.emailformcode || personality.quizemailformcode}
                  ></textarea>
                </div>
              ) : null}
            </div>
          );
        }, this);
      } else {
        return null;
      }
  
    //else{
    // return (
    //   <>
    //     <div className="Sign-In-Label">
    //       Enter HTML code for lead generation form
    //     </div>
    //     <textarea
    //       className="Sign-In-TextBox"
    //       onChange={this.onFieldChanged}
    //       name="quizemailformcode"
    //       value={
    //         this.state.quiz != null ? this.state.quiz.quizemailformcode : ""
    //       }
    //       style={{ marginTop: 5, width: 100 + "%", height: 150 }}
    //     />
    //     <span className="help-block error-block basic-block">Required</span>
    //   </>
    // );
  }

  //Landing form setting for personality quiz
  renderPersonalityLandingFormSettings() {
    var personalities =
      this.state.quiz != null ? this.state.quiz.personalityArr : null;
    var content = null;
    if (personalities != null) {
      var temp = personalities.map((element, index) => {
        return (
          <div
            className="col-md-12 ans-wrapper-modify"
            key={index}
            style={{ paddingLeft: 0 }}
          >
            <div id={"divpersonality" + index} className="">
              <div
                style={{ color: "red", fontSize: 12 }}
                id={"anserror" + index + 1}
              ></div>
              <div className="">
                <div
                  className="col-md-2 col-sm-2 col-xs-12"
                  style={{ height: 50, marginTop: 0 }}
                >
                  <label>{element.title}</label>
                </div>
                <div className="col-md-10 col-sm-10 col-xs-10 ans-option-list">
                  <input
                    type="url"
                    className="Sign-In-TextBox"
                    id={"ans" + index + 1}
                    onChange={this.changePersonalityLandingFormUrl}
                    data-id={index}
                    value={element == null ? "" : element.landingformurl}
                    placeholder="Enter url for landing page"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });
      content = (
        <div>
          <h5 className="Sign-In-Label">
            Please enter url for landing page for personalities{" "}
          </h5>
          <br />
          {temp}
        </div>
      );
    }

    return content;
  }

  //Landing form setting for graded quiz
  renderGradedLandingFormSetting() {
    return (
      <>
        <div className="Sign-In-Label">Enter url for custom landing page</div>
        <input
          className="Sign-In-TextBox"
          type="url"
          onChange={this.onFieldChanged}
          name="landingformurl"
          value={this.state.quiz != null ? this.state.quiz.landingformurl : ""}
          style={{ marginTop: 5, width: 100 + "%" }}
        />
        <span className="help-block error-block basic-block">Required</span>
      </>
    );
  }

  //Landing form setting for graded quiz with grades
  renderPersonalitiesWithGrades() {
    var self = this;
    var personalityArr = null;
    var customlandingflag =
      self.state.quiz && self.state.quiz.landingformtype == "custom"
        ? true
        : false;
    if (this.state.quiz != null && this.state.quiz.personalityArr) {
      personalityArr = this.state.quiz.personalityArr;
    }
    if (personalityArr != null && personalityArr.length > 0) {
      return personalityArr.map(function (personality, index) {
        return (
          <div className="row" style={{ marginBottom: 8 }}>
            <div className="col-md-3">
              {personality.mingrade} - {personality.maxgrade} %
            </div>
            <div className={customlandingflag ? "hide" : "col-md-4"}>
              <textarea
                className="Sign-In-TextBox"
                type="text"
                placeholder="Enter personality title"
                name="title"
                data-id={index}
                onChange={this.onChangePersonalityWithGrade}
                value={personality.title}
              ></textarea>
            </div>
            <div className={customlandingflag ? "hide" : "col-md-5"}>
              <textarea
                className="Sign-In-TextBox"
                type="text"
                placeholder="Enter personality description"
                name="description"
                data-id={index}
                onChange={this.onChangePersonalityWithGrade}
                value={personality.description}
              ></textarea>
            </div>
            {customlandingflag ? (
              <div className="col-md-9">
                <textarea
                  className="Sign-In-TextBox"
                  type="text"
                  placeholder="Enter url for landing page for score-range"
                  name="landingformurl"
                  data-id={index}
                  onChange={this.onChangePersonalityWithGrade}
                  value={personality.landingformurl}
                ></textarea>
              </div>
            ) : null}
          </div>
        );
      }, this);
    } else {
      return null;
    }
  }

  renderOutcomesWithScoreRange() {
    var self = this;
    var personalityArr = null;
    var customlandingflag = self.state.quiz && self.state.quiz.landingformtype == "custom"? true: false;

    if (this.state.quiz != null && this.state.quiz.personalityArr) {
      personalityArr = this.state.quiz.personalityArr;
    }
    if (personalityArr != null && personalityArr.length > 0) {
      return personalityArr.map(function (personality, index) {
        return (
          <div className="row" style={{ marginBottom: 8 }}>
            <div className={`${customlandingflag?'col-md-2':'col-md-2'}`}>
              <input style={{padding:0, textAlign:"center"}}
                type="text"
                data-id={index}
                className="form-control custome_input"
                value={personality.mingrade}
                name="mingrade"
                onChange={this.onChangeScoreRange}
              />
            </div>
            <div className={`${customlandingflag?'col-md-2':'col-md-2'}`}>
              <input
                type="text"
                data-id={index}
                className="form-control custome_input"
                value={personality.maxgrade}
                name="maxgrade"
                onChange={this.onChangeScoreRange}
              />
            </div>
            <div className={`${customlandingflag?'hide':'col-md-4'}`}>
              <textarea
                className="Sign-In-TextBox"
                type="text"
                placeholder="Enter outcome title"
                name="title"
                data-id={index}
                onChange={this.onChangePersonalityWithGrade}
                value={personality.title}
              ></textarea>
            </div>
            <div className={`${customlandingflag?'hide':'col-md-4'}`}>
              <textarea
                className="Sign-In-TextBox"
                type="text"
                placeholder="Enter outcome description"
                name="description"
                data-id={index}
                onChange={this.onChangePersonalityWithGrade}
                value={personality.description}
              ></textarea>
            </div>
            {customlandingflag?<div className={`${customlandingflag?'col-md-8':'col-md-4'}`}>
                <textarea
                  className="Sign-In-TextBox"
                  type="text"
                  placeholder="Enter outcome url"
                  name="url"
                  data-id={index}
                  onChange={this.onChangePersonalityWithGrade}
                  value={personality.url}
                ></textarea>
              </div>:null}
           
          </div>
        );
      }, this);
    } else {
      return null;
    }
  }

  render() {
    var customlandingflag =
      this.state.quiz && this.state.quiz.landingformtype == "custom"
        ? true
        : false;
    return (
      
        <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="Sign-In-Label">Select landing page</div>
                <input
                  type="radio"
                  onChange={this.onRadioChanged}
                  name="landingformtype"
                  value="standard"
                  checked={
                    this.state.quiz == null ||
                    this.state.quiz.landingformtype == null ||
                    this.state.quiz.landingformtype == "standard"
                      ? true
                      : false
                  }
                />
                <span style={{ marginLeft: 5 }}>Standard</span>
                <input
                  type="radio"
                  onChange={this.onRadioChanged}
                  name="landingformtype"
                  value="custom"
                  style={{ marginLeft: 20 }}
                  checked={
                    this.state.quiz != null &&
                    this.state.quiz.landingformtype != null &&
                    this.state.quiz.landingformtype == "custom"
                      ? true
                      : false
                  }
                />
                <span style={{ marginLeft: 5 }}>Custom</span>
                <p className="upgrade_error">
                  {this.state.messageUpgradechecbox}
                </p>
              </div>
            </div>

            <div
              id="lead-generate-form"
              className={`${
                this.state.quiz != null &&
                this.state.quiz.landingformtype == "custom"
                  ? ""
                  : "hide"
              }`}
            >
              {this.state.quiz != null &&
                ((this.state.quiz.quiztype == "qztype2" ||
                this.state.quiz.quiztype == "qztype7" || this.state.quiz.quiztype == "qztype8" || this.state.quiz.quiztype == "qztype9"
                  ? this.renderPersonalityLandingFormSettings()
                  : null) ||
                  (this.state.quiz.quiztype == "qztype5" ||
                  this.state.quiz.quiztype == "qztype6" ? (
                    <div
                      className={`col-md-12 ${
                        this.state.quiz != null &&
                        (this.state.quiz.quiztype == "qztype5" ||
                          this.state.quiz.quiztype == "qztype6")
                          ? ""
                          : "hide"
                      }`}
                    >
                      <div className="form-group has-feedback">
                        <label className="Sign-In-Label" htmlFor="productName">
                          {this.state.quiz.quiztype == "qztype5"
                            ? (customlandingflag?"Assign custom urls to Score Range":"Assign Personalities to Score Range")
                            : (customlandingflag?"Assign custom urls to Score Range":"Assign Outcomes to Score Range")}
                        </label>
                        <div className="Sign-In-Label">
                          <div className="row" style={{ marginBottom: 8 }}>
                            <div className={`${customlandingflag?"col-md-4":"col-md-3"}`} style={{ height: 20 }}>
                              Score Range
                            </div>
                            <div
                              className={
                                customlandingflag ? "hide" : "col-md-4"
                              }
                              style={{ height: 20 }}
                            >
                              Title
                            </div>
                            <div
                              className={
                                customlandingflag ? "hide" : "col-md-5"
                              }
                              style={{ height: 20 }}
                            >
                              Description
                            </div>
                            <div className={
                                customlandingflag ? "col-md-8":"hide"
                              } style={{ height: 20 }}>
                              Url
                            </div>
                            
                          </div>
                          {this.state.quiz.quiztype == "qztype5"
                          ? this.renderPersonalitiesWithGrades()
                          : (customlandingflag?this.renderOutcomesWithScoreRange():null)}
                        </div>
                      </div>
                    </div>
                  ) : null) || 
                  (this.state.quiz.quiztype == "qztype1" ? (
                    <div
                      className={`col-md-12 ${
                        this.state.quiz != null &&
                        this.state.quiz.quiztype == "qztype1" ? "": "hide"}`}
                    >
                      <div className="form-group has-feedback">
                        {this.renderGradedLandingFormSetting()}
                      </div>
                    </div>
                  ) : null)
              )
            }
            </div>
            {this.state.quiz == null ||
            this.state.quiz.landingformtype == null ||
            this.state.quiz.landingformtype == "standard" ? (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="form-group has-feedback"
                      id={
                        this.state.quiz != null &&
                        this.state.quiz.quiztype == "qztype1"
                          ? ""
                          : "r-p"
                      }
                    >
                      <div className="Sign-In-Label" htmlFor="productName">
                        Show score in {" "}
                      </div>
                      <div className="Sign-In-Label">
                        <div className="row">
                          <div className="col-md-6" style={{ height: 20 }}>
                            <input
                              type="radio"
                              name="inlineRadioOptions1"
                              value="Marks"
                              checked={
                                this.state.quiz == null ||
                                this.state.quiz.scoretype == "Marks"
                              }
                              onChange={this.handleScoreType}
                              data-value="Marks"
                            />
                            <span>Marks</span>
                          </div>
                          <div className="col-md-6" style={{ height: 20 }}>
                            <input
                              type="radio"
                              name="inlineRadioOptions2"
                              value="Percentage"
                              checked={
                                this.state.quiz == null ||
                                this.state.quiz.scoretype == "Percentage"
                              }
                              onChange={this.handleScoreType}
                              data-value="Percentage"
                            />
                            <span>Percentage</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-md-12 ${
                      this.state.quiz != null &&
                      (this.state.quiz.quiztype == "qztype5" ||
                        this.state.quiz.quiztype == "qztype6")
                        ? ""
                        : "hide"
                    }`}
                  >
                    <div className="form-group has-feedback">
                      <label className="Sign-In-Label" htmlFor="productName">
                        {this.state.quiz.quiztype == "qztype5"
                          ? "Assign Personalities to Score Range"
                          : "Assign Outcomes to Score Range"}
                      </label>
                      <div className="Sign-In-Label">
                        <div className="row" style={{ marginBottom: 8 }}>
                          <div className="col-md-3" style={{ height: 20 }}>
                            Score Range
                          </div>
                          <div
                            className={
                              customlandingflag ? "col-md-3" : "col-md-4"
                            }
                            style={{ height: 20 }}
                          >
                            Title
                          </div>
                          <div
                            className={
                              customlandingflag ? "col-md-3" : "col-md-5"
                            }
                            style={{ height: 20 }}
                          >
                            Description
                          </div>
                          {customlandingflag ? (
                            <div className="col-md-3" style={{ height: 20 }}>
                              Url
                            </div>
                          ) : null}
                        </div>
                        {this.state.quiz.quiztype == "qztype5"
                          ? this.renderPersonalitiesWithGrades()
                          : this.renderOutcomesWithScoreRange()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="form-group has-feedback"
                      ng-class="{'has-error': vm.addressForm.address.$invalid &amp;&amp; (vm.addressForm.address.$dirty || vm.addressForm.$submitted)}"
                    >
                      <div className="Sign-In-Label">Offer Message</div>
                      <input
                        // name="offermsg"
                        onChange={this.onFieldChanged}
                        name="quizoffermsg"
                        className="Sign-In-TextBox"
                        required=""
                        type="text"
                        placeholder="Schedule a free visit"
                        maxLength="50"
                        value={
                          this.state.quiz != null
                            ? this.state.quiz.quizoffermsg
                            : ""
                        }
                      />
                      <div className="counter-wrapper">
                        <span>
                          {this.state.quiz.quizoffermsg != null
                            ? this.state.quiz.quizoffermsg.length
                            : "0"}
                          /50
                        </span>
                      </div>
                      <div className="Sign-In-Label">Offer Button Text</div>
                      <input
                        onChange={this.onFieldChanged}
                        className="Sign-In-TextBox"
                        required=""
                        name="quizofferbtntxt"
                        type="text"
                        placeholder="Schedule Now"
                        maxLength="20"
                        value={
                          this.state.quiz != null
                            ? this.state.quiz.quizofferbtntxt
                            : ""
                        }
                      />
                      <div className="counter-wrapper">
                        <span>
                          {this.state.quiz.quizofferbtntxt != null
                            ? this.state.quiz.quizofferbtntxt.length
                            : "0"}
                          /20
                        </span>
                      </div>
                      <div className="Sign-In-Label">Offer Button Link</div>
                      <input
                        // name="offerbtnlinkmsg"
                        onChange={this.onFieldChanged}
                        className="Sign-In-TextBox"
                        required=""
                        name="quizofferbtnlink"
                        type="text"
                        placeholder="www.quizzes4leads.com"
                        maxLength="50"
                        value={
                          this.state.quiz != null
                            ? this.state.quiz.quizofferbtnlink
                            : ""
                        }
                      />
                      <div className="counter-wrapper">
                        <span>
                          {this.state.quiz.quizofferbtnlink != null
                            ? this.state.quiz.quizofferbtnlink.length
                            : "0"}
                          /50
                        </span>
                      </div>
                      
                      {/* <div className="Sign-In-Label">Book A Call Text</div>
                      <input
                        name="book_a_call_button_text"
                        onChange={this.onFieldChanged}
                        className="Sign-In-TextBox"
                        type="text"
                        placeholder="Book A Call"
                        maxLength="20"
                        value={
                          this.state.quiz != null
                            ? this.state.quiz.book_a_call_button_text
                            : ""
                        }
                      />
                      <div className="counter-wrapper">
                        <span>
                          {this.state.quiz.book_a_call_button_text != null
                            ? this.state.quiz.book_a_call_button_text.length
                            : "0"}
                          /20
                        </span>
                      </div> */}
                      <div className="Sign-In-Label">Retake This Quiz Text</div>
                      <input
                        name="retake_this_quiz_text"
                        onChange={this.onFieldChanged}
                        className="Sign-In-TextBox"
                        type="text"
                        placeholder="Retake This Quiz"
                        maxLength="50"
                        value={
                          this.state.quiz != null
                            ? this.state.quiz.retake_this_quiz_text
                            : ""
                        }
                      />
                      <div className="counter-wrapper">
                        <span>
                          {this.state.quiz.retake_this_quiz_text != null
                            ? this.state.quiz.retake_this_quiz_text.length
                            : "0"}
                          /50
                        </span>
                      </div>
                      {/* {this.state.quiz.quiztype!='qztype2' && this.state.quiz.quiztype!='qztype5' && this.state.quiz.quiztype!='qztype7' && this.state.quiz.quiztype!='qztype8' && this.state.quiz.quiztype!='qztype9' ?<>
                      <div className="Sign-In-Label">
                        This Certificate Is Proudly Text...
                      </div>
                      <input
                        name="this_is_certificate_is_text"
                        onChange={this.onFieldChanged}
                        className="Sign-In-TextBox"
                        type="text"
                        maxLength="50"
                        placeholder="This Certificate Is Proudly..."
                        value={
                          this.state.quiz != null
                            ? this.state.quiz.this_is_certificate_is_text
                            : ""
                        }
                      />
                      
                      <div className="counter-wrapper">
                        <span>
                          {this.state.quiz.this_is_certificate_is_text != null
                            ? this.state.quiz.this_is_certificate_is_text.length
                            : "0"}
                          /50
                        </span>
                      </div>
                      </> :null} */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            
           
        {this.state.quiz && this.state.quiz.landingformtype=='standard'? 
        <div className="row">
          <div className="col-md-12">
            <div className="Sign-In-Label">
              <input
                type="checkbox"
                onChange={this.isEnableSocialMedia}
                name="is_enable_social_media"
                checked={
                  this.state.quiz == null ||
                  this.state.quiz.is_enable_social_media
                    ? true
                    : false
                }
              />{" "}
              Enable social sharing ?
            </div>
          </div>
        </div> : null }
      </div>
    );
  }
}

class QueSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queformActive: this.props.queformActive,
      quiz: this.props.quiz,
      user: this.props.user,
      quetable: this.props.quetable,
      selectedQue: this.props.selectedQue,
      updateQuiz: this.props.updateQuiz,
    };
    this.onClickSaveQue = this.onClickSaveQue.bind(this);
    this.onClickEditQue = this.onClickEditQue.bind(this);
    this.onClickDelQue = this.onClickDelQue.bind(this);
    this.showQueForm = this.showQueForm.bind(this);
    this.updateLogicBranchingFlag = this.updateLogicBranchingFlag.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      queformActive: nextProps.queformActive,
      quiz: nextProps.quiz,
    });
  }

  showQueForm(event) {
    var tempque = {
      title: "",
      quetype: "MCSA",
      quizid: this.state.quiz != null ? this.state.quiz.quizid : null,
      queimg: null,
      queid: null,
      jsonmarks: "[]",
      jsonans: '["",""]',
      jsonansimg: "[]",
      jsonpersonality: "[]",
      action: "",
    };
    this.setState({ selectedQue: tempque }, () => {
      this.props.updateSelectedQue(tempque);
      this.props.activateQueForm();
    });
  }

  onClickSaveQue(event, selectedque, flag) {
    var queid = event.target.getAttribute("data-id");
    var tempquiz = this.state.quiz;
    if (queid != null) {
      var index = queid - 1;
      if (tempquiz == null) {
        tempquiz = {};
        tempquiz.que = {};
      } else if (tempquiz.que == null) {
        tempquiz.que = {};
      }
      selectedque.action = "";
      var tempque = Object.values(tempquiz.que).filter(
        (element) => element.queid == queid
      );
      if (tempque != null && tempque.length > 0) {
        tempque[0] = selectedque;
        //tempquiz.que.index = selectedque;
      }
    } else {
      var index = 0;
      if (tempquiz == null) {
        tempquiz = {};
        //tempquiz.que = [];
        tempquiz.que = {};
        tempquiz.quizid = null;
      } else if (tempquiz.que == null) {
        //tempquiz.que = [];
        tempquiz.que = [];
      }

      //if(tempquiz.que!=null && tempquiz.que.length==0)
      if (tempquiz.que != null) {
        //index = tempquiz.que.length;
        if (tempquiz.que.length == null || tempquiz.que.length == 0) {
          index = 0;
        } else {
          var queidArr = tempquiz.que.map((item, counter) => item.queid);
          //var keys = Object.values(tempquiz.que);
          if (queidArr == null || queidArr.length == 0) {
            index = 0;
          }
          var keys = Object.values(queidArr);
          if (keys.length == 0) {
            index = 0;
          } else {
            index = Math.max.apply(
              Math,
              keys.map(function (o) {
                return o;
              })
            );
          }
        }
      }
      selectedque.queid = "" + (index + 1);
      selectedque.quizid = tempquiz.quizid;
      selectedque.action = "";
      //tempquiz.que.queid = selectedque;
      tempquiz.que.push(selectedque);
    }

    if (flag) {
      var tempque = {
        title: "",
        quetype: "MCSA",
        quizid: this.state.quiz.quizid,
        queimg: null,
        queid: null,
        jsonmarks: "[]",
        jsonans: '["",""]',
        jsonansimg: "[]",
        action: "",
      };
      //this.setState({selectedQue: tempque});
    }
    this.props.updateQuiz(tempquiz);
    this.props.closeQueForm();
    // alert("question saved!");
    var self = this;
    var quizid = this.state.quiz["quizid"];
    //var postdata = JSON.stringify(tempquiz.que);
    var postdata = JSON.stringify(Object.values(tempquiz.que));
    var queryurl = this.props.basepath + "question/saveque/" + quizid;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: postdata,
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      self.setState({ showLoaderFlag: false });
      if (response && response.status ==200 && response.data) {
        NotificationManager.success("Question", "Question saved successfully");
      }else{
        NotificationManager.error("Server error: ", "Question failed to be saved");
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
    //swal("Question saved!", "", "success");
  }

  onClickEditQue(event) {
    var queid = event.target.getAttribute("data-id");

    //var selQue = this.state.quiz.que[index];
    var que = Object.values(this.state.quiz.que);
    //var selQue = this.state.quiz.que.index;
    var selQue = que.filter((element) => element.queid == queid);
    if (selQue != null && selQue.length > 0) {
      this.setState({ selectedQue: selQue[0] }, this.props.activateQueForm);
    }
  }

  onClickDelQue(event) {
    var queid = event.target.getAttribute("data-id");
    var quizid = this.state.quiz["quizid"];
    var self = this;
    swal({
      title: "Delete Question",
      text: "Are you sure you want to delete this question? This question and all related data will be permanently deleted.",
      type: "warning",
      buttons: { cancel: true, text: "Yes, Delete it!!", closeModal: false },
      icon: "warning",
    }).then(function (isConfirm) {
      if (isConfirm) {
        var queryurl =
          self.props.basepath + "question/deleteque/" + quizid + "/" + queid;
        fetch(queryurl, {
          method: "get",
          credentials: "include",
        }).then(function (response) {
          return response.json();
        }).then((response) => {
          if (response && response.status ==200 && response.data) {
            var quizArr = self.state.quiz;
            var queArr = quizArr.que;
            queArr = queArr.filter(element => element.queid !== parseInt(queid));
            quizArr.que = queArr;
            self.setState({ quiz: quizArr }, () => {
              NotificationManager.success("Question Deleted", "Question has been deleted successfully.");
            });
          }else{
            NotificationManager.error("Server error: ",response.message);
          }
        }).catch(err=>{
          NotificationManager.error("Server error: " , err.message)
        });
      }
    });
  }

  updateLogicBranchingFlag(e) {
    var quiz = this.state.quiz;

    if (e.target.checked) {
      swal({
        title: "Logic Branching",
        text: "Please ensure all your questions are added before you create logic branching.",
        type: "info",
        buttons: { cancel: true, text: "Ok", closeModal: false },
        icon: "info",
        className: "logic-branching-confirm",
      }).then((willDelete, e) => {
        if (willDelete) {
          quiz.islogicbranchingenabled = 1;
          this.setState({ quiz });
        }
      });
    } else {
      swal({
        title: "Are you sure?",
        text: "All your branching logic will get reset.",
        icon: "warning",
        buttons: { cancel: true, text: "Ok", closeModal: false },
        className: "logic-branching-confirm",
        dangerMode: true,
      }).then((willDelete, e) => {
        if (willDelete) {
          quiz.logicalbranches = null;
          quiz.islogicbranchingenabled = 0;
          this.setState({ quiz });
        } else {
          quiz.islogicbranchingenabled = 1;
          this.setState({ quiz });
          return false;
        }
      });
    }
    if (quiz == null) {
      quiz = {};
    }

    this.setState({ quiz });
  }

  render() {
    return (
      <div>
        <div className={`col-md-12 ${this.state.queformActive ? "hide" : ""}`}>
          <QueTable
            quiz={this.state.quiz}
            tableItems={this.state.quetable}
            // tableTitle="Question Table"
            quizid={this.state.quiz != null ? this.state.quiz.quizid : null}
            // addQue={this.addQue}
            updateQuiz={this.props.updateQuiz}
            editQue={this.onClickEditQue}
            delQue={this.onClickDelQue}
            addAns={this.props.addAns}
            delAns={this.props.delAns}
            selectedQue={this.props.selectedQue}
            reportQue={this.props.reportQue}
            saveQuiz={this.props.saveQuiz}
            showQueForm={this.showQueForm}
            handleQuePreview={this.props.handleQuePreview}
          />
        </div>
        <div className="col=md-12">
          <Queform
            active={this.state.queformActive}
            modaltitle="Question Wizard"
            closeQueForm={this.props.closeQueForm}
            saveQue={this.onClickSaveQue}
            selectedQue={this.props.selectedQue}
            quetype={
              this.state.selectedQue == null ||
              this.state.selectedQue.quetype == null
                ? "MCSA"
                : this.state.selectedQue.quetype
            }
            quiz={this.state.quiz}
            basepath={this.props.basepath}
            user={this.props.user}
            updateQue={this.props.updateQue}
            updateAns={this.props.updateAns}
          />
        </div>
        {this.state.quiz.quiztype != "qztype1" && this.state.quiz.quiztype != "qztype9" ? (
          <div className="row">
            <div className="col-md-12">
              <div className="checkbox">
                <div className="Sign-In-Label">
                  <div
                    className="col-md-12"
                    style={{ marginLeft: 5, height: 20 }}
                  >
                    <input
                      type="checkbox"
                      onChange={this.updateLogicBranchingFlag}
                      name="islogicbranchingenabled"
                      checked={
                        this.state.quiz == null ||
                        this.state.quiz.islogicbranchingenabled == 1
                          ? true
                          : false
                      }
                    />
                    <div style={{ marginTop: 2.5 }}>
                      <span> Enable Logic Branching</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

class QuizPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabnum: this.props.tabnum,
      quiz: this.props.quiz,
      queformActive: this.props.queformActive,
      que: this.props.que,
      selectedQue: this.props.selectedQue,
      user: this.props.user,
      abc: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tabnum: nextProps.tabnum,
      quiz: nextProps.quiz,
      queformActive: nextProps.queformActive,
      que: nextProps.que,
      selectedQue: nextProps.selectedQue,
      showEemailPreviewImg: this.props.quiz.collectuseremailflag,
      user: nextProps.user,
    });
  }

  showImages(jsonansimg) {
    if (jsonansimg != null) {
      var imgs = null;
      imgs = JSON.parse(jsonansimg);
      if (imgs.length != 0) {
        imgs.map((i_url, index) => {
          //    return <img key={index} className="quz-ans-img" src={i_url} />
          return <img className="quz-ans-img" key={index} src={"localhost/"} />;
        });
      }
    }
  }

  showAns(que) {
    var content = "";
    //var que = this.state.selectedQuePreview || this.state.que;
    //var que = this.state.que;

    var tempquetype = que == null ? "MCSA" : que.quetype;
    var jsonans = null;
    var jsonansimg = null;

    if (que != null) {
      jsonans = que.jsonans;
      jsonansimg = que.jsonansimg;
    }
    var ans = null;
    var anscount = 0;
    if (jsonans != null) {
      ans = JSON.parse(jsonans);
      anscount = ans.length;
    }

    var img = null;
    if (jsonansimg != null) {
      img = JSON.parse(jsonansimg);
    }

    if (ans == null || ans.length == 0) {
      ans = [];
      ans[0] = ans[1] = "";
    }
    if (tempquetype == "TF") {
      ans[0] = true.toString().toUpperCase();
      ans[1] = false.toString().toUpperCase();
    }
    // for theme colors select
    var button_color = { hex: "" };
    var button_color_text = { hex: "" };
    var header_text = { hex: "" };
    var header_bg = { hex: "" };
    if (
      this.state.quiz.theme_color != null &&
      this.state.quiz.theme_color != ""
    ) {
      let theme_colors = JSON.parse(this.state.quiz.theme_color);
      button_color.hex = theme_colors[6].button_color;
      button_color_text.hex = theme_colors[7]
        ? theme_colors[7].button_color_text
        : "";
      header_text.hex = theme_colors[1].header_text;
      header_bg.hex = theme_colors[0].header_bg;
    }
    content = (
      <div className="col-md-12">
        {ans.map((element, index) => {
          if (element != null) {
            // var backgroundurl = ((this.state.selectedQue == null || this.state.selectedQue.queimg == null || this.state.selectedQue.queimg == "") ? null : "url('" + this.props.basepath + this.state.selectedQue.queimg + "') no-repeat center");
            var backgroundurl =
              img == null || img[index] == null || img[index] == ""
                ? null
                : "url('" + img[index] + "') no-repeat center";
            if (Array.isArray(img) && img.length != 0) {
              return (
                <div className="col-md-4 m-t-sm" key={index}>
                  <div id={`divans${index}`} className="">
                    {backgroundurl != null ? (
                      <div
                        className="upload-image"
                        style={{
                          background: backgroundurl,
                          margin: 0,
                          height: 121,
                          backgroundSize: "cover",
                        }}
                      ></div>
                    ) : null}
                    <div
                      style={{
                        width: 100 + "%",
                        whiteSpace: "normal",
                        cursor: "unset",
                      }}
                      className={
                        backgroundurl == null
                          ? "quizpreview btn y"
                          : "quizpreview"
                      }
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty(
                            "background-color",
                            button_color ? button_color.hex : "",
                            "important"
                          );
                          el.style.setProperty(
                            "color",
                            button_color_text && button_color_text.hex
                              ? button_color_text.hex
                              : this.state.quiz &&
                                this.state.quiz.welcome_message_color
                              ? this.state.quiz.welcome_message_color
                              : "",
                            "important"
                          );
                        }
                      }}
                    >
                      {backgroundurl != null && backgroundurl != ""
                        ? decodeURIComponent(element)
                        : element != "" && element != null
                        ? decodeURIComponent(element)
                        : "Ans " + (index + 1)}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className="col-md-offset-2 col-md-8 m-t-sm col-md-offset-2"
                  key={index}
                >
                  <div id={`divans${index}`} className="">
                    {backgroundurl != null ? (
                      <div
                        className="upload-image"
                        style={{
                          background: backgroundurl,
                          margin: 0,
                          height: 121,
                          backgroundSize: "cover",
                        }}
                      ></div>
                    ) : null}
                    <div
                      style={{
                        width: 100 + "%",
                        whiteSpace: "normal",
                        cursor: "unset",
                      }}
                      className={
                        backgroundurl == null
                          ? "quizpreview btn"
                          : "quizpreview"
                      }
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty(
                            "background-color",
                            button_color ? button_color.hex : "",
                            "important"
                          );
                          el.style.setProperty(
                            "color",
                            button_color_text && button_color_text.hex
                              ? button_color_text.hex
                              : this.state.quiz &&
                                this.state.quiz.welcome_message_color
                              ? this.state.quiz.welcome_message_color
                              : "",
                            "important"
                          );
                        }
                      }}
                    >
                      {backgroundurl != null && backgroundurl != ""
                        ? decodeURIComponent(element)
                        : element != "" && element != null
                        ? decodeURIComponent(element)
                        : "Ans " + (index + 1)}
                    </div>
                  </div>
                </div>
              );
            }
          }
        })}
      </div>
    );
    return content;
  }

  renderQuizPreviewHeader() {
    // for theme colors select
    var header_bg = { hex: "" };
    var header_text = { hex: "" };
    if (
      this.state.quiz.theme_color != null &&
      this.state.quiz.theme_color != ""
    ) {
      let theme_colors = JSON.parse(this.state.quiz.theme_color);
      header_bg.hex = theme_colors[0].header_bg;
      header_text.hex = theme_colors[1].header_text;
    }
    return (
      <div
        className={`c panel-heading ${
          this.state.quiz == null || this.state.quiz.theme == null
            ? "theme1"
            : this.state.quiz.theme
        }`}
        ref={(el) => {
          if (el) {
            el.style.setProperty(
              "background",
              this.props.header_bg
                ? this.props.header_bg.hex
                : header_bg
                ? header_bg.hex
                : "",
              "important"
            );
            el.style.setProperty(
              "color",
              this.props.header_text
                ? this.props.header_text.hex
                : header_text
                ? header_text.hex
                : "",
              "important"
            );
          }
        }}
      >
        <div className="row">
          <div
            className="col-md-10 col-sm-10 col-xs-10"
            style={{ fontWeight: "bold", wordBreak: "break-word" }}
          >
            {this.state.quiz == null || this.state.quiz.title == ""
              ? "Enter Quiz Title"
              : this.state.quiz.title}
          </div>
          <div className="col-md-2 col-sm-2 col-xs-2">
            <img
              className="glyphicon img-responsive pull-right vcenter"
              src={this.state.quiz != null ? this.state.quiz.companylogo : ""}
              style={{ minWidth: 25, height: 25 }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderQuizPreviewFooter() {
    // for theme colors select

    var footer_bg = { hex: "" };
    var footer_text_color = { hex: "" };
    if (
      this.state.quiz.theme_color != null &&
      this.state.quiz.theme_color != ""
    ) {
      let theme_colors = JSON.parse(this.state.quiz.theme_color);

      footer_bg.hex = theme_colors[0].footer_bg;
      footer_text_color.hex = theme_colors[1].footer_text_color;
    }
    var content = (
      <div
        className="panel-footer"
        ref={(el) => {
          if (el) {
            el.style.setProperty(
              "background",
              this.props.footer_bg
                ? this.props.footer_bg.hex
                : footer_bg
                ? footer_bg.hex
                : "",
              "important"
            );
          }
        }}
      >
        <a
          className="graded-footer"
          href="test"
          id="btnqzcreate"
          ref={(el) => {
            if (el) {
              el.style.setProperty(
                "color",
                this.props.footer_text_color
                  ? this.props.footer_text_color.hex
                  : footer_text_color
                  ? footer_text_color.hex
                  : "",
                "important"
              );
            }
          }}
        >
          Create Your Free Quiz
        </a>
        <a
          className="pull-right graded-footer"
          id="qzfooter"
          style={{ paddingTop: 3 }}
          ref={(el) => {
            if (el) {
              el.style.setProperty(
                "color",
                this.props.footer_text_color
                  ? this.props.footer_text_color.hex
                  : footer_text_color
                  ? footer_text_color.hex
                  : "",
                "important"
              );
            }
          }}
        >
          {this.state.quiz != null &&
          this.state.quiz.quizfootermsg != null &&
          this.state.quiz.quizfootermsg != ""
            ? this.state.quiz.quizfootermsg
            : "Powered By Quizzes4Leads"}
        </a>
      </div>
    );

    if (this.state.user != null && this.state.user.mode == "paid") {
      content = (
        <div
          className="panel-footer"
          ref={(el) => {
            if (el) {
              el.style.setProperty(
                "background",
                this.props.footer_bg
                  ? this.props.footer_bg.hex
                  : footer_bg
                  ? footer_bg.hex
                  : "",
                "important"
              );
            }
          }}
        >
          <a
            className="graded-footer"
            href="test"
            id="btnqzcreate"
            ref={(el) => {
              if (el) {
                el.style.setProperty(
                  "color",
                  this.props.footer_text_color
                    ? this.props.footer_text_color.hex
                    : footer_text_color
                    ? footer_text_color.hex
                    : "",
                  "important"
                );
              }
            }}
          ></a>
          <a
            className="pull-right graded-footer"
            id="qzfooter"
            style={{ paddingTop: 3 }}
            ref={(el) => {
              if (el) {
                el.style.setProperty(
                  "color",
                  this.props.footer_text_color
                    ? this.props.footer_text_color.hex
                    : footer_text_color
                    ? footer_text_color.hex
                    : "",
                  "important"
                );
              }
            }}
          ></a>
        </div>
      );
    }

    return content;
  }

  render() {
    // for theme colors select
    var header_bg = { hex: "" };
    var header_text = { hex: "" };
    var header_content_bg = { hex: "" };
    var header_content_text = { hex: "" };
    var footer_bg = { hex: "" };
    var footer_text_color = { hex: "" };
    var button_color = { hex: "" };
    var button_color_text = { hex: "" };

    if (
      this.state.quiz.theme_color != null &&
      this.state.quiz.theme_color != ""
    ) {
      let theme_colors = JSON.parse(this.state.quiz.theme_color);
      header_bg.hex = theme_colors[0].header_bg;
      header_text.hex = theme_colors[1].header_text;
      header_content_bg.hex = theme_colors[2].header_content_bg;
      header_content_text.hex = this.state.quiz.welcome_message_color ? this.state.quiz.welcome_message_color :theme_colors[3].header_content_text;
      footer_bg.hex = theme_colors[4].footer_bg;
      footer_text_color.hex = theme_colors[5].footer_text_color;
      button_color.hex = theme_colors[6].button_color;
      button_color_text.hex = theme_colors[7].button_color_text
        ? theme_colors[7].button_color_text
        : this.state.quiz && this.state.quiz.welcome_message_color
        ? this.state.quiz.welcome_message_color
        : "";
    }
    
    var content = null;
    var result = this.state.quiz.quizscoremsg;
    if(this.state.quiz.quiztype == "qztype1" && this.state.quiz.scoretype == 'Percentage'){
      result = "You performed remarkably well in this quiz and your score is 80%."
    }
    if(this.state.quiz && this.state.quiz.quiztype == "qztype2" && this.state.quiz.personalityArr && this.state.quiz.personalityArr.length>0){
      result = <div>Your personality is {this.state.quiz.personalityArr[0].title}.<br />{this.state.quiz.personalityArr[0].description}</div>
    }
    if(this.state.quiz && (this.state.quiz.quiztype == "qztype5" || this.state.quiz.quiztype == "qztype6")){
      var  jsonmetadata = this.state.quiz.quizmetadata;
      var quizmetadata = JSON.parse(jsonmetadata);
      result = <div>Your personality is {quizmetadata[0].title}.<br />{quizmetadata[0].description}</div>
    }
    if(this.state.quiz && (this.state.quiz.quiztype == "qztype9")){
      var  jsonmetadata = this.state.quiz.quizmetadata;
      var quizmetadata = JSON.parse(jsonmetadata);
      var personalityArr = JSON.parse(quizmetadata.personalityArr);
      result = <div>Your personality is {personalityArr[0].title}.<br />{personalityArr[0].description}</div>;
    }

    switch (parseInt(this.state.tabnum)) {
      //quizsetting page
      case 0:
        content = (
          <div
            className="panel-body a"
            id="quizcontent"
            ref={(el) => {
              if (el) {
                el.style.setProperty(
                  "background",
                  this.props.header_content_bg
                    ? this.props.header_content_bg.hex
                    : header_content_bg,
                  "important"
                );
              }
            }}
          >
            <div className="m-t-md qzwcmsg ql-editor">
              <div
                style={{
                  fontSize: "medium",
                  wordWrap: "break-word",
                  color: this.props.header_content_text.hex,
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    this.state.quiz == null ||
                      this.state.quiz.quizwelcomemsg == ""
                      ? "Enter Welcome Message"
                      : this.state.quiz.quizwelcomemsg
                  ),
                }}
              ></div>
            </div>
            <button
              className="btn"
              id="previewbtnqzstart"
              ref={(el) => {
                if (el) {
                  el.style.setProperty(
                    "background",
                    this.props.button_color
                      ? this.props.button_color.hex
                      : button_color,
                    "important"
                  );
                  el.style.setProperty(
                    "color",
                    this.props.button_color_text &&
                      this.props.button_color_text.hex
                      ? this.props.button_color_text.hex
                      : this.state.quiz && this.state.quiz.welcome_message_color
                      ? this.state.quiz.welcome_message_color
                      : "",
                    "important"
                  );
                }
              }}
              style={{ marginTop: 10, width: 25 + "%", cursor: "unset" }}
              type="button"
            >
              {this.state.quiz.btn_start_text
                ? this.state.quiz.btn_start_text
                : "Start"}
            </button>
          </div>
        );
        {
          /* {this.renderQuizPreviewFooter()}
                            </div>
                        </div> */
        }
        break;
      //quetable
      case 1:
        if (this.state.queformActive) {
          // content = <div className="col-md-12" style={{backgroundSize: 'cover', backgroundImage: 'url(' + this.state.quiz.backgroundimg + ')'}}>
          //         <div className="Sign-In-Label" style={{marginTop:0,paddingTop:3,}}>Quiz Preview </div>
          //         <div className={`panel panel-blur animated slideIn ${this.state.quiz.theme}`}>
          //         {this.renderQuizPreviewHeader()}
          //<img className={(this.state.que==null || this.state.que.queimg==null || this.state.que.queimg=="")?"hide":""} src={(this.state.que== null || this.state.que.queimg==null)?"":this.state.que.queimg} style={{height:48,width:48}}/>
          var backgroundurl =
            this.state.selectedQue == null ||
            this.state.selectedQue.queimg == null ||
            this.state.selectedQue.queimg == ""
              ? null
              : "url('" + this.state.selectedQue.queimg + "') no-repeat center";
          content = (
            <div
              className="panel-body b"
              id="quizcontent"
              style={{
                backgroundColor: this.props.header_content_bg
                  ? this.props.header_content_bg.hex
                  : header_content_bg,
              }}
            >
              <div id="startForm" action="#" method="get">
                <div className="startcontainer col-md-12 col-sm-12 col-xs-12">
                  <label
                    className="control-label row-label"
                    id="lblque"
                    htmlFor="input-success"
                  ></label>
                  <div
                    className="text-center m-md qzwcmsg col-md-12 ql-editor"
                    style={{
                      fontSize: "medium",
                      wordWrap: "break-word",
                      paddingBottom: 40,
                    }}
                  >
                    {backgroundurl != null ? (
                      <div
                        className="col-md-8 col-md-offset-2"
                        style={{
                          background: backgroundurl,
                          backgroundSize: "contain",
                          height: 150,
                        }}
                      ></div>
                    ) : null}
                    <div
                      className="col-md-8 col-md-offset-2"
                      style={{ marginTop: 10 }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty(
                            "color",
                            header_content_text ? header_content_text.hex : "",
                            "important"
                          );
                        }
                      }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          this.state.selectedQue == null
                            ? "Sample Question Title"
                            : this.state.selectedQue.title == null ||
                              this.state.selectedQue.title == ""
                            ? "Sample Question Title"
                            : this.state.selectedQue.title
                        ),
                      }}
                    ></div>
                  </div>
                  {this.showAns(this.state.selectedQue)}
                </div>
              </div>
            </div>
          );
        } else {
          var backgroundurl =
            this.state.selectedQue == null ||
            this.state.selectedQue.queimg == null ||
            this.state.selectedQue.queimg == ""
              ? null
              : "url('" + this.state.selectedQue.queimg + "') no-repeat center";

          content = (
            <div
              className="panel-body c"
              id="quizcontent"
              style={{
                backgroundColor: this.props.header_content_bg
                  ? this.props.header_content_bg.hex
                  : header_content_bg,
              }}
            >
              <div id="startForm" action="#" method="get">
                <div className="startcontainer col-md-12 col-sm-12 col-xs-12">
                  <label
                    className="control-label row-label"
                    id="lblque"
                    htmlFor="input-success"
                  ></label>
                  <div
                    className="text-center m-md qzwcmsg col-md-12"
                    style={{
                      fontSize: "medium",
                      wordWrap: "break-word",
                      paddingBottom: 40,
                    }}
                  >
                    {backgroundurl != null ? (
                      <div
                        className="col-md-8 col-md-offset-2"
                        style={{
                          background: backgroundurl,
                          backgroundSize: "contain",
                          height: 150,
                        }}
                      ></div>
                    ) : null}
                    <div
                      className="col-md-8 col-md-offset-2 Personality-text"
                      style={{
                        marginTop: 10,
                        color:
                          header_content_text && header_content_text.hex
                            ? header_content_text.hex
                            : this.state.quiz && this.state.quiz.welcome_message_color
                            ? this.state.quiz.welcome_message_color
                            : ""
                      }}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty(
                            "color",
                            header_content_text && header_content_text.hex
                              ? header_content_text.hex
                              : this.state.quiz &&
                                this.state.quiz.welcome_message_color
                              ? this.state.quiz.welcome_message_color
                              : "",
                            "important"
                          );
                        }
                      }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          this.state.selectedQue == null
                            ? "Question Title"
                            : this.state.selectedQue.title == null ||
                              this.state.selectedQue.title == ""
                            ? "Question Title"
                            : this.state.selectedQue.title
                        ),
                      }}
                    ></div>
                  </div>
                  {this.showAns(this.state.selectedQue)}
                </div>
              </div>
            </div>
          );
        }

        break;
      
      // leadgeneration
      case 2:
        content = (
          <div
            className="panel-body d"
            id="quizcontent"
            style={{
              backgroundColor: this.props.header_content_bg
                ? this.props.header_content_bg.hex
                : header_content_bg,
            }}
          >
            <div className="startcontainer col-md-10 col-sm-10 col-xs-10">
              <div
                className="m-t-sm qzwcmsg"
                style={{
                  fontSize: "medium",
                  wordWrap: "break-word",
                }}
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      header_content_text ? header_content_text.hex : "",
                      "important"
                    );
                  }
                }}
              >
                {this.state.quiz != null &&
                this.state.quiz.collectuseremailflag == "1"
                  ? this.state.quiz.quizemailmsg
                  : null}
              </div>
              <input type='text' className="form-control m-t-sm"
              placeholder="Name" value={this.state.quiz && this.state.quiz.enter_your_name_text}
              />
              <input type='text' className="form-control m-t-sm"
              placeholder="Email" value={this.state.quiz && this.state.quiz.enter_your_email_text}
              />
              {this.state.quiz && this.state.quiz.privacy_policy_text?<div class="quiz_wrap__content___text privacy-policy-text"><p>{this.state.quiz.privacy_policy_text}</p></div>:null}
              <a
                className="btn m-t-sm result-button"
                href={`http://${
                  this.state.quiz == null
                    ? "www.socialmbuzz.com"
                    : this.state.quiz.quizofferbtnlink
                }`}
                target="_blank"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "background-color",
                      button_color ? button_color.hex : "",
                      "important"
                    );
                    el.style.setProperty("border", "0", "important");
                    el.style.setProperty(
                      "color",
                      button_color_text ? button_color_text.hex : "",
                      "important"
                    );
                  }
                }}
              >

                {this.state.quiz == null ||
                this.state.quiz.continue_button_text == null ||
                this.state.quiz.continue_button_text == ""
                  ? "Check Your Score"
                  : this.state.quiz.continue_button_text}
              </a>

              <div
                className="m-t-md qzwcmsg"
                style={{
                  fontSize: "medium",
                  wordWrap: "break-word",
                }}
              >
                {this.state.quiz == null
                  ? "Sample Thank You Message"
                  : this.state.quiz.quizthankyoumsg}{" "}
              </div>
              <br />
            </div>
          </div>
        );

        break;
      
      // landing page  
      case 3:

        content = (
          <div
            className="panel-body d"
            id="quizcontent"
            style={{
              backgroundColor: this.props.header_content_bg
                ? this.props.header_content_bg.hex
                : header_content_bg,
            }}
          >
            <div className="startcontainer col-md-10 col-sm-10 col-xs-10">
              <div
                className="m-t-sm qzwcmsg"
                style={{
                  fontSize: "medium",
                  wordWrap: "break-word",
                }}
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      header_content_text ? header_content_text.hex : "",
                      "important"
                    );
                  }
                }}
              >
                {this.state.quiz != null &&
                this.state.quiz.collectuseremailflag == "1"
                  ? this.state.quiz.quizemailmsg
                  : null}
              </div>
              <div className='col-md-12 m-t-md' style={{ color:header_content_text ? header_content_text.hex : ''}}>{this.state.quiz? this.state.quiz.this_is_certificate_is_text:null}</div>
              <div className='col-md-12 m-t-md' style={{ color:header_content_text ? header_content_text.hex : ''}}>{result}</div>
              
              <div
                className="m-t-md qzwcmsg"
                style={{
                  fontSize: "medium",
                  wordWrap: "break-word",
                }}
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      header_content_text && header_content_text.hex
                        ? header_content_text.hex
                        : this.state.quiz &&
                          this.state.quiz.welcome_message_color
                        ? this.state.quiz.welcome_message_color
                        : "",
                      "important"
                    );
                  }
                }}
              >
                {this.state.quiz == null || this.state.quiz.quizoffermsg == ""
                  ? "Schedule a free visit"
                  : this.state.quiz.quizoffermsg}{" "}
              </div>

              <a
                className="btn m-t-sm result-button"
                href={`http://${
                  this.state.quiz == null
                    ? "www.socialmbuzz.com"
                    : this.state.quiz.quizofferbtnlink
                }`}
                target="_blank"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "background-color",
                      button_color ? button_color.hex : "",
                      "important"
                    );
                    el.style.setProperty("border", "0", "important");
                    el.style.setProperty(
                      "color",
                      button_color_text && button_color_text.hex
                        ? button_color_text.hex
                        : this.state.quiz &&
                          this.state.quiz.welcome_message_color
                        ? this.state.quiz.welcome_message_color
                        : "",
                      "important"
                    );
                  }
                }}
              >
                {this.state.quiz == null ||
                this.state.quiz.quizofferbtntxt == null ||
                this.state.quiz.quizofferbtntxt == ""
                  ? "Schedule Now"
                  : this.state.quiz.quizofferbtntxt}
              </a>

              <div
                className="qzwcmsg text-center"
                style={{
                  fontSize: 16,
                  wordWrap: "break-word",
                }}
              >
                {this.state.quiz == null
                  ? "Sample Thank You Message"
                  : this.state.quiz.enable_social_sharing_text}{" "}
              </div>
              {this.state.quiz.is_enable_social_media?<div className="col-md-12 m-t-md">
                  <div className="col-md-1 col-md-offset-3">
                    <FacebookShareButton url={shareUrl} quote="">
                      <FacebookIcon size={shareBtnSize} round />
                    </FacebookShareButton>
                  </div>
                  <div className="col-md-1">
                    <TwitterShareButton url={shareUrl} quote="">
                      <TwitterIcon size={shareBtnSize} round />
                    </TwitterShareButton>
                  </div>
                  <div className="col-md-1">
                    <LinkedinShareButton
                      url={shareUrl}
                      quote=""
                      windowWidth={750}
                      windowHeight={600}
                    >
                      <LinkedinIcon size={shareBtnSize} round />
                    </LinkedinShareButton>
                  </div>
                  <div className="col-md-1">
                    <WhatsappShareButton
                      url={shareUrl}
                      quote=""
                      seperator="::"
                    >
                      <WhatsappIcon size={shareBtnSize} round />
                    </WhatsappShareButton>
                  </div>
                  <div className="col-md-1">
                    <EmailShareButton
                      url={shareUrl}
                      subject=""
                      body="email body"
                    >
                      <EmailIcon size={shareBtnSize} round />
                    </EmailShareButton>
                  </div>
                </div>:null}
              <div
                className="m-t-md qzwcmsg"
                style={{
                  fontSize: "medium",
                  wordWrap: "break-word",
                }}
              >
                {this.state.quiz == null
                  ? "Sample Thank You Message"
                  : this.state.quiz.quizthankyoumsg}{" "}
              </div>
              <div className="col-md-12">
                <a
                  className="btn m-t-sm result-button"
                  ref={(el2) => {
                    if (el2) {
                      el2.style.setProperty(
                        "background-color",
                        button_color
                          ? button_color.hex
                          : "",
                        "important"
                      );
                      el2.style.setProperty("border", "0", "important");
                      el2.style.setProperty(
                        "color",
                        button_color_text ? button_color_text.hex : "",
                        "important"
                      );
                    }
                  }}
                >
                  {this.state.quiz.retake_this_quiz_text
                    ? this.state.quiz.retake_this_quiz_text
                    : "Retake This Quiz"}
                </a>
                
              </div>
              <br />
            </div>
            

            <div className="row">
              
          </div>
          </div>
        );

        break;
    }
    return (
      <div>
        {this.state.quiz.theme_type == 1 ? (
          <div>
            <QuizeThemeFirst
              user={this.state.user}
              bgimg={
                this.state.quiz == null ? null : this.state.quiz.backgroundimg
              }
              is_company_logo={this.state.quiz.is_company_logo}
              header_logo={this.state.quiz.companylogo}
              quiz_title={this.state.quiz.title}
              quiz_footer={this.state.quiz.quizfootermsg}
              content={content}
              is_enable_quiz_title={this.state.quiz.is_enable_quiz_title}
              header_bg={this.props.header_bg}
              header_text={this.props.header_text}
              header_content_bg={this.props.header_content_bg}
              header_content_text={this.props.header_content_text}
              button_color={this.props.button_color}
              footer_bg={this.props.footer_bg}
              footer_text_color={this.props.footer_text_color}
              theme_color_code={
                this.state.theme_color != null ||
                this.state.theme_color != "undefined"
                  ? this.state.quiz.theme_color
                  : ""
              }
              theme_color={
                this.state.quiz == null || this.state.quiz.theme == null
                  ? "theme1"
                  : this.state.quiz.theme
              }
            ></QuizeThemeFirst>
          </div>
        ) : this.state.quiz.theme_type == 2 ? (
          <div>
            <QuizeThemeSecond
              user={this.state.user}
              bgimg={
                this.state.quiz == null ? null : this.state.quiz.backgroundimg
              }
              header_logo={this.state.quiz.companylogo}
              is_company_logo={this.state.quiz.is_company_logo}
              quiz_title={this.state.quiz.title}
              quiz_footer={this.state.quiz.quizfootermsg}
              is_enable_quiz_title={this.state.quiz.is_enable_quiz_title}
              theme_color={
                this.state.quiz == null || this.state.quiz.theme == null
                  ? "theme2"
                  : this.state.quiz.theme
              }
              content={content}
              header_bg={this.props.header_bg}
              header_text={this.props.header_text}
              header_content_bg={this.props.header_content_bg}
              header_content_text={this.props.header_content_text}
              button_color={this.props.button_color}
              footer_bg={this.props.footer_bg}
              footer_text_color={this.props.footer_text_color}
              theme_color_code={
                this.state.theme_color != null ||
                this.state.theme_color != "undefined"
                  ? this.state.quiz.theme_color
                  : ""
              }
            ></QuizeThemeSecond>
          </div>
        ) : this.state.quiz.theme_type == "" ? (
          <div className="col-md-12">
            <div
              className="Sign-In-Label"
              style={{ marginTop: 0, paddingTop: 3, fontSize: 16 }}
            ></div>
            {/* <div className={`panel panel-blur animated slideIn ${this.state.quiz.theme}`}> */}
            <div
              className={`panel panel-blur animated slideIn ${
                this.state.quiz == null || this.state.quiz.theme == null
                  ? "theme1"
                  : this.state.quiz.theme
              }`}
              style={{ marginTop: 10 }}
            >
              {this.renderQuizPreviewHeader()}
              {content}
              {this.renderQuizPreviewFooter()}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

class PersonalityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz: this.props.quiz,
      flagShowForm: true,
      personalitiesInputs: this.props.quiz
        ? this.props.quiz.personalityArr
        : [],
      addButton: true,
      removeButton: false,
    };
    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onImageUploadComplete = this.onImageUploadComplete.bind(this);
    this.onImageDeleteComplete = this.onImageDeleteComplete.bind(this);
  }
  componentDidMount() {
    if (
      this.state.personalitiesInputs
        ? this.state.personalitiesInputs.length >= 2
        : ""
    ) {
      this.setState({ removeButton: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    var quiz = nextProps["quiz"];

    var personalitiesInputs = quiz.personalityArr;
    var quecategory = quiz.quecategory;
    
    if (quiz != null){
      if(quiz.quiztype == 'qztype8' || quiz.quiztype == 'qztype9'){
        personalitiesInputs = quiz.personalityArr;
        //quecategory = quiz.quecategory;
      }
      else{
        if(quiz.personalityArr != null && quiz.personalityArr.length > 0) {
          //flagShowForm = true;
          personalitiesInputs = quiz.personalityArr;
        }
      }
    }
    //this.setState({ quiz, personalitiesInputs, quecategory });
    this.setState({ quiz, personalitiesInputs});
  }
  onFieldChanged(event) {
    var quiz = this.state.quiz;

    if (quiz == null) {
      quiz = {};
      quiz.personalityArr = [];
    }

    if (quiz.personalityArr == null) {
      quiz.personalityArr = [];
    }

    if(quiz.quizmetadata){
      if(quiz.quiztype == 'qztype8' || quiz.quiztype == 'qztype9'){
        if(quiz.quizmetadata.personalityArr != null && quiz.quizmetadata.personalityArr != ""){
          quiz.personalityArr = JSON.parse(quiz.quizmetadata.personalityArr);
        }
      }else{
        quiz.personalityArr = JSON.parse(quiz.quizmetadata);
      }
    }else{
      if(quiz.quiztype == 'qztype8' || quiz.quiztype == 'qztype9'){
        quiz.quizmetadata = JSON.stringify({personalityArr:[], quecategory:[]});
      }else{
        quiz.quizmetadata = {};
      }

    }
    var dataid = event.target.getAttribute("data-id");
    var name = event.target.name;
    var datavalue = event.target.value;
    var oldvalue = null;
    
      if (quiz.personalityArr[dataid] == null) {
        quiz.personalityArr[dataid] = {};
      }
    
  
    oldvalue = quiz.personalityArr[dataid][name];

    //search question containing oldvalue of personality.
    var queArr = quiz.que;
    if (queArr != null && queArr.length > 0) {
      queArr = queArr.map((element, index) => {
        if(element && element.jsonmarks){
          var jsonmarks = JSON.parse(element.jsonmarks)
          jsonmarks = jsonmarks.map((marks,index) => {
            if(marks == oldvalue) {
              //replace old personality with new personality
              marks = datavalue;
            }
            return marks;
          });
          element.jsonmarks = JSON.stringify(jsonmarks);
        }
        return element;
      });
    }

    quiz.personalityArr[dataid][name] = datavalue;
    if((quiz.quiztype == 'qztype8' || quiz.quiztype == 'qztype9') && quiz.quizmetadata != null && quiz.quizmetadata != ''){
      //var metadata = {personalityArr: JSON.stringify(quiz.personalityArr), quecategory: JSON.stringify(quiz.quecategory)}
      //var metadata = JSON.stringify(quiz.quizmetadata);
      var metadata = JSON.parse(quiz.quizmetadata);
      metadata.personalityArr = JSON.stringify(quiz.personalityArr);
      quiz.quizmetadata = JSON.stringify(metadata);
      
    }else{
      quiz.quizmetadata = JSON.stringify(quiz.personalityArr);
    }
    this.setState({ quiz }, () => {
      this.props.updateQuiz(quiz);
    });
  }

  onSave(event) {
    // this.props.closeForm();
    if (!this.state.quiz.personalityArr) {
      //swal("Personality", "You have to add min two personality", "info");
      NotificationManager.error(
        "Validation Error",
        "Please add atleast two personalities"
      );
      return false;
    }
    //swal("Personalities", "Saved successfully.", "success");
    NotificationManager.success(
      "Personalities Save",
      "Personalities saved successfully"
    );
    var quiz = this.state.quiz;
    this.props.updateQuiz(quiz);
  }

  onImageUploadComplete(imgtype, imgid, filepath) {
    var quiz = this.state.quiz;
    if (quiz == null) {
      quiz = {};
      quiz.personalityArr = [];
    }
    if (quiz.personalityArr == null) {
      quiz.personalityArr = [];
    }
    var dataid = imgid;
    var name = imgtype;

    if (quiz.personalityArr[dataid] == null) {
      quiz.personalityArr[dataid] = {};
    }
    quiz.personalityArr[dataid][name] = filepath;
    this.setState({ quiz }, () => {
      //swal('Image Uploaded','Image uploaded successfully');
      NotificationManager.success(
        "Image Upload",
        "Image uploaded successfully"
      );
    });
  }

  onImageDeleteComplete(imgtype, imgid, filepath) {
    if (imgtype == "ansimg") {
      var imgArr = this.state.img;
      var tempque = this.state.que;
      if (imgArr == null) {
        imgArr = [];
      }
      imgArr[imgid] = filepath;
      tempque["jsonansimg"] = JSON.stringify(imgArr);
      this.setState({ que: tempque, img: imgArr }, () => {
        //swal("Image Delete", "Image deleted successfully", "success");
        NotificationManager.success(
          "Image Delete",
          "Image deleted successfully"
        );
        this.props.updateQue(tempque);
      });
    } else if (imgtype == "queimg") {
      var selectedQue = this.state.que;
      selectedQue.queimg = filepath;
      this.setState({ que: selectedQue }, () => {
        //swal("Image Delete", "Image deleted successfully", "success");
        NotificationManager.success(
          "Image Delete",
          "Image deleted successfully"
        );
        this.props.updateQue(tempque);
      });
    }
  }

  addPersonality(e, index) {
    var removeButton = true;
    var addButton = true;
    // get current personality
    let getPersonalityInputs = this.state.personalitiesInputs;
    // when click on add new set empty personality
    if (getPersonalityInputs) {
      getPersonalityInputs[getPersonalityInputs.length] = {
        title: "",
        description: "",
        personalityimage: "",
      };
    } else {
      // for blank template
      getPersonalityInputs = [];
      getPersonalityInputs.push({
        title: "Enter personality title",
        description: "Enter personality description",
        personalityimage: "",
      });
    }
    var personalitiesInputs = getPersonalityInputs;

    this.setState({
      personalitiesInputs: personalitiesInputs,
      addButton,
      removeButton,
    });
  }

  removePersonality(index) {
    var removeButton = true;
    var addButton = true;
    var personalitiesInputs = this.state.personalitiesInputs;

    personalitiesInputs.splice(this.state.personalitiesInputs.length - 1, 1);
    if (personalitiesInputs.length == 2) {
      removeButton = false;
    }
    this.setState({
      personalitiesInputs,
      removeButton,
      addButton,
    });
  }
  renderFields() {
    var personalitiesinput = this.state.personalitiesInputs; //this.state.quiz != null && this.state.quiz.personalityArr != null && this.state.quiz.personalityArr.length > 0 ? this.state.quiz.personalityArr : this.state.personalitiesInputs;
    return (
      <div>
        {personalitiesinput ? (
          <div>
            {personalitiesinput.map((p, index) => {
              return (
                <div className="personality_input_item" key={index}>
                  <input
                    className="Sign-In-TextBox"
                    type="text"
                    placeholder="Enter personality title"
                    name="title"
                    data-id={index}
                    onChange={this.onFieldChanged}
                    value={
                      this.state.quiz != null &&
                      this.state.quiz.personalityArr != null &&
                      this.state.quiz.personalityArr.length > 1 &&
                      this.state.quiz.personalityArr[index] != null
                        ? this.state.quiz.personalityArr[index].title
                        : null
                    }
                  ></input>
                  <input
                    className="Sign-In-TextBox"
                    type="text"
                    placeholder="Enter personality description"
                    name="description"
                    data-id={index}
                    onChange={this.onFieldChanged}
                    value={
                      this.state.quiz != null &&
                      this.state.quiz.personalityArr != null &&
                      this.state.quiz.personalityArr.length > 1 &&
                      this.state.quiz.personalityArr[index] != null
                        ? this.state.quiz.personalityArr[index].description
                        : null
                    }
                  ></input>
                  <ReactTooltip />
                  <ImageUpload
                    basepath={this.props.basepath}
                    ansid={index}
                    quizid={
                      this.state.quiz != null ? this.state.quiz.quizid : null
                    }
                    imgtype="personalityimage"
                    imagepath={
                      this.state.quiz != null &&
                      this.state.quiz.personalityArr != null &&
                      this.state.quiz.personalityArr.length > 1 &&
                      this.state.quiz.personalityArr[index] != null
                        ? this.state.quiz.personalityArr[index].personalityimage
                        : null
                    }
                    onImageUploadComplete={this.onImageUploadComplete}
                    imagePreviewFlag={true}
                    uploaded_uri=""
                    info_text="Preferred image size 1920x1075"
                    onImageDeleteComplete={this.onImageDeleteComplete}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        <div className="row col-md-12">
          <div>
            {this.state.addButton ? (
              <button
                id="personality_add_btn"
                onClick={(e, index) => this.addPersonality(e, index)}
                type="button"
                className={"btn btn-primary btn-rounded m-t-sm"}
              >
                <i className="fa fa-plus fa-2x"></i>
              </button>
            ) : null}
            {this.state.removeButton ? (
              <button
                id={`personality_add_btn${
                  this.state.removeButton ? "" : " hide"
                }`}
                // show={this.state.removeButton}
                onClick={(index) => this.removePersonality(index)}
                type="button"
                className={"btn btn-primary btn-rounded m-t-sm"}
              >
                <i className="fa fa-minus fa-2x"></i>
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  renderPersonalityForm() {
    var content = null;
    if (this.state.flagShowForm) {
      content = (
        <div
          name="personalityform"
          id="personalityform"
          style={{ marginTop: 0 }}
        >
          <h3 style={{ margin: "0" }}>Create Personalities</h3>
          {this.renderFields()}
        </div>
      );
    }
    return content;
  }
  render() {
    var content = null;
    content = (
      <div>
        {/*this.renderPersonalities()*/}
        {this.renderPersonalityForm()}
      </div>
    );
    return content;
  }
}

class QueCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz: this.props.quiz,
      flagShowForm: true,
      quecategoryInputs: this.props.quiz && this.props.quiz.quecategory
        ? this.props.quiz.quecategory
        : [],
      addButton: true,
      removeButton: false,
    };
    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onImageUploadComplete = this.onImageUploadComplete.bind(this);
    this.onImageDeleteComplete = this.onImageDeleteComplete.bind(this);
    this.addQueCategory = this.addQueCategory.bind(this);
    this.removeQueCategory = this.removeQueCategory.bind(this);
  }
  componentDidMount() {
    if (this.state.quecategoryInputs ? this.state.quecategoryInputs.length >= 2: "") {
      this.setState({removeButton: true});
    }
  }

  componentWillReceiveProps(nextProps) {
    var quiz = nextProps["quiz"];

    var quecategoryInputs = quiz.quecategory;
    if (
      quiz != null &&
      quiz.quecategory != null &&
      quiz.quecategory.length > 0
    ) {
      //flagShowForm = true;
      quecategoryInputs = quiz.quecategory;
    }
    this.setState({ quiz, quecategoryInputs });
  }

  

  onFieldChanged(event) {
    var quiz = this.state.quiz;

    if (quiz == null) {
      quiz = {};
      quiz.quecategory = [];
      quiz.personalityArr = [];
    }

    if (quiz.quecategory == null) {
      quiz.quecategory = [];
    }
    if (quiz.personalityArr == null) {
      quiz.personalityArr = [];
    }
    
    // if (quiz.quizmetadata) {
    //   if(quiz.quizmetadata.quecategory !=null && quiz.quizmetadata.quecategory != ""){
    //     quiz.quecategory = JSON.parse(quiz.quizmetadata.quecategory);
    //   }
    // }else{
    //   quiz.quizmetadata = {personalityArr:[], quecategory:[]}
    // }

    var dataid = event.target.getAttribute("data-id");
    var name = event.target.name;
    var datavalue = event.target.value;
    if (quiz.quecategory[dataid] == null) {
      quiz.quecategory[dataid] = {};
    }
    if (quiz.personalityArr[dataid] == null) {
      quiz.personalityArr[dataid] = {};
    }
    var oldvalue = quiz.quecategory[dataid][name];

    //search question containing oldvalue of personality.
    var queArr = quiz.que;
    if (queArr != null && queArr.length > 0) {
      queArr = queArr.map((element, index) => {
        if(element && element.jsonmarks){
          var jsonmarks = JSON.parse(element.jsonmarks)
          jsonmarks = jsonmarks.map((marks,index) => {
            if(marks == oldvalue) {
              //replace old personality with new personality
              marks = datavalue;
            }
            return marks;
          });
          element.jsonmarks = JSON.stringify(jsonmarks);
        }
        return element;
      });
    }

    quiz.quecategory[dataid][name] = datavalue;

   
    
    var metadata = {};
    metadata.quecategory = JSON.stringify(quiz.quecategory);
    //code to have same personality data as quecategory.
    // remove this code if personality and quecategory required to be different
    metadata.personalityArr = metadata.quecategory;
    quiz.personalityArr[dataid][name] = datavalue;
    // end code
    quiz.quizmetadata = JSON.stringify(metadata);

    

    this.setState({ quiz }, () => {
      this.props.updateQuiz(quiz);
    });

  }

  onSave(event) {
    // this.props.closeForm();
    if (!this.state.quiz.quecategory) {
      //swal("Personality", "You have to add min two personality", "info");
      NotificationManager.error(
        "Validation Error",
        "Please add atleast two question categories"
      );
      return false;
    }
    //swal("Personalities", "Saved successfully.", "success");
    NotificationManager.success(
      "Question categories Save",
      "Question categories saved successfully"
    );
    var quiz = this.state.quiz;
    this.props.updateQuiz(quiz);
  }

  onImageUploadComplete(imgtype, imgid, filepath) {
    var quiz = this.state.quiz;
    if (quiz == null) {
      quiz = {};
      quiz.quecategory = [];
    }
    if (quiz.quecategory == null) {
      quiz.quecategory = [];
    }
    var dataid = imgid;
    var name = imgtype;

    if (quiz.quecategory[dataid] == null) {
      quiz.quecategory[dataid] = {};
    }
    quiz.quecategory[dataid][name] = filepath;
    this.setState({ quiz }, () => {
      //swal('Image Uploaded','Image uploaded successfully');
      NotificationManager.success(
        "Image Upload",
        "Image uploaded successfully"
      );
    });
  }

  onImageDeleteComplete(imgtype, imgid, filepath) {
    if (imgtype == "ansimg") {
      var imgArr = this.state.img;
      var tempque = this.state.que;
      if (imgArr == null) {
        imgArr = [];
      }
      imgArr[imgid] = filepath;
      tempque["jsonansimg"] = JSON.stringify(imgArr);
      this.setState({ que: tempque, img: imgArr }, () => {
        //swal("Image Delete", "Image deleted successfully", "success");
        NotificationManager.success(
          "Image Delete",
          "Image deleted successfully"
        );
        this.props.updateQue(tempque);
      });
    } else if (imgtype == "queimg") {
      var selectedQue = this.state.que;
      selectedQue.queimg = filepath;
      this.setState({ que: selectedQue }, () => {
        //swal("Image Delete", "Image deleted successfully", "success");
        NotificationManager.success(
          "Image Delete",
          "Image deleted successfully"
        );
        this.props.updateQue(tempque);
      });
    }
  }

  addQueCategory(e, index) {
    var removeButton = true;
    var addButton = true;
    // get current personality
    let getquecategoryInputs = this.state.quecategoryInputs;
    // when click on add new set empty personality
    if (getquecategoryInputs) {
      getquecategoryInputs[getquecategoryInputs.length] = {
        title: "",
        description: "",
        quecategoryimage: "",
      };
    } else {
      // for blank template
      getquecategoryInputs = [];
      getquecategoryInputs.push({
        title: "Enter question category title",
        description: "Enter question category description",
        personalityimage: "",
      });
    }
    var quecategoryInputs = getquecategoryInputs;

    this.setState({
      quecategoryInputs,
      addButton,
      removeButton,
    });
  }

  removeQueCategory(index) {
    var removeButton = true;
    var addButton = true;
    var quecategoryInputs = this.state.quecategoryInputs;

    quecategoryInputs.splice(this.state.quecategoryInputs.length - 1, 1);
    if (quecategoryInputs.length == 2) {
      removeButton = false;
    }
    this.setState({
      quecategoryInputs,
      removeButton,
      addButton,
    });
  }
  renderFields() {
    var quecategoryinput = this.state.quecategoryInputs; //this.state.quiz != null && this.state.quiz.personalityArr != null && this.state.quiz.personalityArr.length > 0 ? this.state.quiz.personalityArr : this.state.personalitiesInputs;
    return (
      <div>
        {quecategoryinput ? (
          <div>
            {quecategoryinput.map((p, index) => {
              return (
                <div className="personality_input_item" key={index}>
                  <input
                    className="Sign-In-TextBox"
                    type="text"
                    placeholder="Enter quecategory title"
                    name="title"
                    data-id={index}
                    onChange={this.onFieldChanged}
                    value={
                      this.state.quiz != null &&
                      this.state.quiz.quecategory != null &&
                      this.state.quiz.quecategory.length > 1 &&
                      this.state.quiz.quecategory[index] != null
                        ? this.state.quiz.quecategory[index].title
                        : null
                    }
                  ></input>
                  <input
                    className="Sign-In-TextBox"
                    type="text"
                    placeholder="Enter que category description"
                    name="description"
                    data-id={index}
                    onChange={this.onFieldChanged}
                    value={
                      this.state.quiz != null &&
                      this.state.quiz.quecategory != null &&
                      this.state.quiz.quecategory.length > 1 &&
                      this.state.quiz.quecategory[index] != null
                        ? this.state.quiz.quecategory[index].description
                        : null
                    }
                  ></input>
                  <ReactTooltip />
                  <ImageUpload
                    basepath={this.props.basepath}
                    ansid={index}
                    quizid={
                      this.state.quiz != null ? this.state.quiz.quizid : null
                    }
                    imgtype="quecategoryimage"
                    imagepath={
                      this.state.quiz != null &&
                      this.state.quiz.quecategory != null &&
                      this.state.quiz.quecategory.length > 1 &&
                      this.state.quiz.quecategory[index] != null
                        ? this.state.quiz.quecategory[index].quecateogryimage
                        : null
                    }
                    onImageUploadComplete={this.onImageUploadComplete}
                    imagePreviewFlag={true}
                    uploaded_uri=""
                    info_text="Preferred image size 1920x1075"
                    onImageDeleteComplete={this.onImageDeleteComplete}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        <div className="row col-md-12">
          <div>
            {this.state.addButton ? (
              <button
                id="personality_add_btn"
                onClick={(e, index) => this.addQueCategory(e, index)}
                type="button"
                className={"btn btn-primary btn-rounded m-t-sm"}
              >
                <i className="fa fa-plus fa-2x"></i>
              </button>
            ) : null}
            {this.state.removeButton ? (
              <button
                id={`personality_add_btn${
                  this.state.removeButton ? "" : " hide"
                }`}
                // show={this.state.removeButton}
                onClick={(index) => this.removeQueCategory(index)}
                type="button"
                className={"btn btn-primary btn-rounded m-t-sm"}
              >
                <i className="fa fa-minus fa-2x"></i>
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  renderQueCategoryForm() {
    var content = null;
    if (this.state.flagShowForm) {
      content = (
        <div
          name="personalityform"
          id="personalityform"
          style={{ marginTop: 0 }}
        >
          <h3 style={{ margin: "0" }}>Create Question Category</h3>
          {this.renderFields()}
        </div>
      );
    }
    return content;
  }
  render() {
    var content = null;
    content = (
      <div>
        {/*this.renderPersonalities()*/}
        {this.renderQueCategoryForm()}
      </div>
    );
    return content;
  }
}

class SeoSettingTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seoSeetingInfo: [],
      quiz: "",
    };
    this.getSeoSeetingValue = this.getSeoSeetingValue.bind(this);
  }
  componentWillReceiveProps() {
    var getQuiz = this.props.quiz;
    // getQuiz.privacy_policy = getQuiz.privacy_policy;
    this.setState({
      quiz: getQuiz,
      selectedCheckboxes: getQuiz.privacy_policy
        ? JSON.parse(getQuiz.privacy_policy)
        : [],
    });
    this.getSeoSeetingValue = this.getSeoSeetingValue.bind(this);
  }
  getSeoSeetingValue(event) {
    var seoSeetingInfo = this.state.seoSeetingInfo;
    seoSeetingInfo[event.target.name] = event.target.value;
    this.setState({
      seoSeetingInfo: seoSeetingInfo,
    });
    this.props.sendSeoSeetingData(this.state.seoSeetingInfo);
  }

  render() {
    var content = null;
    content = (
      <div>
        <div className="form-group">
          <div className="Sign-In-Label">Meta Title</div>
          <input
            type="text"
            onChange={this.getSeoSeetingValue}
            className="Sign-In-TextBox"
            name="meta_title"
            value={this.props.quiz.meta_title}
            placeholder="Meta Title"
          />
        </div>
        <div className="form-group">
          <div className="Sign-In-Label">Meta Description </div>
          <textarea
            className="form-control Sign-In-TextBox"
            name="meta_description"
            onChange={this.getSeoSeetingValue}
            placeholder="Meta Description"
            id="txtqzque"
            rows="3"
            value={this.props.quiz.meta_description}
          ></textarea>
        </div>
        <div className="form-group">
          <div className="Sign-In-Label">Google Analytics</div>
          <textarea
            className="form-control Sign-In-TextBox"
            placeholder="UA-123456"
            name="google_analytics"
            onChange={this.getSeoSeetingValue}
            id="txtqzque"
            rows="3"
            value={this.props.quiz.google_analytics}
          ></textarea>
        </div>
        <div className="form-group">
          <div className=" Sign-In-Label">
            CRM tag (Please ensure crm settings are configured and tags are
            configured in CRM system)
          </div>
          <textarea
            className="crm_text_block form-control Sign-In-TextBox"
            placeholder="CRM Tag"
            name="crm_tag"
            onChange={this.getSeoSeetingValue}
            id="txtqzque"
            rows="3"
            value={this.props.quiz.crm_tag}
          ></textarea>
        </div>
      </div>
    );
    return content;
  }
}
