/**
 * This file contains the code for showing question table.
 * This table is shown when user is creating or editing a quiz.
 */

import React from 'react';
import { checkNull } from '../helper';

function textWithoutHtml(str) {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.documentElement.textContent;
}

export class QueTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableItems: this.props.tableItems,
      tableTitle: this.props.tableTitle,
      quizid: this.props.quizid,
      queformActive: false,
      quiz: this.props.quiz,
      selectedQuePreview: null

    };
    this.onClickSaveDraft = this.onClickSaveDraft.bind(this);
    this.moveQue = this.moveQue.bind(this);
    this.onclickshowlogicbranchingform = this.onclickshowlogicbranchingform.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps['quiz'] != null) {
      var queArr = nextProps['quiz']['que'];

      var selectedQue = null;
      if (queArr != null) {
        selectedQue = queArr[0];
      }
      this.setState({ tableItems: nextProps['tableItems'], quizid: nextProps['quizid'], quiz: nextProps['quiz'], selectedQuePreview: selectedQue });
    }
  }


  onClickSaveDraft(event) {
    this.props.saveQuiz(event, this.state.quiz, true);
  }



  moveQue(event) {
    var type = event.target.getAttribute('data-value');
    //var row = $(event.target).closest('tr');
    var id = event.target.getAttribute('data-id');
    id = id - 1;
    var quiz = this.state.quiz;
    var queArr = quiz.que;

    if (type == "up") {
      if (id > 0) {
        var tempque = queArr[id - 1];
        queArr[id - 1] = queArr[id];;
        queArr[id] = tempque;
        //row.prev().before(row);

      }
    }
    else {
      if (id < this.state.quiz.que.length) {
        var tempque = queArr[id + 1];
        queArr[id + 1] = queArr[id];;
        queArr[id] = tempque;
        //row.next().after(row);
      }
    }
    quiz.que = queArr;

    this.setState({ quiz: quiz });
  }

  onclickshowlogicbranchingform(event) {
    //var questionCount = JSON.parse(this.state.selectedQuePreview.jsonans).length
    var id = event.target.getAttribute('data-id');
    
    var queid = id;//this.props.selectedQue.queid.toString();

    var quiz = this.state.quiz;
    var queArr = quiz.que;
    var selectedQue = queArr.filter(element=>element.queid == queid)[0];
    var jsonans = JSON.parse(selectedQue.jsonans);
    var questionCount = jsonans.length;//JSON.parse(this.state.selectedQuePreview.jsonans).length
    
    var logicalbranches = this.state.quiz.logicalbranches;


    if (logicalbranches == null) {
      logicalbranches = {};
    }
    if (logicalbranches[queid] == null) {
      logicalbranches[queid] = [];
      for (let i = 0; i < questionCount; i++) {
        logicalbranches[queid].push({ question: '',questionid:'', answer: '', nextquestion: '',nextquestionid:'' });
      }
    } else {
      logicalbranches[queid] = [];
      for (let i = 0; i < questionCount; i++) {
        logicalbranches[queid].push({ question: '',questionid:'', answer: '', nextquestion: '', nextquestionid:'' });
      }
    }

    //id = id - 1;
    //queArr[id].showlogicbranchingform = true;
    //queArr[id].addButton = false;
    selectedQue.showlogicbranchingform = true;
    selectedQue.addButton = false;
    


    quiz.que = queArr;
    quiz.logicalbranches = logicalbranches;
    this.setState({ quiz,logicalbranches });

  }

  // onQuestionChanged(event){
  //   var name = event.target.name;
  //   var index = event.target.getAttribute('data-id');
  //   var value = event.target.value;
  //   var {logicalbranches,quiz} = this.state;
  //   if(logicalbranches == null){
  //     logicalbranches = [];
  //     logicalbranches[index]={question:'',answer:'',nextquestion:''}
  //   }
  //   logicalbranches[index][name] = value;
  //   quiz.logicalbranches = logicalbranches;
  //   console.log('logicalbranches -- '+JSON.stringify(logicalbranches));
  //   this.setState({logicalbranches}, () => {
  //     this.props.updateQuiz(quiz);
  //   });

  // }

  // onAnswerChanged(event){
  //   var name = event.target.name;
  //   var index = event.target.getAttribute('data-id');
  //   var value = event.target.value;
  //   var {logicalbranches,quiz} = this.state;
  //   if(logicalbranches == null){
  //     logicalbranches = [];
  //     logicalbranches[index]={question:'',answer:'',nextquestion:''}
  //   }
  //   logicalbranches[index]['answer'] = value;
  //   quiz.logicalbranches = logicalbranches;
  //   console.log('logicalbranches -- '+JSON.stringify(logicalbranches));
  //   this.setState({logicalbranches}, () => {
  //     this.props.updateQuiz(quiz);
  //   });
  // }

  renderHeaders() {

    return this.state.tableItems.header.map(function (headerItem, counter) {
      return (
        <thead key={counter}>
          <tr className="black-muted-bg sortable">
            <th className="text-left hide">{headerItem[0]}</th>
            <th className="text-left" width="60%"><h5>{headerItem[1]}</h5></th>
            {/* <th className="text-center"><h5>{headerItem[3]}</h5></th> */}
          </tr>
        </thead>
      );
    }, this)
  }

  renderAction(queid, index) {
    return (<div className='btn-group' data-id={queid}>
      {!this.state.quiz.islogicbranchingenabled ? (
        <>
      <span style={{ margin: 5, cursor: 'pointer' }}><i onClick={this.props.editQue} className='fa fa-edit fa-x cursor' data-id={queid}></i></span>
      <span type="button" style={{ margin: 5, cursor: 'pointer' }} onClick={this.props.delQue} className='' title='Delete Question' data-original-title='Delete Question' data-toggle='tooltip' data-placement='top' data-id={queid}><i className='fa fa-trash fa-x cursor' data-id={queid}></i></span>
      </>
      ):('')}
      {this.state.quiz.islogicbranchingenabled == 1 ? <span type="button" style={{ margin: 5, cursor: 'pointer' }} onClick={this.onclickshowlogicbranchingform} className='' title='Branch Question' data-original-title='Branch Question' data-toggle='tooltip' data-placement='top' data-id={queid}><i className='fa fa-code-branch fa-x cursor' data-id={queid}></i></span> : null}
    </div>
    );

  }

  // renderQueForLogicalBranching(name,branchindex){
  //   var self = this;
  //   var queArr = null;
  //   var content=null;
  //   var questions = null;
  //   if (this.state.quiz != null && this.state.quiz.que) {
  //     queArr = this.state.quiz.que;
  //   }
  //   if (queArr != null && queArr.length > 0) {
  //     questions =  queArr.map(function (que, counter) {
  //       return (
  //         <option
  //           key={counter}
  //           value={que.title}
  //           selected={
  //             self.state.logicalbranches != null &&
  //             self.state.logicalbranches[counter] != null &&
  //             self.state.logicalbranches[counter].question == que.title
  //               ? true
  //               : false
  //           }
  //         >
  //           {que.title}
  //         </option>
  //       );
  //     });

  //   } 
  //   content = <select tabIndex="-98"
  //     title={name}
  //     style={{ marginTop: 8 }}
  //     className="form-control Sign-In-TextBox personalitySelect"
  //     selectpicker=""
  //     value={
  //       this.state.logicalbranches != null &&
  //       this.state.logicalbranches[branchindex] != null
  //         ? this.state.logicalbranches[branchindex][name]
  //         : null
  //     }
  //     onChange={this.onQuestionChanged}
  //     data-id={branchindex}
  //     name={name}
  //   >
  //     <option value="-1">Select a question</option>
  //     {questions}
  //     {name=='nextquestion'?<option value='landingpage'>landing page</option>:null}
  //   </select>
  //   return content;
  // }

  // renderLogicalBranch(name,branchindex){
  //   var self = this;
  //   var queArr = null;
  //   var content=null;
  //   var questions = null;
  //   if (this.state.quiz != null && this.state.quiz.que) {
  //     queArr = this.state.quiz.que;
  //   }
  //   if (queArr != null && queArr.length > 0) {
  //     questions =  queArr.map(function (que, counter) {
  //       return (
  //         <option
  //           key={counter}
  //           value={que.title}
  //           selected={
  //             self.state.logicalbranches != null &&
  //             self.state.logicalbranches[counter] != null &&
  //             self.state.logicalbranches[counter].question == que.title
  //               ? true
  //               : false
  //           }
  //         >
  //           {que.title}
  //         </option>
  //       );
  //     });

  //   } 
  //   content = <select tabIndex="-98"
  //     title={name}
  //     style={{ marginTop: 8 }}
  //     className="form-control Sign-In-TextBox personalitySelect"
  //     selectpicker=""
  //     value={
  //       this.state.logicalbranches != null &&
  //       this.state.logicalbranches[branchindex] != null
  //         ? this.state.logicalbranches[branchindex][name]
  //         : null
  //     }
  //     onChange={this.onQuestionChanged}
  //     data-id={branchindex}
  //     name={name}
  //   >
  //     <option value="-1">Select a question</option>
  //     {questions}
  //     {name=='nextquestion'?<option value='landingpage'>landing page</option>:null}
  //   </select>
  //   return content;
  // }
  

  renderQues() {
    var self = this;
    if (this.state.quiz == null || this.state.quiz.que == null || this.state.quiz.que.length == 0) {
      return (
        <tr>
          <td>Let's get started with your first question!</td>
        </tr>
      );
    }
    else {
      //var quekeys = Object.keys(this.state.quiz.que);
      var quevalues = Object.values(this.state.quiz.que);
      return quevalues.map(function (rowItem, index) {
        //var queid= rowItem['queid'];
        var queid = rowItem['queid'];
        var quetitle = textWithoutHtml(rowItem['title']);
        var filteredque = quevalues.filter(element => element.queid != queid);
        var anscount = ((JSON.parse(rowItem['jsonans'])).filter(checkNull)).length;
        return (
          <tr className="col-md-12 no-top-border hover-table-row question-wrapper" style={{ cursor: 'unset', height: this.state.quiz.islogicbranchingenabled ? 'auto' : '', paddingBottom: this.state.quiz.logicalbranches != null && this.state.quiz.logicalbranches[index + 1] ? '20px' : '0px' }} onMouseOver={this.props.handleQuePreview} data-id={queid} key={index}>
            <td className='hide' data-id={queid}>{queid}</td>
            <td className='col-md-12 text-left ' data-id={queid} style={{ wordBreak: "break-word", wordWrap: "break-word", verticalAlign: 'middle' }}>
              <span type="button" style={{ cursor: 'unset' }} className='' title='Edit Question' data-original-title='Edit Question' data-toggle='tooltip' data-placement='top' data-id={queid}>
                {quetitle}
              </span>
            </td>
            <td className="quiz-question-list-action text-left">
              <div className='col-md-3' data-id={queid}>
                {this.renderAction(queid, index)}
              </div>
            </td>

            <tr id='logic-branching-wrapper' className={`${rowItem.showlogicbranchingform == true ? null : 'hide'}`}>
              <LogicBranchingForm updateQuiz={this.props.updateQuiz} quiz={this.state.quiz} selectedqueid={queid} selectedque={this.state.quiz && this.state.quiz.que && this.state.quiz.que[queid - 1] ? this.state.quiz.que[queid - 1] : null}></LogicBranchingForm>
            </tr>


          </tr>
        );

      }, this)
    }




  }

  // renderAnswers(name,branchindex){
  //   var self = this;
  //   var queArr = null;
  //   var content=null;
  //   var que = this.state.logicalbranches?this.state.logicalbranches[branchindex].question:null;
  //   var answers = null;
  //   if (this.state.quiz != null && this.state.quiz.que) {
  //     queArr = this.state.quiz.que;
  //   }
  //   if (queArr != null && queArr.length > 0) {
  //     var filteredquestion = queArr.filter(element=>element.title==que);
  //     if(filteredquestion && filteredquestion.length > 0) {
  //       var jsonans = filteredquestion[0].jsonans;
  //       var ans = JSON.parse(jsonans);
  //       answers =  ans.map(function (element, counter) {
  //         return (
  //           <option
  //             key={counter}
  //             value={element}
  //             selected={
  //               self.state.logicalbranches != null &&
  //               self.state.logicalbranches[counter] != null &&
  //               self.state.logicalbranches[counter].answer == element
  //                 ? true
  //                 : false
  //             }
  //           >
  //             {element}
  //           </option>
  //         );
  //       });


  //     }

  //   } 
  //   content = <select tabIndex="-98"
  //       title={name}
  //       style={{ marginTop: 8 }}
  //       className="form-control Sign-In-TextBox personalitySelect"
  //       selectpicker=""
  //       value={
  //         this.state.logicalbranches != null &&
  //         this.state.logicalbranches[branchindex] != null
  //           ? this.state.logicalbranches[branchindex].answer
  //           : null
  //       }
  //       onChange={this.onAnswerChanged}
  //       data-id={branchindex}
  //       name={name}
  //     >
  //       <option value="-1">Select an answer</option>
  //       <option value="any">Any answer</option>
  //       {answers}
  //     </select>
  //   return content;

  // }


  render() {
    return (
      <div className="row">
        <div className="qzpanel-blur with-scroll table-qzpanel animated zoomIn quize_edit_block">
          <div className="">
            <div className="qzpanel-title">
              {/* <h3>{this.state.tableTitle}</h3> */}
              <h5>Question Count: {(this.state.quiz == null || this.state.quiz.que == null) ? 0 : this.state.quiz.que.length}</h5>
            </div>
          </div>
          <div className="qzpanel-body" >
            <div className="horizontal-scroll">
              <table className="table table-striped table-editbutton">
                {this.renderHeaders()}
                <tbody>
                  {this.renderQues()}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row col-md-12">
            <nav>
              <ul className="pager ba-wizard-pager">
                <li className="previous">
                {!this.state.quiz.islogicbranchingenabled ? (
                  <button className="btn-normal" onClick={this.props.showQueForm} data-id={this.state.quizid} data-draft={false} style={{ width: 150 }}>
                    Add A Question
                  </button>
                ):('')}
                </li>
                {/* <li className="next">
                      <button className="btn btn-primary" type="button" onClick={this.onClickSaveDraft} data-id={this.state.quizid} data-draft={true}>
                        Save as Draft
                      </button>
                    </li> */}
              </ul>
            </nav>
          </div>
        </div>

      </div>
    );
  }
}

class LogicBranchingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz: this.props.quiz,
      logicalbranches: this.props.quiz ? this.props.quiz.logicalbranches : {},
      selectedqueid: this.props.selectedqueid,
      addButton: true,
      removeButton: false,
      selectedque: this.props.selectedque
    };
    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.onQuestionChanged = this.onQuestionChanged.bind(this);
    //this.onAnswerChanged = this.onAnswerChanged.bind(this);
    this.addBranch = this.addBranch.bind(this);

    this.onSave = this.onSave.bind(this);
  }
  componentDidMount() {
    console.log('inside componentdidmount selected queid is '+this.state.selectedqueid )
     
  }

  componentWillReceiveProps(nextProps) {
    var quiz = nextProps.quiz;
    var selectedque = nextProps.selectedque;
    var queArr = quiz.que;

    var logicalbranches = quiz != null ? quiz.logicalbranches : {};

    if (logicalbranches && queArr && queArr.length > 0) {
      queArr = queArr.map((element, index) => {
        if (logicalbranches[element.queid]) {
          element.showlogicbranchingform = true
        }
        return element;
      });
    }
    quiz.que = queArr;
    this.setState({ logicalbranches, quiz, selectedque });
  }

  addBranch(event) {
    var index = event.currentTarget.getAttribute('data-id');
    var queid = this.state.selectedque.queid.toString();
    var addButton = true;
    // get current personality
    let logicalbranches = this.state.logicalbranches
    if (logicalbranches == null) {
      logicalbranches = {};
    }
    if (logicalbranches[queid] == null) {
      logicalbranches[queid] = [];
    }
    logicalbranches[queid].push({ question: '', questionid:'', answer: '', nextquestion: '', nextquestionid:'' });


    //var filteredbranches =  logicalbranches[queid].filter(element=>element!=null);
    this.setState({ logicalbranches, addButton });
  }


  onFieldChanged(event) {
    var quiz = this.state.quiz;
    if (quiz == null) {
      quiz = {};
      quiz.personalityArr = [];
    }
    if (quiz.personalityArr == null) {
      quiz.personalityArr = [];
    }
    var dataid = event.target.getAttribute("data-id");
    var name = event.target.name;
    var datavalue = event.target.value;
    if (quiz.personalityArr[dataid] == null) {
      quiz.personalityArr[dataid] = {};
    }
    quiz.personalityArr[dataid][name] = datavalue;
    quiz.quizmetadata = JSON.stringify(quiz.personalityArr);
    this.setState({ quiz }, () => {
      this.props.updateQuiz(quiz);
    });
  }

  onQuestionChanged(event) {
    var name = event.target.name;
    var index = event.target.getAttribute('data-id');
    // get the index of the option / nextquestion from the selected list.
    var optionindex = event.target.selectedIndex;
    // get the object
    var el = event.target.childNodes[optionindex];
    var ans = null;
    //get the string value / title of the nextquestion
    var value = event.target.value;//jsonobject.title;
    //get the questionid of the nextquestion.
    var nextquestionid = el.getAttribute('data-row')//;
    
    var queid = this.state.selectedqueid;
    var selectedque = this.state.quiz.que.filter(element=>element.queid==queid)[0];
    var { logicalbranches, quiz } = this.state;
    if (logicalbranches == null) {
      logicalbranches = [];
      logicalbranches[queid] = [];
      logicalbranches[queid][index] = { question: '', questionid:'', answer: '', nextquestion: '', nextquestionid:'' }
    }
    if (logicalbranches[queid] == null) {
      logicalbranches[queid] = [];
      logicalbranches[queid][index] = { question: '', questionid:'', answer: '', nextquestion: '', nextquestionid:'' }
    }
    if (logicalbranches[queid][index] == null) {
      logicalbranches[queid][index] = { question: '', questionid:'', answer: '', nextquestion: '', nextquestionid:'' }
    }
    logicalbranches[queid][index][name] = value;
    logicalbranches[queid][index]['nextquestionid'] = nextquestionid;
    logicalbranches[queid][index]['questionid'] = queid;
    logicalbranches[queid][index]['question'] = selectedque.title;
    
    var jsonans = selectedque.jsonans;
    ans = JSON.parse(jsonans);
    logicalbranches[queid][index]['answer'] = ans[index];


    quiz.logicalbranches = logicalbranches;
    this.setState({ logicalbranches }, () => {
      this.props.updateQuiz(quiz);
    });

  }

  // onAnswerChanged(event) {
  //   var index = event.target.getAttribute('data-id');
  //   var value = event.target.value;
  //   var queid = this.state.selectedque.queid;
  //   var { logicalbranches, quiz } = this.state;
  //   if (logicalbranches == null) {
  //     logicalbranches = [];
  //     logicalbranches[queid] = [];
  //     logicalbranches[queid][index] = { question: '', answer: '', nextquestion: '', nextquestionid:'' }
  //   }
  //   if (logicalbranches[queid] == null) {
  //     logicalbranches[queid] = [];
  //     logicalbranches[queid][index] = { question: '', answer: '', nextquestion: '',nextquestionid:'' }
  //   }
  //   if (logicalbranches[queid][index] == null) {
  //     logicalbranches[queid][index] = { question: '', answer: '', nextquestion: '',nextquestionid:'' }
  //   }

  //   logicalbranches[queid][index]['answer'] = value;
  //   logicalbranches[queid][index]['question'] = this.state.selectedque.title;

  //   quiz.logicalbranches = logicalbranches;
  //   this.setState({ logicalbranches }, () => {
  //     this.props.updateQuiz(quiz);
  //   });
  // }


  onSave(event) {
    var quiz = this.state.quiz;
    this.props.saveQuiz(quiz);

  }


  renderAnswers(name, branchindex) {
    var self = this;
    var queArr = null;
    var content = null;
    var {selectedqueid} = this.state
    //var que = this.state.selectedque;
    var answers = null;
    if (this.state.quiz != null && this.state.quiz.que) {
      queArr = this.state.quiz.que;
    }
    var ans = '';
    if (queArr != null && queArr.length > 0) {
      var filteredquestion = queArr.filter(element => element.queid == selectedqueid);
      if (filteredquestion && filteredquestion.length > 0) {
        var jsonans = filteredquestion[0].jsonans;
         ans = JSON.parse(jsonans);
        
      }
    }
    content = <div className='col-md-12'>
    <label><span>{branchindex + 1}.</span> {decodeURIComponent(ans[branchindex])}</label>
    </div>
    return content;

  }

  renderQuestions(name, branchindex) {
    var self = this;
    var queArr = null;
    var content = null;
    var questions = null;
    var { selectedqueid } = this.state;
    

    if (this.state.quiz != null && this.state.quiz.que) {
      queArr = [...this.state.quiz.que];
      queArr = queArr.filter(element => element.queid != selectedqueid);
    }
    if (queArr != null && queArr.length > 0) {
      questions = queArr.map(function (que, counter) {
        return (
          <option
            key={counter}
            data-row={que.queid}
            value={que.title}
            selected={
              self.state.logicalbranches != null && self.state.logicalbranches[selectedqueid] &&
                self.state.logicalbranches[selectedqueid][branchindex] != null &&
                self.state.logicalbranches[selectedqueid][branchindex].nextquestion == que.title && 
                self.state.logicalbranches[selectedqueid][branchindex].nextquestionid == que.queid
                ? true
                : false
            }
          >
            {textWithoutHtml(que.title)}
          </option>
        );
      });
      content = <div className='col-md-12'><select tabIndex="-98"
        title={name}
        style={{ marginTop: 8 }}
        className="form-control Sign-In-TextBox personalitySelect"
        selectpicker=""
        value={
          this.state.logicalbranches != null && this.state.logicalbranches[selectedqueid] != null &&
            this.state.logicalbranches[selectedqueid][branchindex] != null
            ? this.state.logicalbranches[selectedqueid][branchindex][name]
            : null
        }
        onChange={this.onQuestionChanged}
        data-id={branchindex}
        name={name}
      >
        <option value="-1">Redirect to</option>
        {questions}
        {name == 'nextquestion' ? <option value='landingpage'>landing page</option> : null}
      </select></div>
    }
    return content;
  }
  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }


  renderBranches() {

    var logicalbranches = this.state.logicalbranches;//this.state.quiz != null && this.state.quiz.personalityArr != null && this.state.quiz.personalityArr.length > 0 ? this.state.quiz.personalityArr : this.state.personalitiesInputs;
     var self = this;
     //var selectedque = this.state.selectedque;
     var queid = this.state.selectedqueid;//selectedque ? selectedque.queid : '';
     var checkIsJson = this.isJsonString(this.state.logicalbranches);
     if(checkIsJson) {
      logicalbranches = JSON.parse(logicalbranches);
     }
     
    return (
      <div className='personality-input-for-logic-branchin'>
        <h5>If selected answer is</h5>
        {logicalbranches && logicalbranches[queid] ? (
          <div>
            {logicalbranches[queid].map((branch, index) => {
              branch.addButton = true;
              branch.removeButton = true;
              return (
                <div className="personality_input_item" key={index}>
                  <div className='col-md-12'>
                    <div className='row ans-question-wrapper-logic-b'>
                      <div className='row'>
                      {self.renderAnswers('answer', index)}  {self.renderQuestions('nextquestion', index)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : ''}
      </div>
    );
  }

  renderBranchingForm() {
    var content = null;
    content = (
      <div name="brachingform" id="branching" style={{ marginTop: 0 }}>
        {this.renderBranches()}
      </div>
    );


    return content;
  }
  render() {
    var content = null;
    content = <div>
      {this.state.logicalbranches ? (
      <>
      {this.renderBranchingForm()}
      </>
      ):''}
    </div>
    return content;
  }
}
