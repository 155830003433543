import React from "react";
import ReactTooltip from "react-tooltip";

export class PoolShare extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedShareIcon: 'iframe',
            copyText: "Copy",
        };
        this.onChangeShareIcon = this.onChangeShareIcon.bind(this)
    }
    onChangeShareIcon(e) {
        var selectedShareIcon = e.target.getAttribute('name');
        var self = this;
        this.setState({ selectedShareIcon });
    }
    render() {
        var content = null;
        if (this.props != null && this.props.match != null && this.props.match.params != null && this.props.match.params.id != null) {
            let pool_id = this.props.match.params.id;
            let pool_slug = this.props.match.params.slug;
            var poolPath = this.props.localpath + "poll/publish/show/" + pool_id + '/'+pool_slug;
            var scriptsrc = "https://api.quizzes4leads.com/pollWidget.js";
            if (process.env.REACT_APP_ENV === 'stage') {
                scriptsrc = "https://api.quizzes4leads.com/poll_widget_stage.js"
            }
              
              
            

            var iframecode = "<iframe width='713' height='419' src='" + poolPath + "' frameborder='0' allowfullscreen></iframe>";
            if (this.state.selectedShareIcon == "iframe") {
                content = <textarea className="form-control Sign-In-TextBox" readOnly value={iframecode} style={{ height: 100 }}></textarea>;
            } else if (this.state.selectedShareIcon == "widget") {
                var pollPath = this.props.localpath + "poll/publish/show/" + pool_id + '/'+pool_slug;
                var pollWidget = `<script type="text/javascript">
        
                (function()
                {
                    var script = document.createElement("script");
                    script.type = "text/javascript";
                    script.src="${scriptsrc}";
                    var pollLink = "${pollPath}";
                    var pollId = "${pool_id}";
                    var pollSlug = "${pool_slug}";
                    var charsetmetatag = document.createElement('meta');
                    charsetmetatag.setAttribute("charset", "UTF-8");
                    document.getElementsByTagName("head")[0].appendChild(charsetmetatag);
                    document.getElementsByTagName("head")[0].appendChild(script);
                    if(script.readyState){
                        script.onreadystatechange = function(){
                            if(script.readyState==='loaded' || script.readyState==='complete'){
                                script.onreadystatechange = null;
                                init(pollLink,pollId,pollSlug);
                            }
                        }
                    }
                    else {  //Others
                    script.onload = function() {
                      init(pollLink,pollId,pollSlug);
                    };
                  } 
                })();
            
              </script>`;
                navigator.clipboard.writeText(pollWidget)
                content = <textarea className="form-control Sign-In-TextBox" readOnly value={pollWidget} style={{ height: 100,overflowY: 'scroll' }}></textarea>;
            }
        }



        return (
            <div>
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <div id="quizpanel" className="qzpanel qzpanel-blur">
                            <div className="panel-heading text-center" style={{ backgroundColor: "#5d13e7" }}>
                                <h2 style={{ color: "#fff" }}>Your Poll Is Ready</h2>
                            </div>
                            <div className="panel-body">

                                <div className="form-group">
                                    <div className="col-md-12 icon_block" style={{ marginBottom: 30 }}>
                                        <div data-tip="Poll Iframe, copy and Paste this inside html, Where you want to show." name="iframe" className={`col-md-2 text-center share-icon ${this.state.selectedShareIcon == "iframe" ? 'active' : ''}`} onClick={this.onChangeShareIcon}>
                                            <i name="iframe" className="fa fa-globe fa-3x"></i>
                                        </div>
                                        <ReactTooltip />
                                        <div data-tip="Poll Widget, copy and Paste this inside your website header file." name="widget" className={`col-md-3 text-center share-icon ${this.state.selectedShareIcon == "widget" ? 'active' : ''}`} onClick={this.onChangeShareIcon}><i name="widget" class="fa fa-code fa-3x" aria-hidden="true"></i></div>

                                    </div>
                                </div>
                                <hr />
                                <div className="form-group">
                                    <div className="col-md-12">
                                        {content}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <button style={{ marginRight: '15px' }} className="btn-normal copy-btn" onClick={this.onClose}>{this.state.copyText}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default PoolShare;
