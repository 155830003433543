import React from "react";
import * as DOMPurify from "dompurify";
import { NotificationManager } from "react-notifications";


class QuizThemeOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz_title: this.props.quiz_title,
      content: this.props.content,
      header_logo: this.props.header_logo,
      quiz: this.props.quiz,
      quiz_footer: this.props.quiz_footer,
      theme_color: this.props.theme_color,
      theme: this.props.theme,
      user: this.props.user,
    };
    //TODO: No need to call this getprofile instead use props to provide company logo
    //this.getUserProfile();
  }
  componentWillReceiveProps(props) {
    this.state = {
      quiz_title: props.quiz_title,
      content: props.content,
      header_logo: props.header_logo,
      quiz: props.quiz,
      quiz_footer: props.quiz_footer,
      theme_color: this.props.theme_color,
      theme: this.props.theme,
    };
    //this.getUserProfile();
  }

  // handleLanguage = (childData) =>{
  //   this.setState({picker_color: childData})
  //   console.log("from state",childData)
  // }
  getUserProfile() {
    var self = this;
    var dataurl = self.props.basepath + "user/getprofile";
    fetch(dataurl, {
      method: "get",
      credentials: "include",
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      if (response.status == true) {
        self.setState({
          profiledata: response.data,
          header_logo: response.data.companylogo,
        });
      } else {
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }
  render() {
    let theme_color_code = "";
    if (this.props.theme_color_code) {
      theme_color_code = JSON.parse(this.props.theme_color_code);
    }
    var href =
      this.state.quiz == null ||
      this.state.quiz.compurl == null ||
      this.state.quiz.compurl == ""
        ? "https://www.quizzes4leads.com"
        : this.state.quiz.compurl;
    return (
      <div>
        <div
          className={`panel panel-blur animated slideIn ${
            this.props.theme_color == null || this.props.theme_color == null
              ? "theme1"
              : this.props.theme_color
          }`}
          style={{ margin: 0, border: 0 }}
        >
          {this.props.is_enable_quiz_title == true ? (
            <div
              className="panel-heading theme1"
              style={{
                background: this.props.header_bg
                  ? this.props.header_bg.hex
                  : theme_color_code
                  ? theme_color_code[0].header_bg
                  : "",
              }}
            >
              <div className="row ql-editor">
                <div
                  className="col-md-10 col-sm-10 col-xs-10"
                  style={{
                    fontWeight: "bold",
                    wordBreak: "break-word",
                    color: this.props.header_text
                      ? this.props.header_text.hex
                      : theme_color_code
                      ? theme_color_code[1].header_text
                      : "",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      this.state.quiz_title == null ||
                        this.state.quiz_title == ""
                        ? "Enter Quiz Title"
                        : this.state.quiz_title
                    ),
                  }}
                ></div>

                <div className="col-md-2 col-sm-2 col-xs-2">
                  {/* <ColorPicker onSelectLanguage={this.handleLanguage}/> */}
                  {this.props.is_company_logo ? (
                    <img
                      src={
                        this.state.header_logo == null ||
                        this.state.header_logo == ""
                          ? "https://app.quizzes4leads.com/assets/img/q4llogo.png"
                          : this.state.header_logo
                      }
                      className="glyphicon img-responsive pull-right vcenter"
                      style={{ minWidth: "25px", height: "25px" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            className={`panel-body ${
              this.props.bgimg ? "remove-bg-colors" : ""
            }`}
            id="quizcontent"
            ref={(el) => {
              if (el) {
                el.style.setProperty(
                  "background-color",
                  this.props.header_content_bg
                    ? this.props.header_content_bg.hex
                    : theme_color_code
                    ? theme_color_code[2].header_content_bg
                    : "",
                  "important"
                );
              }
            }}
          >
            <div className="m-t-md qzwcmsg">
              <div style={{ fontSize: "medium", overflowWrap: "break-word" }}>
                {this.state.content}
              </div>
            </div>
          </div>
          {this.state.user != null && this.state.user.mode == "free" ? (
            <div
              className="panel-footer"
              style={{
                background: this.props.footer_bg
                  ? this.props.footer_bg.hex
                  : theme_color_code
                  ? theme_color_code[4].footer_bg
                  : "",
              }}
            >
              <a
                id="btnqzcreate"
                target="_blank"
                href="https://app.quizzes4leads.com/app/Templates"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      this.props.footer_text_color
                        ? this.props.footer_text_color.hex
                        : theme_color_code
                        ? theme_color_code[5].footer_text_color
                        : "",
                      "important"
                    );
                  }
                }}
              >
                Create Your Free Quiz
              </a>
              <a
                className="pull-right"
                id="qzfooter"
                style={{ paddingTop: "" }}
                href={href}
                target="_blank"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      this.props.footer_text_color
                        ? this.props.footer_text_color.hex
                        : theme_color_code
                        ? theme_color_code[5].footer_text_color
                        : "",
                      "important"
                    );
                  }
                }}
              >
                {this.state.quiz_footer != null && this.state.quiz_footer != ""
                  ? this.state.quiz_footer
                  : "Powered By Quizzes4Leads"}
              </a>
            </div>
          ) : (
            <div
              className="panel-footer"
              style={{
                background: this.props.footer_bg
                  ? this.props.footer_bg.hex
                  : theme_color_code
                  ? theme_color_code[4].footer_bg
                  : "",
              }}
            >
              <a
                id="btnqzcreate"
                target="_blank"
                href="https://app.quizzes4leads.com/app/Templates"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      this.props.footer_text_color
                        ? this.props.footer_text_color.hex
                        : theme_color_code
                        ? theme_color_code[5].footer_text_color
                        : "",
                      "important"
                    );
                  }
                }}
              ></a>
              <a
                className="pull-right"
                id="qzfooter"
                style={{ paddingTop: "" }}
                href={href}
                target="_blank"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      this.props.footer_text_color
                        ? this.props.footer_text_color.hex
                        : theme_color_code
                        ? theme_color_code[5].footer_text_color
                        : "",
                      "important"
                    );
                  }
                }}
              ></a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default QuizThemeOne;
