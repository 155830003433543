/*
This file contains the view which is shown after user is successfully loggedin.
This is the workspace of user where he can interact with app to create quiz for eg.
*/

import React from 'react';
import { tableItemsArr, cardItemsArr, navItemsArr, tabItemArr, pageItemsArr } from './global'
import { NotificationContainer } from 'react-notifications';
import { Link, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { InvoiceTable } from './components/invoicetable';
import { QuizTable } from './components/quiztable';
import { PlanForm } from './forms/planform';
import { UserSettingForm } from './forms/usersettingform';
import { AnswerSheetForm } from './forms/answersheetform';

import { SettingForm } from './forms/settingform';
import { QuizShareForm } from './forms/quizshareform';
import { QuizReportForm } from './forms/quizreportform';
import { QuizEditForm } from './forms/quizeditform';
import { Tutorials } from './forms/tutorials';
import { Templates } from './forms/templates';
import { Feedbacks } from './forms/feedback';
import { FeedbackLists } from './forms/feedbacklists';
import { FeedbackEdit } from './forms/feedbackEdit';
import { FeedbackPreview } from './forms/feedbackPreview';
import { FeedbackShare } from './forms/feedbackShare';
import { FeedbackResponse } from './forms/feedbackResponse';
import { Admin } from './Admin';
import { ContactUs } from './forms/contactus';

//import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';

import { AppDashboard } from './appdashboard';
import { ReferFriend } from './components/referfriend';
import PoolLists from './forms/pool/poolList';
import PoolCreate from './forms/pool/poolCreate';
import PoolShare from './forms/pool/poolShare';
import PoolEdit from './forms/pool/poolEdit';
import PoolVotes from './forms/pool/pollVotes';
import WheelSpinnerList from './forms/wheelspinner/wheel_spinner_list';
import WheelSpinnerCreate from './forms/wheelspinner/wheel_spinner_create';
import WheelSpinnerEdit from './forms/wheelspinner/wheel_spinner_edit';
import WheelSpinShare from './forms/wheelspinner/WheelSpinShare';
import WheelSpinResponse from './forms/wheelspinner/wheelSpinResponse';

// this class will be responsible for creating the side-nav-bar
class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = { navItems: navItemsArr, selectedNav: "Dashboard", collapsed: this.props.collapsed };
    this.onNavItemClick = this.onNavItemClick.bind(this);
  }


  componentWillReceiveProps(nextProps) {
    this.setState({ collapsed: nextProps.collapsed, selectedNav: nextProps.selectedNav });
  }

  componentDidMount() {
    if (this.props.location != null && this.props.location.pathname != null) {
      var patharr = this.props.location.pathname.split("/app");
      if (patharr != null && patharr.length > 1 && patharr[1] != "/") {
        var tempnav = patharr[1].split("/");
        if (tempnav != null && tempnav.length > 1) {
          this.setState({ selectedNav: tempnav[1] });
        }
      }
    }
  }

  onNavItemClick(e) {
    var dashtype = e.target.getAttribute("data-type");
    var navlabel = e.target.getAttribute('data-label');
    this.setState({ selectedNav: navlabel });

  }
  isSelected(navItem) {
    //return this.props.location.pathname === navItem.pathname ? 'active' : null;
    return this.state.selectedNav == navItem.label ? 'active' : null;
  }


  renderLinks() {
    return this.state.navItems.map(function (navItem, index) {
      return (
        <li className={`sidebar-list-item ${(this.isSelected(navItem))} ${navItem.label}`} key={index}
          onClick={this.onNavItemClick}>
          <Link className={`sidebar-list-link`} to={navItem.url} data-url={navItem.url} data-type={navItem.type} data-label={navItem.label}>
            <i className={`fa fa-${navItem.icon} fa-2x`} data-type={navItem.type} data-label={navItem.label}></i>
            <span data-url={navItem.url} data-type={navItem.type} data-label={navItem.label}>{navItem.label}</span>
          </Link>

        </li>
      );
    }, this);

  }

  render() {
    return (
      // <div className={`sidebar ${this.state.collapsed?'collapsed':''}`}>
      <div className={`sidebar collapsed`}>
        <ul className="sidebar-list">
          {this.renderLinks()}
        </ul>

      </div>
    );
  }
}

//this class will be responsible for the page header which consists of profile button
class PageTop extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false, isHelpMenuOpen: false, isMenuOpe: false };


  }


  onToggleMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }
  onToggleMen() {
    this.setState({ isMenuOpe: !this.state.isMenuOpe });
  }
  onToggleHelpMenu() {
    this.setState({ isHelpMenuOpen: !this.state.isHelpMenuOpen });
  }
  
 

  renderQuizCreateButton() {
  return(
    <div className="web-response" style={{ display: 'none' }}>
    <div className={`user-profile-block dropdown ${this.state.isMenuOpe ? 'open' : ''}`}>
   
      <a className="profile-toggle-link dropdown-toggle web-toggle" style={{textDecoration: 'none',color: 'white',fontSize: 15 }} onClick={this.onToggleMen.bind(this)}>
      Create<i className="fa fa-angle-down"></i>
            
      </a>
     
      <ul className="top-dropdown-menu profile-dropdown dropdown-menu">
      <span className="triangle"></span>
        <li> <Link to="/app/Templates" >Quiz
        <span>A Modern Lead Generation Tool</span></Link>
        </li>
        <li><Link to="/app/Feedback" style={{textDecoration: 'none' }}>Web Response
        <span>An Instant Visual Web Feedback.</span></Link>
        </li>
        <li><Link to="/app/poll/create" style={{textDecoration: 'none' }}>Poll Create
        <span>Create Awesome Poll. .</span></Link>
        </li>
        {/* <li><Link to="/app/wheel_spinner/create" style={{textDecoration: 'none' }}>Wheel Spinner
        <span>Create Wheel Spinner.</span></Link>
        </li>  */}
      </ul>
    </div>
  </div >
    );
    
  }


  renderLogo() {
    return (
      <a href={this.props.logourl} className="al-logo clearfix"><img src="/assets/img/q4llogo.png" style={{ height: 40 }} />Quizzes4<span>Leads</span></a>
    );
  }

  renderHamburgerMenu() {
    return (
      <div>
      
      <a className="collapse-menu-link" href="#" style={{ height: 50, width: 50, marginLeft: -6 }} onClick={this.props.toggleSideBar}>
        <i className="fa fa-bars fa-2x"></i>
      </a>
      
      <Link to="/app/contactus" className="header-tolbar">Need some help building your quiz? Don't worry our awesome team is just an email away, write to us at <span>contact@quizzes4leads.com.</span></Link>
      
      </div>
    );
  }

  renderHelpButton() {
    return (
      <div className="helpbutton" style={{ display: 'none' }}>
        <div className={`user-profile-block dropdown ${this.state.isHelpMenuOpen ? 'open' : ''}`}>
       
          <a className="profile-toggle-link dropdown-toggle" onClick={this.onToggleHelpMenu.bind(this)}>
            <i className="fa fa-question-circle fa-2x"></i>
          </a>
         
          <ul className="top-dropdown-menu profile-dropdown dropdown-menu">
            <li><Link to="/app/contactus"><i className="fa fa-envelope-o" style={{ marginRight: 10 }}></i>Contact Us</Link></li>
          </ul>
        </div>
      </div >
    );
  }

  renderUserSection() {
    return (
      <div className="user-profile clearfix">
        {this.renderHelpButton()}
        {this.renderQuizCreateButton()}
        <div className={`user-profile-block dropdown ${this.state.isMenuOpen ? 'open' : ''}`}>
          <a className="profile-toggle-link dropdown-toggle" onClick={this.onToggleMenu.bind(this)}>
            <img className="img-responsive" src={this.props.user && this.props.user.picture ? this.props.user.picture : '/assets/img/user.png'} />
          </a>
          <ul className="top-dropdown-menu profile-dropdown dropdown-menu">
           

            <li><Link to="/app/Account"><span><i className="fa fa-user" aria-hidden="true"></i></span> Profile Setting</Link></li>
            <li><Link to="/app/refer-friend"><span><i className="fa fa-user-plus" aria-hidden="true"></i></span> Refer Friend</Link></li>
            <li><a className="signout" href="#" onClick={this.props.logout}><i className="fa fa-power-off" style={{ marginRight: 10 }}></i>Sign out</a></li>
          </ul>

        </div>
      </div>
    );
  }

  /*
  
  <ul className="top-dropdown-menu profile-dropdown dropdown-menu">
            <li><i className="dropdown-arr"></i></li>
            <li><a href="/"><i className="fa fa-user"></i>Profile</a></li>
            <li><a href="/"><i className="fa fa-cog"></i>Settings</a></li>
            <li>
              <a className="signout" onClick={this.props.logout}>
                <i className="fa fa-power-off"></i>Sign out
              </a>
            </li>
          </ul>
  */

  render() {
    // dropdown - .open
    // @todo msg-center
    // onClick startSearch
    // import message cente
    return (
      <div className="page-top clearfix">
        {/* {this.renderLogo()} */}

        {this.renderHamburgerMenu()}

        {this.renderUserSection()}

      </div>
    );
  }
}


class PageFooter extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false, collapsed: this.props.collapsed };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ collapsed: nextProps.collapsed });
  }
  renderHamburgerMenu() {
    return (
      <a className="sidebar-list-link" href="https://www.quizzes4leads.com" data-url="http://www.ndtv.com" data-type="appdashboard" target="_blank">
        <img src="/assets/img/q4llogo.png" width="30px" height="30px" />
        <span data-url="http://www.ndtv.com" data-type="appdashboard" style={{ verticalAlign: "-webkit-baseline-middle", color: "black", paddingLeft: 10 }}>Quizzes4Leads</span>
      </a>
    );
  }


  render() {
    return (
      <div className={`page-footer ${this.state.collapsed ? 'collapsed' : ''}`}>
        {this.renderHamburgerMenu()}
      </div>
    );
  }
}


// this class will render Sidebar, PageTop, PageFooter.
// PageFooter depending on which link in sidebar is clicked will render the relevant data
export class Dashboard extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      facebookloginurl: this.props.facebookloginurl,
      contentTitle: this.props.contentTitle,
      contentType: this.props.contentType,
      tableItems: this.props.tableItems,
      tableTitle: this.props.tableTitle,
      planItems: this.props.plandata,
      isSideBarCollapsed: false,
      profiledata: this.props.profiledata,
      reportdata: this.props.reportdata,
      selectedNav: 'Dashboard',
      selectedShareIcon: this.props.selectedShareIcon,
      jrsteps: [
        {
          content: (
            <div>
              This is your dashboard where you can see a quick snapshots of quizzes.
            </div>
          ),
          placement: 'bottom',
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          target: '.Dashboard',
          title: 'Dashboard',
        },
        {
          content: (
            <div>
              This is your quiz workarea where you can see the list of all quizzes and perform quick actions
              like edit, publish / unpublish, preview, delete and see quiz analytics.
            </div>
          ),
          disableBeacon: false,
          placement: 'bottom',
          spotlightClicks: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          target: '.Quizzes',
          title: 'Quizzes',
        },
        {
          content: (
            <div>
              This is your plan area where you can purchase / update new plans.
            </div>
          ),
          placement: 'bottom',
          spotlightClicks: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          target: '.Plans',
          title: 'Plans',
        },
        {
          content: (
            <div>
              This is your invoice area where you can see your previous invoices.
            </div>
          ),
          placement: 'bottom',
          spotlightClicks: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          target: '.Invoices',
          title: 'Invoices',
        },
        {
          content: (
            <div>
              This is your Account area where you can change your password and personal details.
            </div>
          ),
          placement: 'bottom',
          spotlightClicks: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          target: '.Account',
          title: 'Account',
        },
        {
          content: (
            <div>
              This is your Settings area where you can add / modify application settings like CRM integration, email notifications etc.
            </div>
          ),
          placement: 'bottom',
          spotlightClicks: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          target: '.Settings',
          title: 'Settings',
        },
        {
          content: (
            <div>
              Use this button to create quiz
            </div>
          ),
          placement: 'left',
          spotlightClicks: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
          target: '#btnquizcreate',
          title: 'Create Quiz',
        }
      ],
      stepIndex: 0,
      run: true



    };
    //this.onLoginCheck = this.onLoginCheck.bind(this);
    this.toggleSideBar = this.toggleSideBar.bind(this);
    //this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      contentTitle: nextProps['contentTitle'],
      contentType: nextProps['contentType'],
      contentData: nextProps['contentData'],
      tableItems: nextProps['tableItems'],
      tableTitle: nextProps['tableTitle'],
      planItems: nextProps['plandata'],
      profiledata: nextProps['profiledata'],
      reportdata: nextProps['reportdata'],
      selectedShareIcon: nextProps['selectedShareIcon'],
      user: nextProps['user']
    });
  }
  componentDidMount() {
    if (this.state.user.isAuthenticated == false) {
      this.props.history.push("/index.html");
    }
    else if (this.props.match.params != null && this.props.match.params.id) {
      var quizid = this.props.match.params.id;
      this.setState({ quizid }, this.getQuizData);
    }

    // var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    // (function () {
    //   var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    //   s1.async = true;
    //   s1.src = 'https://embed.tawk.to/5cc3f6bbd6e05b735b4484e9/default';
    //   s1.charset = 'UTF-8';
    //   s1.setAttribute('crossorigin', '*');
    //   s0.parentNode.insertBefore(s1, s0);
    // })();

  }
  // onLoginCheck(e) {
  //     this.setState({isAuthenticated: e});
  // }

  // handleJoyrideCallback(data) {
  //   //const { sidebarOpen } = this.state;
  //   const { action, index, type, status } = data;

  //   if ((status == STATUS.FINISHED || status == STATUS.SKIPPED)) {
  //     // Need to set our running state to false, so we can restart if we click start again.
  //     this.setState({ run: false, stepIndex: 0 });
  //   } else if ((type == EVENTS.STEP_AFTER || type == EVENTS.TARGET_NOT_FOUND)) {
  //     const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

  //     /*if (sidebarOpen && index === 0) {
  //       setTimeout(() => {
  //         this.setState({ run: true });
  //       }, 400);
  //     } else if (sidebarOpen && index === 1) {
  //       this.setState(
  //         {
  //           run: false,
  //           sidebarOpen: false,
  //           stepIndex,
  //         },
  //         () => {
  //           setTimeout(() => {
  //             this.setState({ run: true });
  //           }, 400);
  //         },
  //       );
  //     } else if (index === 2 && action === ACTIONS.PREV) {
  //       this.setState(
  //         {
  //           run: false,
  //           sidebarOpen: true,
  //           stepIndex,
  //         },
  //         () => {
  //           setTimeout(() => {
  //             this.setState({ run: true });
  //           }, 400);
  //         },
  //       );
  //     } else */
  //     {
  //       // Update state to advance the tour
  //       this.setState({
  //         stepIndex
  //       });
  //     }
  //   }
  // };

  toggleSideBar(e) {
    e.preventDefault();
    this.setState({ isSideBarCollapsed: !this.state.isSideBarCollapsed });
  }
  render() {

    //add class on quiz create and edit page
   let checkURl =  this.props.location.pathname.split("/");
   let makeUrlForQuizPage = checkURl[2]+'/'+checkURl[3];

    if (this.state.user == null || this.state.user.isAuthenticated == false) {
      return <Redirect to="/index.html" />
    }
    return <main className={`${this.state.isSideBarCollapsed ? "menu-collapsed" : ""}`}>
      <Sidebar {...this.props} collapsed={this.state.isSideBarCollapsed} />
      <PageTop location={this.props.location} user={this.props.user} logout={this.props.logout}
        logourl={this.props.logourl}
        quizCreate={this.props.quizCreate}
        appName={this.props.appName} toggleSideBar={this.toggleSideBar} />
      <PageFooter collapsed={this.state.isSideBarCollapsed} />
      {/* <div className={`content-container ${this.state.isSideBarCollapsed==true?'expanded':''}`}> */}
      <div className={`content-container expanded`} id={makeUrlForQuizPage == "Quiz/Edit"?"quiz-create-edit":'general-page'}>

        <div className="content">
          <div className="col-md-12 table_bg">
            <Switch>

              {/* <PrivateRoute path="/app/Quizzes" testcomponent={QuizTable} basepath={this.props.basepath}
                                              user={this.state.user} {...this.props}/> */}
              <Route exact path="/app/Quizzes" component={(props) => <QuizTable basepath={this.props.basepath} localpath={this.props.localpath}
                user={this.state.user}
                {...this.props} {...props} />} />


              <Route exact path="/app/Plans" component={(props) => <PlanForm basepath={this.props.basepath}
                user={this.state.user}
                {...this.props} {...props} />} />
              <Route exact path="/app/Invoices" component={(props) => <InvoiceTable basepath={this.props.basepath}
                user={this.state.user}
                {...this.props} {...props} />} />
              <Route exact path="/app/Account" component={(props) => <UserSettingForm basepath={this.props.basepath}
                user={this.state.user}
                {...this.props} {...props} />} />
                <Route exact path="/app/refer-friend" component={(props) => <ReferFriend basepath={this.props.basepath}
                user={this.state.user}
                {...this.props} {...props} />} />

              <Route exact path="/app/Settings" component={(props) => <SettingForm basepath={this.props.basepath}
                user={this.state.user}
                {...this.props} {...props} />} />

              <Route exact path="/app/Quiz/Share/:id" component={(props) => <QuizShareForm basepath={this.props.basepath} userid={this.props.user.userid} localpath={this.props.localpath} user={this.state.user}
                {...this.props} {...props} />} />

              <Route exact path="/app/Quiz/Edit/:id" component={(props) => <QuizEditForm basepath={this.props.basepath} user={this.state.user}
                {...this.props} {...props} />} />

              <Route exact path="/app/Answersheet/:id" component={(props) => <AnswerSheetForm basepath={this.props.basepath} user={this.state.user}
                {...this.props} {...props} />} />
              

              <Route exact path="/app/Tutorials" component={(props) => <Tutorials basepath={this.props.basepath}
                user={this.state.user}
                {...this.props} {...props} />} />

              <Route exact path="/app/Templates" component={(props) => <Templates basepath={this.props.basepath} user={this.state.user}
                {...this.props} {...props} />} />

              <Route exact path="/app/Feedback" component={(props) => <Feedbacks basepath={this.props.basepath} user={this.state.user}
                {...this.props} {...props} />} />

              <Route exact path="/app/FeedbackLists" component={(props) => <FeedbackLists basepath={this.props.basepath} localpath={this.props.localpath} user={this.state.user}
                {...this.props} {...props} />} />
              <Route exact path="/app/Feedback/Edit/:id" component={(props) => <FeedbackEdit basepath={this.props.basepath} user={this.state.user}
                {...this.props} {...props} />} />

              <Route exact path="/app/Feedback/share/:id" component={(props) => <FeedbackShare basepath={this.props.basepath} user={this.state.user}
                {...this.props} {...props} />} />

              <Route exact path="/app/Feedback/response/:id" component={(props) => <FeedbackResponse basepath={this.props.basepath} user={this.state.user}
                {...this.props} {...props} />} />
              {/* Pool Routes */}
              <Route exact path="/app/poll/lists" component={(props) => <PoolLists basepath={this.props.basepath} localpath={this.props.localpath} user={this.state.user}
              {...this.props} {...props} />} />
              <Route exact path="/app/poll/vote/:id" component={(props) => <PoolVotes basepath={this.props.basepath} localpath={this.props.localpath} user={this.state.user}
              {...this.props} {...props} />} />
               <Route exact path="/app/poll/create" component={(props) => <PoolCreate basepath={this.props.basepath} localpath={this.props.localpath} user={this.state.user}
              {...this.props} {...props} />} />
              <Route exact path="/app/poll/edit/:id" component={(props) => <PoolEdit basepath={this.props.basepath} localpath={this.props.localpath} user={this.state.user}
              {...this.props} {...props} />} />
              <Route exact path="/app/poll/share/:id/:slug" component={(props) => <PoolShare basepath={this.props.basepath} user={this.state.user}
                {...this.props} {...props} />} />
              <Route exact path="/app/wheel_spinner/lists" component={(props) => <WheelSpinnerList basepath={this.props.basepath} localpath={this.props.localpath} user={this.state.user}
              {...this.props} {...props} />} />
              <Route exact path="/app/wheel_spinner/create" component={(props) => <WheelSpinnerCreate basepath={this.props.basepath} localpath={this.props.localpath} user={this.state.user}
              {...this.props} {...props} />} />
              <Route exact path="/app/wheel_spinner/edit/:id" component={(props) => <WheelSpinnerEdit basepath={this.props.basepath} localpath={this.props.localpath} user={this.state.user}
              {...this.props} {...props} />} />
              <Route exact path="/app/wheel_spinner/edit/:id" component={(props) => <WheelSpinnerEdit basepath={this.props.basepath} localpath={this.props.localpath} user={this.state.user}
              {...this.props} {...props} />} />
              <Route exact path="/app/wheelspin/response/:id" component={(props) => <WheelSpinResponse basepath={this.props.basepath} user={this.state.user}
                {...this.props} {...props} />} />
                <Route exact path="/app/wheelspin/share/:id/:slug" component={(props) => <WheelSpinShare basepath={this.props.basepath} user={this.state.user}
                {...this.props} {...props} />} />

              <Route exact path="/app/Quiz/Create" component={(props) => <QuizEditForm basepath={this.props.basepath}
                user={this.state.user} {...this.props} {...props} />} />

              <Route exact path="/app/Contactus" component={(props) => <ContactUs basepath={this.props.basepath}
                user={this.state.user} {...this.props} {...props} />} />


              <Route exact path="/app/Quiz/Analytics/:id" component={(props) => <QuizReportForm basepath={this.props.basepath}
                user={this.state.user} {...this.props} {...props} />} />
              <Route path="/app/Admin" component={(props) => <Admin basepath={this.props.basepath}
                user={this.state.user} {...this.props} {...props} />} />

              <Route path="/app/" component={(props) => <AppDashboard basepath={this.props.basepath}
                user={this.state.user} {...this.props} {...props} />} />
              
             
            </Switch>

          </div>
        </div>
        {/*<Joyride continuous={true}
                            callback={this.handleJoyrideCallback}
                            run={this.state.run}
                            steps={this.state.jrsteps}
                            stepIndex={this.state.stepIndex}
                            scrollToFirstStep={true}
                            showProgress={true}
                            showSkipButton={true} 
                            styles={{
                              options: {
                                zIndex: 100000,
                              },
                            }}/>*/}
      </div>
      <NotificationContainer />
    </main>


    {/* <ContentDash contentTitle={this.state.contentTitle}
                             contentType={this.state.contentType}
                             contentData={this.state.contentData}
                             cardItems={this.props.cardItems}
                             tableTitle={this.state.tableTitle}
                             fbbasepath={this.props.fbbasepath}
                             basepath={this.props.basepath}
                             tableItems={this.state.tableItems}
                             pageItems={this.props.pageItems}
                             planItems={this.state.planItems}
                             tabItems={this.props.tabItems}
                             getPageDetails={this.props.getPageDetails}
                             saveQuiz={this.props.saveQuiz}
                             quiz={this.props.quiz}
                             quizCreate={this.props.quizCreate}
                             quizEdit={this.props.quizEdit}
                             quizPlay={this.props.quizPlay}
                             quizDel={this.props.quizDel}
                             quizShare={this.props.quizShare}
                             quizCopy={this.props.quizCopy}
                             quizReport={this.props.quizReport}
                             quizTogglePublish={this.props.quizTogglePublish}
                             addAns={this.props.addAns}
                             delAns={this.props.delAns}
                             reportQue={this.props.reportQue}
                             user={this.props.user}
                             profiledata={this.state.profiledata}
                             reportdata={this.state.reportdata}
                             collapsed={this.state.isSideBarCollapsed}
                             selectedShareIcon={this.state.selectedShareIcon}
                /> */}



  }
}

class PrivateRoute extends React.Component {

  constructor(props) {
    super(props);
    this.state = { testcomponent: this.props.testcomponent, path: this.props.path, user: this.props.user };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      testcomponent: nextProps['testcomponent'],
      path: nextProps['path'],
      user: nextProps['user']

    });
  }
  render() {
    var content = <Route {...this.props} render={props => <Redirect to={{ pathname: "/index.html" }} />} />
    if (this.state.user.isAuthenticated) {
      //content = <Route {...this.props} render={props=> (<this.state.testcomponent {...this.props} isAuthenticated={this.state.isAuthenticated}/>)} />

      content = <Route exact path={this.state.path} component=
        {(props) => <this.state.testcomponent basepath={this.props.basepath}
          user={this.state.user}
          {...this.props} {...props} />} />

    }
    return content;

  }
}