/**
 * This file contains the functionality to render quiz table
 */
import React from "react";
import Moment from 'moment';
import swal from "sweetalert";

import { NotificationManager } from 'react-notifications';
import { Link, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
export class PoolLists extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableItems: { header: null, rowdata: null },
      dataLoadedFlag: false,
      user: this.props.user,
      tempArrayWithIdIndex:[]
    };
    this.poolEdit = this.poolEdit.bind(this)
    this.poolPreview = this.poolPreview.bind(this);
    this.poolDelete = this.poolDelete.bind(this);
    this.poolShare = this.poolShare.bind(this);
    this.poolResponse = this.poolResponse.bind(this);
    // this.getPoolList(); // i will remove this did mount not work on listing so i added this
  }
  componentDidMount(){
    this.getPoolList();
  }

  getPoolList() {
    var dataurl = this.props.basepath + "poll/lists";
    var self = this;
    fetch(dataurl, {method: "get", credentials: "include"
    }).then(function (response) {
        return response.json();
    }).then(function (response) {
      if (response && response.status==200 && response.data && response.data.length) {
        var tempArr = self.state.tableItems;
        tempArr.rowdata = [];
        let tempArrayWithIdIndex = [];
        response.data.map(function (item, index) {
          var arrItem = [
            item["pool_description"],
            item["pool_create_date"],
            item["pool_status"],
            item["total_vote"],
            item["Action"],
            item["id"],
            item["slug"]
          ];
          tempArr.rowdata[index] = [];
          tempArrayWithIdIndex[item.id] = arrItem; 
          tempArr.rowdata[index] = arrItem;
        }, self);

        tempArr.header = [];
        tempArr.header[0] = ["Title", "Created Date", 'Status', 'Total Votes', 'Action'];

        self.setState({ tableItems: tempArr, dataLoadedFlag: true,tempArrayWithIdIndex:tempArrayWithIdIndex });
      }
      else{
        NotificationManager.error("Server error: ", response.message);
      }
      self.setState({dataLoadedFlag:true})
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }
  poolEdit(event) {
    var poolId = event.target.getAttribute("data-id");
    this.props.history.push("/app/poll/edit/" + poolId);
  }
  poolShare(event) {
    var poolId = event.target.getAttribute("data-id");
    var poolSlug = event.target.getAttribute("data-slug");
    this.props.history.push("/app/poll/share/" + poolId +'/'+poolSlug);
  }
  poolDelete(event) {
    var poolId = event.target.getAttribute("data-id");
    var self = this;
    swal({
      title: "Delete Pool",
      text: "Are you sure you want to delete this pool?",
      type: "warning",
      buttons: { cancel: true, text: "Yes, Delete it!!", closeModal: false },
      icon: "warning"
    }).then(function (isConfirm) {
      if (isConfirm) {
        var jsonbody = JSON.stringify({ userid: null });
        var queryurl =
          self.props.basepath +
          "poll/delete/" +
          poolId;
        fetch(queryurl, {method: "post",body: jsonbody,
          credentials: "include"
        }).then(function (response) {
          return response.json();
        }).then(function (response) {
          if(response && response.status==200 && response.data){
            NotificationManager.success("Poll Deletion: ","Poll deleted successfully!!");
          }
          else{
            NotificationManager.error("Poll Deletion: ", response.message);
          }
          self.props.history.push("/app/poll/lists");
        }).catch(err=>{
          NotificationManager.error("Server error: " , err.message)
        });
      }
    });
  }
  poolPreview(event) {
    var poolId = event.target.getAttribute("data-id");
    var poolSlug = event.target.getAttribute("data-slug");
    
    let pollPreviewUrl = this.props.localpath + "poll/publish/show/" + poolId + '/'+poolSlug;
    window.open(pollPreviewUrl);
  }
  poolResponse(event) {
    var poolId = event.target.getAttribute("data-id");
    // get selected poll data
    let pollData = this.state.tempArrayWithIdIndex[poolId]
    this.props.history.push({
      pathname:"/app/poll/vote/" + poolId,
      state: pollData
    });
    
  }
  renderRows() {
    if (this.state.dataLoadedFlag == false) {
      return (
        <tr className="no-top-border">
          <td>Loading Data...</td>
        </tr>
      );
    }
    if (
      this.state.tableItems == null ||
      this.state.tableItems.rowdata == null ||
      this.state.tableItems.rowdata.length == 0
    ) {
      return (
        <tr className="no-top-border no-quiz-message">
          <td>You have not created any Instant Poll yet.</td>
        </tr>
      );
    } else {
      return this.state.tableItems.rowdata.map(function (rowItem, index) {
        return (
          <tr className="no-top-border" key={index}>
            <td className="thead_td">
              {rowItem[0]}
            </td>
            <td className="thead_td">{rowItem[1]}</td>
           
            <td className="thead_td">{rowItem[2]}</td>
            <td className="thead_td">{rowItem[3]}</td>

            <td className="thead_td">
              <div className="btn-group poll-control-modify">
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.poolEdit}
                  data-id={rowItem[5]}
                >
                  <i
                    className="fa fa-edit fa-x cursor"
                    data-id={rowItem[5]}
                  ></i>
                </span>
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.poolPreview}
                  data-id={rowItem[5]}
                >
                  <i
                    className="fa fa-play fa-x cursor"
                    data-id={rowItem[5]}
                    data-slug={rowItem[6]}
                  ></i>
                </span>
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  onClick={this.poolDelete}
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                >
                  <i
                    className="fa fa-trash fa-x cursor"
                    data-id={rowItem[5]}
                  ></i>
                </span>

                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.poolShare}
                  data-id={rowItem[5]}
                >
                  <i
                    className="fa fa-share-alt fa-x cursor"
                    data-id={rowItem[5]}
                    data-slug={rowItem[6]}
                  ></i>
                </span>

                <span
                  type="button"
                  className="more-menu-btn modify-chart-icon"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.poolResponse}
                >
                  <i
                    className="fa fa-line-chart fa-x cursor fa-modify-chart-icon"
                    data-id={rowItem[5]}
                  ></i>
                </span>
              </div>
            </td>
          </tr>
        );
      }, this);
    }
  }
  renderHeaders() {
    if (
      this.state.tableItems == null ||
      this.state.tableItems.header == null ||
      this.state.tableItems.header.length == 0
    ) {
      return null;
    }

    return this.state.tableItems.header.map(function (headerItem, index) {
      return (
        <thead className="heading" key={index}>
          <tr className="black-muted-bg">
            <th className="thead_title">{headerItem[0]}</th>
            <th className="thead_title">{headerItem[1]}</th>
            <th className="thead_title">{headerItem[2]}</th>
            <th className="thead_title">{headerItem[3]}</th>
            <th className="thead_title">{headerItem[4]}</th>
            
          </tr>
        </thead>
      );
    }, this);
  }
  render() {
    return (
      <div>
        <div className="col-md-12">
          <div className="tableFixHead pool-data-list">
            <table className="table table-striped">
              {this.renderHeaders()}
              <tbody>{this.renderRows()}</tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}
export default PoolLists;
