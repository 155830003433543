/**
 * This file contains the functionality to render quiz table
 */
import React from "react";
import { NotificationManager } from "react-notifications";

export class PollDemoPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pool_description: '',
            pool_expiry_date: '',
            pool_option: [],
            pool_status: '',
            vote_item: '',
            data: '',
            email: '',
            pool_vote: '',
            successMessage: '',
            updatedPollVote: [],
            updatedTotalVote: '',
            emailValidationError: '',
            loginsendResponse: false,
            poll_custom_color: '',
            poll_custom_color_text: '',
            poll_custom_color_bg: ''
        };
        this.poolVote = this.poolVote.bind(this);
        this.voteSave = this.voteSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getPool();
    }

    getPool() {
        if (this.props != null && this.props.match != null && this.props.match.params != null && this.props.match.params.id != null) {
            let pool_id = this.props.match.params.id;
            let pool_slug = this.props.match.params.slug;
            var dataurl = this.props.basepath + "poll/publish/show/" + pool_id + '/' + pool_slug;
            let basePath = this.props.localpath;
            var self = this;
            fetch(dataurl, {method: "get",credentials: "include",
            }).then(function (response) {
                return response.json();
            }).then(function (response) {
                if (response && response.status==200 && response.data) {
                    let getPollCustomColor = JSON.parse(response.data[0].poll_custom_color);
                    self.setState({
                        data: response.data[0],
                        pool_description: response.data[0].pool_description,
                        pool_option: JSON.parse(response.data[0].pool_option),
                        pool_create_date: response.data[0].pool_create_date,
                        pool_expiry_date: response.data[0].pool_expiry_date,
                        pool_status: response.data[0].pool_status,
                        pool_vote: JSON.parse(response.data[0].pool_vote),
                        poll_custom_color: response.data[0].poll_custom_color,
                        poll_custom_color_bg: getPollCustomColor['bg_color'] ? getPollCustomColor['bg_color'] : '#8144e5',
                        poll_custom_color_text: getPollCustomColor['text_color'] ? getPollCustomColor['text_color'] : '#ffffff'
                    });
                }
                else{
                    NotificationManager.error("Server error: ",response.message);
                }
            }).catch(err=>{
                NotificationManager.error("Server error: " , err.message)
            });
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    poolVote(index, item) {
        // When first vote send
        if (this.state.pool_vote == null) {
            // make array for vote based on pool opition
            var pool_vote = [];
            for (var i = 1; i <= this.state.pool_option.length; i++) {
                // set initial vote value 0
                pool_vote.push(0);
            }
            // update specific option index
            pool_vote[index] = pool_vote[index] + 1;
            // update state with new pool_vote
            this.setState({
                vote_item: item,
                pool_vote: pool_vote,
            })
        } else {
            // if first vote already sent 
            pool_vote = this.state.pool_vote
            // just update specific index
            pool_vote[index] = pool_vote[index] + 1;
            this.setState({
                vote_item: item,
                pool_vote: pool_vote,
            })
        }
    }

    voteSave() {
        var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (this.state.email == '') {
            this.setState({
                emailValidationError: "Email is required.",
            })
            return false;
        } else if (!emailPattern.test(this.state.email)) {
            this.setState({
                emailValidationError: "Please enter valid email."
            })
            return false
        }
        this.setState({
            loginsendResponse: true
        })
        var data = {};
        data["vote_item"] = this.state.vote_item;
        data["pool_id"] = this.state.data.id;
        data["email"] = this.state.email;
        data['pool_vote'] = this.state.pool_vote;
        var jsondata = JSON.stringify(data);
        var postdata = "data=" + jsondata;
        var queryurl = this.props.basepath + "poll/vote/save";
        let self = this;
        fetch(queryurl, {
            method: "post",
            credentials: "include",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: postdata,
        }).then(function (data) {
            return data.json();
        }).then(function (response) {
            if (response && response.status==200 && response.data) {
                self.setState({
                    successMessage: response.data.message,
                    email: '',
                    emailValidationError: '',
                    poolUpdated: response.data.poolUpdated,
                    updatedPollVote: JSON.parse(response.data.poolUpdated.pool_vote),
                    updatedTotalVote: response.data.poolUpdated.total_vote,
                    loginsendResponse: false,
                })
            }else{
                //todo: show error message from response.message 
                NotificationManager.error("Server error: ", response.message);
            }
        }).catch(err=>{
            NotificationManager.error("Server error: " , err.message)
        });
    }

    render() {
        return (
            <div>

                <div className="container pole_wrapper">
                    {this.state.vote_item == '' ? (
                        <div className="pool-preview-wrapper text-center">
                            <h2>{this.state.pool_description}</h2>
                            <ul className="list-group">
                                {this.state.pool_option.map((item, index) =>
                                    <li ref={(el) => {
                                        if (el) {
                                            el.style.setProperty(
                                                "background-color",
                                                this.state.poll_custom_color_bg
                                            );
                                            el.style.setProperty(
                                                "color",
                                                this.state.poll_custom_color_text
                                            );
                                        }
                                    }} style={{ marginTop: '15px' }} onClick={() => this.poolVote(index, item)} key={index} className="list-group-item list-group-item-info">{item}</li>
                                )}
                            </ul>
                            {this.state.data ? (
                                <h5> <b>Total Voted</b> : {this.state.data.total_vote}</h5>
                            ) : ''}
                        </div>
                    ) : ''}
                    {this.state.vote_item != '' && this.state.successMessage == '' ? (
                        <div className="poll-sent">
                            <div className="form-group">
                                <label><strong>Confirm Your Vote for</strong> 👉 {this.state.vote_item}</label>
                                <input ref={(el) => {
                                    if (el) {
                                        el.style.setProperty(
                                            "border-left",
                                            '4px solid ' + this.state.poll_custom_color_bg,
                                            "important"
                                        );
                                    }
                                }} value={this.state.email} onChange={this.handleChange} placeholder="Please enter your email" type="email" className="form-control" name="email" required />
                            </div>
                            <p className="email-validation-error-message">{this.state.emailValidationError}</p>
                            <div className="text-center">
                                <button ref={(el) => {
                                    if (el) {
                                        el.style.setProperty(
                                            "background-color",
                                            this.state.poll_custom_color_bg,
                                            "important"
                                        );
                                        el.style.setProperty(
                                            "color",
                                            this.state.poll_custom_color_text,
                                            "important"
                                        );
                                    }
                                }} type="butoon" onClick={this.voteSave} className="btn btn-primary">Send Vote {this.state.loginsendResponse ? <i className='fa fa-spinner fa-spin'></i> : ''}</button>
                            </div>
                        </div>
                    ) : ''}
                    {this.state.successMessage ? (
                        <div className="vote-success">
                            {this.state.successMessage}
                            {this.voteResponse()}
                        </div>
                    ) : ''}

                </div>
                )
            </div>
        );
    }

    voteResponse() {
        return (
            <div className="vote-result display-flex">
                <ul className="list-group">
                    {this.state.pool_option.map((item, index) =>
                        <div key={index + 4}>
                            <li ref={(el) => {
                                if (el) {
                                    el.style.setProperty(
                                        "background-color",
                                        this.state.poll_custom_color_bg,
                                        "important"
                                    );
                                    el.style.setProperty(
                                        "color",
                                        this.state.poll_custom_color_text,
                                        "important"
                                    );
                                }
                            }} style={{ marginTop: '15px' }} key={index} className="list-group-item list-group-item-info">{item}</li>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: ((this.state.updatedPollVote[index] / this.state.updatedTotalVote) * 100).toFixed(0) + "%" }}>
                                    {((this.state.updatedPollVote[index] / this.state.updatedTotalVote) * 100).toFixed(0)}%
                                </div>
                            </div>
                        </div>
                    )}
                </ul>
            </div>
        )
    }
}
export default PollDemoPreview;
