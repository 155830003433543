/**
 * This file contains the functionality to render quiz table
 */
import React from "react";
import swal from "sweetalert";
import { Link, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";

export class WheelSpinnerList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: this.props.user,
      tableItems: { header: null, rowdata: null },
      tableTitle: "Wheel Spin",
      dataLoadedFlag: false,
      actionFlag: false,
    };
    this.wheelspinDelete = this.wheelspinDelete.bind(this);
    this.wheelspinEdit = this.wheelspinEdit.bind(this);
    this.wheelSpinShare = this.wheelSpinShare.bind(this);
    this.getWheelSpinLists();
    this.wheelSipinShare = this.wheelSipinShare.bind(this);
    this.wheelSpinResponse = this.wheelSpinResponse.bind(this);
  }

  getWheelSpinLists() {
    var dataurl = this.props.basepath + "wheelspin/lists";
    var self = this;
    fetch(dataurl, {method: "get",credentials: "include"
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      var tempArr = self.state.tableItems;
      tempArr.rowdata = [];
      let tempArrayWithIdIndex = [];
      if(response && response.status==200 && response.data && response.data.length > 0){
        response.data.map(function (item, index) {
          var arrItem = [
            item["id"],
            item["description"],
            item["crated_date"],
            item["slug"]
          ];
          tempArr.rowdata[index] = [];
          tempArrayWithIdIndex[item.id] = arrItem;
          tempArr.rowdata[index] = arrItem;
        }, self);

        tempArr.header = [];
        tempArr.header[0] = ["ID", "Title", "Date", " Status"];

        self.setState({ tableItems: tempArr, dataLoadedFlag: true, tempArrayWithIdIndex:tempArrayWithIdIndex });
      } else{          
        NotificationManager.error("Server error: ", response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  wheelspinEdit(event) {
    var wheelSpinId = event.target.getAttribute("data-id");
    this.props.history.push("/app/wheel_spinner/Edit/" + wheelSpinId);
  }

  wheelSipinShare(event) {
    var wheelSpinId = event.target.getAttribute("data-id");
    var wheelspinSlug = event.target.getAttribute("data-slug");
    this.props.history.push("/app/wheelspin/share/" + wheelSpinId + '/' + wheelspinSlug);
  }

  wheelSpinShare() {
    //share
  }

  wheelspinDelete(event) {
    var wheelspinId = event.target.getAttribute("data-id");
    var self = this;
    swal({
      title: "Delete Feedback",
      text: "Are you sure you want to delete this?",
      type: "warning",
      buttons: { cancel: true, text: "Yes, Delete it!!", closeModal: false },
      icon: "warning"
    }).then(function (isConfirm) {
      if (isConfirm) {
        var jsonbody = JSON.stringify({ userid: null });
        var queryurl = self.props.basepath + "wheelspin/delete/" + wheelspinId;
        fetch(queryurl, {
          method: "post",
          body: jsonbody,
          credentials: "include"
        }).then(function (response) {
          return response.json();
        }).then(function (response) {
          if (response && response.status==200 && response.data) {
            //swal("Feedback Delete", "Your feedback has been deleted.","success");
            NotificationManager.success("Wheel Spin Delete", "Your wheel Spin has been deleted.");
          } else {
            //swal("Feedback Delete", "Error in deleting feedback", "error");
            NotificationManager.error("Wheel Spin Delete", "Error in deleting wheel Spin");
          }
          self.props.history.push("/app/wheel_spinner/lists");
        }).catch(err=>{
          NotificationManager.error("Server error: " , err.message)
        });
      }
    });
  }
  wheelSpinResponse(event) {
    var wheelSpinId = event.target.getAttribute("data-id");
    let wheelSpinData=this.state.tempArrayWithIdIndex[wheelSpinId]
    this.props.history.push({
      pathname: "/app/wheelspin/response/" + wheelSpinId,
      state:wheelSpinData
    });
  }
  renderHeaders() {
    if (
      this.state.tableItems == null ||
      this.state.tableItems.header == null ||
      this.state.tableItems.header.length == 0
    ) {
      return null;
    }

    return this.state.tableItems.header.map(function (headerItem, index) {
      return (
        <thead className="heading" key={index}>
          <button className="btn-normal instant-btn" id="btnquizcreate" style={{ width: "150px", display: "none" }}>
            <Link
              to="/app/Feedback"
              style={{ color: "white", textDecoration: "none", display: "none", width: "200px" }}
            >
              Wheel Spin
            </Link>
          </button>
          <tr className="black-muted-bg">
            <th style={{ width: '100px', textAlign: 'center' }} className="">Id</th>
            <th className="thead_title">{headerItem[1]}</th>
            <th className="thead_title">Date</th>
            <th className="thead_title">Action</th>
          </tr>
        </thead>
      );
    }, this);
  }
  renderRows() {
    if (this.state.dataLoadedFlag == false) {
      return (
        <tr className="no-top-border">
          <td>Loading Data...</td>
        </tr>
      );
    }
    if (
      this.state.tableItems == null ||
      this.state.tableItems.rowdata == null ||
      this.state.tableItems.rowdata.length == 0
    ) {
      return (
        <tr className="no-top-border no-quiz-message">
          <td>You have not created any Instant Response yet.</td>
        </tr>
      );
    } else {
      return this.state.tableItems.rowdata.map(function (rowItem, index) {
        var title = rowItem[1];
        var contactTitle = title;
        var titles = contactTitle;
        contactTitle = titles + "..";

        var quizurl = "";

        return (
          <tr className="no-top-border" key={index}>
            <td style={{ width: '100px' }} >{index + 1}</td>
            <td
              onClick={this.wheelspinEdit}
              data-id={rowItem[0]}
              className="thead_td"
            >
              {contactTitle}
            </td>
            <td className="thead_td">{rowItem[2]}</td>


            <td className="thead_td">
              <div className="btn-group">
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.wheelspinEdit}
                  title="Edit Feedback"
                  data-original-title="Edit"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-edit fa-x cursor"
                    data-id={rowItem[0]}
                  ></i>
                </span>
                
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  onClick={this.wheelspinDelete}
                  title="Delete Wheel Spin"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  data-original-title="Delete"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-trash fa-x cursor"
                    data-id={rowItem[0]}
                  ></i>
                </span>
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.wheelSipinShare}
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-share-alt fa-x cursor"
                    data-id={rowItem[0]}
                    data-slug={rowItem[3]}
                  ></i>
                </span>
                <span
                  type="button"
                  className="more-menu-btn modify-chart-icon"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.wheelSpinResponse}
                >
                  <i
                    className="fa fa-line-chart fa-x cursor fa-modify-chart-icon"
                    data-id={rowItem[0]}
                  ></i>
                </span>
              </div>
            </td>
          </tr>
        );
      }, this);
    }
  }
  render() {
    return (
      <div style={{ marginTop: 2 + "%" }}>
        {this.state.tableItems != null &&
          this.state.tableItems.rowdata != null &&
          this.state.tableItems.rowdata.length > 0
          ? <div>
            <div className="col-md-12">
              <div className="table-title">
                Wheel Spin
                <span className="table-subtitle">
                  {this.state.tableItems != null &&
                    this.state.tableItems.rowdata != null &&
                    this.state.tableItems.rowdata.length > 0
                    ? this.state.tableItems.rowdata.length + " Total"
                    : "0 records"}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="tableFixHead queiz-analysis">
                <table className="table table-striped">
                  {this.renderHeaders()}
                  <tbody>{this.renderRows()}</tbody>
                </table>
              </div>
            </div>
          </div>
          : <div className="row">
            <div className="web_responsive_block">
              <h4>You don't have any data currently, let's get started by creating your first wheel spin <br /></h4>
              <button className="btn-normal" id="btnquizcreate" style={{ width: 200 }}><Link to="/app/wheel_spinner/create" style={{ color: 'white', textDecoration: 'none' }}>Build Your Wheel Spin</Link></button>
            </div>

          </div>}
      </div>
    );
  }
}
export default WheelSpinnerList;
