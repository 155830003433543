import React from "react";
import { NotificationManager } from "react-notifications";

export class ReferFriend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            friend_name: '',
            friend_email: '',
            success_message: ''
        };
        this.referToFriend = this.referToFriend.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    referToFriend() {
        var data = {};
        data["friend_name"] = this.state.friend_name;
        data["friend_email"] = this.state.friend_email;
        data["sender_name"] = this.props.user.name;
        var jsondata = JSON.stringify(data);
        var postdata = "userid=" + this.props.user.userid + "&data=" + jsondata;

        var queryurl = this.props.basepath + "referfriend/send";
        var self = this;
        fetch(queryurl, {
            method: "post",
            credentials: "include",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: postdata,
        }).then(function (data) {
            return data.json();
        }).then(function (response) {
            if (response && response.data && response.status == 200) {
                self.setState({
                    friend_name: '',
                    friend_email: '',
                    success_message: response.message
                })
            } else{
                NotificationManager.error("Server error: " + response.message);
            }
        }).catch(err=>{
            NotificationManager.error("Server error: " , err.message)
        });
    }

    render() {
        return (
            <div>
                <div className="refer-friend-wrapper">
                    <h2>Refer Friend</h2>
                    <div className="form-group">
                        <label>Friend's Name</label>
                        <input
                            type="text"
                            onChange={this.handleChange}
                            placeholder="Please enter friend's name"
                            className="form-control"
                            name="friend_name"
                            value={this.state.friend_name}
                        />
                    </div>
                    <div className="form-group">
                        <label>Friend Email</label>
                        <input
                            type="email"
                            onChange={this.handleChange}
                            placeholder="Please enter friend's email"
                            className="form-control"
                            name="friend_email"
                            value={this.state.friend_email}
                        />
                    </div>
                    {this.state.success_message ? (
                        <div className="alert alert-success">{this.state.success_message}</div>
                    ) : ''}
                    <button
                        onClick={this.referToFriend}
                        className="button-preview btn btn-primary"
                    >
                        Refer to Friend
                    </button>
                </div>
            </div>
        );
    }
}
