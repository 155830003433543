/*
This file contains code for quizplayer.
This file renders component for quizplayer and is used for playing the quiz.
*/

import React from "react";
import queryString from "query-string";
import ReactGA from "react-ga";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import * as DOMPurify from "dompurify";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { NotificationManager } from "react-notifications";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

// const url = new URL(window.location.href);
const isIframe = "";

ChartJS.register(ArcElement, Tooltip, Legend);
var piedata = {
  labels: [],
  datasets: [
    {
      label: ["a", "b", "c", "d", "e", "f"],
      data: [1, 1, 1, 1, 1, 1],
      color: "white",
      backgroundColor: ["red", "green", "orange", "lightblue"],
      borderColor: ["red", "green", "orange", "lightblue"],
      borderWidth: 1,
    },
  ],
};

var options = {
  plugins: {
    label: {
      display: false,
    },
  },
};

// var options = {
//plugins: {

// datalabels: {
//   backgroundColor: function (context) {
//       return context.dataset.backgroundColor;
//   },
//   borderColor: 'white',
//   borderRadius: 25,
//   borderWidth: 2,
//   color: 'white',
//   formatter: function (value, context) {
//       var label1 = context.dataset.label[context.dataIndex];
//       return label1 + ' : ' + value +'%';
//   },
//   font: {
//       weight: 'bold'
//   },
//   padding: 6,
//   //formatter: Math.round
// }
//}
//}

var shareBtnSize = 48;

function onCustomEmailFormSubmit(event, selfobj) {
  var forms = document.forms;
  var self = selfobj;
  
  var jwt = self.state.jwt;
  var email = null;
  var playername = null;
  var optin = false;
  if (forms && forms.length > 0) {
    var fields = forms[0].elements;
    //var url = forms[0].getAttribute("action");

    if (fields && fields.length > 0) {
      for (var i = 0; i < fields.length; i++) {
        var element = fields[i];
        var elementvalue = element.value;
        if (
          element.hasAttribute("required") ||
          element.hasAttribute("aria-required")
        ) {
          if (elementvalue == null || elementvalue == "") {
            alert("All fields are required in this form");
            return false;
          }
        }
        if (element.getAttribute("type") == "email") {
          email = element.value;
        }
        if (element.getAttribute("type") == "text") {
          playername = element.value;
        }
        if (element.getAttribute("type") == "checkbox") {
          optin = element.checked;
          if (optin === false) {
            alert("Please select the checkbox before proceeding.");
            return false;
          }
        }
      }
    }

    //submit data to quizzes4leads.com
    var queryurl =
      self.state.basepath + "quizplayer/playquizEx/" + self.state.quizid + "/3";
    var postdata = "email=" + email + "&name=" + playername;
    var playerreportcard = {};

    fetch(queryurl, {
      method: "post",
      headers: {
        jwt,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then((response) => {
      return response.json();
    }).then(function (response) {
        if (response && response.status == 200 && response.data) {
          var formresponse = forms[0].submit();
          if (
            self.state.landingformtype == null ||
            self.state.landingformtype == "standard"
          ) {
            var scoremessage = response.data.quizscoremsg;
            var score = response.data.score;
            var maxscore = response.data.maxscore;
            var scorepercent = response.data.scorepercent;
            var scoretype = response.data.scoretype;
            var personalitydetails = response.data.personalitydetails;
            var pollresponse = response.data.pollresponse;
            playerreportcard["score"] = score;
            playerreportcard["maxscore"] = maxscore;
            playerreportcard["scorepercent"] = scorepercent;
            playerreportcard["quizid"] = self.state.quizid;
            playerreportcard["anssheet"] = self.state.ansSheet;
            playerreportcard["scoretype"] = scoretype;
            self.setState({
              finishflag: true,
              startflag: false,
              queflag: false,
              selectedQue: null,
              ansArr: null,
              imgArr: null,
              quescounter: 0,
              qucounter: 0,
              quizthankyoumessage: response.data.quizthankyoumsg,
              quizoffermsg: response.data.quizoffermsg,
              quizofferbtnlink: response.data.quizofferbtnlink,
              quizofferbtntxt: response.data.quizofferbtntxt,
              quizscoremessage: scoremessage,
              playerreportcard: playerreportcard,
              personalitydetails,
              pollresponse,
              showLoaderFlag: false,
              loaderMessage: "",
            });
          } else if (self.state.landingformtype == "custom") {
            window.location.href = self.state.landingformurl;
          }
        } else {
          NotificationManager.error("Server error: ", response.message);
        }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });

    //submit data to custom html domain
    forms[0].setAttribute("target", "invisibleframe");
    forms[0].submit();
  }
}

class QuizPlayerTheme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz_title: this.props.quiz_title,
      content: this.props.content,
      header_logo: this.props.header_logo,
      quiz: this.props.quiz,
      quiz_footer: this.props.quiz_footer,
      theme_color: this.props.theme_color,
      enable_quiz_title: this.props.enable_quiz_title,
      theme: this.props.theme,
      user: this.props.user,
      mode: this.props.mode,
    };
  }
  componentWillReceiveProps(props) {
    this.setState({
      quiz_title: props.quiz_title,
      content: props.content,
      header_logo: props.header_logo,
      quiz: props.quiz,
      quiz_footer: props.quiz_footer,
      theme_color: props.theme_color,
      theme: props.theme,
      mode: props.mode,
      questionsAttr: props.questionsAttr,
      quescounter: this.props.quescounter,
      startflag: this.props.startflag,
      queflag: this.props.queflag,
    });
  }

  render() {
    let theme_color_code = "";
    if (this.props.theme_color_code) {
      theme_color_code = JSON.parse(this.props.theme_color_code);
    }
    var href =
      this.state.quiz == null ||
      this.state.quiz.compurl == null ||
      this.state.quiz.compurl == ""
        ? "https://www.quizzes4leads.com"
        : this.state.quiz.compurl;
    return (
      <div>
        <div
          className={`panel panel-blur animated slideIn ${
            this.props.theme_color == null || this.props.theme_color == null
              ? this.props.themetype
              : this.props.theme_color
          }`}
          style={{ margin: 0, border: 0 }}
        >
          <div
            className="panel-heading theme1 ql-editor"
            style={{
              background: this.props.header_bg
                ? this.props.header_bg.hex
                : theme_color_code
                ? theme_color_code[0].header_bg
                : "",
            }}
          >
            <div className="row">
              {this.state.enable_quiz_title ? (
                <div
                  className="col-md-10 col-sm-10 col-xs-10"
                  style={{
                    fontWeight: "bold",
                    wordBreak: "break-word",
                    color: this.props.header_text
                      ? this.props.header_text.hex
                      : theme_color_code
                      ? theme_color_code[1].header_text
                      : "",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      this.state.quiz_title == null ||
                        this.state.quiz_title == ""
                        ? "Enter Quiz Title"
                        : this.state.quiz_title
                    ),
                  }}
                ></div>
              ) : (
                ""
              )}
              <div className="col-md-2 col-sm-2 col-xs-2">
                {/* <ColorPicker onSelectLanguage={this.handleLanguage}/> */}
                {this.props.is_company_logo == "1" ? (
                  <img
                    src={
                      this.state.header_logo == null ||
                      this.state.header_logo == ""
                        ? "https://app.quizzes4leads.com/assets/img/q4llogo.png"
                        : this.state.header_logo
                    }
                    className="glyphicon img-responsive pull-right vcenter player"
                    style={{ minWidth: "25px", height: "25px" }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div
            className={`panel-body ${
              this.props.bgimg ? "remove-bg-colors" : ""
            }`}
            id="quizcontent"
            ref={(el) => {
              if (el) {
                el.style.setProperty(
                  "background-color",
                  this.props.header_content_bg
                    ? this.props.header_content_bg.hex
                    : theme_color_code
                    ? theme_color_code[2].header_content_bg
                    : "",
                  "important"
                );
              }
            }}
          >
            <div className="m-t-md qzwcmsg">
              <div style={{ fontSize: "medium", overflowWrap: "break-word" }}>
                {this.state.content}
              </div>
            </div>
          </div>

          {this.state.mode == "free" ? (
            <div
              className="panel-footer 3"
              style={{
                background: this.props.footer_bg
                  ? this.props.footer_bg.hex
                  : theme_color_code
                  ? theme_color_code[4].footer_bg
                  : "",
              }}
            >
              <a
                id="btnqzcreate"
                target="_blank"
                href="https://app.quizzes4leads.com/app/Templates"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      this.props.footer_text_color
                        ? this.props.footer_text_color.hex
                        : theme_color_code
                        ? theme_color_code[5].footer_text_color
                        : "",
                      "important"
                    );
                  }
                }}
              >
                Create Your Free Quiz
              </a>
              <a
                className="pull-right"
                id="qzfooter"
                style={{ paddingTop: "" }}
                href={href}
                target="_blank"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      this.props.footer_text_color
                        ? this.props.footer_text_color.hex
                        : theme_color_code
                        ? theme_color_code[5].footer_text_color
                        : "",
                      "important"
                    );
                  }
                }}
              >
                {this.state.quiz_footer != null && this.state.quiz_footer != ""
                  ? this.state.quiz_footer
                  : "Powered By Quizzes4Leads"}
              </a>
            </div>
          ) : (
            <div
              className="panel-footer"
              style={{
                background: this.props.footer_bg
                  ? this.props.footer_bg.hex
                  : theme_color_code
                  ? theme_color_code[4].footer_bg
                  : "",
              }}
            >
              {!this.props.startflag && this.props.queflag ? (
                <div
                  className="quiz-counter-wrapper"
                  style={{
                    color: this.props.button_color_text
                      ? this.props.button_color_text.hex
                      : theme_color_code
                      ? theme_color_code[7].button_color_text
                      : "",
                  }}
                >
                  {this.props.quescounter + 1} /{" "}
                  {this.props.questionsAttr.length}
                </div>
              ) : null}

              <a
                id="btnqzcreate"
                target="_blank"
                href="https://app.quizzes4leads.com/app/Templates"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      this.props.footer_text_color
                        ? this.props.footer_text_color.hex
                        : theme_color_code
                        ? theme_color_code[5].footer_text_color
                        : "",
                      "important"
                    );
                  }
                }}
              ></a>
              <a
                className="pull-right"
                id="qzfooter"
                style={{ paddingTop: "" }}
                href={href}
                target="_blank"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      this.props.footer_text_color
                        ? this.props.footer_text_color.hex
                        : theme_color_code
                        ? theme_color_code[5].footer_text_color
                        : "",
                      "important"
                    );
                  }
                }}
              ></a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export class Quizplayer extends React.Component {
  constructor(props) {
    super(props);
    this.chartReference = React.createRef();

    this.state = {
      quizid: null,
      quiz: this.props.quiz,
      loading: true,
      showLoaderFlag: false,
      loaderMessage: "Loading Quiz...",
      selectedQue: null,
      startflag: null,
      queflag: null,
      landingflag: null,
      finishflag: null,
      errorflag: null,
      basepath: this.props.basepath,
      localpath: this.props.localpath,
      playeremail: null,
      ansArr: null,
      imgArr: null,
      queType: null,
      quescounter: 0,
      anscounter: 0,
      ansSheet: null,
      selectedAnsArr: null,
      playerreportcard: null,
      quizthankyoumessage: null,
      checkMaxCount: "",
      quizemailmessage: null,
      quizscoremessage: null,
      maxanscount: 1,
      basepath: this.props.basepath,
      isHoveredOnQuizOption: false,
      toggleHover: false,
      theme_color: "",
      chartdata: piedata,
    };
    this.onQuizStart = this.onQuizStart.bind(this);
    this.clickAns = this.clickAns.bind(this);
    this.submitAns = this.submitAns.bind(this);
    this.onSubmitEmail = this.onSubmitEmail.bind(this);
    this.onEmailChanged = this.onEmailChanged.bind(this);
    this.onNameChanged = this.onNameChanged.bind(this);
    this.restartQuiz = this.restartQuiz.bind(this);
    this.handleFBShare = this.handleFBShare.bind(this);
    this.mouseOutOnQuizOption = this.mouseOutOnQuizOption.bind(this);
    this.getQuizResult = this.getQuizResult.bind(this);
    this.onCustomEmailFormSubmit = onCustomEmailFormSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    //   console.log("componentWillReceiveProps "+nextProps);

    this.setState({
      quizid: nextProps["quizid"],
      quiz: nextProps["quiz"],
      selectedQue: nextProps["selectedQue"],
      startflag: nextProps["startflag"],
      finishflag: nextProps["finishflag"],
      errorflag: nextProps["errorflag"],
      basepath: nextProps["basepath"],
    });
  }

  testAlert() {
    alert("success");
  }
  componentDidMount() {
    var queryStringArr = queryString.parse(this.props.location.search);

    var self = this;
    document.body.addEventListener(
      "submit",
      function (event) {
        event.preventDefault();
        onCustomEmailFormSubmit(event, self);
      },
      false
    );

    this.setState(
      { quizid: queryStringArr.quizid, previewFlag: queryStringArr.preview },
      this.loadQuiz
    ); // "top"
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  loadQuiz() {
    var queryurl =
      this.state.basepath + "quizplayer/getquiz/" + this.state.quizid;
    if (this.state.previewFlag != null) {
      queryurl += "?preview=" + this.state.previewFlag;
    }
    var self = this;

    fetch(queryurl, {
      method: "get",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then(function (response) {
      var jwt = response.headers.get("jwt");
      self.setState({jwt});
      return response.json();
    }).then(function (response) {
        if (response && response.status == 200 && response.data) {
          if (
            response.data.quizid == "NULL" ||
            response.data.btnstart == false
          ) {
            self.setState({
              quiz: null,
              quizid: null,
              startflag: false,
              finishflag: false,
              loading: false,
              errorflag: true,
              maxanscount: response.data.maxanscount,
              checkMaxCount: response.data.maxques,
              queArr: response.data.que,
              quecounter: 0,
            });
          } else {
            document.body.classList.remove("blur-theme");
            document.body.setAttribute("style", "background:transparent");
            var root = document.getElementById("root");
            root.setAttribute("style", "background:transparent");

            var googletags = [
              {
                trackingId: "UA-155030356-1",
                gaOptions: {
                  name: "quizzes4leads_ga",
                },
              },
            ];

            //{ debug: true, alwaysSendToDefaultTracker: false }

            document.body.classList.add(response.data.theme);
            if (response.data.google_analytics != null) {
              googletags.push({
                trackingId: response.data.google_analytics,
                gaOptions: { name: "custom_ga" },
              });
            }

            ReactGA.initialize(googletags, {
              debug: true,
              alwaysSendToDefaultTracker: false,
            });
            ReactGA.pageview(window.location.href, [
              "custom_ga",
              "quizzes4leads_ga",
            ]);

            if (response.data.backgroundimg != null) {
              var bgimagesetting =
                'background-image: url("' +
                response.data.backgroundimg +
                '"); background-size: cover; background-repeat: no-repeat;background-attachment:fixed;';

              if (
                response.data.theme == "theme12" ||
                response.data.theme == "theme13"
              ) {
                bgimagesetting =
                  'background-image:url("' +
                  response.data.backgroundimg +
                  '"); background-size: cover; background-repeat: no-repeat;background-attachment:fixed;';
              }

              document.body.setAttribute("style", bgimagesetting);
            }

            self.setState({
              quiz: response.data,
              startflag: response.data.btnstart,
              finishflag: !response.data.btnstart,
              loading: false,
              errorflag: false,
              queArr: response.data.que,
              checkMaxCount: response.data.maxques,
              quecounter: 0,
              collectuseremailflag: response.data.collectuseremailflag,
              emailformtype: response.data.emailformtype,
              quizemailformcode: response.data.quizemailformcode,
              quizemailmessage: response.data.quizemailmsg,
              theme_color: response.data.theme_color,
              landingformtype: response.data.landingformtype,
              landingformurl: response.data.landingformurl,
              mode: response.data.mode,
            });
          }
        } else {
          NotificationManager.error("Server error: " + response.message);
        }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  restartQuiz(event) {
    this.setState({ startflag: true, finishflag: false, errorflag: false,queflag: false,
      selectedQue: null,
      ansArr: null,
      ansSheet:null,
      ansCounter:0,
      imgArr: null,
      quescounter: 0,
      quecounter: 0 });
  }

  onQuizStart(event) {
    var data = this.state.queArr;
    var jwt = this.state.jwt;
    var quecounter = this.state.quecounter;
    var tempAnsArr = JSON.parse(data[quecounter].jsonans);
    var tempquetype = data[quecounter].quetype;
    var tempMarkArr = JSON.parse(data[quecounter].jsonmarks);
    var maxanscount = 1;
    if (tempquetype == "MCMA") {
      maxanscount = tempMarkArr.filter((element) => element == 1).length;
    }

    var tempimgArr = null;
    var ansSheet = [];
    if (data[quecounter].jsonansimg != null) {
      tempimgArr = JSON.parse(data[quecounter].jsonansimg);
    }
    //quecounter++;
    this.setState({
      startflag: false,
      queflag: true,
      finishflag: false,
      selectedQue: data[quecounter],
      ansArr: tempAnsArr,
      queType: tempquetype,
      imgArr: tempimgArr,
      ansSheet: ansSheet,
      maxanscount: maxanscount,
      quecounter: quecounter,
    });

    var queryurl =this.state.basepath + "quizplayer/playquizEx/" + this.state.quizid + "/1";
    fetch(queryurl, {
      method: "post",
      headers:{jwt},
      body: null,
    }).then((response) => {
      return response.json();
    }).then((response) => {
      if (response && response.status == 200 && response.data) {
      } else {
        NotificationManager.error("Server error: " + response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  handleFBShare(event) {
    var quizid = this.state.quizid;

    //fbshare(this.state.basepath+"quizplayer.html?quizid="+quizid,this.state.quiz.quiztitle,this.state.basepath+this.state.quiz.backgroundimg);
  }

  getQuizResult(event) {
    var playerreportcard = {};
    var self = this;
    var jwt = this.state.jwt;
    var queryurl =
      this.state.basepath + "quizplayer/playquizEx/" + this.state.quizid + "/3";
    fetch(queryurl, {
      method: "get",
      headers: {
        jwt,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      return response.json();
    }).then(function (response) {
        if (response && response.status == 200 && response.data) {
          var scoremessage = response.data.quizscoremsg;
          var score = response.data.score;
          var maxscore = response.data.maxscore;
          var scorepercent = response.data.scorepercent;
          var scoretype = response.data.scoretype;
          var personalitydetails = response.data.personalitydetails;
          var pollresponse = response.data.pollresponse;
          playerreportcard["score"] = score;
          playerreportcard["maxscore"] = maxscore;
          playerreportcard["scorepercent"] = scorepercent;
          playerreportcard["quizid"] = self.state.quizid;
          playerreportcard["anssheet"] = self.state.ansSheet;
          playerreportcard["scoretype"] = scoretype;
          self.setState({
            finishflag: true,
            startflag: false,
            queflag: false,
            selectedQue: null,
            ansArr: null,
            imgArr: null,
            quescounter: 0,
            qucounter: 0,
            quizthankyoumessage: response.data.quizthankyoumsg,
            quizoffermsg: response.data.quizoffermsg,
            quizofferbtnlink: response.data.quizofferbtnlink,
            quizofferbtntxt: response.data.quizofferbtntxt,
            quizscoremessage: scoremessage,
            playerreportcard: playerreportcard,
            personalitydetails,
            pollresponse,
            showLoaderFlag: false,
            loaderMessage: "",
          });
        }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  onSubmitEmail(event) {
    var email = this.state.playeremail;
    var { playername, landingformtype, landingformurl, quiz, chartdata, jwt } =
      this.state;
    if (email == undefined || email == null || email == "") {
      //$('#emailError').show();
      //$('#emailError').html("Email address cannot be blank!!");
      alert("Email address cannot be blank!!");
      return false;
    }
    if (playername == undefined || playername == null || playername == "") {
      //$('#emailError').show();
      //$('#emailError').html("Email address cannot be blank!!");
      alert("Name cannot be empty!!");
      return false;
    }

    var re =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    var flag = re.test(email);
    if (flag == false) {
      //$('#emailError').show();
      //$('#emailError').html("Incorrect Email Address!!");
      alert("Incorrect Email Address!!");
      return false;
    } else {
      this.setState({
        showLoaderFlag: true,
        loaderMessage: "",
      });
      var self = this;
      var queryurl =
        //this.state.basepath + "index.php/quizzer/playquizEx/" + this.state.quizid + "/3";
        this.state.basepath +
        "quizplayer/playquizEx/" +
        this.state.quizid +
        "/3";
      var postdata = "email=" + email + "&name=" + playername;
      var playerreportcard = {};
      playerreportcard["email"] = email;
      playerreportcard["name"] = playername;

      fetch(queryurl, {
        method: "post",
        headers: {
          jwt,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: postdata,
      }).then((response) => {
        return response.json();
      }).then(function (response) {
        if (response && response.status == 200 && response.data) {
          if (landingformtype == null || landingformtype == "standard") {
            var scoremessage = response.data.quizscoremsg;
            var score = response.data.score;
            var maxscore = response.data.maxscore;
            var scorepercent = response.data.scorepercent;
            var scoretype = response.data.scoretype;
            var personalitydetails = response.data.personalitydetails;
            if (quiz.quiztype == "qztype7") {
              console.log("dataset")
              chartdata.datasets[0].data = [];
              chartdata.datasets[0].label = [];
              personalitydetails.forEach(function (value, index) {
                chartdata.datasets[0].data[index] = value.occurence;
                chartdata.labels[index] = value.title;
              });
            }

            if (quiz.quiztype == "qztype8") {
              chartdata.datasets[0].data = [];
              chartdata.labels = [];
              if(personalitydetails && personalitydetails.categoryresult) {
                var categoryresult =  JSON.parse(personalitydetails.categoryresult)
                // Object.keys(categoryresult).forEach(function (item, index1) {
                //   Object.keys(categoryresult[item]).filter(item=>item!='totaloccurences').forEach(function (key, index2) {
                //     if(chartdata[index1] == null){
                //       chartdata[index1] = {datasets:[],labels:[]}
                //     }
                //     if(chartdata[index1].datasets == null){
                //       chartdata[index1] = {datasets:[],labels:[]}
                //       chartdata[index1].datasets[0]= {data:[]};
                //     }
                //     if(chartdata[index1].datasets[0] == null){
                //       chartdata[index1].datasets[0] = {data:[]};
                //     }
                //     if(chartdata[index1].datasets[0].data[index2] == null){
                //       chartdata[index1].datasets[0].data[index2]= [];
                //     }
                //     if(chartdata[index1].labels==null){
                //       chartdata[index1].labels = [];
                //     }
                //     chartdata[index1].datasets[0].data[index2] = categoryresult[item][key];
                //     chartdata[index1].labels[index2] = key;
                //   });
                // })

                //Object.keys(categoryresult).forEach(function (item, index1) {
                  Object.keys(categoryresult).filter(item=>item!='totaloccurences').forEach(function (key, index2) {
                    chartdata.datasets[0].data[index2] = categoryresult[key];
                    chartdata.labels[index2] = key;
                  });
                //})
              }
            }

            var pollresponse = response.data.pollresponse;
            playerreportcard["score"] = score;
            playerreportcard["maxscore"] = maxscore;
            playerreportcard["scorepercent"] = scorepercent;
            playerreportcard["quizid"] = self.state.quizid;
            playerreportcard["anssheet"] = self.state.ansSheet;
            playerreportcard["scoretype"] = scoretype;
            self.setState({
              chartdata: chartdata,
              finishflag: true,
              startflag: false,
              queflag: false,
              selectedQue: null,
              ansArr: null,
              imgArr: null,
              quescounter: 0,
              qucounter: 0,
              quizthankyoumessage: response.data.quizthankyoumsg,
              quizoffermsg: response.data.quizoffermsg,
              quizofferbtnlink: response.data.quizofferbtnlink,
              quizofferbtntxt: response.data.quizofferbtntxt,
              quizscoremessage: scoremessage,
              playerreportcard: playerreportcard,
              personalitydetails,
              pollresponse,
              showLoaderFlag: false,
              loaderMessage: "",
            });
          } else if (landingformtype == "custom") {
            if (quiz.quiztype == "qztype5" || quiz.quiztype == "qztype6") {
              landingformurl = response.data.personalitydetails.url;
            } else if (quiz.quiztype == "qztype2" || quiz.quiztype == "qztype5" || quiz.quiztype == "qztype9") {
              landingformurl = response.data.personalitydetails.landingformurl;
            }
            window.location.href = landingformurl;
          }
          return flag;
        } else {
          self.setState({
            showLoaderFlag: false,
            loaderMessage: response.message,
          });
        }
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });

      return flag;
    }
  }

  onEmailChanged(event) {
    this.setState({ playeremail: event.target.value });
  }
  onNameChanged(event) {
    this.setState({ playername: event.target.value });
  }

  clickAns(event) {
    event.currentTarget.classList.add("remove-hover");
    // event.currentTarget.style.backgroundColor = 'unset';
    var counter = this.state.quescounter + 1;
    var quecounter = this.state.quecounter;
    var anscounter = this.state.anscounter;
    var maxanscount = this.state.maxanscount;
    var jsonselans = null;

    if (anscounter < maxanscount) {
      var id = event.currentTarget.getAttribute("data-id");
      var checkedFlag = event.target.checked;

      //console.log(id);
      //var queid = this.state.selectedQue.id;
      var ansSheet = this.state.ansSheet;

      var selans = this.state.selectedAnsArr;
      var quetype = this.state.selectedQue.quetype;
      if (selans == null) {
        selans = {};
        selans["val"] = [];
        selans["img"] = [];
        selans["quetype"] = quetype;
      }
      if (selans["val"] == null) {
        selans["val"] = [];
      }
      if (selans["img"] == null) {
        selans["img"] = [];
      }

      if (quetype != "MCMA" || checkedFlag) {
        if (this.state.ansArr[id] != null) {
          selans["val"].push(this.state.ansArr[id]);
        } else {
          selans["val"] = null;
        }
        if (this.state.imgArr[id] != null) {
          selans["img"].push(this.state.imgArr[id]);
        } else {
          selans["img"] = null;
        }

        if (ansSheet[counter] == null) {
          ansSheet[counter] = [];
        }
        ansSheet[counter].push(this.state.ansArr[id]);
        anscounter++;
        jsonselans = JSON.stringify(selans);
        if (anscounter == maxanscount) {
          // we have got all the answer..submit it and move on to next question.

          this.submitAns(counter, jsonselans, ansSheet);
          if (quecounter + 1 < this.state.queArr.length) {
            //alert("continue");
            quecounter++;
            counter = this.state.quescounter;
            counter++;

            var selectedQue = this.state.queArr[quecounter];

            //check for logic branching
            if (this.state.quiz.islogicbranchingenabled) {
              var logicalbranches = this.state.quiz.logicalbranches;
              if (logicalbranches) {
                //var filteredbranch = logicalbranches.filter(element=>((element.answer == selans.val[0])));
                var branch = logicalbranches[this.state.selectedQue.queid];
                if (branch && branch.length > 0) {
                  var filteredbranch = branch.filter(
                    (element) =>
                      element.questionid == this.state.selectedQue.queid &&
                      (decodeURIComponent(element.answer) ==
                        decodeURIComponent(selans.val[0]) ||
                        element.answer == "any")
                  );

                  if (filteredbranch && filteredbranch.length > 0) {
                    if (filteredbranch[0].nextquestion == "landingpage") {
                      if (this.state.collectuseremailflag == "1") {
                        //alert('collectemail');
                        this.setState({
                          finishflag: false,
                          startflag: false,
                          queflag: false,
                          landingflag: true,
                          selectedQue: null,
                          ansArr: null,
                          imgArr: null,
                          quescounter: 0,
                          quecounter: 0,
                          anscounter: 0,
                          ansSheet: ansSheet,
                          selectedAnsArr: null,
                          showLoaderFlag: false,
                          loaderMessage: "",
                          endquizflag: true,
                        });
                      } else {
                        this.setState({ endquizflag: true });
                      }
                      return;
                    } else {
                      var nextqueid = filteredbranch[0].nextquestionid;
                      var nextqueobj = this.state.queArr.filter(
                        (element) => element.queid == nextqueid
                      );
                      if (nextqueobj && nextqueobj.length > 0) {
                        selectedQue = nextqueobj[0];
                      }
                    }
                  }
                }
              }
            }

            var tempAnsArr = JSON.parse(selectedQue.jsonans);
            var tempimgArr = null;
            var tempquetype = selectedQue.quetype;
            var tempMarkArr = JSON.parse(selectedQue.jsonmarks);
            var maxanscount = 1;

            if (selectedQue.jsonansimg != null) {
              tempimgArr = JSON.parse(selectedQue.jsonansimg);
            }
            this.setState({
              startflag: false,
              queflag: true,
              finishflag: false,
              selectedQue: selectedQue,
              ansArr: tempAnsArr,
              selectedAnsArr: null,
              queType: tempquetype,
              showLoaderFlag: false,
              loaderMessage: "",
              imgArr: tempimgArr,
              maxanscount: maxanscount,
              anscounter: 0,
              quescounter: counter,
              quecounter,
              //selectedAnsArr: selans,
            });
          } else {
            //alert("complete");
            if (this.state.collectuseremailflag == "1") {
              //alert('collectemail');
              this.setState({
                finishflag: false,
                startflag: false,
                queflag: false,
                landingflag: true,
                selectedQue: null,
                ansArr: null,
                imgArr: null,
                quescounter: 0,
                quecounter: 0,
                anscounter: 0,
                ansSheet: ansSheet,
                selectedAnsArr: null,
                showLoaderFlag: false,
                loaderMessage: "",
              });
            }else{
              this.setState({loading:true,loaderMessage:"Hang tight!! We are processing your responses..."})
            }
          }
        } else {
          this.setState({ anscounter, selectedAnsArr: selans });
        }
      } else {
        var index = selans["val"].indexOf(this.state.ansArr[id]);
        selans["val"].splice(index, 1);
        selans["img"].splice(index, 1);
        anscounter--;
        this.setState({ anscounter, selectedAnsArr: selans });
      }
    }
  }

  mouseOutOnQuizOption(e) {
    e.target.classList.remove("remove-hover");
  }

  submitAns(counter, jsonselans, ansSheet) {
    var queryurl =
      this.state.basepath + "quizplayer/" + "getQuestion/" + counter;
    var {emailformtype, landingformtype, landingformurl,quiz,chartdata,jwt } =
      this.state;
    var postdata =
      "userid=&type=quiz&action=getnextque&params=" +
      jsonselans +
      "&quetitle=" +
      this.state.selectedQue.title +
      "&queid=" +
      this.state.selectedQue.queid;

    var self = this;
    
    fetch(queryurl, {
      method: "post",
      headers: {
        jwt,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then((response) => {
      return response.json();
    }).then(function (response) {
        if (response && response.status == 200 && response.data) {
            if (response.data.status == "complete") {
              var { quiztype, quizemailformcode } = response.data.data.quiz[0];
              var personalitydetails = response.data.data.personalitydetails;

              if (response.data.data.collectuseremailflag) {
                //console.log("quiz collect user email")
                
                if (emailformtype == "standard") {
                  console.log("email form type is standard")
                
                  var quizemailmsg = response.data.data.quizemailmsg;
                  if (landingformtype == "custom"){
                    console.log("landing form type is custom")
                
                    if(quiztype == "qztype2" || quiztype == "qztype7" || quiztype == "qztype9") {
                      landingformurl = personalitydetails.landingformurl;
                    }
                    if(quiztype == "qztype1") {
                      landingformurl = response.data.data.quiz[0].landingformurl;
                    }

                  }
                  else{
                    console.log("landing form type is standard")
                  
                  }
                } else if (emailformtype == "custom") {
                  var quizemailmsg = response.data.data.quizemailmsg;
                  if (quiztype == "qztype2" || quiztype == "qztype7" || quiztype == "qztype5" || quiztype == "qztype9") {
                    quizemailformcode = personalitydetails.quizemailformcode;
                    if (landingformtype == "custom") {
                      landingformurl = personalitydetails.landingformurl;
                    }
                  }
                  if(quiztype == "qztype1") {
                    landingformurl = response.data.data.quiz[0].landingformurl;
                  }
                  if (quiztype == "qztype6") {
                    quizemailformcode = personalitydetails.emailformcode || personalitydetails.quizemailformcode;
                    if (landingformtype == "custom") {
                      landingformurl = personalitydetails.url;
                    }
                  }
                  

                  self.setState({
                    landingformurl,
                    finishflag: false,
                    startflag: false,
                    queflag: false,
                    landingflag: true,
                    selectedQue: null,
                    ansArr: null,
                    imgArr: null,
                    quescounter: 0,
                    quecounter: 0,
                    anscounter: 0,
                    quizemailmessage: quizemailmsg,
                    quizemailformcode,
                    ansSheet: ansSheet,
                    selectedAnsArr: null,
                    showLoaderFlag: false,
                    loaderMessage: "",
                  });
                }
              }
              // condition when quiz does not collect user email 
              else {
                console.log("quiz does not collect user email")
                var scoremessage = response.data.data.quizscoremsg;
                var score = response.data.data.score;
                var maxscore = response.data.data.maxscore;
                var scorepercent = response.data.data.scorepercent;
                var scoretype = response.data.data.scoretype;
                var personalitydetails = response.data.data.personalitydetails;
                var pollresponse = response.data.data.pollresponse;
                
                var playerreportcard = {score,maxscore,scorepercent,scoretype,quizid:self.state.quizid,anssheet:self.state.ansSheet};
                    
                if (landingformtype == "custom" ){
                  if(quiztype == "qztype2" || quiztype == "qztype7" || quiztype == "qztype5" || quiztype == "qztype9") {
                    landingformurl = personalitydetails.landingformurl;
                  }
                  if(quiztype == "qztype6") {
                    landingformurl = personalitydetails.landingformurl || personalitydetails.url;
                  }
                  if(quiztype == "qztype1") {
                    landingformurl = response.data.data.quiz[0].landingformurl;
                  }
                  window.location.href = landingformurl;
                }
                // landing form is standard condition
                else{
                  if (quiz.quiztype == "qztype7") {
                    chartdata.datasets[0].data = [];
                    chartdata.datasets[0].label = [];
                    console.log("personalitydetails",personalitydetails)
                    personalitydetails.forEach(function (value, index) {
                      chartdata.datasets[0].data[index] = value.occurence;
                      chartdata.labels[index] = value.title;
                    });
                  }
      
                  if (quiz.quiztype == "qztype8") {
                    //chartdata = [];
                    chartdata.datasets[0].data = [];
                    chartdata.labels = [];
                    // if(personalitydetails && personalitydetails.categoryresult) {
                    //   var categoryresult =  JSON.parse(personalitydetails.categoryresult)
                    //   Object.keys(categoryresult).forEach(function (item, index1) {
                    //     Object.keys(categoryresult[item]).filter(item=>item!='totaloccurences').forEach(function (key, index2) {
                    //       if(chartdata[index1] == null){
                    //         chartdata[index1] = {datasets:[],labels:[]}
                    //       }
                    //       if(chartdata[index1].datasets == null){
                    //         chartdata[index1] = {datasets:[],labels:[]}
                    //         chartdata[index1].datasets[0]= {data:[]};
                    //       }
                    //       if(chartdata[index1].datasets[0] == null){
                    //         chartdata[index1].datasets[0] = {data:[]};
                    //       }
                    //       if(chartdata[index1].datasets[0].data[index2] == null){
                    //         chartdata[index1].datasets[0].data[index2]= [];
                    //       }
                    //       if(chartdata[index1].labels==null){
                    //         chartdata[index1].labels = [];
                    //       }
                    //       chartdata[index1].datasets[0].data[index2] = categoryresult[item][key];
                    //       chartdata[index1].labels[index2] = key;
                    //     });
                    //   })
                    // }
                    if(personalitydetails && personalitydetails.categoryresult) {
                      var categoryresult =  JSON.parse(personalitydetails.categoryresult)
                      Object.keys(categoryresult).filter(item=>item!='totaloccurences').forEach(function (key, index2) {
                        chartdata.datasets[0].data[index2] = categoryresult[key];
                        chartdata.labels[index2] = key;
                      });
                    }      
                  }
                  
                  self.setState({
                    chartdata,
                    landingformurl,
                    finishflag: true,
                    startflag: false,
                    queflag: false,
                    selectedQue: null,
                    ansArr: null,
                    imgArr: null,
                    selectedAnsArr: null,
                    quescounter: 0,
                    quizthankyoumessage: self.state.quiz.quizthankyoumsg,
                    quizoffermsg: self.state.quiz.quizoffermsg,
                    quizofferbtnlink: self.state.quiz.quizofferbtnlink,
                    quizofferbtntxt: self.state.quiz.quizofferbtntxt,
                    quizscoremessage: scoremessage,
                    personalitydetails,
                    pollresponse,
                    playerreportcard: playerreportcard,
                    showLoaderFlag: false,
                    loaderMessage: "",
                    loading:false,
                  });
                }
            }
          }
        }
      }).catch(err=>{
        NotificationManager.error("Server error: " , err.message)
      });
  }

  onChangeCheckedBox(event) {
    var id = event.data.getAttribute("id");
  }

  renderAns() {
    let theme_color_code = JSON.parse(this.state.theme_color);
    var self = this;
    var tempquetype = this.state.queType;
    var maxanscount = this.state.maxanscount;
    return this.state.ansArr.map(function (ansItem, counter) {
      var checkFlag =
        self.state.selectedAnsArr == null ||
        self.state.selectedAnsArr.val == null ||
        self.state.selectedAnsArr.val.filter((element) => element == ansItem)
          .length == 0
          ? false
          : true;
      if (ansItem == null) {
        return null;
      } else if (tempquetype != "MCMA") {
        return (
          <div
            className={
              self.state.imgArr && !self.state.imgArr[0]
                ? "col-md-12 col-sm-12 col-xs-12" // If there is no image, it will take full width
                : "col-md-6 col-sm-6 col-xs-12" // If there is an image, it will take half width
            }
          >
            <button
              key={counter}
              style={{ whiteSpace: "normal", outline: "none" }}
              className="m-t-md btn ansfont ans-full-width"
              id={
                theme_color_code && theme_color_code[6].button_color !== ""
                  ? "custom_theme_button_modify"
                  : ""
              }
              onClick={self.clickAns}
              onMouseOut={(e) => self.mouseOutOnQuizOption(e)}
              onMouseLeave={self.toggleHover}
              data-id={counter}
              ref={(el) => {
                if (el) {
                  el.style.setProperty(
                    "background",
                    theme_color_code ? theme_color_code[6].button_color : "",
                    "important"
                  );
                  el.style.setProperty(
                    "color",
                    theme_color_code &&
                      theme_color_code[7] &&
                      theme_color_code[7].button_color_text
                      ? theme_color_code[7].button_color_text
                      : self.state.quiz && self.state.quiz.welcome_message_color
                      ? self.state.quiz.welcome_message_color
                      : "", // If none of the conditions are met, set an empty color value
                    "important"
                  );
                }
              }}
            >
              {self.state.imgArr &&
                self.state.imgArr[counter] && ( // Check if there is an image
                  <img
                    className={self.state.imgArr[counter] ? "" : "hide"}
                    src={self.state.imgArr[counter] || ""}
                    data-id={counter}
                  />
                )}
              <div className="ans-text">
                {ansItem === "" ? "" : decodeURIComponent(ansItem)}
              </div>
            </button>
          </div>
        );
      } else {
        // console.log("check2 checkflag is " + ansItem);
        return (
          <div
            className={
              self.state.imgArr && self.state.imgArr[0]
                ? "m-t-md col-md-6 col-sm-12"
                : "mulit-checkbox"
            }
            key={counter}
          >
            <input
              id={counter}
              type="checkbox"
              name={counter}
              onChange={self.clickAns}
              data-id={counter}
              checked={
                self.state.selectedAnsArr != null &&
                self.state.selectedAnsArr.val != null &&
                self.state.selectedAnsArr.val.length != -1 &&
                self.state.selectedAnsArr.val.length != 0 &&
                self.state.selectedAnsArr.val[counter] == ansItem
              }
            />

            <img
              className={
                self.state.imgArr == null ||
                self.state.imgArr.length == -1 ||
                self.state.imgArr.length == 0 ||
                self.state.imgArr[counter] == null ||
                self.state.imgArr[counter] == ""
                  ? "hide"
                  : "col-md-12"
              }
              src={
                self.state.imgArr == null ||
                self.state.imgArr.length == -1 ||
                self.state.imgArr.length == 0 ||
                self.state.imgArr[counter] == null ||
                self.state.imgArr[counter] == ""
                  ? ""
                  : self.state.imgArr[counter]
              }
              data-id={counter}
            />
            <label style={{ paddingLeft: 10 }}>
              {ansItem == "" ? "" : decodeURIComponent(ansItem)}
            </label>
          </div>
        );
      }
    });
  }

  renderFooter() {
    var href =
      this.state.quiz == null ||
      this.state.quiz.compurl == null ||
      this.state.quiz.compurl == ""
        ? "https://www.quizzes4leads.com"
        : this.state.quiz.compurl;
    // console.log('href in renderfooter is ' + href);
    return (
      <div className="panel-footer 2">
        <a
          className="graded-footer"
          id="btnqzcreate"
          href="https://app.quizzes4leads.com/index.html"
          target="_blank"
        >
          Create Your Free Quiz
        </a>
        <a
          style={{ paddingTop: 3 }}
          className="pull-right graded-footer"
          id="qzfooter"
          target="_blank"
          href="http://wwww.google.com"
        >
          {this.state.quiz == null ||
          this.state.quiz.qzfootermsg == null ||
          this.state.quiz.qzfootermsg == ""
            ? "Powered by Quizzes4Leads"
            : this.state.quiz.qzfootermsg}
        </a>
      </div>
    );
  }

  renderHeader() {
    return (
      <div
        className={`panel-heading ${
          this.state.quiz == null || this.state.quiz.theme == null
            ? null
            : this.state.quiz.theme
        }`}
      >
        <div className="row">
          <div
            className="col-md-10 col-sm-10 col-xs-10"
            style={{
              fontWeight: "bold",
              wordBreak: "break-word",
              marginTop: 10,
            }}
          >
            {this.state.quiz == null
              ? "Error"
              : this.state.quiz.quiztitle == ""
              ? "Error"
              : this.state.quiz.quiztitle.toUpperCase()}
          </div>
          <div className="col-md-2 col-sm-2 col-xs-2">
            {this.state.quiz.is_company_logo == "on" ? (
              <a
                href={this.state.quiz == null ? null : this.state.quiz.compurl}
                target="_blank"
              >
                <img
                  className="glyphicon img-responsive pull-right vcenter"
                  src={
                    this.state.quiz == null || this.state.quiz.complogo == null
                      ? "/assets/img/q4llogo.png"
                      : this.state.quiz.complogo
                  }
                  width="40"
                  height="40"
                  style={{ minWidth: 40 }}
                />
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }

  renderScorePage() {
    console.log("this.state.chartdata",this.state.chartdata)
    let theme_color_code = JSON.parse(this.state.theme_color);

    var link =
      this.state.quizofferbtnlink.indexOf("http://") == 0 ||
      this.state.quizofferbtnlink.indexOf("https://") == 0
        ? this.state.quizofferbtnlink
        : "https://";
    var shareUrl =
      this.state.localpath + "quizplayer.html?quizid=" + this.state.quizid;
    var title = this.state.quiz.quiztitle;
    var quizscore =
      this.state.playerreportcard != null &&
      this.state.playerreportcard.score != null
        ? this.state.playerreportcard.score
        : 0 + "%";
    if (
      this.state.playerreportcard != null &&
      this.state.playerreportcard["scoretype"] != null &&
      this.state.playerreportcard["scoretype"] == "Marks"
    ) {
      quizscore = this.state.playerreportcard["score"].substring(0, 1);
    }

    var content = (
      <div
        className="col-md-10 message-warpper-modify"
        style={{ marginTop: 15 }}
      >
        {this.state.quiz.result_page_custom_text ? (
          <div className="custom-result-wrapper qzwcmsg">
            <div
              ref={(el4) => {
                if (el4) {
                  el4.style.setProperty(
                    "color",
                    theme_color_code &&
                      theme_color_code[3] &&
                      theme_color_code[3].header_content_text
                      ? theme_color_code[3].header_content_text
                      : this.state.quiz && this.state.quiz.welcome_message_color
                      ? this.state.quiz.welcome_message_color
                      : "",
                    "important"
                  );
                }
              }}
              dangerouslySetInnerHTML={{
                __html: this.state.quiz.result_page_custom_text,
              }}
            />
          </div>
        ) : (
          <div>
            <div
              ref={(el4) => {
                if (el4) {
                  el4.style.setProperty(
                    "color",
                    theme_color_code &&
                      theme_color_code[3] &&
                      theme_color_code[3].header_content_text
                      ? theme_color_code[3].header_content_text
                      : this.state.quiz && this.state.quiz.welcome_message_color
                      ? this.state.quiz.welcome_message_color
                      : "",
                    "important"
                  );
                }
              }}
              className="qzwcmsg text-center"
              style={{ fontSize: 24, color: "black" }}
            >
              {this.state.quiz && this.state.quiz.this_is_certificate_is_text
                ? this.state.quiz.this_is_certificate_is_text
                : "This certificate is proudly presented on completion of the quiz"}
            </div>

            <div
              ref={(el4) => {
                if (el4) {
                  el4.style.setProperty(
                    "color",
                    theme_color_code &&
                      theme_color_code[3] &&
                      theme_color_code[3].header_content_text
                      ? theme_color_code[3].header_content_text
                      : this.state.quiz && this.state.quiz.welcome_message_color
                      ? this.state.quiz.welcome_message_color
                      : "",
                    "important"
                  );
                }
              }}
              className="qzwcmsg text-center"
              style={{ fontSize: 38, color: "black" }}
            >
              <b>
                {this.state.quiz.quiztitle
                  .replace(/<[^>]*>/g, "")
                  .toUpperCase()}{" "}
              </b>
            </div>
            <br />
            <div
              className="qzwcmsg text-center"
              style={{ fontSize: 22, color: "black" }}
              ref={(el4) => {
                if (el4) {
                  el4.style.setProperty(
                    "color",
                    theme_color_code &&
                      theme_color_code[3] &&
                      theme_color_code[3].header_content_text
                      ? theme_color_code[3].header_content_text
                      : this.state.quiz && this.state.quiz.welcome_message_color
                      ? this.state.quiz.welcome_message_color
                      : "",
                    "important"
                  );
                }
              }}
            >
              to{" "}
            </div>
          </div>
        )}
        <div
          className="qzwcmsg text-center"
          ref={(el4) => {
            if (el4) {
              el4.style.setProperty(
                "color",
                theme_color_code &&
                  theme_color_code[3] &&
                  theme_color_code[3].header_content_text
                  ? theme_color_code[3].header_content_text
                  : this.state.quiz && this.state.quiz.welcome_message_color
                  ? this.state.quiz.welcome_message_color
                  : "",
                "important"
              );
            }
          }}
          style={{ fontSize: 28, color: "black" }}
        >
          <b>
            <i>
              <u>
                {this.state.playername != null && this.state.playername != ""
                  ? this.state.playername.toUpperCase()
                  : "Test Player"}
              </u>
            </i>
          </b>
        </div>
        <div
          className="qzwcmsg text-center"
          style={{ fontSize: 20, color: "black" }}
          ref={(el4) => {
            if (el4) {
              el4.style.setProperty(
                "color",
                theme_color_code &&
                  theme_color_code[3] &&
                  theme_color_code[3].header_content_text
                  ? theme_color_code[3].header_content_text
                  : this.state.quiz && this.state.quiz.welcome_message_color
                  ? this.state.quiz.welcome_message_color
                  : "",
                "important"
              );
            }
          }}
        >
          <b>
            <i>{parseFloat(quizscore)}% </i>
          </b>
        </div>
      </div>
    );
    var backgroundImage = "url('/assets/img/certificate.png')";
    var imageurl = encodeURIComponent(
      this.props.localpath + this.state.quiz.backgroundimg
    );
    imageurl = encodeURIComponent("&img=" + imageurl);
    var title = encodeURIComponent("I got " + quizscore);
    title = encodeURIComponent("&title=" + title);
    var facebookshareurl =
      "app_id=3805076026252317&display=popup&link=" +
      shareUrl +
      imageurl +
      title +
      encodeURIComponent("&url=" + shareUrl) +
      "&redirect_uri=" +
      shareUrl;
    facebookshareurl =
      "https://www.facebook.com/dialog/feed?" + facebookshareurl;
    // https://www.facebook.com/dialog/feed?app_id=3805076026252317&display=popup&link=https%3A%2F%2Fwww.tryinteract.com%2Fshare%2Fquiz%2F5eeb9913081532001424aab2%3Fid%3D5eeb9913081532001424aab2%26img%3Dhttps%253A%252F%252Fwww.tryinteract.com%252Fimages%252Fdefault-cover.jpg%26title%3DI%2520got%2520undefined%2520-%2520Untitled%2520Quiz%26url%3Dhttps%253A%252F%252Fquiz.tryinteract.com%252F%2523%252F5eeb9913081532001424aab2&redirect_uri=https%3A%2F%2Fwww.tryinteract.com%2Fshare.php%3Fid%3D5eeb9913081532001424aab2'

    if (
      this.state.quiz.quiztype == "qztype2" ||
      this.state.quiz.quiztype == "qztype5" ||
      this.state.quiz.quiztype == "qztype9"
    ) {
      backgroundImage = null;
      content = (
        <div
          className="col-md-10 message-warpper-modify"
          style={{ marginTop: 15 }}
        >
          <div
            ref={(el4) => {
              if (el4) {
                el4.style.setProperty(
                  "color",
                  theme_color_code &&
                    theme_color_code[3] &&
                    theme_color_code[3].header_content_text
                    ? theme_color_code[3].header_content_text
                    : this.state.quiz && this.state.quiz.welcome_message_color
                    ? this.state.quiz.welcome_message_color
                    : ""
                );
              }
            }}
            className="qzwcmsg text-center"
            style={{ fontSize: 38, color: "black" }}
          >
            <b>{this.toTitleCase(this.state.personalitydetails.title)}</b>
          </div>
          <br />

          {this.state.personalitydetails != null &&
          this.state.personalitydetails.personalityimage != null ? (
            <div
              ref={(el4) => {
                if (el4) {
                  el4.style.setProperty(
                    "color",
                    theme_color_code &&
                      theme_color_code[3] &&
                      theme_color_code[3].header_content_text
                      ? theme_color_code[3].header_content_text
                      : this.state.quiz && this.state.quiz.welcome_message_color
                      ? this.state.quiz.welcome_message_color
                      : "",
                    "important"
                  );
                }
              }}
              className="qzwcmsg text-center"
            >
              <img
                src={this.state.personalitydetails.personalityimage}
                style={{ width: "100%" }}
              />
              <br />
            </div>
          ) : null}

          {/* <div className="qzwcmsg text-center" style={{ fontSize: 22, color: "black" }}>
            This is how people describe you -{" "}
          </div> */}
          <div
            ref={(el4) => {
              if (el4) {
                el4.style.setProperty(
                  "color",
                  theme_color_code &&
                    theme_color_code[3] &&
                    theme_color_code[3].header_content_text
                    ? theme_color_code[3].header_content_text
                    : this.state.quiz && this.state.quiz.welcome_message_color
                    ? this.state.quiz.welcome_message_color
                    : "",
                  "important"
                );
              }
            }}
            className="qzwcmsg text-center"
            style={{ fontSize: 28, color: "black" }}
          >
            <b>{this.state.personalitydetails.description}</b>
          </div>
        </div>
      );
    }
    if (this.state.quiz.quiztype == "qztype6") {
      backgroundImage = null;
      content = (
        <div
          className="row message-warpper-modify"
          id="multiple_quiz-result"
          style={{ marginTop: 15 }}
        >
          <div
            ref={(el4) => {
              if (el4) {
                el4.style.setProperty(
                  "color",
                  theme_color_code &&
                    theme_color_code[3] &&
                    theme_color_code[3].header_content_text
                    ? theme_color_code[3].header_content_text
                    : this.state.quiz && this.state.quiz.welcome_message_color
                    ? this.state.quiz.welcome_message_color
                    : "",
                  "important"
                );
              }
            }}
            className="qzwcmsg text-center"
            style={{ fontSize: 38, color: "black" }}
          >
            <b>{this.toTitleCase(this.state.personalitydetails.title)}</b>
          </div>
          <br />

          {this.state.personalitydetails != null &&
          this.state.personalitydetails.personalityimage != null ? (
            <div
              ref={(el4) => {
                if (el4) {
                  el4.style.setProperty(
                    theme_color_code &&
                      theme_color_code[3] &&
                      theme_color_code[3].header_content_text
                      ? theme_color_code[3].header_content_text
                      : this.state.quiz && this.state.quiz.welcome_message_color
                      ? this.state.quiz.welcome_message_color
                      : "",
                    "important"
                  );
                }
              }}
              className="qzwcmsg text-center"
            >
              <img
                src={this.state.personalitydetails.personalityimage}
                style={{ width: "100%" }}
              />
              <br />
            </div>
          ) : null}

          {/* <div className="qzwcmsg text-center" style={{ fontSize: 22, color: "black" }}>
            This is how people describe you -{" "}
          </div> */}
          <div
            ref={(el4) => {
              if (el4) {
                el4.style.setProperty(
                  "color",
                  theme_color_code && theme_color_code[3]
                    ? theme_color_code[3].header_content_text
                    : "",
                  "important"
                );
              }
            }}
            className="qzwcmsg text-center"
            style={{ fontSize: 28, color: "black" }}
          >
            <b>{this.state.personalitydetails.description}</b>
          </div>
        </div>
      );
    }
    if (this.state.quiz.quiztype == "qztype7") {
      backgroundImage = null;
      content = this.state.personalitydetails.map(
        (personalitydetails, index) => {
          return (
            <div className="row" key={index}>
              <div
                className="qzwcmsg text-center"
                ref={(el4) => {
                  if (el4) {
                    el4.style.setProperty(
                      "color",
                      theme_color_code &&
                        theme_color_code[3] &&
                        theme_color_code[3].header_content_text
                        ? theme_color_code[3].header_content_text
                        : this.state.quiz &&
                          this.state.quiz.welcome_message_color
                        ? this.state.quiz.welcome_message_color
                        : "",
                      "important"
                    );
                  }
                }}
                style={{ fontSize: 38, color: "black" }}
              >
                <b>{this.toTitleCase(personalitydetails.title)}</b> -{" "}
                {personalitydetails.occurence + "%"}
              </div>
            </div>
          );
        }
      );

      content = (
        <div
          className="col-md-10 message-warpper-modify"
          style={{ marginTop: 15 }}
        >
          <div className="col-md-6">
            <Pie
              ref={this.chartReference}
              data={this.state.chartdata}
              width={300}
              height={300}
            />
          </div>
          <div className="col-md-6">{content}</div>
        </div>
      );
    }

    if (this.state.quiz.quiztype == "qztype8") {
      backgroundImage = null;
      var categoryresult = JSON.parse(this.state.personalitydetails.categoryresult);
      var personalityArr = JSON.parse(this.state.personalitydetails.personalityArr);
      content = <div className="row">
                    <div className="col-md-6">
                      <Pie ref={this.chartReference} data={this.state.chartdata} width={300} height={300}/>
                    </div>
                    <div className="col-md-6">
                      <div className="qzwcmsg text-center" ref={(el4) => {
                          if (el4) { el4.style.setProperty("color", theme_color_code && theme_color_code[3] ? theme_color_code[3].header_content_text: "","important");
                          }}} style={{ fontSize: 38, color: "black" }}>

                        { Object.keys(categoryresult).filter(item=> item != 'totaloccurences').map((personality,index1) =>{
                        return <h4 key={index1}>
                        {personality.toUpperCase()} - {categoryresult[personality] + "%"} <br/>
                        {personalityArr.filter(item=>item.title == personality)[0].description}

                        </h4>})}
                      </div>
                    </div>

                  </div>
          
      
      

      content = (
        <div className="col-md-10 message-warpper-modify" style={{ marginTop: 15 }}>
          <div className="col-md-12">{content}</div>
        </div>
      );
    }

    if (this.state.quiz.quiztype == "qztype4") {
      backgroundImage = null;
      content = (
        <div
          className="col-md-10 message-warpper-modify"
          style={{ marginTop: 15 }}
        >
          <div
            ref={(el4) => {
              if (el4) {
                el4.style.setProperty(
                  theme_color_code &&
                    theme_color_code[3] &&
                    theme_color_code[3].header_content_text
                    ? theme_color_code[3].header_content_text
                    : this.state.quiz && this.state.quiz.welcome_message_color
                    ? this.state.quiz.welcome_message_color
                    : "",
                  "important"
                );
              }
            }}
            className="qzwcmsg text-center"
            style={{ fontSize: 16, color: "black" }}
          >
            <b>Here are the Poll results </b>
          </div>
          {this.renderPollResponse()}
        </div>
      );
    }

    return (
      <div className="m-t-xl panel-modify">
        <div
          className="panel-body a"
          id="quizcontent"
          style={{
            backgroundImage: backgroundImage,
            background: theme_color_code
              ? theme_color_code[2].header_content_bg
              : "",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: 40 + "%",
            backgroundPositionY: 40,
            minHeight: 500,
          }}
        >
          <div
            className={`startcontainer m-t-xl startcontainer-quize ${
              this.state.quiz != null &&
              (this.state.quiz.quiztype == "qztype2" ||
                this.state.quiz.quiztype == "qztype4" ||
                this.state.quiz.quiztype == "qztype7" || 
                this.state.quiz.quiztype == "qztype8" || this.state.quiz.quiztype == "qztype9")
                ? "col-md-12"
                : null
            }`}
            style={{ marginLeft: 10 + "%", marginRight: 12 + "%" }}
          >
            <div className="m-t-xl" style={{ marginTop: 100 }}>
              {this.state.quiz.quiztype == "qztype1" ? (
                <div className="col-md-2 badge-container">
                  <img
                    src="/assets/img/badge.png"
                    style={{ width: 200 + "%" }}
                  />
                  <div className="badge-text"></div>
                </div>
              ) : null}
              {content}
              {/*<div className="text-center m-t-sm qzwcmsg" >{this.state.quizscoremessage==null?"Sample Quiz Score Message":this.state.quizscoremessage ==""?"Sample Quiz Score Message":this.state.quizscoremessage} </div>
                    <br/>   
                    
                    <div className="m-t-sm qzwcmsg">{this.state.quizoffermsg==null || this.state.quizoffermsg==''?'Sample Offer Message':this.state.quizoffermsg} </div>
                    <a className="btn m-t-sm" href={this.state.quizofferbtnlink} style={{fontWeight:'bold'}} target='_blank'>{this.state.quizofferbtntxt==null || this.state.quizofferbtntxt==''?'Sample Offer Button Text':this.state  .quizofferbtntxt}</a>
                    <br/> <br />
                                      
                    <div className="text-left m-t-md qzwcmsg">{this.state.quizthankyoumessage==null?"Sample Quiz Thank You Message":this.state.quizthankyoumessage ==""?"Sample Quiz Thank You Message":this.state.quizthankyoumessage} </div>*/}
            </div>
          </div>

          <div className="row">
            <div className="m-t-xl col-md-12 mod" style={{ marginTop: 100 }}>
              <div
                className="m-t-sm qzwcmsg"
                ref={(el1) => {
                  if (el1) {
                    el1.style.setProperty(
                      "color",
                      theme_color_code &&
                        theme_color_code[3] &&
                        theme_color_code[3].header_content_text
                        ? theme_color_code[3].header_content_text
                        : this.state.quiz &&
                          this.state.quiz.welcome_message_color
                        ? this.state.quiz.welcome_message_color
                        : "",
                      "important"
                    );
                  }
                }}
              >
                {this.state.quizoffermsg == null ||
                this.state.quizoffermsg == ""
                  ? ""
                  : this.state.quizoffermsg}{" "}
              </div>
              <a
                className="btn m-t-sm cer-lastbtn"
                href={
                  this.state.quizofferbtnlink == null
                    ? "#"
                    : this.state.quizofferbtnlink
                }
                style={{ fontWeight: "bold" }}
                target="_blank"
                ref={(el1) => {
                  if (el1) {
                    el1.style.setProperty(
                      "background",
                      theme_color_code ? theme_color_code[6].button_color : "",
                      "important"
                    );
                    el1.style.setProperty(
                      "color",
                      theme_color_code &&
                        theme_color_code[7] &&
                        theme_color_code[7].button_color_text
                        ? theme_color_code[7].button_color_text
                        : this.state.quiz &&
                          this.state.quiz.welcome_message_color
                        ? this.state.quiz.welcome_message_color
                        : "",
                      "important"
                    );
                  }
                }}
              >
                {this.state.quizofferbtntxt == null ||
                this.state.quizofferbtntxt == ""
                  ? ""
                  : this.state.quizofferbtntxt}
              </a>
            </div>
            {this.state.quiz.is_enable_social_media ? (
              <div className="m-t-xl col-md-12" style={{ marginTop: 40 }}>
                <div
                  ref={(el4) => {
                    if (el4) {
                      el4.style.setProperty(
                        "color",
                        theme_color_code &&
                          theme_color_code[3] &&
                          theme_color_code[3].header_content_text
                          ? theme_color_code[3].header_content_text
                          : this.state.quiz &&
                            this.state.quiz.welcome_message_color
                          ? this.state.quiz.welcome_message_color
                          : "",
                        "important"
                      );
                    }
                  }}
                  className="qzwcmsg text-center"
                  style={{ fontSize: 16 }}
                >
                  {this.state.quiz && this.state.quiz.enable_social_sharing_text
                    ? this.state.quiz.enable_social_sharing_text
                    : "Share it with your family, friends and colleagues"}
                </div>

                <div className="col-md-12 m-t-md">
                  <div className="col-md-1 col-md-offset-3">
                    <FacebookShareButton url={shareUrl} quote={title}>
                      <FacebookIcon size={shareBtnSize} round />
                    </FacebookShareButton>
                  </div>
                  <div className="col-md-1">
                    <TwitterShareButton url={shareUrl} quote={title}>
                      <TwitterIcon size={shareBtnSize} round />
                    </TwitterShareButton>
                  </div>
                  <div className="col-md-1">
                    <LinkedinShareButton
                      url={shareUrl}
                      quote={title}
                      windowWidth={750}
                      windowHeight={600}
                    >
                      <LinkedinIcon size={shareBtnSize} round />
                    </LinkedinShareButton>
                  </div>
                  <div className="col-md-1">
                    <WhatsappShareButton
                      url={shareUrl}
                      quote={title}
                      seperator="::"
                    >
                      <WhatsappIcon size={shareBtnSize} round />
                    </WhatsappShareButton>
                  </div>
                  <div className="col-md-1">
                    <EmailShareButton
                      url={shareUrl}
                      subject={title}
                      body="email body"
                    >
                      <EmailIcon size={shareBtnSize} round />
                    </EmailShareButton>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="padding_remove-rate m-t-xl col-md-12">
                <button
                  className="btn btn-certificate"
                  onClick={this.restartQuiz}
                  ref={(el2) => {
                    if (el2) {
                      el2.style.setProperty(
                        "background",
                        theme_color_code
                          ? theme_color_code[6].button_color
                          : "",
                        "important"
                      );
                      el2.style.setProperty(
                        "color",
                        theme_color_code &&
                          theme_color_code[7] &&
                          theme_color_code[7].button_color_text
                          ? theme_color_code[7].button_color_text
                          : this.state.quiz &&
                            this.state.quiz.welcome_message_color
                          ? this.state.quiz.welcome_message_color
                          : "",
                        "important"
                      );
                    }
                  }}
                  style={{
                    marginRight: 25,
                    fontWeight: "bold",
                    outline: "none",
                  }}
                >
                  {this.state.quiz.retake_this_quiz_text
                    ? this.state.quiz.retake_this_quiz_text
                    : "Retake This Quiz"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPollResponse() {
    var content = null;
    var self = this;
    if (this.state.pollresponse != null) {
      var keys = Object.keys(this.state.pollresponse);
      var responsecounts = Object.values(this.state.pollresponse);
      var maxcount = responsecounts.reduce((a, b) => a + b, 0);
      content = keys.map(function (element, index) {
        return (
          <tr key={index}>
            <td style={{ textAlign: "center" }}>{element}</td>
            <td style={{ textAlign: "center" }}>
              <progress
                value={self.state.pollresponse[element]}
                max={maxcount}
              ></progress>
            </td>
            <td style={{ textAlign: "center" }}>
              {self.state.pollresponse[element]}
            </td>
          </tr>
        );
      });
    }
    return (
      <table>
        <tbody>{content}</tbody>
      </table>
    );
  }
  renderQuePage() {
    let theme_color_code = JSON.parse(this.state.theme_color);
    return (
      <div
        className="panel-body b"
        id="quizcontent"
        style={{
          background: theme_color_code
            ? theme_color_code[2].header_content_bg
            : "",
        }}
      >
        {/* <form id="startForm" action="#" method="get"> */}
        <div id="startForm">
          <div className="startcontainer">
            <label className="control-label row-label" id="lblque"></label>
            <div className="text-center">
              <div
                className="quetext"
                style={{
                  color:
                    theme_color_code && theme_color_code[3]
                      ? theme_color_code[3].header_content_text
                      : "",
                }}
              >
                <div
                  style={{
                    color: this.state.quiz.welcome_message_color || "",
                    cssText:
                      "color: " +
                      (this.state.quiz.welcome_message_color || "") +
                      " !important",
                  }}
                  className="question-render qzwcmsg ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      this.state.selectedQue == null
                        ? "Sample Que Title"
                        : this.state.selectedQue.title == ""
                        ? "Sample Que Title"
                        : this.state.selectedQue.title
                    ),
                  }}
                />
                {this.state.selectedQue.quetype == "MCMA" ? (
                  <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                    (Check up to {this.state.maxanscount})
                  </span>
                ) : (
                  <p></p>
                )}
              </div>
              <img
                id="question-img"
                className={
                  this.state.selectedQue.queimg == null ||
                  this.state.selectedQue.queimg == ""
                    ? "hide"
                    : ""
                }
                src={
                  this.state.selectedQue.queimg == null ||
                  this.state.selectedQue.queimg == ""
                    ? ""
                    : this.state.selectedQue.queimg
                }
              />
            </div>
            <div className="col-md-12">
              <div className="ans_wrapper">
                <div className="row">{this.renderAns()}</div>
              </div>
            </div>
            <div className="m-t-md col-md-offset-4 col-md-4 col-sm-offset-4 col-sm-4 col-xs-offset-4 col-xs-4"></div>
          </div>
        </div>
      </div>
    );

    //   );
  }

  renderErrorPage() {
    return (
      /*<div className="panel panel-blur animated slideIn">
                {this.renderHeader()}
                <div className="panel-body" id="quizcontent">
                    <div className="startcontainer m-t-xl">
                        <div style={{color:"white",fontSize:"large",wordWrap:"break-word"}} id="qzwcmsg">Something wrong!! we are not able to find the quiz.</div>
                    </div>
                        
                    {this.renderFooter()}
                </div>
            </div>*/
      <div className="panel-body" id="quizcontent">
        <div className="startcontainer m-t-xl">
          <div className="qzwcmsg">
            Something wrong!! we are not able to find the quiz.
          </div>
        </div>
      </div>
    );
  }

  renderStartPage() {
    let theme_color_code = JSON.parse(this.state.theme_color);
    // console.log("this.props",theme_color_code)

    document.title = this.state.quiz.quiztitle.replace(/<[^>]*>/g, "");
    //$('#metatitle').attr('content',this.state.quiz.quiztitle);
    //$('#metaimage').attr('content',this.state.basepath+this.state.quiz.backgroundimg);

    var quizurl =
      this.state.basepath + "quizplayer.html?quizid=" + this.state.quizid;
    //$('#metaurl').attr('content',quizurl);

    //updateFBOpenGraph(quizurl);
    return (
      //   <div className={`panel panel-blur animated slideIn ${this.state.quiz.theme}`}>

      //       {this.renderHeader()}
      //       <div className="panel-body" id="quizcontent">
      //           <form id="startForm" action="#" method="get" accept-charset="utf-8">
      //               <div className="startcontainer m-t-xl">
      //                   <label className="control-label row-label" id="lblque" for="input-success">
      //                   </label>
      //                   <div className="m-t-md" style={{color:"white",fontSize:"large",wordWrap:"break-word",lineHeight:1.35}} id="qzwcmsg">{this.state.quiz==null?"Enter Quiz Welcome Message":this.state.quiz.quizdesc ==""?"Enter Quiz Welcome Message":this.state.quiz.quizdesc} </div>
      //                   <button className="m-t-md btn col-md-offset-4 col-md-4" id="btnqzstart" onClick={this.onQuizStart} style={{width: 25+"%",fontWeight:'bold'}} type="button">Start</button>
      //               </div>
      //           </form>
      //           {this.renderFooter()}
      //       </div>
      //   </div>
      <div
        className="panel-body text-center c ql-editor"
        id="quizcontent"
        style={{
          background: theme_color_code
            ? theme_color_code[2].header_content_bg
            : "",
        }}
      >
        <div
          className="m-t-md qzwcmsg"
          ref={(el) => {
            if (el) {
              el.style.setProperty(
                "color",
                theme_color_code && theme_color_code[3]
                  ? theme_color_code[3].header_content_text
                  : "",
                "important"
              );
            }
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              this.state.quiz == null
                ? "Enter Quiz Welcome Message"
                : this.state.quiz.quizdesc == ""
                ? "Enter Quiz Welcome Message"
                : this.state.quiz.quizdesc
            ),
          }}
        ></div>

        <button
          className={`m-t-md btn ${isIframe}`}
          id="btnqzstart"
          onClick={this.onQuizStart}
          style={{ width: 175, fontWeight: "bold", outline: "none" }}
          type="button"
          ref={(el) => {
            if (el) {
              el.style.setProperty(
                "background",
                theme_color_code ? theme_color_code[6].button_color : "",
                "important"
              );
              el.style.setProperty(
                "color",
                theme_color_code &&
                  theme_color_code[7] &&
                  theme_color_code[7].button_color_text
                  ? theme_color_code[7].button_color_text
                  : this.state.quiz && this.state.quiz.welcome_message_color
                  ? this.state.quiz.welcome_message_color
                  : "",
                "important"
              );
            }
          }}
        >
          {this.state.quiz.btn_start_text
            ? this.state.quiz.btn_start_text
            : "Start"}
        </button>
      </div>
    );
  }

  renderLandingPage() {
    let theme_color_code = JSON.parse(this.state.theme_color);
    var { emailformtype, quizemailformcode } = this.state;
    let getPrivacyPolicy = this.state.quiz.privacy_policy
      ? JSON.parse(this.state.quiz.privacy_policy)
      : [];
    var content = (
      <div
        className="panel-body d"
        id="quizcontent"
        style={{
          background:
            theme_color_code && theme_color_code[2]
              ? theme_color_code[2].header_content_bg
              : "",
        }}
      >
        <div
          className="m-t-md qzwcmsg"
          style={{
            color: this.state.quiz.welcome_message_color || "",
            cssText:
              "color: " +
              (this.state.quiz.welcome_message_color || "") +
              " !important",
          }}
        >
          {this.state.quizemailmessage == null
            ? this.state.quiz.quizemailmsg
              ? this.state.quiz.quizemailmsg
              : "Enter Email Message"
            : this.state.quizemailmessage == ""
            ? this.state.quiz.quizemailmsg
              ? this.state.quiz.quizemailmsg
              : "Enter Email Message"
            : this.state.quizemailmessage}
        </div>
        <div className="row">
          <div className="col-md-6 align-center">
            <input
              name="player email"
              className="form-control required m-t-sm"
              type="text"
              placeholder={
                this.state.quiz.enter_your_name_text
                  ? this.state.quiz.enter_your_name_text
                  : "Enter Your Name"
              }
              onChange={this.onNameChanged}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 align-center">
            <input
              name="player email"
              className="form-control required m-t-sm"
              type="mail"
              placeholder={
                this.state.quiz.enter_your_email_text
                  ? this.state.quiz.enter_your_email_text
                  : "Enter Your Email"
              }
              onChange={this.onEmailChanged}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 align-center privacy-policy-wrapper-play">
            {this.state.quiz.privacy_policy_text ? (
              <div
                className="m-t-md qzwcmsg"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      theme_color_code && theme_color_code[3]
                        ? theme_color_code[3].header_content_text
                        : "",
                      "important"
                    );
                    el.style.setProperty("font-weight", "200", "important");
                    el.style.setProperty("text-align", "left");
                    el.style.setProperty("margin-left", "15px");
                  }
                }}
              >
                <span
                  ref={(el2) => {
                    if (el2) {
                      el2.style.setProperty(
                        "color",
                        theme_color_code && theme_color_code[3]
                          ? theme_color_code[3].header_content_text
                          : "",
                        "important"
                      );
                    }
                  }}
                  className="m-t-md qzwcmsg"
                  style={{ display: "inline" }}
                >
                  &#10132;{" "}
                </span>
                {this.state.quiz.privacy_policy_text
                  ? this.state.quiz.privacy_policy_text
                  : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="align-center">
            <button
              className="btn m-t-sm btnclick"
              onClick={this.onSubmitEmail}
              style={{ outline: "none" }}
              type="button"
              ref={(el) => {
                if (el) {
                  el.style.setProperty(
                    "background",
                    theme_color_code && theme_color_code[6]
                      ? theme_color_code[6].button_color
                      : "",
                    "important"
                  );
                  el.style.setProperty(
                    "color",
                    theme_color_code &&
                      theme_color_code[7] &&
                      theme_color_code[7].button_color_text
                      ? theme_color_code[7].button_color_text
                      : this.state.quiz && this.state.quiz.welcome_message_color
                      ? this.state.quiz.welcome_message_color
                      : "", // If none of the conditions are met, set an empty color value
                    "important"
                  );
                }
              }}
            >
              {this.state.quiz.continue_button_text
                ? this.state.quiz.continue_button_text
                : "Continue"}{" "}
              {this.state.showLoaderFlag == true ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </div>
    );
    if (emailformtype == "custom") {
      content = (
        <div>
          {quizemailformcode ? (
            <>
              <iframe
                name="invisibleframe"
                id="invisibleframe"
                style={{ display: "none" }}
              ></iframe>
              <div
                name="customemailform"
                id="customemailform"
                dangerouslySetInnerHTML={{ __html: quizemailformcode }}
              />
            </>
          ) : (
            <i style={{ fontSize: 48 }} className="fa fa-spinner fa-spin"></i>
          )}
        </div>
      );
    }
    return content;
  }

  render() {
    var self = this;
    if (this.state.loading) {
      return (
        <div
          className="col-md-12 text-center"
          style={{ height: 100 + "%", background: "white", color: "black" }}
        >
          <div className="loader"></div>
          <h1
            className="loader-text"
            style={{ color: (this.state.quiz && this.state.quiz.welcome_message_color) || "white",
            cssText:
              "color: " +
              ((this.state.quiz && this.state.quiz.welcome_message_color) || "") +
              " !important", position: "absolute", top: "250px" }}
          >
            {this.state.loaderMessage}
            <i className="fa fa-spinner fa-spin"></i>
          </h1>
        </div>
      );
    } else {
      var content = "";

      if (this.state.errorflag == true) {
        content = this.renderErrorPage();
      } else if (this.state.startflag == true) {
        content = this.renderStartPage();
      } else if (this.state.queflag == true) {
        content = this.renderQuePage();
      } else if (this.state.finishflag == true) {
        content = this.renderScorePage();
      } else if (this.state.landingflag == true) {
        content = this.renderLandingPage();
      }
      // console.log("this.state.quiz",this.state.quiz.theme_color)
      var themetype =
        this.state.quiz != null && this.state.quiz.theme_type != null
          ? "theme" + this.state.quiz.theme_type
          : "theme1";
      return (
        <div>
          {this.state.quiz.theme_type == 1 ||
          this.state.quiz.theme_type == 2 ||
          true ? (
            <div>
              {this.state.showLoaderFlag ? (
                <div>
                  <div className="loader"></div>
                </div>
              ) : (
                <div className="loader" style={{ display: "none" }}></div>
              )}
              <div>
                {this.state.showLoaderFlag ? (
                  <div
                    className="loaderMessage text-center"
                    style={{ zIndex: 99999 }}
                  >
                    {this.state.loaderMessage}
                  </div>
                ) : (
                  <div
                    className="loaderMessage"
                    style={{ display: "none" }}
                  ></div>
                )}
              </div>
              <div
                className="quiz_theme__center qzplayer"
                id={isIframe}
              >
                <div
                  className={`panel qzpanel ${
                    this.state.quiz == null || this.state.quiz.theme == null
                      ? null
                      : "modify" +
                        this.state.quiz.theme +
                        " " +
                        this.state.quiz.theme
                  }`}
                >
                  <QuizPlayerTheme
                    queflag={this.state.queflag}
                    startflag={this.state.startflag}
                    questionsAttr={this.state.queArr}
                    quescounter={this.state.quescounter}
                    bgimg={this.state.quiz.backgroundimg}
                    header_logo={this.state.quiz.complogo}
                    theme={this.state.quiz.theme}
                    enable_quiz_title={this.state.quiz.is_enable_quiz_title}
                    themetype={themetype}
                    mode={this.state.mode}
                    is_company_logo={this.state.quiz.is_company_logo}
                    quiz_title={
                      this.state.quiz.quiztitle == null ||
                      this.state.quiz.quiztitle == ""
                        ? "Enter Quiz Title"
                        : this.state.quiz.quiztitle
                    }
                    quiz_footer={
                      this.state.quiz != null &&
                      this.state.quiz.qzfootermsg != null &&
                      this.state.quiz.qzfootermsg != ""
                        ? this.state.quiz.qzfootermsg
                        : "Powered By Quizzes4Leads"
                    }
                    content={content}
                    theme_color_code={
                      this.state.quiz.theme_color != null ||
                      this.state.quiz.theme_color != "undefined"
                        ? this.state.quiz.theme_color
                        : ""
                    }
                  ></QuizPlayerTheme>
                </div>
              </div>
            </div>
          ) : this.state.quiz.theme_type == "" ? (
            <div>
              {this.state.showLoaderFlag ? (
                <div
                  style={{
                    width: 100 + "%",
                    height: 100 + "%",
                    background: "rgb(0,0,0,0.5)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 99998,
                  }}
                >
                  <div className="loader"></div>
                </div>
              ) : (
                <div className="loader" style={{ display: "none" }}></div>
              )}
              <div>
                {this.state.showLoaderFlag ? (
                  <div
                    className="loaderMessage text-center"
                    style={{ zIndex: 99999 }}
                  >
                    {this.state.loaderMessage}
                  </div>
                ) : (
                  <div
                    className="loaderMessage"
                    style={{ display: "none" }}
                  ></div>
                )}
              </div>
              <div
                className="col-md-offset-2 col-md-8 col-sm-12 col-xs-12 qzplayer"
                style={{ marginTop: 80 }}
              >
                <div
                  className={`panel qzpanel  dkfjkdjf ${
                    this.state.quiz == null || this.state.quiz.theme == null
                      ? null
                      : this.state.quiz.theme
                  }`}
                >
                  {this.state.finishflag ? null : this.renderHeader()}
                  {content}
                  {this.state.finishflag ? null : this.renderFooter()}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  }
}
