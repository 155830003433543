/**
 * This file contains the code for application settings like email notification and CRM settings
 */
import React from "react";
import swal from "sweetalert";
import { NotificationManager } from "react-notifications";
export class AnswerSheetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { answersheetid: null, answersheetdata: null };
    this.getAnswerSheetData = this.getAnswerSheetData.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params != null && this.props.match.params.id) {
      var answersheetid = this.props.match.params.id;
      this.setState({ answersheetid }, this.getAnswerSheetData);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ setting: nextProps.setting });
  }

  getAnswerSheetData() {
    var answersheetid = this.state.answersheetid;
    var queryurl = this.props.basepath + "quiz/getanswersheet/" + answersheetid;
    var self = this;

    fetch(queryurl, { method: "get", credentials: "include" })
      .then(function (data) {
        return data.json();
      })
      .then(function (response) {
        if (response && response.status == 200 && response.data) {
          var answersheetdata = response.data;
          self.setState({ answersheetdata });
        } else {
          NotificationManager.error("Server error: ", response.message);
        }
      })
      .catch((err) => {
        NotificationManager.error("Server error: ", err.message);
      });
  }

  renderAns(jsonans, jsonimg, selectedans, selectedimg) {
    var content = null;
    var ans = JSON.parse(jsonans);
    var img = JSON.parse(jsonimg);

    // image answer
    if (img != null && img.length > 0) {
      content = img.map((element, index) => {
        if (element) {
          var backgroundColor = "black";

          element = decodeURIComponent(element);
          backgroundColor =
            element.toLowerCase() == selectedans.toLowerCase()
              ? "green"
              : "black";
          var imgelement = <img src={element} style={{ width: "50%" }} />;
          return (
            <div style={{ marginLeft: 40 }}>
              {imgelement}
              <div
                className="ans-text"
                style={{ backgroundColor, color: "white" }}
              >
                {index + 1}. {ans[index]}
              </div>{" "}
              <br />
            </div>
          );
        } else {
          return null;
        }
      });
    }
    if (ans != null && ans.length > 0) {
      content = ans.map((element, index) => {
        if (element) {
          var backgroundColor = "black";
          element = decodeURIComponent(element);
          backgroundColor =
            element.toLowerCase() == selectedans.toLowerCase()
              ? "green"
              : "black";

          return (
            <div style={{ marginLeft: 40 }}>
              <div
                className="ans-text"
                style={{ backgroundColor, color: "white" }}
              >
                {index + 1}. {element}
              </div>{" "}
              <br />
            </div>
          );
        } else {
          return null;
        }
      });
    }
    return content;
  }

  renderQue() {
    var answersheet = this.state.answersheetdata.answersheet
      ? this.state.answersheetdata.answersheet
      : [];
    var content = null;

    if (Array.isArray(answersheet)) {
      content = answersheet.map((element, index) => (
        <div key={index}>
          <label style={{ fontSize: "16px" }}>{index + 1}. </label>
          <label dangerouslySetInnerHTML={{ __html: `${element.title}` }} />
          <br />
          {this.renderAns(
            element.jsonans,
            element.jsonansimg,
            element.selectedAns
          )}
          <br />
        </div>
      ));
    } else {
      content = <p className="text text-danger">Not supported</p>;
    }

    return <div>{content}</div>;
  }

  render() {
    var answersheetdata = this.state.answersheetdata;
    var content = "No data found.";
    if (answersheetdata != null) {
      if (answersheetdata.answersheet != null) {
        content = (
          <div className="" style={{ marginTop: 2 + "%" }}>
            <div className="col-md-12">
              <h2>Answer Sheet</h2>
              <h3>Player Details</h3>
              <div className="col-md-6">
                <label>Date:- {answersheetdata.playdate}</label> <br />
                <label>Result:- {answersheetdata.quizscore}</label>
                <br />
                <label>State:- {answersheetdata.state}</label>
                <br />
              </div>
              <div className="col-md-6">
                <label>Email:- {answersheetdata.email}</label>
                <br />
                <label>City:- {answersheetdata.city}</label>
                <br />
                <label>Country:- {answersheetdata.country}</label>
                <br />
              </div>{" "}
              <br /> <br />
              <h3 className="question_title">Question Details</h3>
              <br></br>
              <div className="col-md-12">
                <div className="question_block">{this.renderQue()}</div>
              </div>
            </div>
          </div>
        );
      } else {
        content = <p className="text text-info">No data found</p>;
      }
    }
    return content;
  }
}
