import React from "react";
import { NotificationManager } from "react-notifications";

export class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { message: null, basepath: this.props.basepath };
        this.onSubmitMessage = this.onSubmitMessage.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }
    onTextChange(event) {
        this.setState({ message: event.target.value });
    }
    onSubmitMessage() {
        var message = this.state.message;
        if (message == null || message == '') {
            NotificationManager.error("Error", "Message cannot be empty.");
            return false;
        }
        var queryurl = this.state.basepath + "contactus/send";
        var postdata = "message=" + message;

        fetch(queryurl, {
            method: "post",
            credentials: "include",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: postdata
        }).then(response => response.json())
        .then(response=> {
            if(response && response.status==200 && response.data){
                NotificationManager.success("Message Sent", "Message Sent Successfully!!!");
            }else{
                NotificationManager.error("Message Sent Error", response.message);
            }
        }).catch(err=>{
            NotificationManager.error("Server error: " , err.message)
        });
    }
    render() {
        return <div>
            <h4 className="row">
                Please use this form to send us a message to help you out.
            </h4>
            <div className="row">
                <form>
                    <textarea onChange={this.onTextChange} value={this.state.message} style={{ width: 50 + '%', height: 150 }} /> <br />
                    <button className="btn-normal" type="button" onClick={this.onSubmitMessage}>Submit</button>
                </form>
            </div>
        </div >
    }
}
