/*
This file contains the functionality for user login, user registration and forget password
*/

import React from "react";
import { NotificationManager } from "react-notifications";

export class Quizplayshort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getQuizId: "",
    };
    this.getQuizData = this.getQuizData.bind(this);
    this.redirectQuiz = this.redirectQuiz.bind(this);
  }
  componentDidMount() {
    this.getQuizData();
  }

  getQuizData() {
    var queryurl = this.props.basepath + "quizzer/get_quiz_short_link";
    var postdata = "short_link=" + this.props.match.params.id;
    var self = this;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      if(response && response.status==200 && response.data){
        self.setState({getQuizId: response.data.quizid});
        self.redirectQuiz(response.data.title);
      }else{
        NotificationManager.error("Server error: " + response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  redirectQuiz(quizTitle) {
    var basepath = this.props.localpath;
    var getQuizTitleSlug =  quizTitle.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
    window.location.href =
      basepath + "quizplayer.html?quiztitle="+getQuizTitleSlug+"&quizid=" + this.state.getQuizId;
  }

  render() {
    return <div></div>;
  }
}
