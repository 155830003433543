import React from "react";
import swal from "sweetalert";
import { NotificationManager } from 'react-notifications';
import { Link, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class FeedbackResponse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webResponseTitle:'',
      fresponseData:[]
    };
    this.DatatablePage = this.DatatablePage.bind(this);
    this.getFeedbackResponse();
  }
  componentDidMount(){
    if (this.props.location.state) {
      this.setState({
          webResponseTitle: this.props.location.state[1],
      })
  }
  }

  getFeedbackResponse() {
    // console.log("this",this.props.match.params.id)
    var dataurl = this.props.basepath + "feedbackr/rlist/" + this.props.match.params.id;
    var self = this;
    fetch(dataurl, {method: "get",credentials: "include"
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      var dataContent = null;
      var feedbackResponseData = [];
      if (response && response.status==200 && response.data && response.data.length > 0) {
        dataContent = response.data.map((element, index) => {
          var tempdate = new Date(element.created_at);
          var newdate = tempdate.getFullYear() + "-" + (tempdate.getMonth() + 1) + "-" + tempdate.getDay() + 1;
          
          feedbackResponseData[index] = {
            id:index + 1,
            feedback_type: element.feedback_type,
            feedback: element.feedback,
            email:element.email,
            created_at: newdate
          }
        })
      }else{
        NotificationManager.error("Server error: ",response.message);
      }
      self.setState({ dataContent,fresponseData: feedbackResponseData});
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  DatatablePage() {
    const tabledata = {
      columns: [
        {
          label: "No.",
          field: "id",
          sort: "asc"
        },
        {
          label: "Feedback Type",
          field: "feedback_type",
          sort: "asc"
        },
        {
          label: "Feedback Score",
          field: "feedback",
          sort: "asc"
        },
        {
          label: "Email",
          field: "email",
          sort: "asc"
        },
        {
          label: "Created on",
          field: "created_at",
          sort: "asc"
        }
      ],
      rows: this.state.fresponseData
    };

    return <MDBDataTable striped bordered hover data={tabledata} />;
  };

  render() {
    return (
      <div className="feedback_response" style={{ marginTop: 2 + "%" }}>
        <div className="row">
          <div className="col-md-9">
            <div class="table-title">Web Response: {this.state.webResponseTitle}</div>
          </div>
          <div className="col-md-3 export-feedback-response">
            <ExcelFile element={<button className="btn btn-primary">Export Data</button>}>
              <ExcelSheet data={this.state.fresponseData} name={this.state.webResponseTitle}>
                <ExcelColumn width="500" label="Feedback Type" value="feedback_type" />
                <ExcelColumn label="Feedback Score" value="feedback" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Date of feedback send" value="created_at" />
              </ExcelSheet>
            </ExcelFile>
          </div>
        </div>

        {this.DatatablePage()}
      </div>
    );
  }
}
export default FeedbackResponse;
