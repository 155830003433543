/**
 * This file contains the code for application settings like email notification and CRM settings
 */
import React from 'react';
export class Tutorials extends React.Component {
  constructor(props) {
    super(props);

  }

  componentWillReceiveProps(nextProps) {
    this.setState({ setting: nextProps.setting })
  }


  render() {
    return (<div className="" style={{ marginTop: 2 + '%' }}>
      <div className="col-md-12">
        <div className="qzpanel-default" style={{ 'width': '100%' }}>


          <div className="qzpanel-body" >
            <div className="row">
              <div className="col-md-6" id="col_block">
                <div className="table-title tutorial_block">How to Create Your First Quiz?</div>
                <video width="100%" className='height_block'  controls>
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1576569297/Tutorial/How%20to%20Create%20your%20First%20Quiz.mp4" type="video/mp4" />
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1576569297/Tutorial/How%20to%20Create%20your%20First%20Quiz.mp4" type="video/ogg" />
                </video>

              </div>
              <div className="col-md-6" id="col_block">
                <div className="table-title tutorial_block">How to Enable Email Notifications?</div>
                <video width="100%" className='height_block'  controls>
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1575797801/Tutorial/How%20to%20Enable%20Email%20Notifications.mp4" type="video/mp4" />
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1575797801/Tutorial/How%20to%20Enable%20Email%20Notifications.mp4" type="video/ogg" />
                </video>

              </div>
              <div className="col-md-6" id="col_block">
                <div className="table-title tutorial_block">How to Upload your Company Logo in Free Trial?</div>
                <video width="100%" className='height_block'  controls>
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1576568223/Tutorial/How%20to%20Upload%20your%20Company%20Logo%20in%20Free%20Trial.mp4" type="video/mp4" />
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1576568223/Tutorial/How%20to%20Upload%20your%20Company%20Logo%20in%20Free%20Trial.mp4" type="video/ogg" />
                </video>

              </div>

              <div className="col-md-6" id="col_block">
                <div className="table-title tutorial_block">How to Embed Quiz in your Website?</div>
                <video width="100%" className='height_block'  controls>
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1576569499/Tutorial/How%20to%20Embed%20Quiz%20in%20your%20Website.mp4" type="video/mp4" />
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1576569499/Tutorial/How%20to%20Embed%20Quiz%20in%20your%20Website.mp4" type="video/ogg" />
                </video>
              </div>
              <div className="col-md-6" id="col_block">
                <div className="table-title tutorial_block">Zapier Integration with Quizzes4Leads.</div>
                <video width="100%" className='height_block'  controls>
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1649082611/Tutorial/Zapier%20Integration%20with%20Quizzes4Leads.mp4" type="video/mp4" />
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1649082611/Tutorial/Zapier%20Integration%20with%20Quizzes4Leads.mp4" type="video/ogg" />
                </video>
              </div>

              <div className="col-md-6" id="col_block">
                <div className="table-title tutorial_block">Pabbly Integration with Quizzes4Leads.</div>
                <video width="100%" className='height_block'  controls>
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1649082820/Tutorial/Pabbly%20Integration%20with%20Quizzes4Leads.mp4" type="video/mp4" />
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1649082820/Tutorial/Pabbly%20Integration%20with%20Quizzes4Leads.mp4" type="video/ogg" />
                </video>
              </div>

              <div className="col-md-6" id="col_block">
                <div className="table-title tutorial_block">Vbout Integration - Quizzes4Leads.</div>
                <video width="100%" className='height_block'  controls>
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1649082962/Tutorial/Vbout%20Integration%20-%20Quizzes4Leads.mp4" type="video/mp4" />
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1649082962/Tutorial/Vbout%20Integration%20-%20Quizzes4Leads.mp4" type="video/ogg" />
                </video>
              </div>
              <div className="col-md-6" id="col_block">
                <div className="table-title tutorial_block">Mailerlite Integration with Quizzes4Leads.</div>
                <video width="100%" className='height_block'  controls>
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1649083087/Tutorial/Mailerlite%20Integration%20with%20Quizzes4Leads.mp4" type="video/mp4" />
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1649083087/Tutorial/Mailerlite%20Integration%20with%20Quizzes4Leads.mp4" type="video/ogg" />
                </video>
              </div>

              <div className="col-md-6" id="col_block">
                <div className="table-title tutorial_block">Birdsend Integration with Quizzes4Leads.</div>
                <video width="100%" className='height_block'  controls>
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1649083236/Tutorial/Birdsend%20Integration%20with%20Quizzes4Leads.mp4" type="video/mp4" />
                  <source src="https://res.cloudinary.com/quizzes4leads/video/upload/v1649083236/Tutorial/Birdsend%20Integration%20with%20Quizzes4Leads.mp4" type="video/ogg" />
                </video>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

    );
  }
}
