import React from "react";
import swal from "sweetalert";
import { NotificationManager } from "react-notifications";
import ColorPicker from "../picker/feedbackcolorpicker";

export class FeedbackEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emotion: false,
      star: false,
      thumb_up: false,
      scale: false,
      data: [],
      title: "",
      question: "",
      feeling_type: "",
      custom_theme_color: "",
      custom_color_checked: "",
      is_enable_email: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateFeedback = this.updateFeedback.bind(this);
    this.feedbackCustomColor = this.feedbackCustomColor.bind(this);
    this.customColorChecked = this.customColorChecked.bind(this);
    this.isEnableEmail = this.isEnableEmail.bind(this);
    this.getFeedback();
  }
  componentDidMount() {
    this.getFeedback();
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value == "emotion") {
      this.setState({
        emotion: true,
        star: false,
        thumb_up: false,
        scale: false,
      });
    } else if (event.target.value == "star") {
      this.setState({
        emotion: false,
        star: true,
        thumb_up: false,
        scale: false,
      });
    } else if (event.target.value == "thumb") {
      this.setState({
        emotion: false,
        star: false,
        thumb_up: true,
        scale: false,
      });
    } else if (event.target.value == "scale") {
      this.setState({
        emotion: false,
        star: false,
        thumb_up: false,
        scale: true,
      });
    }
  }
  customColorChecked(event) {
    if (event.target.checked == true) {
      this.setState({
        custom_color_checked: true,
      });
    } else {
      this.setState({
        custom_color_checked: false,
      });
    }
  }

  isEnableEmail(event) {
    if (event.target.checked == true) {
      this.setState({
        is_enable_email: true,
      });
    } else {
      this.setState({
        is_enable_email: false,
      });
    }
  }

  getFeedback() {
    let feedback_id = this.props.match.params.id;
    var dataurl = this.props.basepath + "feedback/edit/" + feedback_id;
    var self = this;
    fetch(dataurl, {
      method: "get",
      credentials: "include",
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      if(response && response.status==200 && response.data && response.data.length > 0){
        var custom_color_checked = false; 
        var star = false;
        var thumb_up = false;
        var scale=false;
        var emotion = false;

      if (response.data[0].custom_theme_color) {
        custom_color_checked = true;
      }
      if (response.data[0].feeling_type == "star") {
        star=true;
      } else if (response.data[0].feeling_type == "thumb") {
          thumb_up= true;
      } else if (response.data[0].feeling_type == "scale") {
          scale = true;        
      } else if (response.data[0].feeling_type == "emotion") {
          emotion= true
      }
        self.setState({
          title: response.data[0].title,
          question: response.data[0].question,
          feeling_type: response.data[0].feeling_type,
          custom_theme_color: response.data[0].custom_theme_color,
          is_enable_email: response.data[0].is_enable_email,
          custom_color_checked, star,thumb_up,scale,emotion
        });
      }
      else{
        NotificationManager.error("Server error: ",response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }
  feedbackCustomColor(color) {
    this.setState({
      custom_theme_color: color.hex,
    });
  }

  updateFeedback(event) {
    let feedback_id = this.props.match.params.id;
    this.setState({ [event.target.name]: event.target.value });
    var data = {};

    if (this.state.title == "" || this.state.title == null) {
      //swal("Required", "Title field is required.", "warning");
      NotificationManager.error(
        "Validation Error",
        "Title field cannot be null or empty."
      );
      return false;
    } else if (
      this.state.feeling_type == "" ||
      this.state.feeling_type == null
    ) {
      //swal("Required", "Please select feeling type.", "warning");
      NotificationManager.error(
        "Validation Error",
        "Feeling type cannot be null or empty."
      );
      return false;
    } else if (this.state.question == "" || this.state.question == null) {
      //swal("Required", "Question field is required.", "warning");
      NotificationManager.error(
        "Validation Error",
        "Question field cannot be null or empty."
      );
      return false;
    }

    data["title"] = this.state.title;
    data["feeling_type"] = this.state.feeling_type;
    data["question"] = this.state.question;
    data['is_enable_email'] = this.state.is_enable_email;
    data["custom_theme_color"] = this.state.custom_theme_color
      ? this.state.custom_theme_color
      : "";
    var jsondata = JSON.stringify(data);
    var postdata = "userid=" + this.props.user.userid + "&data=" + jsondata;

    var queryurl = this.props.basepath + "feedback/update/" + feedback_id;
    var self = this;
    fetch(queryurl, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: postdata,
    }).then(function (data) {
      return data.json();
    }).then(function (response) {
      if (response && response.data && response.status==200) {
        //swal("Updated", "Your feedback has been updated successfully.","success");
        NotificationManager.success("Feedback update","Feedback has been updated successfully.");
        self.props.history.push("/app/Feedback/share/" + feedback_id);
      } else {
        NotificationManager.Error("Feedback update",response.message);
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }
  
  render() {
    if (this.state.title == '') {
      return (
        <tr className="no-top-border">
          <td>Loading Data...</td>
        </tr>
      );
    }
    return (
      <div>
        <div className="">
          <div className="col-md-6 col-xs-12">
            <div className="qzpanel-default" style={{ width: "100%" }}>
              <div
                className="qzpanel-body"
                style={{ minHeight: "100%", height: "100%" }}
              >
                <div className="frmqzsettings">
                  <div className="form-group has-feedback">
                    <label className="Sign-In-Label">Feedback Title</label>
                    <input
                      name="title"
                      className="Sign-In-TextBox"
                      id="exampleqztitle"
                      onChange={this.handleChange}
                      type="text"
                      value={this.state.title}
                    />
                  </div>
                  <div className="form-group has-feedback">
                    <label className="Sign-In-Label">Feedback Feeling</label>
                    <br></br>
                    <select
                      class="custom-select form-control"
                      onChange={this.handleChange}
                      value={this.state.feeling_type}
                      name="feeling_type"
                    >
                      <option>Select Feeling</option>
                      <option value="emotion">Emotion</option>
                      <option value="star">Star</option>
                      <option value="thumb">Thumb Up</option>
                      <option value="scale">Scale</option>
                    </select>
                  </div>
                  <div
                    className={this.state.emotion == false ? "hidden" : "show"}
                  >
                    <span title="Angry">😠</span>
                    <span title="Worried">😟</span>
                    <span title="Neutral">😐</span>
                    <span title="Happy">🙂</span>
                    <span title="Delighted">😀</span>

                  </div>
                  <div className={this.state.star == false ? "hidden" : "show"}>
                    <span>⭐</span>
                    <span>⭐</span>
                    <span>⭐</span>
                    <span>⭐</span>
                    <span>⭐</span>
                  </div>
                  <div
                    className={this.state.thumb_up == false ? "hidden" : "show"}
                  >
                    <span>👍</span>
                    <span>👎</span>
                  </div>
                  <div
                    className={this.state.scale == false ? "hidden" : "show"}
                  >
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                  <div className="form-group has-feedback">
                    <label className="Sign-In-Label">Question</label>
                    <textarea
                      style={{ height: "100px" }}
                      value={this.state.question}
                      name="question"
                      onChange={this.handleChange}
                      className="Sign-In-TextBox"
                      id="exampleqztitle"
                    >
                      {this.state.question}
                    </textarea>
                  </div>

                  <label for="scales">Custom Color</label>
                  {this.state.custom_color_checked != "" ? (
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={
                          this.state.custom_color_checked ? true : false
                        }
                        onClick={this.customColorChecked}
                        id="custom_color"
                        name="custom_color"
                      />
                      <span className="slider round"></span>
                    </label>
                  ) : (
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={this.customColorChecked}
                        id="custom_color"
                        name="custom_color"
                      />
                      <span className="slider round"></span>
                    </label>
                  )}
                  <br></br>
                  {this.state.custom_color_checked == true ? (
                    <div className="custom_color-wrapper">
                      <ColorPicker
                        custom_theme_color={
                          this.state.custom_theme_color
                            ? this.state.custom_theme_color
                            : ""
                        }
                        onSelectChangeColor={this.feedbackCustomColor}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <br></br>
                  <label for="scales">Do you want to collect user emails before showing the result?</label>
                  <label className="switch">
                    <input
                      type="checkbox"
                      defaultChecked={this.state.is_enable_email == true ? true : false}
                      onClick={this.isEnableEmail}
                      name="is_enable_email"
                    />
                    <span className="slider round"></span>
                  </label>
                  <br></br>
                  <button
                    style={{ width: "27%" }}
                    onClick={this.updateFeedback}
                    className="btn-normal"
                  >
                    Update Feedback
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <h3 className="wr-p-title">Web Response Preview</h3>
            <div className="alert alert-success alert-dismissible feedback-preview">
              <span
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "color",
                      this.state.custom_theme_color ? this.state.custom_theme_color : '',
                      "important"
                    );
                  }
                }}
                className="pull-right fc"
              >
                X
              </span>
              <h4>
                {this.state.title
                  ? this.state.title
                  : "Your Experience is Important for Us!"}
              </h4>
              <p>
                {this.state.question
                  ? this.state.question
                  : "Do you find the information on this page useful?"}
              </p>
              <div className={this.state.emotion == false ? "hidden" : "show"}>
                <span title="Angry">😠</span>
                <span title="Worried">😟</span>
                <span title="Neutral">😐</span>
                <span title="Happy">🙂</span>
                <span title="Delighted">😀</span>
              </div>
              <div className={this.state.star == false ? "hidden" : "show"}>
                <span>⭐</span>
                <span>⭐</span>
                <span>⭐</span>
                <span>⭐</span>
                <span>⭐</span>
              </div>
              <div className={this.state.thumb_up == false ? "hidden" : "show"}>
                <span>👍</span>
                <span>👎</span>
              </div>
              <div className={this.state.scale == false ? "hidden" : "show"}>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
              </div>
              <button
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "background-color",
                      this.state.custom_theme_color ? this.state.custom_theme_color : '',
                      "important"
                    );
                  }
                }}
                className="btn btn-primary q4l-send-feedback"
              >
                Send
              </button>
              <a className="feed-link" href="https://www.quizzes4leads.com">
                Wanna try on your website?
              </a>
              <button
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      "background-color",
                      this.state.custom_theme_color ? this.state.custom_theme_color : '',
                      "important"
                    );
                  }
                }}
                className="btn btn-primary feedback-open"
              >
                Feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  }
}
export default FeedbackEdit;
