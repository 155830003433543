/**
 * This file contains the functionality to render quiz table
 */
import React from "react";
import swal from "sweetalert";
import { NotificationManager } from 'react-notifications';
import { Link} from "react-router-dom";
export class FeedbackLists extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableItems: { header: null, rowdata: null },
      tableTitle: "Feedback",
      dataLoadedFlag: false,
      actionFlag: false,
      user: this.props.user,
      menuID: null
    };
    this.feedbackDelete = this.feedbackDelete.bind(this);
    this.feedbackEdit = this.feedbackEdit.bind(this);
    this.previewFeedback = this.previewFeedback.bind(this);
    this.feedbackShare = this.feedbackShare.bind(this);
    this.feedbackResponse = this.feedbackResponse.bind(this);

    this.getFeedback();
  }

  componentDidMount() {
    this.getFeedback();
  }

  getFeedback() {
    var dataurl = this.props.basepath + "feedback/list";
    var self = this;
    fetch(dataurl, {
      method: "get",
      credentials: "include"
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      if(response && response.status==200 && response.data && response.data.length>0) {
        var tempArr = self.state.tableItems;
        tempArr.rowdata = [];
        response.data.map(function (item, index) {
          var arrItem = [
            item["id"],
            item["title"],
            item["date"],
            item["Action"]
          ];
          tempArr.rowdata[index] = [];
          tempArr.rowdata[index] = arrItem;
        }, self);

        tempArr.header = [];
        tempArr.header[0] = ["ID", "Title", "Date", " Status"];
        self.setState({ tableItems: tempArr, dataLoadedFlag: true });
      }
    }).catch(err=>{
      NotificationManager.error("Server error: " , err.message)
    });
  }

  feedbackDelete(event) {
    var quizid = event.target.getAttribute("data-id");
    var self = this;
    swal({
      title: "Delete Feedback",
      text: "Are you sure you want to delete this feedback?",
      type: "warning",
      buttons: { cancel: true, text: "Yes, Delete it!!", closeModal: false },
      icon: "warning"
    }).then(function (isConfirm) {
      if (isConfirm) {
        var jsonbody = JSON.stringify({ userid: null });
        var queryurl =self.props.basepath +"feedback/delete/" +quizid;
        fetch(queryurl, {
          method: "post",
          body: jsonbody,
          credentials: "include"
        }).then(function (response) {
          return response.json();
        }).then(function (response) {
          if (response && response.status==200 && response.data) {
            //swal("Feedback Delete", "Your feedback has been deleted.","success");
            NotificationManager.success("Feedback Delete", "Your feedback has been deleted.");
          } else {
            //swal("Feedback Delete", "Error in deleting feedback", "error");
            NotificationManager.error("Feedback Delete", "Error in deleting feedback");
          }
          self.props.history.push("/app/FeedbackLists");
        }).catch(err=>{
          NotificationManager.error("Server error: " , err.message)
        });
      }
    });
  }

  previewFeedback(event) {
    // var feedbackid = event.target.getAttribute('data-id');
    // this.props.history.push("/app/Feedback/preview/"+feedbackid);
    var quizid = event.target.getAttribute("data-id");
    var quizurl = this.props.localpath + "feedback/preview/" + quizid;
    window.open(quizurl);
  }

  feedbackEdit(event) {
    var feedbackid = event.target.getAttribute("data-id");
    this.props.history.push("/app/Feedback/Edit/" + feedbackid);
  }

  feedbackShare(event) {
    var feedbackid = event.target.getAttribute("data-id");
    this.props.history.push("/app/Feedback/share/" + feedbackid);
  }

  feedbackResponse(event) {
    var feedbackid = event.target.getAttribute("data-id");
    // get selected poll data
    const getSpecificResponse = this.state.tableItems.rowdata.find(data => data[0] == feedbackid);
    this.props.history.push({
      pathname: "/app/Feedback/response/" + feedbackid,
      state: getSpecificResponse
    });
  }

  renderHeaders() {
    if (
      this.state.tableItems == null ||
      this.state.tableItems.header == null ||
      this.state.tableItems.header.length == 0
    ) {
      return null;
    }

    return this.state.tableItems.header.map(function (headerItem, index) {
      return (
        <thead className="heading" key={index}>
          <button className="btn-normal instant-btn" id="btnquizcreate" style={{ width: "150px", display: "none" }}>
            <Link
              to="/app/Feedback"
              style={{ color: "white", textDecoration: "none", display: "none", width: "200px" }}
            >
              Web Response
            </Link>
          </button>
          <tr className="black-muted-bg">
            <th style={{width:'100px',textAlign:'center'}} className="">Id</th>
            <th className="thead_title">{headerItem[1]}</th>
            <th className="thead_title">Date</th>
            <th className="thead_title">Action</th>
          </tr>
        </thead>
      );
    }, this);
  }

  renderRows() {
    if (this.state.dataLoadedFlag == false) {
      return (
        <tr className="no-top-border">
          <td>Loading Data...</td>
        </tr>
      );
    }
    if (
      this.state.tableItems == null ||
      this.state.tableItems.rowdata == null ||
      this.state.tableItems.rowdata.length == 0
    ) {
      return (
        <tr className="no-top-border no-quiz-message">
          <td>You have not created any Instant Response yet.</td>
        </tr>
      );
    } else {
      return this.state.tableItems.rowdata.map(function (rowItem, index) {
        var title = rowItem[1];
        var contactTitle = title;
        var titles = contactTitle;
        contactTitle = titles + "..";

        var quizurl = "";

        return (
          <tr className="no-top-border" key={index}>
            <td style={{width:'100px'}} >{index + 1}</td>
            <td
              onClick={this.feedbackEdit}
              data-id={rowItem[0]}
              className="thead_td"
            >
              {contactTitle}
            </td>
            <td className="thead_td">{rowItem[2]}</td>


            <td className="thead_td">
              <div className="btn-group">
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.feedbackEdit}
                  title="Edit Feedback"
                  data-original-title="Edit"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-edit fa-x cursor"
                    data-id={rowItem[0]}
                  ></i>
                </span>
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.previewFeedback}
                  title="Preview Feedback"
                  data-original-title="Play"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-play fa-x cursor"
                    data-id={rowItem[0]}
                  ></i>
                </span>
                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  onClick={this.feedbackDelete}
                  title="Delete Feedback"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  data-original-title="Delete"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-trash fa-x cursor"
                    data-id={rowItem[0]}
                  ></i>
                </span>

                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.feedbackShare}
                  title="Share Feedback"
                  data-original-title="Share"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-share-alt fa-x cursor"
                    data-id={rowItem[0]}
                  ></i>
                </span>

                <span
                  type="button"
                  className="more-menu-btn"
                  role="menuitem"
                  style={{ marginLeft: 5, marginRight: 5, cursor: "pointer" }}
                  onClick={this.feedbackResponse}
                  title="View Response"
                  data-original-title="Share"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-id={rowItem[0]}
                >
                  <i
                    className="fa fa-eye-alt fa-x cursor"
                    data-id={rowItem[0]}
                  ></i>
                </span>
              </div>
            </td>
          </tr>
        );
      }, this);
    }
  }

  render() {
    return (
      <div style={{ marginTop: 2 + "%" }}>
        {this.state.tableItems != null &&
          this.state.tableItems.rowdata != null &&
          this.state.tableItems.rowdata.length > 0
          ? <div>
            <div className="col-md-12">
              <div className="table-title">
                Web Response
                <span className="table-subtitle">
                  {this.state.tableItems != null &&
                    this.state.tableItems.rowdata != null &&
                    this.state.tableItems.rowdata.length > 0
                    ? this.state.tableItems.rowdata.length + " Total"
                    : "0 records"}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="tableFixHead queiz-analysis">
                <table className="table table-striped">
                  {this.renderHeaders()}
                  <tbody>{this.renderRows()}</tbody>
                </table>
              </div>
            </div>
          </div>
          : <div className="row">
            <div className="web_responsive_block">
            <h4>You don't have any data currently, let's get started by creating your first web response <br /></h4>
            <button className="btn-normal" id="btnquizcreate" style={{ width: 200 }}><Link to="/app/Feedback" style={{ color: 'white', textDecoration: 'none' }}>Build Your Web Response</Link></button>
            </div>
            
          </div>}
      </div>
    );
  }
}
export default FeedbackLists;
